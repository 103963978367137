import React from 'react';
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import { DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  children: any;
  title?: string;
  yesButton?: string;
  yesButtonColor?: string;
  noButton?: string;
  noButtonColor?: string;
  onConfirm: Function;
  onCancel: Function;
};

const ConfirmDialog = ({ isOpen, children, title, yesButton, yesButtonColor, noButton, noButtonColor, onConfirm, onCancel }: Props) => {

  const { t } = useTranslation();

  if (!yesButtonColor) {
    yesButtonColor = '#f34943';
  }

  if (yesButtonColor === "primary") {
    yesButtonColor = '#33AD93'
  }

  if (yesButtonColor === "info") {
    yesButtonColor = '#35b8e0'
  }

  if (yesButtonColor === 'warning') {
    yesButtonColor = '#fbcc5c'
  }

  if (!noButtonColor) {
    noButtonColor = 'dark';
  }
  // yesButtonColor = 'primary' as const;
  return <Dialog
    open={isOpen}
    onClose={() => onCancel()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{title || t("warning")}</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button sx={{ color: `${yesButtonColor} !important` }} className='buttonAlwaysText' onClick={() => onConfirm()}>{yesButton || t("confirm")}</Button>
      <Button sx={{ color: "#414d5f !important" }} color="dark" className='buttonAlwaysText' onClick={() => onCancel()} autoFocus>{noButton || t("cancel")}</Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmDialog;