import React from 'react';

type Props = {
  children: any;
};

const PublicLayout = ({ children }: Props) => {
  return <React.Fragment>{children}</React.Fragment >
}

export default PublicLayout;
