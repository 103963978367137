import React, { useCallback, useEffect, useState } from "react";
import GridContainer from "component/common/GridContainer";
import Preloader from "component/common/Preloader";
import { createPaymentSetupIntent } from "helper/backend";
import { showError } from "helper/util";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PaymentForm from "../Form/PaymentForm";

type Props = {
  onFinish: Function;
};

const SectionCardEdit = ({ onFinish }: Props) => {

  const { t } = useTranslation();

  // saves the payment intent
  const [paymentIntent, setPaymentIntent] = useState<any>();
  // whether the load of the data is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Event handler called at first render to create a payment intent
  */
  const createPaymentIntent = useCallback(() => {
    setIsLoadInProgress(true)
    createPaymentSetupIntent()
      .then((response: any) => {
        setPaymentIntent(response.setupIntent)
      })
      .catch(_ex => {
        showError(t("unableToCreatePaymentIntent"));
      })
      .finally(() => {
        setIsLoadInProgress(false)
      });
  }, [t]);

  useEffect(() => {
    createPaymentIntent();
  }, [createPaymentIntent])

  const getStripeOptions = () => ({
    clientSecret: paymentIntent?.clientSecret,
  });

  return <React.Fragment>
    <GridContainer>
      <Grid xs={12}>
        {!!paymentIntent &&
          <PaymentForm options={getStripeOptions()} onFinish={onFinish} />
        }
      </Grid>
      {/* Show the prealoder only on the first fetch */}
      {isLoadInProgress && <Preloader container="parent" />}
    </GridContainer>
  </React.Fragment >
}

export default SectionCardEdit;