import React from "react";
import ReportsPicture1 from 'asset/image/ReportsPicture1.png';
import ReportsPicture2 from 'asset/image/ReportsPicture2.png';
import ReportsPicture3 from 'asset/image/ReportsPicture3.png';
import ReportsPicture4 from 'asset/image/ReportsPicture4.png';
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";

type Props = {
  isClient?: boolean
}

const ReportsStep = ({ isClient = false }: Props) => {

  const { t } = useTranslation();

  return (
    <GridContainer sx={{ overflow: "auto", maxHeight: "80vh" }}>
      <Grid xs={12}>
        <Box sx={{ fontSize: "22px", fontWeight: 600, marginTop: "12px" }}>
          {t("reports")}
        </Box>
      </Grid>
      {
        !isClient &&
        <>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "70%" }}>
              <img src={ReportsPicture1} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("reportsOrganizationText1")}
            </p>
          </Grid>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "70%" }}>
              <img src={ReportsPicture2} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("reportsOrganizationText2")}
            </p>
          </Grid>
        </>
      }
      {
        isClient &&
        <>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={ReportsPicture4} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("reportsClientText1")}
            </p>
          </Grid>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={ReportsPicture3} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("reportsClientText2")}
            </p>
          </Grid>
        </>
      }
    </GridContainer>
  )
};

export default ReportsStep;