import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { Box, Button, IconButton, Link, Menu, MenuItem, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft, MoreVert } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { routes } from "helper/route";
import { AppError } from "@type";
import { deleteUser, getUser, getVerificationLink, impersonateUser, sendVerificationLink } from "helper/backend";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess, toSimpleError } from "helper/util";
import Error from 'page/Error';
import { useTranslation } from "react-i18next";
import User from "model/user";
import SectionInfo from "./Partial/SectionInfo";
import Mdi from "component/common/Mdi";
import { saveAccessToken, saveRefreshToken } from "helper/storage";
import AccessRole from "model/accessRole";
import { useAuth } from "context/auth";
import { mdiAccountArrowLeft } from '@mdi/js';

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the driver record fetched from the backend
  const [driver, setDriver] = useState<User | undefined>()
  // error encoutered while fetching the driver (if any)
  const [driverError, setDriverError] = useState<AppError | undefined>();
  // whether the loading of the driver is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the driver is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  // whether the impersonation of the user is in progress (being requested from the server)
  const [isImpersInProgress, setIsImpersInProgress] = useState(false);
  // menu anchor element
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement | null>(null);
  // whether the send verification link confirmation is visible
  const [isSendVerifConfOpen, setIsSendVerifConfOpen] = useState(false);
  // whether the verification link sending is in progress
  const [isSendVerifInProgress, setIsSendVerifInProgress] = useState(false);
  // the email verification link
  const [emailVerifLink, setEmailVerifLink] = useState<string | null>(null);


  /**
  * Fetches the driver record from the backend
  */
  const refreshDriver = useCallback(() => {
    setIsLoadInProgress(true)
    getUser(recordId)
      .then(response => {
        setDriver(response.user);
      })
      .catch(ex => {
        setDriverError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId]);

  /**
  * Deletes the driver record in the backend
  */
  const removeDriver = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteUser(recordId)
      .then(_response => {
        showSuccess(t("driverHasBeenDeleted"));
        return navigate(routes.list_drivers);
      })
      .catch(_ex => {
        let errMessage = t("unableToDeleteDriver");
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshDriver();
  }, [refreshDriver]);

  /**
 * Impersonate the user
 */
  const masqueradeUser = useCallback(() => {
    setIsImpersInProgress(true);
    impersonateUser(recordId)
      .then(response => {
        saveAccessToken(response.access.token);
        saveRefreshToken(response.refresh.token);
        // redirect to homepage using native redirect
        // this will re-check and refresh the authenticated user
        window.location.replace(routes.home);
      })
      .catch(_ex => {
        showError(t("unableToImpersonateUser"));
      })
      .finally(() => {
        setIsImpersInProgress(false);
      });
  }, [recordId, t]);

  const confirmSendVerificationLink = () => {
    setIsSendVerifConfOpen(true);
    toggleMenu({} as MouseEvent<HTMLButtonElement>);
  }

  const doSendVerificationLink = () => {
    setIsSendVerifInProgress(true);
    sendVerificationLink(recordId)
      .then(_response => {
        showSuccess(t("verificationLinkSent"));
      })
      .catch(_ex => {
        showError(t("unableToSendVerificationLink"));
      })
      .finally(() => {
        setIsSendVerifInProgress(false);
      });
  }

  const doGetVerificationLink = useCallback(() => {
    getVerificationLink(recordId)
      .then(response => {
        setEmailVerifLink(response.url);
      })
      .catch(_ex => {
        showError(t("unableToFetchVerificationLink"));
      });
  }, [recordId, t]);

  useEffect(() => {
    // make sure the user is loaded
    if (!!driver && !driver.isEmailVerified) {
      doGetVerificationLink();
    }
  }, [doGetVerificationLink, driver]);

  const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElem(prevElem => !!prevElem ? null : e.currentTarget);
  }

  const copyVerificationLink = () => {
    if (!emailVerifLink) {
      return;
    }
    navigator.clipboard.writeText(emailVerifLink);
    showSuccess(t("verificationLinkCopied"));
    toggleMenu({} as MouseEvent<HTMLButtonElement>);
  }

  /**
  * Checking the permission to impersonate the user
  */
  const checkImpersonatePermission = () => {
    let canImpersonate = false;

    if (driver?.id !== authUser.id) {
      canImpersonate = true;
    };
    if (driver?.accessRoleId === AccessRole.ID_MASTER_ADMIN && authUser.accessRoleId === AccessRole.ID_MASTER_ADMIN) {
      canImpersonate = false;
    };

    return canImpersonate;
  }


  return (
    <>
      {isGranted(perms.view_drivers)
        &&
        <>
          {
            !!driver
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(driver, t)}>
                {isGranted(perms.edit_users) && !driver.isEmailVerified && <IconButton sx={{ mr: 1 }} onClick={toggleMenu}><MoreVert /></IconButton>}
                <Menu
                  sx={{ mt: '36px' }}
                  anchorEl={menuAnchorElem}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={!!menuAnchorElem}
                  onClose={toggleMenu}
                >
                  <MenuItem disabled={isSendVerifInProgress} onClick={confirmSendVerificationLink}>
                    <Typography textAlign="center">{t("resendVerificationLink")}</Typography>
                  </MenuItem>
                  {!!emailVerifLink && <MenuItem onClick={copyVerificationLink}>
                    <Typography textAlign="center">{t("copyVerificationLink")}</Typography>
                  </MenuItem>}
                </Menu>
                {isGranted(perms.impersonate_users, driver) && checkImpersonatePermission() && <ProgressButton variant="contained" color="purple" sx={{ mr: 1 }} startIcon={<Mdi path={mdiAccountArrowLeft} />} isBusy={isImpersInProgress} onClick={masqueradeUser} className='buttonAlwaysText'>{t("impersonateUser")}</ProgressButton>}

                {isGranted(perms.delete_drivers) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteDriver")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_drivers} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo driver={driver} refreshHandler={refreshDriver} isLoadInProgress={isLoadInProgress} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeDriver();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteDriverConfirmation")} &quot;{driver.fullName}&quot;?</ConfirmDialog>
              <ConfirmDialog
                isOpen={isSendVerifConfOpen}
                yesButton={t("send")}
                onConfirm={() => {
                  setIsSendVerifConfOpen(false);
                  doSendVerificationLink();
                }}
                onCancel={() => {
                  setIsSendVerifConfOpen(false);
                }}
              >{t("resendVerificationLinkConfirmation")} &quot;{driver.email}&quot;?</ConfirmDialog>

            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !driver && <Preloader container="content" />}
          {!!driverError && <Error error={driverError} title404={t("driverNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_drivers) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (driver: User, t: Function) => [{
  text: t("drivers"),
  url: routes.list_drivers,
}, {
  text: driver.fullName!,
}];

export default Single;