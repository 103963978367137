import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  handleChange: Function;
  handleSelectedOption: Function;
  options: any;
  isLoadInProgress: boolean;
  setOptionSelected: Function;
  toggleDropPin: Function;
}

const GeoSearchAutocomplete = ({ handleChange, options, isLoadInProgress, handleSelectedOption, setOptionSelected, toggleDropPin }: Props) => {

  const { t } = useTranslation();

  return (
    <Autocomplete
      onInputChange={(e, _v, reason) => {
        return reason === "input" && handleChange(e)
      }}
      onChange={(_e, selectedOption) => {
        setOptionSelected(selectedOption);
        toggleDropPin();
      }}
      value={handleSelectedOption()}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      filterOptions={options => options}
      options={options}
      renderOption={(props: any, option: any) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
      disableClearable
      isOptionEqualToValue={(option, value) => option?.label === value?.label}
      loading={isLoadInProgress}
      renderInput={(params: any) =>
        <TextField
          {...params}
          label={t("searchAddress")}
          InputProps={{
            ...params.InputProps,
            autoComplete: "autocomplete-adress",
            endAdornment: (
              <>
                {isLoadInProgress && <CircularProgress color="primary" size={20} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />}
    />
  )
}

export default GeoSearchAutocomplete;