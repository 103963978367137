
export default class Article {
  id!: number;
  name!: string;
  code!: string;
  items!: number;
  rentPrice!: number;
  purchasePrice!: number;
  penaltyCost!: number;
  packageItemCount!: number;
  distributionMode!: number;
  toBeReturned!: number;

  //ENTITY TYPE AND BUSINESS MODEL
  static DISTRIBUTION_MODE_SALE = 1;
  static DISTRIBUTION_MODE_RENT = 2;

  static DistributionModes = [
    {
      id: this.DISTRIBUTION_MODE_SALE,
      name: "Sale"
    },
    {
      id: this.DISTRIBUTION_MODE_RENT,
      name: "Rent"
    }
  ];

  static getDistributionModeName(id: number) {
    return this.DistributionModes.find((distrMode) => distrMode.id === id)?.name
  }

}