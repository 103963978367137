import React, { useEffect, useMemo, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { PinIcon } from "./PinIcon";
import { LatLng } from "leaflet";
import { Box } from "@mui/material";

type Props = {
  setLatLng?: Function;
  locationCoord?: any | undefined;
  defaultLocationCoord?: LatLng | undefined;
  isDraggable: boolean;
  refreshSelectedOption?: Function;
  locationInfo?: string;
  setIsReversed?: Function;
}

const MapPin = ({ setLatLng, locationCoord, isDraggable, locationInfo, defaultLocationCoord, refreshSelectedOption, setIsReversed }: Props) => {

  const map = useMap()
  const pinRef = useRef(null)

  // State where position (lat,lng) of the DraggablePin is
  const [position, setPosition] = useState<any>(map.getCenter())

  // Event Handlers for dragging the DraggablePin
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        if (setIsReversed) {
          setIsReversed(true)
        }
        const pin: any = pinRef.current
        if (pin != null) {
          const latLng = pin.getLatLng()
          setPosition(latLng)
          if (refreshSelectedOption) {
            refreshSelectedOption(undefined)
          }
          if (setLatLng) {
            setLatLng("latitude", latLng.lat)
            setLatLng("longitude", latLng.lng)
          }
        }
      },
    }), [setLatLng, refreshSelectedOption, setIsReversed])

  //Setting the map and position if the search autocomplete was used
  useEffect(() => {
    if (!!locationCoord.latitude && !!locationCoord?.longitude) {
      setPosition(new LatLng(locationCoord.latitude, locationCoord.longitude))
      map.setView(new LatLng(locationCoord.latitude, locationCoord.longitude), 20)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCoord, map])

  //Setting the default position on map when using this on Edit Mode
  useEffect(() => {
    if (!!defaultLocationCoord?.lat && !!defaultLocationCoord?.lng && defaultLocationCoord) {
      setPosition(defaultLocationCoord!)
      map.setView(defaultLocationCoord!, 20)
    } else {
      map.setView(new LatLng(locationCoord.latitude, locationCoord.longitude), 20)
    }

    // Save initial position
    if (setLatLng) {
      setLatLng("latitude", position.lat)
      setLatLng("longitude", position.lng)
    }

    // this needs to run on first render if this component is used on Edit Mode, it should not load everytime
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Marker
      draggable={isDraggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={pinRef}
      icon={PinIcon}
    >
      {!!locationInfo &&
        <Popup minWidth={90} closeButton={false} offset={[4, 4]}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {locationInfo}
          </Box>
        </Popup>
      }
    </Marker>
  )
}

export default MapPin;