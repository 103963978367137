import { closeSocket, openSocket } from "helper/socket";
import React, { useEffect, useRef, useState } from "react";
import Preloader from "component/common/Preloader";
import { ACC_TOKEN_JWT_EXPIRED, ACC_TOKEN_MISSING } from "helper/error";
import { refreshAccessToken } from "helper/api";
import { getAccessToken } from "helper/storage";

const SocketContext = React.createContext<any>({ socket: null });

const SocketProvider = (props: any) => {

  // we use a ref to store the socket client as it won't be updated frequently
  const socket: any = useRef(null);
  if (socket.current === null) {
    socket.current = openSocket(null, {
      // read the auth token from session storage
      // and send it with the connection request
      auth: { token: `${getAccessToken()}` },
      reconnectionAttempts: 5
    })
  }

  // store the socket id in state
  // this will be updated every time the socket connects
  // we do not actually need the value
  // but we need a way to re-render the descendant components when the socket disconnects and reconnects
  // allowing them to re-subscribe to events with the new socket connection
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [socketId, setSocketId] = useState(socket.current.id);
  // store the connection error in state
  // we do not actually need the value
  // but we need a way to wait untill the socket either connects or fails to connect
  const [connErr, setConnErr] = useState<boolean>(false);

  // handler that helps emit an event
  const socketEmit = (event: any, data: any) => socket.current.emit(event, data);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('connect', () => {
        console.log('Socket connected. Id', socket.current.id);
        setSocketId(socket.current.id);
      });
      socket.current.on('connect_error', (err: any) => {
        console.error('Socket connection error:', err.data || err);
        // if this is the first connection attempt then, because it failed, there is no socketId
        // so we need another way to let the execution continue
        if (!socketId) {
          setConnErr(true);
        }
        // check if the client is unable to connect because the access token has expired
        // maybe because the app has been idle for a while
        if (shouldRefreshToken(err)) {
          // refresh the access token
          refreshAccessToken()
            .then(() => {
              console.log('Socket access token refreshed');
              socket.current.auth.token = getAccessToken()
              // in this case the socket will not reconnect automatically
              socket.current.connect();
            })
            .catch(ex => console.error('Socket refresh access token failed', ex));
        }
      });
      socket.current.on('error', (err: any) => {
        console.error('Socket error:', err);
      });
      socket.current.on('disconnect', (reason: any) => {
        console.error('Socket disconnected:', reason);
      });
      socket.current.io.on('reconnect_attempt', () => {
        console.log('Socket attempting to reconnect');
      });
      socket.current.io.on('reconnect', () => {
        console.log('Socket reconnected');
      });
    }
    // Remove all the listeners and close the socket upon unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => closeSocket(socket.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!!socketId || connErr) {
    return <SocketContext.Provider value={{
      socket: socket.current,
      socketEmit: socketEmit,
    }} {...props} />
  }
  return <Preloader />
}

const shouldRefreshToken = (error: any) => !!error.data && [ACC_TOKEN_MISSING, ACC_TOKEN_JWT_EXPIRED].includes(error.data.code);

export const useSocket = () => React.useContext(SocketContext);

export default SocketProvider;