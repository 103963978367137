import React, { useEffect, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import VerticalMenu from './VerticalMenu';
import logo from 'asset/image/logo.png';
import logoSmall from 'asset/image/logo-sm.png';
import Link from '@mui/material/Link';
import { routes } from 'helper/route';
import { useAppSelector } from 'hook/redux';
import env from 'env';
import { useAuth } from 'context/auth';
import { Box } from '@mui/material';

const openedMixin = (theme: Theme): CSSObject => ({
  width: env.SIDEBAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'relative',

}));

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  zIndex: 20,
  padding: "18px 0px",
  height: "100px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: env.SIDEBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type Props = {
  sx: any;
};

const SideBar = ({ sx }: Props) => {

  const { menuIsCollapsed } = useAppSelector(state => state.Layout.View);
  const { authUser } = useAuth();
  const [logoImg, setLogoImg] = useState("")

  const setLogo = () => {
    if (menuIsCollapsed) {
      if (authUser.hasCustomLogo) {
        setLogoImg("");
      } else {
        setLogoImg(logoSmall);
      }
    } else {
      if (authUser.hasCustomLogo) {
        setLogoImg(`${process.env.REACT_APP_API_URL}/static/logo/${authUser.organizationId}/logo.png`)
      } else {
        setLogoImg(logo)
      }
    }
  }

  useEffect(() => {
    setLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsCollapsed])

  return <Drawer variant="permanent" open={!menuIsCollapsed} sx={sx}>
    <DrawerHeader>
      <Box sx={{ height: "90px", width: "100%", margin: "16px", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", minHeight: "90px", }}>
        <Link href={routes.home} sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
          <img src={logoImg} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Link>
      </Box>
    </DrawerHeader>
    <Box sx={{ zIndex: 21, overflow: "auto" }}>
      <VerticalMenu isCollapsed={menuIsCollapsed} />
    </Box>
    {!menuIsCollapsed && !!authUser.hasCustomLogo &&
      <DrawerFooter sx={{ padding: "0px", height: "90px" }}>
        <Box sx={{ height: "50px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", position: "absolute", bottom: "18px" }}>
          <Link href={routes.home} sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
            <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </Link>
        </Box>
      </DrawerFooter>
    }
  </Drawer>
}

export default SideBar;