import React, { useState, FocusEvent, useCallback, useEffect } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from 'component/common/CardTitleBar';
import { getOrganizationList, getLocationListByOrganization } from 'helper/backend';
import Organization from 'model/organization';
import Location from 'model/location';
import { useTranslation } from 'react-i18next';
import MuiAutocomplete from 'component/common/MuiAutocomplete';

type Props = {
  formik: any;
}

const FormCreateStandaloneDevice = ({ formik }: Props) => {

  const { setFieldError, values, handleChange, errors, setStatus, setFieldValue } = formik;

  const { t } = useTranslation();

  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  // list of locations that populate the select field
  const [locations, setLocations] = useState<Location[]>([]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  /**
  * Fetches from the backend the list of locations based on organizationId
  */
  const fetchLocations = useCallback(() => {
    getLocationListByOrganization(values.organizationId!)
      .then(response => {
        setLocations(response.locations);
      })
      .catch(_ex => {
        setFieldError('locationId', t("unableToLoadLocations"));
      });
  }, [setFieldError, values.organizationId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchOrganizations()
    // Fetch only if organization is selected
    if (!!values.organizationId) {
      fetchLocations()
    }
  }, [fetchOrganizations, fetchLocations, values.organizationId])

  /**
  * Converts the list of organizations to select options
  */
  const getOrganizationOptions = () => organizations.map(organization => ({ label: organization.companyName, value: organization.id }));

  /**
  * Returns the selected organization as a select option
  */
  const getSelectedOrganizationOption = () => getOrganizationOptions().find(option => option.value === values.organizationId) || null;

  /**
  * Converts the list of locations to select options
  */
  const getLocationsOptions = () => locations.map(location => ({ label: location.name, value: location.id, type: Location.getTypeName(location.type) }));

  /**
  * Returns the selected location as a select option
  */
  const getSelectedLocationOption = () => getLocationsOptions().find(option => option.value === values.locationId) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <Grid container spacing={2}>
    <Grid xs={12} md={6}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <CardTitleBar sx={{ mb: 4 }} title={t("generalInfo")} />
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <MuiAutocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('organizationId', selectedOption?.value);
                  setFieldValue('locationId', undefined);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('organizationId');
                }}
                value={getSelectedOrganizationOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getOrganizationOptions()}
                renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
            {
              !!values.organizationId &&
              <Grid xs={12} md={6}>
                <MuiAutocomplete
                  onChange={(_e, selectedOption) => {
                    setFieldValue('locationId', selectedOption?.value);
                  }}
                  onFocus={_e => {
                    onSelectFieldFocused('locationId');
                  }}
                  groupBy={(option) => option.type!}
                  value={getSelectedLocationOption()}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={getLocationsOptions().sort((a, b) => a.type! > b.type! ? -1 : 1)}
                  renderInput={(params: any) => <TextField {...params} label={t("location")} error={!!errors.locationId} helperText={errors.locationId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
                />
              </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
};

export default FormCreateStandaloneDevice;