import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";
import { getBusinessModels } from "helper/backend";
import { showError } from "helper/util";
import Preloader from "component/common/Preloader";
import Client from "model/client";
import { StopCircle } from "@mui/icons-material";

const BusinessModels = () => {

  const { t } = useTranslation();

  const [businessModels, setBusinessModels] = useState<(string | number | undefined)[][]>();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchBusinessModels = useCallback(() => {
    setIsLoadInProgress(true)
    getBusinessModels()
      .then(response => {
        let models = response.businessModels.map((el) => { return [Client.getBusinessModelName(el.businessModel), el.clientsCount] })
        models.unshift(["Clients", "Number of clients"])

        setBusinessModels(models);
      })
      .catch(_ex => {
        showError(t("unableToLoadBusinessModels"));
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchBusinessModels();
  }, [fetchBusinessModels])

  return (
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("businessModels")} />
        <Box sx={{ display: "flex", height: "270px", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          {
            !!businessModels?.length &&
            <>
              <Chart
                chartType="PieChart"
                data={businessModels}
                width={"70%"}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-10px" }}
                height={"200px"}
                onLoad={() => { setIsLoadInProgress(false) }}
                options={
                  {
                    chartArea: {
                      height: '90%',
                      width: '90%',
                    },
                    colors: ["#02A8B5", "#FA5C7C", "#33AD93"],
                    legend: "none",
                    pieHole: 0.7,
                    pieSliceText: "none",
                    tooltip: {
                      trigger: 'none'
                    }
                  }
                }
              />
              <Box sx={{ fontSize: "16px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", width: "100%", gap: "10px", zIndex: 1000, padding: "0px 35px 0px 30px" }}>
                {
                  businessModels?.filter(el => el[0] !== "Clients").sort((a, b) => { return +b[1]! - +a[1]! }).map((el, idx) => {
                    return (
                      <Box key={idx} sx={{ display: "flex", justifyContent: "space-between", gap: "40px", width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                          <StopCircle sx={{ color: el[0] === "SSD" ? "#02A8B5" : el[0] === "Non SSD" ? "#FA5C7C" : "#33AD93", fontSize: "16px" }} /> {el[0]}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                          {el[1]}
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </>
          }
        </Box>
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default BusinessModels;