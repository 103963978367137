import React from "react";
import CardTitleBar from "component/common/CardTitleBar";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import UploadFileApp from "component/common/UploadFileApp";

const UploadApp = ({ formik }: any) => {

  const { t } = useTranslation();

  const { setFieldValue, errors, setStatus } = formik;

  return (
    <>
      <CardTitleBar title={t("uploadApp")} sx={{ mb: 4 }} />
      <GridContainer spacing={2}>
        <Grid xs={12}>
          <UploadFileApp setFieldValue={setFieldValue} errors={errors} setStatus={setStatus} />
        </Grid>
      </GridContainer>
    </>
  )
}

export default UploadApp;