import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { AddLocationAlt, Delete } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useDebounce } from "hook/Debounce";
import GeoSearchAutocomplete from "component/map/GeoSearchAutocomplete";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import GeoSearch from "component/map/GeoSearch";
import Maps from "component/map/Map";
import MapPin from "component/map/MapPin";
import { MAP_CENTER_BRASOV } from "constant/map";
import { useTranslation } from "react-i18next";

export type LatLngT = {
  lat: number;
  lng: number;
}

type Props = {
  setFieldValue: Function;
  values: any;
}

const NewDuMap = ({ setFieldValue, values }: Props) => {

  const { t } = useTranslation();

  // this shows/hides the Draggable Drop Pin
  const [dropPinVisible, setDropPinVisible] = useState(false)
  // holds the value of the search address input
  const [addressValue, setAddressValue] = useState("");
  // holds the options of the autocomplete
  const [addressOptions, setAddressOptions] = useState([]);
  // holds the selected option
  const [addressSelected, setAddressSelected] = useState<any>();
  // whether the loading of the search is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // keeps the reverse search option
  const [isReversed, setIsReversed] = useState(false);

  //Debounce the value of the search
  const debouncedAddress = useDebounce(addressValue, 500) as string;

  /**
  * Toggles the dropped Pin
  */
  const toggleDropPin = () => {
    setIsReversed(true);
    setDropPinVisible(true)
  }

  /**
  * Clear Lat/Lng Values
  */
  const clearCoords = () => {
    setDropPinVisible(false)
    setIsReversed(false)
    setFieldValue("latitude", null)
    setFieldValue("longitude", null)
    setAddressSelected(undefined)
    setAddressOptions([])
  }

  /**
  * Handle search bar change
  */
  const handleChange = (e: React.ChangeEvent<any>) => {
    setIsReversed(false);
    setAddressValue(e.target.value);
    setIsLoadInProgress(true);
  }

  /**
  * Returns the selected address as a select option
  */
  const getSelectedAddressOption = () => addressOptions.length === 1 && isReversed ? addressOptions[0] : addressOptions.find(option => option === addressSelected) || null;

  // Saves the address selected into the form
  useEffect(() => {
    if (!!addressSelected?.raw.lat!! && addressSelected?.raw.lon) {
      setFieldValue("latitude", addressSelected?.raw.lat)
      setFieldValue("longitude", addressSelected?.raw.lon)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSelected])

  return (
    <>
      <CardTitleBar title={t("location")} />
      <GridContainer spacing={2}>
        <Grid xs={12}>
          <GridContainer spacing={2} sx={{ alignItems: "center" }}>
            <Grid xs={12} md={6} sx={{ padding: 0 }}>
              <GeoSearchAutocomplete
                handleChange={handleChange}
                handleSelectedOption={getSelectedAddressOption}
                options={addressOptions}
                isLoadInProgress={isLoadInProgress}
                setOptionSelected={setAddressSelected}
                toggleDropPin={toggleDropPin}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {
                  dropPinVisible
                    ?
                    <Button onClick={clearCoords} sx={{ backgroundColor: "#f34943", "&:hover": { backgroundColor: "rgb(170, 51, 46)" }, color: "#FFFFFF" }} startIcon={<Delete sx={{ color: "#FFFFFF" }} />}>
                      {t("removePin")}
                    </Button>
                    :
                    <Button onClick={toggleDropPin} sx={{ backgroundColor: "#33AD93", "&:hover": { backgroundColor: "#4ca492" }, color: "#FFFFFF" }} startIcon={<AddLocationAlt sx={{ color: "#FFFFFF" }} />} >
                      {t("dropPin")}
                    </Button>
                }
              </Box>
            </Grid>
          </GridContainer>
        </Grid>
        <Grid xs={12}>
          <Maps
            defaultMapPosition={MAP_CENTER_BRASOV}
            zoom={14}
          >
            {dropPinVisible &&
              <MapPin setLatLng={setFieldValue} locationCoord={values} isDraggable locationInfo={addressSelected?.raw.display_name} refreshSelectedOption={setAddressSelected} setIsReversed={setIsReversed} />
            }
            <GeoSearch
              searchValue={debouncedAddress}
              setAddressOptions={setAddressOptions}
              setIsLoadInProgress={setIsLoadInProgress}
              isReversed={isReversed}
              values={values}
              setFieldValue={setFieldValue}
            />
          </Maps>
        </Grid>
      </GridContainer>
    </>
  )
}

export default NewDuMap;