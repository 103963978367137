import {
  APPLY_CLIENT_INVOICES_DT_PARAMS,
  PATCH_CLIENT_INVOICES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyClientInvoiceDtParams = (params: DtParams) => ({
  type: APPLY_CLIENT_INVOICES_DT_PARAMS,
  payload: { params },
});

export const patchClientInvoiceDtParams = (params: DtParams) => ({
  type: PATCH_CLIENT_INVOICES_DT_PARAMS,
  payload: { params },
});