export default class Reader {
  id!: number
  deviceId!: number
  deviceName!: string
  duName!: string
  name!: string
  ip!: string
  sensitivity!: number
  power!: number
  vendor!: number
  zone!: number
  antennas!: string
  readerMode!: number
  hasLocalControl!: boolean
  estimatedPopulation!: number
  session!: number
  searchMode!: number

  // Power

  static POWER_MIN_RANGE = 10;
  static POWER_MAX_RANGE = 27.5;
  static POWER_STEP = 0.25;

  // Sensitivity

  static SENSITIVITY_MIN_RANGE = -71;
  static SENSITIVITY_MAX_RANGE = -30;
  static SENSITIVITY_STEP = 1;

  // Vendor
  static VENDOR_IMPINJ = 1;
  static VENDOR_TSL = 2;

  // Zone
  static ZONE_NONE = 0;
  static ZONE_CLEAN = 1;
  static ZONE_SOILED = 2;

  // Reader Mode
  static READER_MODE_MAX_THROUGHPUT = 0;
  static READER_MODE_HYBRID = 1;
  static READER_MODE_DENSE_READER_M4 = 2;
  static READER_MODE_DENSE_READER_M8 = 3;
  static READER_MODE_MAX_MILLER = 4;
  static READER_MODE_DENSE_READER_M4_TWO = 5;
  static READER_MODE_AUTOSET_DENSE_READER = 1000;
  static READER_MODE_AUTOSET_DENSE_READER_DEEP_SCAN = 1002;
  static READER_MODE_AUTOSET_STATIC_FAST = 1003;
  static READER_MODE_AUTOSET_STATIC_DRM = 1004;
  static READER_MODE_AUTOSET_CUSTOM = 1005;

  // Search Mode
  static SEARCH_MODE_READER_SELECTED = 0;
  static SEARCH_MODE_SINGLE_TARGET = 1;
  static SEARCH_MODE_DUAL_TARGET = 2;
  static SEARCH_MODE_TAG_FOCUS = 3;
  static SEARCH_MODE_SINGLE_TARGET_RESET = 5;
  static SEARCH_MODE_DUAL_TARGET_B_TO_A_Select = 6;

  // Mapping

  static VendorTypes = [
    {
      id: this.VENDOR_IMPINJ,
      name: "IMPINJ"
    },
    {
      id: this.VENDOR_TSL,
      name: "TSL"
    }
  ]

  static ZoneTypes = [
    {
      id: this.ZONE_NONE,
      name: "None"
    },
    {
      id: this.ZONE_CLEAN,
      name: "Clean"
    },
    {
      id: this.ZONE_SOILED,
      name: "Soiled"
    }
  ]

  static ReaderModeTypes = [
    {
      id: this.READER_MODE_MAX_THROUGHPUT,
      name: "Max throughput"
    },
    {
      id: this.READER_MODE_HYBRID,
      name: "Hybrid"
    },
    {
      id: this.READER_MODE_DENSE_READER_M4,
      name: "Dense reader M4"
    },
    {
      id: this.READER_MODE_DENSE_READER_M8,
      name: "Dense reader M8"
    },
    {
      id: this.READER_MODE_MAX_MILLER,
      name: "Max miller"
    },
    {
      id: this.READER_MODE_DENSE_READER_M4_TWO,
      name: "Dense reader M4 two"
    },
    {
      id: this.READER_MODE_AUTOSET_DENSE_READER,
      name: "Autoset dense reader"
    },
    {
      id: this.READER_MODE_AUTOSET_DENSE_READER_DEEP_SCAN,
      name: "Autoset dense reader deep scan"
    },
    {
      id: this.READER_MODE_AUTOSET_STATIC_FAST,
      name: "Autoset static fast"
    },
    {
      id: this.READER_MODE_AUTOSET_STATIC_DRM,
      name: "Autoset static dense reader"
    },
    {
      id: this.READER_MODE_AUTOSET_CUSTOM,
      name: "Autoset custom"
    },
  ]

  static SearchModeTypes = [
    {
      id: this.SEARCH_MODE_READER_SELECTED,
      name: "Reader selected"
    },
    {
      id: this.SEARCH_MODE_SINGLE_TARGET,
      name: "Single target inventory"
    },
    {
      id: this.SEARCH_MODE_DUAL_TARGET,
      name: "Dual target"
    },
    {
      id: this.SEARCH_MODE_TAG_FOCUS,
      name: "Tag Focus"
    },
    {
      id: this.SEARCH_MODE_SINGLE_TARGET_RESET,
      name: "Single target reset"
    },
    {
      id: this.SEARCH_MODE_DUAL_TARGET_B_TO_A_Select,
      name: "Dual target select B -> A"
    },
  ]

  // METHODS

  static getVendorName(id: number) {
    return this.VendorTypes.find((vendor) => vendor.id === id)?.name
  }

  static getZoneName(id: number) {
    return this.ZoneTypes.find((zone) => zone.id === id)?.name
  }

  static getReaderModeName(id: number) {
    return this.ReaderModeTypes.find((mode) => mode.id === id)?.name
  }

  static getSearchModeName(id: number) {
    return this.SearchModeTypes.find((searchMode) => searchMode.id === id)?.name
  }
}