import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { getClientCorrections } from "helper/backend";
import { useTranslation } from "react-i18next";
import { showError } from "helper/util";
import TableNoBb from "component/styled/TableNoBb";
import { ClientCorrections as ClientCorrectionsType } from "@type/backend";
import { route, routes } from "helper/route";

const ClientCorrections = () => {

  const { t } = useTranslation();

  // Holds the overdue handovers received from backend
  const [clientCorrections, setClientCorrections] = useState<ClientCorrectionsType[]>([])
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchClientCorrections = useCallback(() => {
    setIsLoadInProgress(true)
    getClientCorrections()
      .then(response => {
        setClientCorrections(response.corrections);
      })
      .catch(_ex => {
        showError(t("unableToLoadClientCorrections"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchClientCorrections();
  }, [fetchClientCorrections])

  return (
    <EditableCard sx={{ height: "360px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={t("clientCorrections")} />
        {
          clientCorrections.length > 0
            ?
            <TableNoBb>
              <TableHead>
                <TableRow>
                  <TableCell>{t("client")}</TableCell>
                  <TableCell>{t("items")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  clientCorrections.map((el, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          <Link href={route(routes.view_client, el.clientId)}>
                            {el.clientName}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {el.items}
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </TableNoBb>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noClientCorrectionsFound")}
            </Box>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard>
  )
};

export default ClientCorrections;