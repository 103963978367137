import React from 'react';
import { AccessDeniedException, NotFoundException, BadRequestException, LocalException, NetworkErrorException } from 'helper/error';
import AccessDenied from './AccessDenied';
import NotFound from './NotFound';
import BadRequest from './BadRequest';
import ServerError from './ServerError';
import LocalError from './LocalError';
import NetworkError from './NetworkError';
import { AppError } from '@type';
import { isErrType } from 'helper/util';

type Props = {
  error: AppError,
  title?: string,
  title400?: string,
  title403?: string,
  title404?: string,
  title500?: string,
};

const Error = ({ error, title, title400, title403, title404, title500 }: Props) => {

  if (isErrType(error, AccessDeniedException)) {
    return <AccessDenied title={title403 || title} />;
  }

  if (isErrType(error, NotFoundException)) {
    return <NotFound title={title404 || title} />;
  }

  if (isErrType(error, BadRequestException)) {
    return <BadRequest title={title400 || title} />;
  }

  if (isErrType(error, NetworkErrorException)) {
    return <NetworkError title={title} />;
  }

  if (isErrType(error, LocalException)) {
    return <LocalError title={title || error.message} />;
  }

  return <ServerError title={title500 || title} />;
}

export default Error;