import { AuthResponse, LogoutResponse } from '@type/backend';
import axios from 'axios';
import env from 'env';
import httpCode from 'constant/httpCode';
import { AppStore } from 'store';
import { logoutUserOk } from 'store/actions';
import {
  AccessDeniedException,
  NotFoundException,
  BadRequestException,
  ValidationException,
  AuthException,
  ServerErrorException,
  ACC_TOKEN_MISSING,
  ACC_TOKEN_JWT_EXPIRED,
  NetworkErrorException,
} from './error';
import { getAccessToken, getRefreshToken, saveAccessToken, saveRefreshToken } from './storage';

const axiosApi = axios.create({
  baseURL: env.API_BE_URL,
});

const refreshEndpoint = '/refresh';
let isRefreshing = false;
let refreshQueue: any[] = [];
let store: AppStore;

axiosApi.interceptors.request.use(config => {
  const authToken = getAccessToken();
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (!!error.response) {
      // see if this error is because the access token expired
      // and therefore we should try to refresh it
      if (shouldRefreshAccessToken(error)) {
        return handleAccessTokenRefresh(error);
      }
      switch (error.response.status) {
        case httpCode.unauthorized:
          error = new AuthException(undefined, error.response.data.code);
          break;
        case httpCode.forbidden:
          error = new AccessDeniedException(undefined, error.response.data.code);
          break;
        case httpCode.notFound:
          error = new NotFoundException(undefined, error.response.data.code);
          break;
        case httpCode.badRequest:
          if (error.response.data.fields) {
            error = new ValidationException(undefined, error.response.data.fields);
          } else {
            error = new BadRequestException(undefined, error.response.data.code);
          }
          break;
        case httpCode.internalServerError:
          error = new ServerErrorException(undefined, error.response.data.code);
          break;
        default:
          break;
      }
    } else if (!!error.request) {
      // we have a request but not a response
      // so this is most likely a network problem
      error = new NetworkErrorException();
    }
    return Promise.reject(error);
  }
);

export const get = <Type>(url: string, config = {}) => axiosApi
  .get<Type>(url, config)
  .then(response => response.data);

export const post = <Type>(url: string, data?: any, config = {}) => axiosApi
  .post<Type>(url, data, config)
  .then(response => response.data);

export const put = <Type>(url: string, data?: any, config = {}) => axiosApi
  .put<Type>(url, data, config)
  .then(response => response.data);

export const del = <Type>(url: string, config = {}) => axiosApi
  .delete<Type>(url, config)
  .then(response => response.data);

export const injectStore = (_store: AppStore) => {
  store = _store;
}

export const refreshAccessToken = () => post<AuthResponse>(refreshEndpoint, { token: getRefreshToken() })
  .then(data => {
    saveAccessToken(data.access.token);
    saveRefreshToken(data.refresh.token);
  });

const shouldRefreshAccessToken = (error: any) => {
  const originalRequest = error.config;
  return !!error.response // server error (not client)
    && error.response.status === httpCode.unauthorized // authentication error
    && [ACC_TOKEN_MISSING, ACC_TOKEN_JWT_EXPIRED].includes(error.response.data.code) // specific error codes
    && originalRequest.url !== refreshEndpoint // not the refresh request itself
    && !originalRequest._retry; // not a retry request
}

const handleAccessTokenRefresh = (error: any) => {
  // check if a refresh call is already in progress
  // we must not allow multiple concurrent refresh calls
  // to avoid race conditions
  if (!isRefreshing) {
    // this refresh call is starting so block other refresh calls
    isRefreshing = true;
    // fire the refresh call
    refreshAccessToken()
      // on refresh success
      .then(() => {
        // now that we have a new access token let's retry all queued requests
        refreshQueue.forEach(item => item.resolve());
      })
      // on refresh error
      .catch(() => {
        // we failed to aquire a new access token so let's abort all queued requests
        refreshQueue.forEach(item => item.reject());
        // logout and redirect to login
        store.dispatch(logoutUserOk({} as LogoutResponse));
      })
      .finally(() => {
        // this refresh call is done so allow other refresh calls
        isRefreshing = false;
        // empty the request queue
        refreshQueue = [];
      })
  }
  // multiple concurrent requests might try to refresh the token at the same time
  // we want to do 2 things in respect to this:
  // 1. allow a single refresh call at a time to avoid race conditions (see 'isRefreshing' above)
  // 2. queue all the requests that occur during a refresh call so they can be retried later (see below)
  return new Promise((resolve, reject) => {
    refreshQueue.push({
      // callback to be called after the token is refreshed
      // retries the request
      resolve: () => {
        // get the original request from the error
        const originalRequest = error.config;
        // mark the request as being a retry
        // to avoid being retried itself
        originalRequest._retry = true;
        // fire the retry request
        resolve(axiosApi.request(originalRequest));
      },
      // callback to be called after the token has failed to refresh
      // aborts the request
      reject: () => {
        reject(error);
      },
    })
  });
}