import React from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";

// This removes warning for duplicates options, this warning showed quite often in testing so we choosed to fix it by adding the renderOption api prop
const MuiAutocomplete = <T extends unknown>(autoCompleteProps: AutocompleteProps<T, undefined, undefined, undefined>) => {
  return (
    <Autocomplete
      renderOption={(props: any, option: any) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      {...autoCompleteProps}
    />
  )
};

export default MuiAutocomplete;