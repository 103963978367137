import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from './auth';
import AccessRole from 'model/accessRole';
import { getOwnClient, getOwnOrganization } from 'helper/backend';
import { showError } from 'helper/util';
import Preloader from 'component/common/Preloader';

type OnboardingContextValue = {
  isOnboardingComplete: boolean,
  isLoggedIn: boolean,
  refreshFinalStep: Function
};

const OnboardingContext = React.createContext<OnboardingContextValue>({} as OnboardingContextValue);

const OnboardingProvider = (props: any) => {

  const { authUser, isLoggedIn } = useAuth();
  const [isOnboarding, setIsOnboarding] = useState<boolean | undefined>(undefined);

  /**
* Fetches the organization record from the backend
*/
  const refreshOrganization = useCallback(() => {
    getOwnOrganization()
      .then(response => {
        setIsOnboarding(!!response.organization.isOnboardingComplete);
      })
      .catch(_ex => {
        showError("Unable to load organization");
      })
  }, [])

  /**
  * Fetches the client record from the backend
  */
  const refreshClient = useCallback(() => {
    getOwnClient()
      .then(response => {
        setIsOnboarding(!!response.client.isOnboardingComplete);
      })
      .catch(_ex => {
        showError("Unable to load client");
      })
  }, [])

  const refreshFinalStep = async () => {
    setIsOnboarding(undefined);
    if (AccessRole.isOrganizationType(authUser.accessRoleId)) {
      refreshOrganization();
    } else if (AccessRole.isClientType(authUser.accessRoleId)) {
      refreshClient();
    };
  }

  // This hook runs once on component mount
  useEffect(() => {
    if (isLoggedIn()) {
      if (AccessRole.isOrganizationType(authUser.accessRoleId)) {
        refreshOrganization();
      } else if (AccessRole.isClientType(authUser.accessRoleId)) {
        refreshClient();
      } else {
        // We set onboarding to true of Master Type or for any other role that doesn't need onboarding
        setIsOnboarding(true)
      };
    } else {
      // We set onboarding to true if the user is not logged in
      setIsOnboarding(true)
    }
  }, [refreshClient, refreshOrganization, authUser, isLoggedIn]);

  // wait until we decide whether the user is authenticated or not
  if (isOnboarding !== undefined) {
    return <OnboardingContext.Provider value={{ isOnboardingComplete: isOnboarding, isLoggedIn: isLoggedIn(), refreshFinalStep: refreshFinalStep }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

export const useOnboarding = () => React.useContext(OnboardingContext);

export default OnboardingProvider;