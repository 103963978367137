import {
  APPLY_PAYMENT_PLANS_DT_PARAMS,
  PATCH_PAYMENT_PLANS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyPaymentPlanDtParams = (params: DtParams) => ({
  type: APPLY_PAYMENT_PLANS_DT_PARAMS,
  payload: { params },
});

export const patchPaymentPlanDtParams = (params: DtParams) => ({
  type: PATCH_PAYMENT_PLANS_DT_PARAMS,
  payload: { params },
});