import React, { useState, FocusEvent } from "react";
import { Button, TextField, FormControlLabel, Switch } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updatePaymentPlan } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import PaymentPlan from "model/paymentPlan";

type Values = {
  name: string;
  price: string | number;
  tagLimit: string | number;
  userLimit: string | number;
  clientLimit: string | number;
  isPublic: number | undefined
};

type Props = {
  paymentPlan: PaymentPlan;
  onFinish: Function;
};

const SectionInfoEdit = ({ paymentPlan, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    name: '',
    price: '',
    tagLimit: '',
    userLimit: '',
    clientLimit: '',
    isPublic: undefined
  };
  formInitialValues = {
    ...formInitialValues,
    name: paymentPlan.name,
    price: paymentPlan.price,
    tagLimit: paymentPlan.tagLimit,
    userLimit: paymentPlan.userLimit,
    clientLimit: paymentPlan.clientLimit,
    isPublic: paymentPlan.isPublic
  }

  /**
   * Form validation rules
   */
  const validationSchema = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    price: Yup.number().required(t("fieldIsRequired")),
    tagLimit: Yup.number().required(t("fieldIsRequired")),
    userLimit: Yup.number().required(t("fieldIsRequired")),
    clientLimit: Yup.number().required(t("fieldIsRequired")),
    isPublic: Yup.number().min(0),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      savePaymentPlan(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const savePaymentPlan = (values: any) => {
    setIsSubmitInProgress(true)
    updatePaymentPlan(paymentPlan.id!, values)
      .then(_response => {
        showSuccess(t("paymentPlanHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableSavePaymentPlan"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={paymentPlan.name!} sx={{ mb: 4 }} >
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveGeneralInfo")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="number" name="price" label={t("price")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.price} error={!!errors.price} helperText={errors.price} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="number" name="tagLimit" label={t("tagLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.tagLimit} error={!!errors.tagLimit} helperText={errors.tagLimit} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="number" name="userLimit" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.userLimit} error={!!errors.userLimit} helperText={errors.userLimit} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="number" name="clientLimit" label={t("clientLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.clientLimit} error={!!errors.clientLimit} helperText={errors.clientLimit} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={8}>
        <FormControlLabel control={<Switch name="isPublic" onChange={(e) => { setFieldValue('isPublic', e.target.checked ? 1 : 0) }} value={values.isPublic} checked={!!values.isPublic} />} label={t("public")} />
      </Grid>
    </GridContainer>
  </form>
}

export default SectionInfoEdit;
