import { Box, Typography } from "@mui/material";
import React from "react";
import logo from 'asset/image/logo.png';
import { useTranslation } from "react-i18next";

const WelcomeStep = () => {

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", padding: "22px" }}>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mb: 10 }}>
        <img src={logo} alt="" style={{ maxWidth: "15%", maxHeight: "15%" }} />
      </Box>
      <Typography variant="h3">
        {t("welcomeTutorial")}
      </Typography>
      <Typography mt={2} sx={{ width: "60%", textAlign: "center" }}>
        {t("welcomeTutorialText")}
      </Typography>
    </Box>
  )
}

export default WelcomeStep;