import {
  APPLY_ORGANIZATIONS_DT_PARAMS,
  PATCH_ORGANIZATIONS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyOrganizationDtParams = (params: DtParams) => ({
  type: APPLY_ORGANIZATIONS_DT_PARAMS,
  payload: { params },
});

export const patchOrganizationDtParams = (params: DtParams) => ({
  type: PATCH_ORGANIZATIONS_DT_PARAMS,
  payload: { params },
});