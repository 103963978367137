import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft, Refresh } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { routes } from "helper/route";
import { AppError } from "@type";
import { deleteExchangeRate, getExchangeRate, pullExchangeRate } from "helper/backend";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess, toSimpleError } from "helper/util";
import Error from 'page/Error';
import { useTranslation } from "react-i18next";
import ExchangeRate from "model/exchangeRate";
import { formatTimestamp, formats } from "helper/date";
import SectionInfo from "./Partial/SectionInfo";
import Currency from "model/currency";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  // read return url from query string
  const recordDate = parseInt(new URLSearchParams(window.location.search).get('dateTs')!);

  // the exchange rate record fetched from the backend
  const [exchangeRate, setExchangeRate] = useState<ExchangeRate[] | undefined>()
  // the exchange rate record fetched from the backend
  const [baseCurrency, setBaseCurrency] = useState<Currency | undefined>()
  // error encoutered while fetching the exchange rate (if any)
  const [exchangeRateError, setExchangeRateError] = useState<AppError | undefined>();
  // whether the loading of the exchange rate is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the exchange rate is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  // whether the pulling of the exchange rate is in progress
  const [isPullInProgress, setIsPullInProgress] = useState(false);

  /**
  * Fetches the exchange rate record from the backend
  */
  const refreshExchangeRate = useCallback(() => {
    setIsLoadInProgress(true)
    getExchangeRate(recordDate!)
      .then(response => {
        setExchangeRate(response.exchangeRate);
        setBaseCurrency(response.baseCurrency)
      })
      .catch(ex => {
        setExchangeRateError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordDate]);

  /**
  * Deletes the exchange rate record in the backend
  */
  const removeExchangeRate = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteExchangeRate(recordDate)
      .then(_response => {
        showSuccess(t("exchangeRateHasBeenDeleted"));
        return navigate(routes.list_exchange_rates);
      })
      .catch(_ex => {
        let errMessage = t("unableToDeleteExchangeRate");
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordDate, t]);

  /**
  * Pulls the exchange rate record in the backend
  */
  const pullDataExchangeRate = useCallback(() => {
    setIsPullInProgress(true);
    pullExchangeRate(recordDate)
      .then(_response => {
        showSuccess(t("exchangeRatesHasBeenPulled"));
        refreshExchangeRate()
      })
      .catch(_ex => {
        let errMessage = t("unableToPullExchangeRates");
        showError(errMessage);
      })
      .finally(() => {
        setIsPullInProgress(false);
      });
  }, [recordDate, t, refreshExchangeRate]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshExchangeRate();
  }, [refreshExchangeRate]);

  return (
    <>
      {isGranted(perms.view_exchange_rates)
        &&
        <>
          {
            !!exchangeRate
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(recordDate, t)}>
                <ProgressButton variant="contained" color="primary" sx={{ mr: 1 }} startIcon={<Refresh />} isBusy={isPullInProgress} onClick={pullDataExchangeRate}>{t("pullExchangeRates")}</ProgressButton>
                {isGranted(perms.delete_exchange_rates) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteExchangeRate")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_exchange_rates} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo exchangeRate={exchangeRate} refreshHandler={refreshExchangeRate} isLoadInProgress={isLoadInProgress} dateTs={recordDate} baseCurrency={baseCurrency!} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeExchangeRate();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteExchangeRateConfirmation")} ?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !exchangeRate && <Preloader container="content" />}
          {!!exchangeRateError && <Error error={exchangeRateError} title404={t("exchangeRateNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_exchange_rates) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (recordDate: number, t: Function) => [{
  text: t("exchangeRates"),
  url: routes.list_exchange_rates,
}, {
  text: formatTimestamp(recordDate, formats.LONG_DATE),
}];

export default Single;