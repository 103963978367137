import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";
import { getHighestRevenue } from "helper/backend";
import { showError } from "helper/util";
import Preloader from "component/common/Preloader";
import moment from "moment";
import { useAuth } from "context/auth";
import { formatCurrency } from "helper/currency";

const HighestRevenue = () => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  const [revenue, setRevenue] = useState<any[]>();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchHighestRevenue = useCallback(() => {
    setIsLoadInProgress(true)
    getHighestRevenue()
      .then(response => {
        let revenues: any[][] = response.revenue.map((el) => {
          let name = "";
          if (!!el.billingInfo.recipient.companyName) {
            name = el.billingInfo.recipient.companyName
          } else {
            name = `${el.billingInfo.recipient.contactFirstName} ${el.billingInfo.recipient.contactLastName}`
          }
          return [name, +el.totalIncome, formatCurrency(el.totalIncome, el.billingInfo.currency?.symbol!, el.billingInfo.currency?.position!, el.billingInfo.currency?.hasSpacing!)]
        })

        revenues[0]?.push("#02A8B5")
        revenues[1]?.push("#FA5C7C")
        revenues[2]?.push("#33AD93")
        revenues[3]?.push("#fbcc5c")
        revenues.unshift(["Clients", "Revenue", { role: 'annotation', type: 'string' }, { role: "style" }])

        setRevenue(revenues);
      })
      .catch(_ex => {
        showError(t("unableToLoadRevenue"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchHighestRevenue();
  }, [fetchHighestRevenue])

  return (
    <EditableCard sx={{ height: "360px" }}>
      <CardContent>
        <CardTitleBar title={authUser.isMasterAdmin() ? t("topOrganizations") : t("topClients")} />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          {
            revenue?.length! > 1
              ?
              <>
                <Box sx={{ fontSize: "18.5px", alignSelf: "flex-start", letterSpacing: "1px", marginTop: "-10px" }}>
                  {moment().subtract(1, "month").format('MMMM YYYY')}
                </Box>
                <Chart
                  chartType="ColumnChart"
                  data={revenue}
                  width={"100%"}
                  height={"280px"}
                  options={
                    {
                      chartArea: {
                        height: '70%',
                        width: '100%',
                      },
                      bar: {
                        groupWidth: 40
                      },
                      annotations: {
                        stemColor: "transparent",
                        alwaysOutside: true
                      },
                      tooltip: {
                        trigger: 'none'
                      },
                      legend: "none"
                    }
                  }
                />
              </>
              :
              authUser.isMasterAdmin()
                ?
                t("noOrganizationsFound")
                :
                t("noClientsFound")
          }
        </Box>
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default HighestRevenue;