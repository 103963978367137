import {
  APPLY_SECURITY_OVERVIEW_DT_PARAMS,
  PATCH_SECURITY_OVERVIEW_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applySecurityOverviewDtParams = (params: DtParams) => ({
  type: APPLY_SECURITY_OVERVIEW_DT_PARAMS,
  payload: { params },
});

export const patchSecurityOverviewDtParams = (params: DtParams) => ({
  type: PATCH_SECURITY_OVERVIEW_DT_PARAMS,
  payload: { params },
});