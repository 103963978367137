import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from 'asset/image/logo.png';
import { Card, CardContent, Link } from '@mui/material';
import env from 'env';
import { useParams } from 'react-router-dom';
import { verifyEmail } from 'helper/backend';
import Spinner from 'component/common/Spinner';
import { toSimpleError } from 'helper/util';
import { EMAIL_ALREADY_VERIFIED, EMAIL_VERIFICATION_INVALID_TOKEN, USER_NOT_FOUND } from 'helper/error';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { Button } from '@mui/material';
import { routes } from 'helper/route';
import background from 'asset/image/bgPattern.png';

const VerifyEmail = () => {

  const { token } = useParams();

  const [error, setError] = useState<string | null>(null);
  const [isVerificationInProgress, setIsVerificationInProgress] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    setIsVerificationInProgress(true);
    verifyEmail(token)
      .then(_response => {
        setIsVerified(true);
      })
      .catch(ex => {
        setIsVerified(false);
        let errMessage = 'Unable to verify email address';
        const err = toSimpleError(ex);
        switch (err.code) {
          case EMAIL_VERIFICATION_INVALID_TOKEN:
            errMessage = 'This link is invalid or has expired';
            break;
          case USER_NOT_FOUND:
            errMessage = 'User account not found';
            break;
          case EMAIL_ALREADY_VERIFIED:
            errMessage = 'This email address is already verified';
            break;
        }
        setError(errMessage);
      })
      .finally(() => {
        setIsVerificationInProgress(false);
      });
  }, [token]);

  return <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
    <Card sx={{ width: 450 }}>
      <CardContent sx={{ p: 3, '&:last-child': { pb: 3 } }}>
        <Box sx={{ height: 100, display: "flex", justifyContent: "center", mb: 8 }}>
          <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
        <Box>

          {/* Visible when email verification request is in progress */}
          {isVerificationInProgress && <Spinner sx={{ width: '100%' }} />}

          {/* Visible when email verification has failed */}
          {!!error && <Box sx={{ textAlign: 'center' }}>
            <HighlightOffOutlined color="error" sx={{ fontSize: "60px" }} />
            <Typography variant="h5" display="block" sx={{ mb: 1 }}>Verification Failed</Typography>
            <Typography display="block">{error}</Typography>
          </Box>}

          {/* Visible when email verification is successfull */}
          {isVerified && <Box sx={{ textAlign: 'center' }}>
            <CheckCircleOutlined color="success" sx={{ fontSize: "60px" }} />
            <Typography variant="h5" display="block" sx={{ mb: 1 }}>Verification Successful</Typography>
            <Typography display="block">Your email address has been verified.<br />You can now sign in</Typography>
            <Button variant="contained" size="large" component={Link} href={routes.home} sx={{ width: '100%', mt: 5 }}>Sign In</Button>
          </Box>}

        </Box>
      </CardContent>
    </Card>
    <Box sx={{ textAlign: 'center', mt: 3, color: "#98a6ad" }}>
      <Typography variant="caption" display="block">
        © {new Date().getFullYear()} {env.APP_TITLE} by <a href="https://www.codeadept.ro" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#33AD93", fontWeight: 600 }}>CodeAdept</a>
      </Typography>
    </Box>
  </Box >
}

export default VerifyEmail;
