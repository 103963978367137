import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";
import { getRevenue } from "helper/backend";
import { showError } from "helper/util";
import Preloader from "component/common/Preloader";
import { useAppSelector } from "hook/redux";
import { formatCurrency } from "helper/currency";

const Revenue = () => {

  const { t } = useTranslation();
  const currency = useAppSelector(store => store.Auth.Login.user?.currency);

  const [revenue, setRevenue] = useState<(string | number | undefined)[][]>();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchRevenue = useCallback(() => {
    setIsLoadInProgress(true)
    getRevenue()
      .then(response => {
        let models: (number | string)[][] = response.revenue.map((el) => { return [el.month, el.revenue || 0] })
        models.unshift(["Months", "Revenue"])

        setRevenue(models);
      })
      .catch(_ex => {
        showError(t("unableToLoadRevenue"));
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchRevenue();
  }, [fetchRevenue])

  return (
    <EditableCard sx={{ height: "360px" }}>
      <CardContent>
        <CardTitleBar title={t("revenue")} />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          {
            !!revenue?.length &&
            <>
              <Box sx={{ fontSize: "25px", alignSelf: "flex-start", letterSpacing: "1px" }}>
                {
                  formatCurrency(revenue?.filter(el => el[0] !== "Months").map((el: any) => el[1]).reduce((partialSum: number, a: number) => partialSum + a, 0).toFixed(2) || "", currency?.symbol!, currency?.position!, currency?.hasSpacing!)
                }
              </Box>
              <Chart
                chartType="LineChart"
                data={revenue}
                width={"100%"}
                height={"250px"}
                onLoad={() => { setIsLoadInProgress(false); }}
                options={
                  {
                    chartArea: {
                      height: '70%',
                      width: '95%',
                    },
                    curveType: "function",
                    pointsVisible: false,
                    colors: ["#35b8e0"],
                    lineWidth: 3,
                    legend: 'none',
                    vAxis: { textPosition: 'none', gridlines: { color: "transparent" }, baselineColor: '#e2e2e2' },
                    hAxis: {
                      gridlines: {
                        count: 12,
                        color: "#e2e2e2"
                      },
                      minorGridlines: { count: 0 },
                    },
                  }
                }
              />
            </>
          }
        </Box>
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default Revenue;