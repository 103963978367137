import countries from 'i18n-iso-countries'

// Support english languages.
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

/**
* Converts the list of countries to select options
*/
export const getCountryOptions = () => {
  const countriesObj = countries.getNames("en", { select: "official" });
  const allCountries = Object.keys(countriesObj).map(key => {
    return {
      label: countriesObj[key],
      value: key
    }
  });

  return allCountries;
};

export const getCountryName = (iso2: string) => countries.getName(iso2, "en");