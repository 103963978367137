import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Table } from "@mui/material";

const StyledTableNoBb = styled(Table)(() => ({
  'tbody tr:last-child td, tbody tr:last-child th': {
    border: 0,
    minWidth: "120px"
  },
}));

const TableNoBb = ({ children, noHorizontalScroll = false, ...props }: any) => {
  return (
    <Box sx={{ overflowY: "auto", height: "100%", overflowX: noHorizontalScroll ? { xs: "auto", md: "hidden" } : "auto" }}>
      <StyledTableNoBb {...props}>
        {children}
      </StyledTableNoBb>
    </Box>
  )
}

export default TableNoBb;