import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import Preloader from "component/common/Preloader";
import { formatTimestamp, formats } from "helper/date";
import User from "model/user";
import { useTranslation } from "react-i18next";

type Props = {
  user: User;
  onEdit: Function;
  isReloading: boolean;
};

const MyAccountView = ({ user, onEdit: onCancel, isReloading }: Props) => {

  const { email, timezone, lastLoginTs, firstName, lastName } = { ...user };

  const { t } = useTranslation()

  return (
    <>
      <CardTitleBar title={t("generalInfo")}>
        <IconButton onClick={() => onCancel()}><Edit /></IconButton>
      </CardTitleBar>
      <TableNoBb>
        <TableBody>
          <TableRow>
            <TableCell>{t("firstName")}</TableCell>
            <TableCell className="preview-value">{firstName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("lastName")}</TableCell>
            <TableCell className="preview-value">{lastName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("email")}</TableCell>
            <TableCell className="preview-value">{email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("timezone")}</TableCell>
            <TableCell className="preview-value">{timezone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t("lastLogin")}
            </TableCell>
            <TableCell className="preview-value">{lastLoginTs ? formatTimestamp(lastLoginTs, formats.DATETIME) : t("never")}</TableCell>
          </TableRow>
        </TableBody>
      </TableNoBb>
      {isReloading && <Preloader container="parent" />}
    </>
  )
}

export default MyAccountView;