import {
  APPLY_EXCHANGE_RATES_DT_PARAMS,
  PATCH_EXCHANGE_RATES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyExchangeRateDtParams = (params: DtParams) => ({
  type: APPLY_EXCHANGE_RATES_DT_PARAMS,
  payload: { params },
});

export const patchExchangeRateDtParams = (params: DtParams) => ({
  type: PATCH_EXCHANGE_RATES_DT_PARAMS,
  payload: { params },
});