
export default class DistributionUnit {
  id!: number;
  name!: string;
  organizationId!: number | null;
  organizationName?: string | null;
  latitude!: number;
  longitude!: number;
  fullStock!: number;
  currentStock!: number;
  minStockPercentage!: number;
  address!: string;
  zip!: string;
  city!: string;
  county!: string;
  cleanItems!: number;
  soiledItems!: number;
  isSecurityActive!: number;
  isReaderActive!: number;
  lastSecurityStatusTs!: number;
  lastReaderStatusTs!: number;
  createdTs!: number;
  updatedTs!: number;

  static STOCK_LIMIT_LOW = 30;
  static STOCK_LIMIT_HIGH = 80;
}