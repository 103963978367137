export default class Currency {
  id!: number;
  name!: string;
  code!: string;
  symbol!: string;
  position!: number;
  decimals!: number;
  hasSpacing!: boolean;

  //POSITION TYPES
  static POSITION_BEFORE = 1;
  static POSITION_AFTER = 2;

  //DECIMAL TYPES
  static DECIMALS_ZERO = 0;
  static DECIMALS_TWO = 2;
  static DECIMALS_THREE = 3;


  static PositionTypes = [
    {
      id: this.POSITION_BEFORE,
      name: "Before"
    },
    {
      id: this.POSITION_AFTER,
      name: "After"
    }
  ];


  static getPositionTypeName(id: number) {
    return this.PositionTypes.find((positionType) => positionType.id === id)?.name
  }

  static DecimalTypes = [
    {
      id: this.DECIMALS_ZERO,
      name: "0"
    },
    {
      id: this.DECIMALS_TWO,
      name: "2 (Default)"
    },
    {
      id: this.DECIMALS_THREE,
      name: "3"
    }
  ];

  static getDecimalsTypeName(id: number) {
    return this.DecimalTypes.find((decimalType) => decimalType.id === id)?.name
  }
}