import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from 'asset/image/logo.png';
import background from 'asset/image/bgPattern.png';
import { routes } from 'helper/route';
import { Home } from '@mui/icons-material';

const defaultTitle = 'There is something wrong with the network';

type Props = {
  title?: string | undefined,
};

const NetworkError = ({ title }: Props) => {

  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2000,
      width: "100%",
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: "#343b4a",
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <Card sx={{ maxWidth: "475px", minWidth: "390px" }}>
        <CardContent sx={{ padding: "2.25rem" }}>
          <Box sx={{
            mt: 2,
            mb: 2,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ height: "150px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", mb: 8 }}>
                <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
              </Box>
              <Typography variant="h6" sx={{ mb: 3, color: "#f34943", textTransform: "uppercase" }}>{title || defaultTitle}</Typography>
              <Typography sx={{ mb: 3, color: "#98a6ad", fontSize: ".9rem" }}>{t("errorMessage")}</Typography>
              <Button variant="contained" component={Link} href={routes.home} startIcon={<Home />} sx={{ mt: 3 }}>{t("returnHome")}</Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default NetworkError;
