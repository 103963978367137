import React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Container from '@mui/system/Container';
import QuickMenu from 'component/common/QuickMenu';
import UserMenu from 'component/common/UserMenu';
import { toggleMenu } from 'store/actions';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAppDispatch, useAppSelector } from 'hook/redux';

const MenuToggleButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: -37,
  borderRadius: '50%',
  backgroundColor: 'white',
  padding: 0,
  minWidth: 0,
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.text.secondary,
  borderColor: theme.palette.text.disabled,
  '&:hover': {
    backgroundColor: 'white',
    borderColor: theme.palette.text.disabled,
  },
}));

type Props = {
  sx: any;
};

const TopBarDesktop = ({ sx }: Props) => {

  const dispatch = useAppDispatch();

  const { menuIsCollapsed } = useAppSelector(state => state.Layout.View);

  const toggleMainMenu = () => dispatch(toggleMenu());

  return <Box sx={sx}>
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar sx={{ flexGrow: 1, position: 'relative' }} disableGutters={true}>
          <MenuToggleButton variant="outlined" onClick={toggleMainMenu}>
            {menuIsCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </MenuToggleButton>
          <QuickMenu />
          <Box className="tutorial-user">
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  </Box>
}

export default TopBarDesktop;