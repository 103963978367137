import React, { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, handleChange }: Props) => {

  const { t } = useTranslation();

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;