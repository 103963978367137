import React, { FocusEvent, useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import Organization from "model/organization";
import { getOrganizationList } from "helper/backend";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import MuiAutocomplete from "component/common/MuiAutocomplete";

const FormCreate = ({ formik }: any) => {

  const { values, errors, setStatus, handleChange, setFieldValue, setFieldError } = formik;

  const { t } = useTranslation();

  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    // fetch the list of organizations
    fetchOrganizations();
  }, [fetchOrganizations]);

  /**
  * Converts the list of organizations to select options
  */
  const getOrganizationOptions = () => organizations.map(organization => ({ label: organization.companyName, value: organization.id }));

  /**
  * Returns the selected organization as a select option
  */
  const getSelectedOrganizationOption = () => getOrganizationOptions().find(option => option.value === values.organizationId) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <>
      <CardTitleBar title={t("general")} />
      <GridContainer spacing={2}>
        <Grid xs={12} md={6}>
          <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <MuiAutocomplete
            onChange={(_e, selectedOption) => {
              setFieldValue('organizationId', selectedOption?.value);
            }}
            onFocus={_e => {
              onSelectFieldFocused('organizationId');
            }}
            value={getSelectedOrganizationOption()}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={getOrganizationOptions()}
            renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
          />
        </Grid>
      </GridContainer>
    </>
  )
}

export default FormCreate;