import React from 'react';
import { Box } from "@mui/material";
import Breadcrumbs from './Breadcrumbs';
import { Breadcrumb } from '@type';

const toolbarHeight = '40px';

type Props = {
  title?: string;
  breadcrumbs?: Breadcrumb[];
  children?: any;
  sx?: any;
};

const PageTitleBar = ({ title, breadcrumbs, children, sx }: Props) => {
  return <Box sx={{ display: 'flex', mb: 2, flexDirection: { xs: "column", sm: "row" }, ...sx }}>
    <Box sx={{ flexGrow: 1, minHeight: toolbarHeight }}>
      <Breadcrumbs
        {...(!!title ? { title } : null)}
        {...(!!breadcrumbs ? { items: breadcrumbs } : null)}
      />
    </Box>
    <Box>{children}</Box>
  </Box>
}

export default PageTitleBar;