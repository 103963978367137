import {
  APPLY_MY_INVOICES_DT_PARAMS,
  PATCH_MY_INVOICES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyMyInvoiceDtParams = (params: DtParams) => ({
  type: APPLY_MY_INVOICES_DT_PARAMS,
  payload: { params },
});

export const patchMyInvoiceDtParams = (params: DtParams) => ({
  type: PATCH_MY_INVOICES_DT_PARAMS,
  payload: { params },
});