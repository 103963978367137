import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, Link, Paper, TableContainer } from "@mui/material";
import { GridColDef, GridFilterItem, GridFilterModel, GridRenderCellParams } from "@mui/x-data-grid";
import { AppError, DtParams } from "@type";
import { perms, useAccess } from "context/access";
import { handleGridFilterModel, insertDtParamsIntoUrl } from "helper/dt";
import { objectIsEmpty, toSimpleError } from "helper/util";
import { getInvoiceDt } from "helper/backend";
import useDtUrlParams from "hook/dtUrlParams";
import { useAppDispatch, useAppSelector } from "hook/redux";
import AccessDenied from "page/Error/AccessDenied";
import Dt from "component/common/Dt";
import PageTitleBar from "component/common/PageTitleBar";
import { applyInvoiceDtParams, patchInvoiceDtParams } from "store/actions";
import * as Yup from "yup"
import { useFormik } from "formik";
import { route, routes } from "helper/route";
import { useTranslation } from "react-i18next";
import Invoice from "model/invoice";
import { formatTimestamp, formats } from "helper/date";
import { formatCurrency } from "helper/currency";
import { useAuth } from "context/auth";
import User from "model/user";
import DtFilterControls from "component/common/DtFilterControls";
import Filters from "./Partial/Filters";

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  /**
  * DataGrid params may sometimes be passed in the url
  * so here we attempt to read any params from the url
  */
  const urlParams = useDtUrlParams();

  // DataGrid rows
  const [dtRows, setDtRows] = useState([] as Invoice[]);
  // DataGrid total number of rows
  const [dtRowCount, setDtRowCount] = useState(0);
  // DataGrid sorting, filtering, pagination, etc
  const dtParams = useAppSelector(store => store.Invoice.DtRequest);
  // whether the loading of the DataGrid rows is in progress
  const [isDtLoadInProgress, setIsDtLoadInProgress] = useState(false);
  // DataGrid error encoutered while fetching the rows (if any)
  const [dtError, setDtError] = useState<AppError | null>(null);
  // whether the filters panel is expanded
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  /**
  * Filter form default values
  * These are the values the form will be reset to when you click "Clear Filters"
  * Thy should be "empty" unless you have a clear need to force default values on specific fields
  */
  const filterFormDefaultValues = useMemo(() => ({
    createdTs: "",
    organizationId: Number.MIN_SAFE_INTEGER,
    type: Number.MIN_SAFE_INTEGER
  }), []);

  /**
  * Filter form initial values
  * These are the values loaded into the form as the component mounts (usually from redux store)
  * Datagrid parameters are stored in redux so they survive component unmount
  * Therefore when the user returns to the same DataGrid we can restore the previous parameters
  */
  const filterFormInitialValues = useMemo(() => ({
    ...filterFormDefaultValues,
    ...dtParams.filters,
  }), [dtParams.filters, filterFormDefaultValues]);

  /**
  * Filter form validation rules
  */
  const validationSchema = useMemo(() => ({
    createdTs: Yup.string().trim(),
    organizationId: Yup.number(),
    type: Yup.number()
  }), []);

  /**
  * Filter form configuration
  */
  const { values, errors, setValues, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filterFormInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      applyFilters(values)
    },
  });

  // This hook runs once on component mount
  useEffect(() => {
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }
    // update the DataGrid params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchInvoiceDtParams(urlParams));
  }, [dispatch, urlParams]);

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    if (urlParams === null) {
      // abort if the url filters have not been evaluated yet
      // this is in order to avoid making 2 requests:
      // a) the initial one and b) another one after the url filters have been evaluated
      // better to wait until the url is parsed and make a single request
      return;
    }
    // fetch the list of DataGrid rows from the server
    setIsDtLoadInProgress(true);
    // filtering the dtParams based on user (Master or Client)
    // they both see this component but the filters are different
    // we do this to clear the filters if the master admin
    // impersonates a client and filter the invoices because we do not want
    // to keep the filters from the client and vice versa
    let dtParamsFiltered = {};
    if (authUser.isMasterAdmin()) {
      dtParamsFiltered = {
        ...dtParams,
        filters: {
          createdTs: dtParams.filters?.createdTs,
          organizationId: dtParams.filters?.organizationId
        }
      }
    } else {
      dtParamsFiltered = {
        ...dtParams,
        filters: {
          type: dtParams.filters?.type,
        }
      }
    }
    getInvoiceDt(dtParamsFiltered)
      .then(response => {
        setDtRows(response.rows);
        setDtRowCount(response.totalCount);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      })
      .finally(() => {
        setIsDtLoadInProgress(false);
      });
    // having 'urlParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'dtParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtParams]);

  /**
  * Saves new DataGrid params to the redux store
  * @param params
  */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyInvoiceDtParams(params));
  }, [dispatch]);

  /**
  * Event handler called whenever the user applies new filters
  * @param values
  */
  const applyFilters = useCallback((values: any) => {
    // prepare the filters in the format expected by the DataGrid
    const filterModel = { items: [] } as GridFilterModel;
    // add all new filters
    for (const [key, value] of Object.entries(values)) {
      filterModel.items.push({ id: key, field: key, value } as GridFilterItem);
    }
    // convert the DataGrid format to DtParams
    const newParams = handleGridFilterModel(filterModel, dtParams);
    // save filters in redux store
    updateDtParams(newParams);
  }, [dtParams, updateDtParams]);

  /**
  * Event handler called whenever the user clears the filters
  */
  const clearFilters = useCallback(() => {
    // reset the form to the default values
    setValues(filterFormDefaultValues);
    // submit the form so the new filters are applied
    handleSubmit();
  }, [filterFormDefaultValues, handleSubmit, setValues]);

  /**
  * Event handler called whenever the user shows or hides the filters panel
  */
  const toggleFilters = useCallback(() => {
    setIsFiltersVisible(val => !val);
  }, []);

  // Check if filters exist
  const checkHasFilters = () => {
    let dtParamsFiltered: any = {};
    if (authUser.isMasterAdmin()) {
      dtParamsFiltered = {
        ...dtParams,
        filters: {
          createdTs: dtParams.filters?.createdTs,
          organizationId: dtParams.filters?.organizationId
        }
      }
    } else {
      dtParamsFiltered = {
        ...dtParams,
        filters: {
          type: dtParams.filters?.type,
        }
      }
    }

    return !objectIsEmpty(dtParamsFiltered.filters, true)
  }

  return (
    <React.Fragment>
      {isGranted(perms.view_invoices) && <Box>

        {/********** Page Title and Actions Toolbar **********/}
        <PageTitleBar title={t("invoices")}>
          {
            (authUser.isMasterAdmin() || authUser.isSsdClient())
            &&
            <DtFilterControls
              isFiltersVisible={isFiltersVisible}
              hasFilters={checkHasFilters()}
              onApplyFilters={handleSubmit}
              onClearFilters={clearFilters}
              onToggleFilters={toggleFilters}
              sx={{ mr: 1 }}
            ></DtFilterControls>
          }
        </PageTitleBar>

        {/* ********* Filters Panel ********* */}
        {isFiltersVisible && <Filters
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleChange={handleChange}
          setStatus={setStatus}
        />}

        {/********** DataGrid **********/}
        <TableContainer component={Paper}>
          {!!dtError && <Alert severity="error" sx={{ mb: 1 }}>{t("unableToLoadInvoices")}</Alert>}
          <Dt
            rows={dtRows}
            rowCount={dtRowCount}
            columns={columns(t, authUser)}
            params={dtParams}
            isBusy={isDtLoadInProgress}
            localeText={{
              noRowsLabel: t("noInvoicesFound"),
              noResultsOverlayLabel: t("noInvoicesFound"),
            }}
            onPaginationChange={updateDtParams}
            onSearchChange={updateDtParams}
            onSortChange={updateDtParams}
            hiddenColumns={['updatedTs']}
          />
        </TableContainer>
      </Box>}
      {isNotGranted(perms.view_invoices) && <AccessDenied />}
    </React.Fragment>
  )
}

const columns = (t: Function, authUser: User) => {
  const columns: GridColDef[] = [
    {
      field: 'number',
      headerName: t("number"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_invoice, params.row.id)}>#{params.value}</Link>
      },
    },
    {
      field: 'createdTs',
      headerName: t("date"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return formatTimestamp(params.value, formats.DATETIME)
      }
    },
    {
      field: 'status',
      headerName: t("status"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return Invoice.getStatusTypeName(params.value)
      }
    },
    {
      field: 'totalAmount',
      headerName: t("total"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        const currency = params.row.billingInfo.currency
        return formatCurrency(params.value, currency?.symbol!, currency?.position!, currency?.hasSpacing!)
      }
    },
  ];

  if (authUser.isMasterAdmin()) {
    columns.splice(1, 0, {
      field: 'organizationName',
      headerName: t("Organization"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_organization, params.row.organizationId)}>{params.value}</Link>
      }
    })
  }

  if (authUser.isSsdClient()) {
    columns.push({
      field: 'type',
      headerName: t("type"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return Invoice.getInvoiceTypeName(params.value)
      }
    })
  }

  return columns
}

export default List;