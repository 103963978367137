import {
  APPLY_ACTIVITY_LOGS_DT_PARAMS,
  PATCH_ACTIVITY_LOGS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyActivityLogDtParams = (params: DtParams) => ({
  type: APPLY_ACTIVITY_LOGS_DT_PARAMS,
  payload: { params },
});

export const patchActivityLogDtParams = (params: DtParams) => ({
  type: PATCH_ACTIVITY_LOGS_DT_PARAMS,
  payload: { params },
});