import React, { ReactNode } from "react";
import { MapContainer, TileLayer } from 'react-leaflet';
import { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css"

type Props = {
  children?: ReactNode;
  defaultMapPosition: LatLngExpression,
  zoom: number;
  zoomControl?: boolean
}

const Maps = ({ defaultMapPosition, children, zoom, zoomControl = true }: Props) => {
  return (
    <MapContainer
      className="map"
      center={defaultMapPosition}
      zoom={zoom}
      scrollWheelZoom={zoomControl}
      zoomSnap={0.25}
      zoomDelta={1}
      wheelPxPerZoomLevel={60}
      style={{ width: "100%", height: "700px" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>
        contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  )
}

export default Maps;