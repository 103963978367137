import {
  APPLY_PACKAGE_PARAMS,
  PATCH_PACKAGE_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyPackageParams = (params: DtParams) => ({
  type: APPLY_PACKAGE_PARAMS,
  payload: { params },
});

export const patchPackageParams = (params: DtParams) => ({
  type: PATCH_PACKAGE_PARAMS,
  payload: { params },
});