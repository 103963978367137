import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { routes } from "helper/route";
import { AppError } from "@type";
import { deleteCurrency, getCurrency } from "helper/backend";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess, toSimpleError } from "helper/util";
import Error from 'page/Error';
import { UNABLE_DELETE_CURRENCY } from "helper/error";
import Currency from "model/currency";
import SectionInfo from "./Partial/SectionInfo";
import { useTranslation } from "react-i18next";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the currency record fetched from the backend
  const [currency, setCurrency] = useState<Currency | undefined>()
  // error encoutered while fetching the currency (if any)
  const [currencyError, setCurrencyError] = useState<AppError | undefined>();
  // whether the loading of the currency is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the currency is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the currency record from the backend
  */
  const refreshCurrency = useCallback(() => {
    setIsLoadInProgress(true)
    getCurrency(recordId)
      .then(response => {
        setCurrency(response.currency);
      })
      .catch(ex => {
        setCurrencyError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId]);

  /**
  * Deletes the currency record in the backend
  */
  const removeCurrency = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteCurrency(recordId)
      .then(_response => {
        showSuccess(t("currencyHasBeenDeleted"));
        return navigate(routes.list_currencies);
      })
      .catch(ex => {
        let errMessage = t("unableToDeleteCurrency");
        const err = toSimpleError(ex)
        switch (err.code) {
          case UNABLE_DELETE_CURRENCY:
            errMessage = t("deleteCurrencyErrorMessage");
            break;
        }
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshCurrency();
  }, [refreshCurrency]);

  return (
    <>
      {isGranted(perms.view_currencies)
        &&
        <>
          {
            !!currency
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(currency, t)}>
                {isGranted(perms.delete_currency) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteCurrency")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_currencies} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo currency={currency} refreshHandler={refreshCurrency} isLoadInProgress={isLoadInProgress} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeCurrency();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteCurrencyConfirmation")} &quot;{currency.name}&quot;?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !currency && <Preloader container="content" />}
          {!!currencyError && <Error error={currencyError} title404={t("currencyNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_currencies) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (currency: Currency, t: Function) => [{
  text: t("currencies"),
  url: routes.list_currencies,
}, {
  text: currency.name!,
}];

export default Single;