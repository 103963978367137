import React from "react";
import { Box, CardContent, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import logo from 'asset/image/logo.png';
import { useTranslation } from "react-i18next";
import Invoice from "model/invoice";
import { formatCurrency } from "helper/currency";
import { formatTimestamp, formats } from "helper/date";
import env from "env";

type Props = {
  invoice: Invoice,
};

const Invoices = ({ invoice }: Props) => {

  const { t } = useTranslation();
  const { issuer, recipient, currency } = invoice.billingInfo;

  return <React.Fragment>
    <EditableCard>
      <CardContent sx={{ margin: "0 50px 0 50px" }}>
        <Box sx={{ height: "90px", width: "100%", margin: "16px", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", minHeight: "90px", }}>
          <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
        <Box sx={{ margin: "21px 0px", display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "center", md: "" }, gap: { xs: "16px", md: "" } }}>
          <Box>
            <Box sx={{ fontWeight: 600, mb: 1 }}>
              {issuer.companyName}
            </Box>
            <Box>
              {issuer.vatNo}
            </Box>
            <Box>
              {issuer.contactEmail}
            </Box>
            <Box>
              {issuer.contactPhone}
            </Box>
            <Box>
              {issuer.billingAddress}, {issuer.billingCity}
            </Box>
            <Box>
              {issuer.billingCounty}, {issuer.billingCountry}
            </Box>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Box sx={{ fontWeight: 600, mb: 1 }}>
              {recipient.companyName}
            </Box>
            <Box>
              {recipient.vatNo}
            </Box>
            <Box>
              {recipient.contactEmail}
            </Box>
            <Box>
              {recipient.contactPhone}
            </Box>
            <Box>
              {recipient.billingAddress}, {recipient.billingCity}
            </Box>
            <Box>
              {recipient.billingCounty}, {recipient.billingCountry}
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: "4px", width: "100%", backgroundColor: "#33AD93" }}></Box>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ fontSize: "21px", fontWeight: 600, mb: 1 }}>{t("invoice")}</Box>
          <Box>{t("number")}: {invoice.number}</Box>
          <Box>{t("date")}: {formatTimestamp(invoice.createdTs, formats.DATETIME)}</Box>
          <Box>{t("status")}: {Invoice.getStatusTypeName(invoice.status)}</Box>
        </Box>
        <TableNoBb sx={{ marginTop: "80px" }}>
          <TableHead>
            <TableRow>
              <TableCell width={20}>{t("no")}</TableCell>
              <TableCell>{t("description")}</TableCell>
              <TableCell>{t("units")}</TableCell>
              <TableCell>{t("unitPrice")}</TableCell>
              <TableCell>{t("value")}</TableCell>
              {
                issuer.vatRate > 0 &&
                <TableCell sx={{ textAlign: "right" }}>{t("vat")}</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {!!invoice.lines.length && invoice.lines.map((invoice, idx) =>
              <TableRow key={idx}>
                <TableCell width={20}>{idx + 1}</TableCell>
                <TableCell>{invoice.description}</TableCell>
                <TableCell>{invoice.quantity}</TableCell>
                <TableCell>{formatCurrency(invoice.unitAmount, currency.symbol, currency.position, currency.hasSpacing)}</TableCell>
                <TableCell >{formatCurrency(invoice.totalAmount, currency.symbol!, currency.position, currency.hasSpacing)}</TableCell>
                {
                  issuer.vatRate > 0 &&
                  <TableCell sx={{ textAlign: "right" }}>{formatCurrency(invoice.vatValue, currency.symbol, currency.position, currency.hasSpacing)}</TableCell>
                }
              </TableRow>)}
            {!invoice.lines.length && <TableRow>
              <TableCell colSpan={4} align="center">{t("noInvoicesFound")}</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {!!invoice.lines.length &&
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "60px", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
              <Box sx={{ width: "100%", fontWeight: 600, textAlign: "end" }}>
                <Box sx={{ mr: 3, fontSize: "18px" }}>
                  {t("totalPrice")}:
                </Box>
              </Box>
              {
                issuer.vatRate > 0 &&
                <Box sx={{ width: "100%", textAlign: "end" }}>
                  <Box sx={{ mr: 3, fontSize: "14px" }}>
                    {t("vatRate")}:
                  </Box>
                </Box>
              }
              {
                !!invoice.txRate &&
                <>
                  <Box sx={{ width: "100%", textAlign: "end" }}>
                    <Box sx={{ mr: 3, fontSize: "14px" }}>
                      {t("exchangeRate")}:
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%", fontWeight: 600, textAlign: "end" }}>
                    <Box sx={{ mr: 3, fontSize: "18px" }}>
                      {t("totalPrice")} ({env.BASE_CURRENCY_CODE}):
                    </Box>
                  </Box>
                </>
              }
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
              <Box sx={{ fontSize: "18px", fontWeight: 600, textAlign: "end" }}>
                {formatCurrency(invoice.totalAmount, currency.symbol, currency.position, currency.hasSpacing)}
              </Box>
              {
                issuer.vatRate > 0 &&
                <Box sx={{ fontSize: "14px", textAlign: "end" }}>
                  {issuer.vatRate}%
                </Box>
              }
              {
                !!invoice.txRate &&
                <>
                  <Box sx={{ fontSize: "14px", textAlign: "end" }}>
                    1 {currency.code} = {invoice.txRate} {env.BASE_CURRENCY_CODE}
                  </Box>
                  <Box sx={{ fontSize: "18px", fontWeight: 600, textAlign: "end" }}>
                    {`${(invoice.totalAmount * +invoice.txRate).toFixed(2)} ${env.BASE_CURRENCY_CODE}`}
                  </Box>
                </>
              }
            </Box>
          </Box>
        }
      </CardContent>
    </EditableCard>
  </React.Fragment>
}

export default Invoices;