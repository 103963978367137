import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Link, Typography } from "@mui/material";
import WizardStepper from "./WizardStepper";
import GridContainer from "component/common/GridContainer";
import EditableCard from "component/styled/EditableCard";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useStripe } from "@stripe/react-stripe-js";
import { updatePayment } from "helper/backend";
import Preloader from "component/common/Preloader";
import withStripeElements from "hoc/stripeElements";
import { Check, Replay } from "@mui/icons-material";
import { useOnboarding } from "context/onboarding";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import background from 'asset/image/bgPattern.png';
import logo from 'asset/image/logo.png';

const PaymentConfirmation = () => {

  const stripe = useStripe();
  const { refreshFinalStep } = useOnboarding();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // whenever stripe is in progress
  const [isStripeInProgress, setIsStripeInProgress] = useState(false);
  // used to save the setupIntent from stripe
  const [setupIntent, setSetupIntent] = useState<any>();
  // used to save the errors from stripe (if any)
  const [screenError, setScreenError] = useState("");

  /**
  * Event handler called at first render to create a payment intent
  */
  const updatePaymentMethod = useCallback(() => {
    setIsStripeInProgress(true)
    updatePayment({ paymentMethodId: setupIntent.payment_method })
      .then((_response: any) => {
        // nothing to do
      })
      .catch(_ex => {
        setScreenError(t("unableToSavePaymentMethod"));
      })
      .finally(() => {
        setIsStripeInProgress(false)
      });
  }, [setupIntent, setScreenError, t]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
    if (!clientSecret) {
      setScreenError(t("stripeBadRequest"));
      return;
    }
    setIsStripeInProgress(true);
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({ setupIntent }) => {
        setIsStripeInProgress(false);
        switch (setupIntent?.status) {
          case 'succeeded':
            setSetupIntent(setupIntent);
            break;
          case 'processing':
            setScreenError(t("stripeUnableToConfirmResult"));
            break;
          case 'requires_payment_method':
            setScreenError(t("stripeUnableToSavePayment"));
            break;
        }
      });
  }, [stripe, t]);

  useEffect(() => {
    if (!setupIntent) {
      return;
    }
    updatePaymentMethod()
  }, [setupIntent, updatePaymentMethod]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: "100%", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <WizardStepper activeStep={1} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          marginTop: { xs: 0, md: "-64px" },
          width: "100%",
          '@media (max-height: 800px)': {
            marginTop: 0
          }
        }}>
        <GridContainer
          sx={{
            width: { sx: "100%", md: "600px" },
            margin: { xs: 0, md: "auto" },
            height: { xs: "100%", md: "auto" },
            '@media (max-height: 800px)': {
              width: "100%",
              margin: 0,
              height: "100%"
            }
          }}>
          <Grid xs={12} sx={{ padding: { xs: 0 } }}>
            {!!setupIntent
              &&
              <EditableCard sx={{ height: '100%', padding: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CardContent>
                  <Box sx={{ textAlign: 'center', mb: 5, height: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                      <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </Box>
                  </Box>
                  {
                    screenError.length > 0
                      ?
                      <>
                        <Box sx={{ mb: 4, textAlign: "center" }}>
                          {t("onboardingPaymentError")}
                        </Box>
                        <Alert severity="warning">{screenError}</Alert>
                      </>
                      :
                      <Box>
                        <Box sx={{ mb: 4, textAlign: "center", fontSize: "21px" }}>
                          {t("onboardingComplete")}!
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItem: "center", justifyContent: "center", width: "100%", p: 2 }}>
                          <Typography textAlign="center">
                            {t("youAreAllSet")}
                          </Typography>
                          <Typography textAlign="center">
                            {t("allyourSettings")}
                          </Typography>
                          <Typography textAlign="center" mb={6}>
                            {t("goAheadAndEnter")}
                          </Typography>
                          <Typography textAlign="center">
                            {t("thanksForJoining")}
                          </Typography>
                          <Typography textAlign="center" fontWeight={600}>
                            {t("24LinenTeam")}
                          </Typography>
                        </Box>
                      </Box>
                  }
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }}>
                    {
                      screenError.length > 0
                        ?
                        <Button variant="contained" color="error" component={Link} href={"/onboarding/payment"} startIcon={<Replay />}>{t("retry")}</Button>
                        :
                        <Button variant="contained" color="primary" startIcon={<Check />} onClick={() => { refreshFinalStep(); navigate("/") }} sx={{ ml: 1 }}>{t("finish")}</Button>
                    }
                  </Box>
                </CardContent>
              </EditableCard>
            }
          </Grid>
        </GridContainer>
      </Box>
      {/* Show the prealoder only on the first fetch */}
      {isStripeInProgress && <Preloader container="parent" />}
    </Box>
  )
}

export default withStripeElements(PaymentConfirmation);