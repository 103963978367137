import React from 'react';
import { Box, Chip, Typography } from "@mui/material";

type Props = {
  title?: string;
  iconTitle?: any;
  chip?: string;
  bgcolor?: string;
  children?: any;
  sx?: any;
};

const CardTitleBar = ({ title, chip, bgcolor, children, sx, iconTitle }: Props) => {
  return <Box sx={{ display: 'flex', mb: 1, pb: 1, mt: -3, pt: 3, ml: -3, pl: 3, mr: -3, pr: 3, bgcolor, flexDirection: { xs: "row" }, ...sx }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography component="span" sx={{ fontSize: { sm: "1rem", lg: "1.3rem" } }}>
        {title}
        {iconTitle}
        {!!chip && <Chip label={chip} size="small" sx={{ ml: 2 }} />}</Typography>
    </Box>
    <Box>{children}</Box>
  </Box>
}

export default CardTitleBar;