import React from "react";
import { Box, CardContent, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import { formatTimestamp, formats } from "helper/date";
import Operation from "model/operation";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth";
import { formatCurrency } from "helper/currency";
import { Error, Warning } from "@mui/icons-material";
import { buildOperationNumber, showDaysText } from "helper/util";
import Client from "model/client";

type Props = {
  operation: Operation;
}

const SectionInfo = ({ operation }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();
  const { currency } = authUser;

  return <React.Fragment>
    <EditableCard sx={{ height: '100%' }}>
      <CardContent>
        <CardTitleBar title={t("generalInfo")} {...({ bgcolor: operation.overdueDays > 0 ? "error.light" : "none" })} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("number")}</TableCell>
              <TableCell className="preview-value">{buildOperationNumber(operation.id, operation.type, operation.createdTs)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("date")}</TableCell>
              <TableCell className="preview-value">{formatTimestamp(operation.createdTs, formats.DATETIME)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("dueDate")}</TableCell>
              <TableCell className="preview-value">{formatTimestamp(operation.handoverByTs, formats.DATETIME)}</TableCell>
            </TableRow>
            {
              operation.handoverByDays !== null &&
              <TableRow>
                <TableCell>{t("remainingTime")}</TableCell>
                <TableCell>
                  {
                    operation.handoverByDays > 0
                      ?
                      <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>
                        {operation.handoverByDays >= 5
                          ?
                          <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                            {operation.handoverByDays} {t(showDaysText(operation.handoverByDays))}
                          </Box>
                          :
                          <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                            {operation.handoverByDays} {t(showDaysText(operation.handoverByDays))}
                            <Warning color="warning" />
                          </Box>
                        }
                      </Typography>
                      :
                      <Typography sx={{ display: "inline", fontSize: "0.960rem" }} >
                        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                          {t("today")}
                          <Warning color="warning" />
                        </Box>
                      </Typography>
                  }
                </TableCell>

              </TableRow>
            }
            {
              !!operation.overdueDays &&
              <>
                <TableRow>
                  <TableCell>{t("overdueBy")}</TableCell>
                  <TableCell className="preview-value">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>
                        - {operation.overdueDays} {t(showDaysText(operation.overdueDays))}
                      </Typography>
                      <Error color="error" sx={{ ml: 0.5, mb: 0.3 }} />
                    </Box>
                  </TableCell>
                </TableRow>
                {
                  authUser.clientBusinessModel !== Client.BUSINESS_MODEL_FREE_SSD &&
                  <TableRow>
                    <TableCell>{t("penalty")}</TableCell>
                    <TableCell className="preview-value">{formatCurrency(operation.penalty, currency.symbol, currency.position, currency.hasSpacing)}</TableCell>
                  </TableRow>
                }
              </>
            }
            <TableRow>
              <TableCell>{t("location")}</TableCell>
              <TableCell className="preview-value">{operation.locationName}</TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment>
}

export default SectionInfo;