import React, { ChangeEvent, FocusEvent, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Button, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import ProgressButton from "component/common/ProgressButton";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ValidationException } from "helper/error";
import { updateExchangeRate } from "helper/backend";
import { useTranslation } from "react-i18next";
import ExchangeRate from "model/exchangeRate";
import env from "env";
import Currency from "model/currency";

type Values = {
  currencies: any[]
};

type Props = {
  exchangeRate: ExchangeRate[];
  onFinish: Function;
  dateTs: number;
  baseCurrency: Currency;
};

const SectionInfoEdit = ({ exchangeRate, onFinish, dateTs, baseCurrency }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    currencies: []
  };
  formInitialValues = {
    ...formInitialValues,
    currencies: exchangeRate.map(el => { return { id: el.currencyId, value: el.value } })
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    currencies: Yup.array().required(t("fieldIsRequired"))
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveExchangeRate(values);
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveExchangeRate = (values: any) => {
    setIsSubmitInProgress(true);
    updateExchangeRate(values, dateTs)
      .then(_response => {
        showSuccess(t("exchangeRateHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveExchangeRate"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
  * Event handler called whenever the currencies changes
  */
  const handleCurrencyChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currency: ExchangeRate) => {
    // copy of the currencies array
    let currenciesArr = [...values.currencies];
    // whether the currency is present in the array
    let currencyIsFound = currenciesArr.find(el => el.id === currency.currencyId)

    if (!!currencyIsFound) {
      currenciesArr[currenciesArr.indexOf(currencyIsFound)] = {
        id: currency.currencyId,
        value: e.target.value
      }
    } else {
      currenciesArr.push({
        id: currency.currencyId,
        value: e.target.value
      })
    }

    setFieldValue('currencies', currenciesArr)
  }


  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveExchangeRate")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={3}>
        <Grid xs={12} >
          <TextField name="baseCurrency" label={t("baseCurrency")} variant="outlined" onFocus={onTextFieldFocused} value={`${baseCurrency.name} (${baseCurrency.code})`} sx={{ width: '100%' }} disabled />
        </Grid>
        {
          exchangeRate.filter(el => el.code !== env.BASE_CURRENCY_CODE).map((currency, idx) => {
            return (
              <Grid xs={12} key={idx}>
                <TextField type="number" name="currency" label={`${currency.currencyName} (${currency.currencyCode})`} variant="outlined" onChange={(e) => handleCurrencyChange(e, currency)} onFocus={onTextFieldFocused} value={values.currencies.find(el => el.id === currency.currencyId)?.value || ""} sx={{ width: '100%' }} />
              </Grid>
            )
          })
        }
      </GridContainer>
    </form>
  )
};

export default SectionInfoEdit;