import { combineReducers } from 'redux';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  TOGGLE_MENU,
} from './actionTypes';
import { LayoutViewState } from './types';

const defaultViewState: LayoutViewState = {
  menuIsCollapsed: false,
};

const View = (state = defaultViewState, action: PayloadAction): LayoutViewState => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        menuIsCollapsed: !state.menuIsCollapsed,
      };
    default:
      return state;
  }
}

export default combineReducers({
  View,
});
