import React, { FocusEvent, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Autocomplete, Button, InputAdornment, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import ProgressButton from "component/common/ProgressButton";
import Article from "model/article";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ValidationException } from "helper/error";
import { updateArticle } from "helper/backend";
import { useAppSelector } from "hook/redux";
import { useTranslation } from "react-i18next";

type Values = {
  name: string,
  code: string,
  rentPrice: number,
  purchasePrice: number,
  packageItemCount: number | null | string,
  distributionMode: number,
};

type Props = {
  article: Article;
  onFinish: Function;
};

const SectionInfoEdit = ({ article, onFinish }: Props) => {

  const currency = useAppSelector(store => store.Auth.Login.user?.currency);
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    name: '',
    code: '',
    rentPrice: Number.MIN_SAFE_INTEGER,
    purchasePrice: Number.MIN_SAFE_INTEGER,
    packageItemCount: Number.MIN_SAFE_INTEGER,
    distributionMode: Number.MIN_SAFE_INTEGER,
  };
  formInitialValues = {
    ...formInitialValues,
    name: article.name!,
    code: article.code!,
    rentPrice: article.rentPrice!,
    purchasePrice: article.purchasePrice!,
    packageItemCount: article.packageItemCount === null ? "" : article.packageItemCount!,
    distributionMode: article.distributionMode!
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    code: Yup.string().trim().required(t("fieldIsRequired")),
    rentPrice: Yup.number().typeError(t("rentPriceNumber")).required(t("fieldIsRequired")),
    purchasePrice: Yup.number().typeError(t("purchasePriceNumber")).required(t("fieldIsRequired")),
    distributionMode: Yup.number().required(t("fieldIsRequired"))
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldError, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveArticle(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveArticle = (values: any) => {
    setIsSubmitInProgress(true);
    const formValues = { ...values, packageItemCount: values.packageItemCount.length === 0 ? null : values.packageItemCount }
    updateArticle(article.id!, formValues)
      .then(_response => {
        showSuccess(t("articleHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveArticle"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Converts the list of distribution modes to select options
  */
  const getDistributionModeOptions = () => Article.DistributionModes.map(distrMode => ({ label: distrMode.name, value: distrMode.id }));

  /**
   * Returns the selected organization as a select option
   */
  const getSelectedDistributionModeOption = () => getDistributionModeOptions().find(option => option.value === values.distributionMode) || null;


  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar title={article.name!} sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveArticle")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12} md={6}>
          <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            name="rentPrice"
            label={t("rentPrice")}
            variant="outlined"
            onChange={handleChange}
            onFocus={onTextFieldFocused}
            value={values.rentPrice}
            error={!!errors.rentPrice}
            helperText={errors.rentPrice}
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{currency?.symbol}</InputAdornment>
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField
            name="purchasePrice"
            label={t("purchasePrice")}
            variant="outlined"
            onChange={handleChange}
            onFocus={onTextFieldFocused}
            value={values.purchasePrice}
            error={!!errors.purchasePrice}
            helperText={errors.purchasePrice}
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{currency?.symbol}</InputAdornment>
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="packageItemCount" label={t("numberOfItemsInPackage")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.packageItemCount} error={!!errors.packageItemCount} helperText={errors.packageItemCount} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <Autocomplete
            onChange={(_e, selectedOption) => {
              setFieldValue('distributionMode', selectedOption?.value);
            }}
            onFocus={_e => {
              onSelectFieldFocused('distributionMode');
            }}
            value={getSelectedDistributionModeOption()}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={getDistributionModeOptions()}
            renderInput={(params: any) => <TextField {...params} label={t("distributionMode")} error={!!errors.distributionMode} helperText={errors.distributionMode} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
          />
        </Grid>
      </GridContainer>
    </form>
  )
};

export default SectionInfoEdit;