import React from "react";
import { CardContent, TableBody, TableCell, TableRow, IconButton } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import ContentCopy from '@mui/icons-material/ContentCopy';
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import env from "env";

const SectionApiUrl = () => {

  const { t } = useTranslation();

  return (
    <>
      <EditableCard sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={t("apiUrl")} />
          <TableNoBb sx={{ overflow: "hidden" }}>
            <TableBody>
              <TableRow>
                <TableCell>{t("apiUrl")}</TableCell>
                <TableCell className="preview-value">
                  {env.API_URL}
                  <IconButton onClick={() => { navigator.clipboard.writeText(env.API_URL) }} sx={{ ml: 2, mt: -1 }}><ContentCopy /></IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </TableNoBb>
        </CardContent>
      </EditableCard >
    </>
  )
}

export default SectionApiUrl;