import { extractDtParamsFromUrl } from 'helper/dt';
import { useEffect, useRef } from 'react';

const useDtUrlParams = () => {
  // prepare the object that will hold the params once read from the url
  const filters = useRef<{ [k: string]: string } | null>(null);
  useEffect(() => {
    // read params from the url into the object
    filters.current = extractDtParamsFromUrl();
  }, []);
  return filters.current;
}

export default useDtUrlParams;