import CardTitleBar from 'component/common/CardTitleBar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { FocusEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { isErrType, showError, showSuccess, toSimpleError, nullsToEmptyStrings } from 'helper/util';
import { getAccessRoleList, getClientList, getOrganizationList, updateUser } from 'helper/backend';
import { ValidationException } from 'helper/error';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ProgressButton from 'component/common/ProgressButton';
import { perms, useAccess } from 'context/access';
import User from 'model/user';
import AccessRole from 'model/accessRole';
import Organization from 'model/organization';
import Client from 'model/client';
import { getTimezoneOptions } from 'helper/date';
import ConfirmDialog from 'component/common/ConfirmDialog';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';
import MuiAutocomplete from 'component/common/MuiAutocomplete';

type Values = {
  firstName: string;
  lastName: string;
  email: string;
  accessRoleId: number | undefined;
  organizationId?: number | undefined;
  clientId?: number | undefined;
  timezone: string | undefined;
  isActive: boolean;
};

type Props = {
  user: User;
  onFinish: Function;
};

const SectionInfoEdit = ({ user, onFinish }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  // list of access roles that populate the select field
  const [accessRoles, setAccessRoles] = useState<AccessRole[]>([]);
  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  // list of clients that populate the select field
  const [clients, setClients] = useState<Client[]>([]);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  // whether the change-email confirmation is visible
  const [isChangeEmailConfOpen, setIsChangeEmailConfOpen] = useState(false);
  // holds form values after validation and until the user accepts the confirmation
  const [tempFormValues, setTempFormValues] = useState<Values | null>(null);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    firstName: '',
    lastName: '',
    email: '',
    accessRoleId: undefined,
    timezone: undefined,
    isActive: false,
  };
  if (isGranted(perms.create_organizations)) {
    formInitialValues = {
      ...formInitialValues,
      organizationId: undefined,
    };
  }
  if (isGranted(perms.create_clients)) {
    formInitialValues = {
      ...formInitialValues,
      clientId: undefined,
    };
  }
  formInitialValues = {
    ...formInitialValues,
    ...nullsToEmptyStrings(user),
  };

  /**
   * Form validation rules
   */
  let validationSchema: any = {
    firstName: Yup.string().trim().required(t("fieldIsRequired")),
    lastName: Yup.string().trim().required(t("fieldIsRequired")),
    email: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    accessRoleId: Yup.number().required(t("fieldIsRequired")),
    timezone: Yup.string().trim().required(t("fieldIsRequired")),
    isActive: Yup.boolean(),
  };
  if (isGranted(perms.create_organizations)) {
    validationSchema = {
      ...validationSchema,
      organizationId: Yup.number().when('accessRoleId', {
        is: (value: number) => roleRequiresOrganization(value),
        then: schema => schema.required(t("fieldIsRequired")),
      }),
    };
  }
  if (isGranted(perms.create_clients)) {
    validationSchema = {
      ...validationSchema,
      clientId: Yup.number().when('accessRoleId', {
        is: (value: number) => roleRequiresClient(value),
        then: schema => schema.required(t("fieldIsRequired")),
      }),
    };
  }

  /**
   * Form configuration
   */
  const { values, errors, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      if (values.email !== user.email) {
        setTempFormValues(values);
        setIsChangeEmailConfOpen(true);
      } else {
        saveUser(values);
      }
    },
  });

  /**
   * Fetches from the backend the list of access roles this user is allowed to see
   */
  const fetchAccessRoles = useCallback(() => {
    getAccessRoleList()
      .then(response => {
        setAccessRoles(response.accessRoles);
      })
      .catch(_ex => {
        setFieldError('accessRoleId', t("unableToLoadRoles"));
      });
  }, [setFieldError, t]);

  /**
   * Fetches from the backend the list of organizations this user is allowed to see
   */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  /**
   * Fetches from the backend the list of clients this user is allowed to see
   */
  const fetchClients = useCallback(() => {
    getClientList()
      .then(response => {
        setClients(response.clients);
      })
      .catch(_ex => {
        setFieldError('clientId', t("unableToLoadClients"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    // fetch the list of access roles
    fetchAccessRoles();
    if (isGranted(perms.create_organizations)) {
      // fetch the list of organizations
      fetchOrganizations();
    }
    if (isGranted(perms.create_clients)) {
      // fetch the list of organizations
      fetchClients();
    }
  }, [fetchAccessRoles, fetchClients, fetchOrganizations, isGranted]);

  /**
   * Event handler called whenever the user saves the form
   */
  const saveUser = (values: any) => {
    setIsSubmitInProgress(true);
    updateUser(user.id!, values)
      .then(_response => {
        showSuccess(t("userHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveUser"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
   * Converts the list of access roles to select options
   */
  const getAccessRoleOptions = () => accessRoles.filter(role => (role.id !== AccessRole.ID_ORGANIZATION_DRIVER)).map(role => ({ label: role.name, value: role.id }));

  /**
   * Returns the selected access role as a select option
   */
  const getSelectedAccessRoleOption = () => getAccessRoleOptions().find(option => option.value === values.accessRoleId) || null;

  /**
   * Converts the list of organizations to select options
   */
  const getOrganizationOptions = () => organizations.map(organization => ({ label: organization.companyName, value: organization.id }));

  /**
   * Returns the selected organization as a select option
   */
  const getSelectedOrganizationOption = () => getOrganizationOptions().find(option => option.value === values.organizationId) || null;

  /**
   * Converts the list of clients to select options
   */
  const getClientOptions = () => clients.map(client => ({ label: client.companyName, value: client.id }));

  /**
   * Returns the selected organization as a select option
   */
  const getSelectedClientOption = () => getClientOptions().find(option => option.value === values.clientId) || null;

  /**
   * Returns the selected timezone as a select option
   */
  const getSelectedTimezoneOption = () => getTimezoneOptions().find(option => option.value === values.timezone) || null;

  /**
   * Returns TRUE if the specified role requires the user to select an organization
   * @param {number} roleId
   * @returns {boolean}
   */
  const roleRequiresOrganization = (roleId: number) => AccessRole.isOrganizationType(roleId);

  /**
   * Returns TRUE if the specified role requires the user to select a client
   * @param {number} roleId
   * @returns {boolean}
   */
  const roleRequiresClient = (roleId: number) => AccessRole.isClientType(roleId);

  /**
   * Event handler called whenever the user changes the access role field
   * Checks if other fields that depend on this one need to be reset
   * For example, if the user has selected a role that does not require an organization, the organization field needs to be cleared
   * @param {number} roleId
   */
  const checkResetDependentFields = (roleId: number | undefined) => {
    if (!roleId || !roleRequiresOrganization(roleId)) {
      setFieldValue('organizationId', undefined);
    }
    if (!roleId || !roleRequiresClient(roleId)) {
      setFieldValue('clientId', undefined);
    }
  }

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={user.fullName!} sx={{ mb: 4 }}>
      <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveUser")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="firstName" label={t("firstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.firstName} error={!!errors.firstName} helperText={errors.firstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="lastName" label={t("lastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.lastName} error={!!errors.lastName} helperText={errors.lastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="email" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('accessRoleId', selectedOption?.value);
            checkResetDependentFields(selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('accessRoleId');
          }}
          value={getSelectedAccessRoleOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getAccessRoleOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("role")} error={!!errors.accessRoleId} helperText={errors.accessRoleId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
      {isGranted(perms.create_organizations) && !!values.accessRoleId && roleRequiresOrganization(values.accessRoleId) && <Grid xs={12} md={6}>
        <MuiAutocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('organizationId', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('organizationId');
          }}
          value={getSelectedOrganizationOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getOrganizationOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>}
      {isGranted(perms.create_clients) && !!values.accessRoleId && roleRequiresClient(values.accessRoleId) && <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('clientId', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('clientId');
          }}
          value={getSelectedClientOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getClientOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("client")} error={!!errors.clientId} helperText={errors.clientId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>}
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('timezone', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('timezone');
          }}
          value={getSelectedTimezoneOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getTimezoneOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("timezone")} error={!!errors.timezone} helperText={errors.timezone} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
      {!!user.isEmailVerified &&
        <Grid xs={12} md={6}>
          <FormControlLabel control={<Switch name="isActive" onChange={handleChange} checked={values.isActive} />} label={t("active")} />
        </Grid>
      }
    </GridContainer>
    <ConfirmDialog
      isOpen={isChangeEmailConfOpen}
      yesButton={t("confirm")}
      onConfirm={() => {
        setIsChangeEmailConfOpen(false);
        saveUser(tempFormValues);
        setTempFormValues(null);
      }}
      onCancel={() => {
        setIsChangeEmailConfOpen(false);
        setTempFormValues(null);
      }}
    >{t("changeEmailConfirmation")}</ConfirmDialog>
  </form>
}

export default SectionInfoEdit;
