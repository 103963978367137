import React, { useState, FocusEvent, useEffect, useCallback } from "react";
import GridContainer from "component/common/GridContainer";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import ProgressButton from "component/common/ProgressButton";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import { regexPhone, regexZip } from "constant/regex";
import { getCurrenciesList, registerOrganization } from "helper/backend";
import { getCountryOptions } from "helper/country";
import Currency from "model/currency";
import { isErrType, showError, toSimpleError } from "helper/util";
import { ValidationException } from "helper/error";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helper/route";

type Values = {
  companyName: string;
  vatNo: string | null;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  billingAddress: string;
  billingCity: string;
  billingZip: string;
  billingCounty: string;
  billingCountry: string | undefined;
  currencyId: number;
  vatRate: number | undefined;
  agreedToTos: boolean;
  invoicePrefix: string;
};

type Props = {
  payload: any,
  setConfirmedTerms: Function,
  token: string | undefined,
  agreeTerms: boolean
}

const OrganizationInformation = ({ payload, setConfirmedTerms, token, agreeTerms }: Props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  // list of currencies that populate the select field
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    companyName: '',
    vatNo: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    billingAddress: '',
    billingCity: '',
    billingZip: '',
    billingCounty: '',
    billingCountry: undefined,
    currencyId: Number.MIN_SAFE_INTEGER,
    invoicePrefix: '',
    vatRate: 0,
    agreedToTos: false,
  };
  formInitialValues = {
    ...formInitialValues,
    contactFirstName: payload.firstName,
    contactLastName: payload.lastName,
    contactEmail: payload.email,
    agreedToTos: agreeTerms
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    companyName: Yup.string().trim().required(t("fieldIsRequired")),
    contactFirstName: Yup.string().trim().required(t("fieldIsRequired")),
    contactLastName: Yup.string().trim().required(t("fieldIsRequired")),
    contactEmail: Yup.string().trim().required(t("fieldIsRequired")).email('Invalid email address'),
    contactPhone: Yup.string().trim().required(t("fieldIsRequired")).matches(regexPhone, t("invalidPhoneNumber")),
    vatNo: Yup.string().trim(),
    billingAddress: Yup.string().trim().required(t("fieldIsRequired")),
    billingCity: Yup.string().trim().required(t("fieldIsRequired")),
    billingZip: Yup.string().trim().required(t("fieldIsRequired")).matches(regexZip, t("invalidZipCode")),
    billingCounty: Yup.string().trim().required(t("fieldIsRequired")),
    billingCountry: Yup.string().trim().required(t("fieldIsRequired")),
    currencyId: Yup.number().min(0, "You need to select a value").required(t("fieldIsRequired")),
    invoicePrefix: Yup.string().trim(),
    vatRate: Yup.number().min(0, "VAT must be 0 or more").required(t("fieldIsRequired"))
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      submitRegisterOrganization(values);
    },
  });

  /**
  * Fetches from the backend the list of currencies this user is allowed to see
  */
  const fetchCurrencies = useCallback(() => {
    getCurrenciesList()
      .then(response => {
        setCurrencies(response.currencies);
      })
      .catch(_ex => {
        setFieldError('currencyId', t("unableFetchCurrencies"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies])

  /**
* Event handler called whenever the user saves the form
*/
  const submitRegisterOrganization = (values: any) => {
    setIsSubmitInProgress(true)
    registerOrganization(token!, values)
      .then((response: any) => {
        navigate(route(routes.activate_account, response.token))
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToRegisterOrganization"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false)
      })
  };

  /**
  * Converts the list of currencies to select options
  */
  const getCurrencyOptions = () => currencies.map(currency => ({ label: `${currency.name} (${currency.symbol})`, value: currency.id }));

  /**
   * Returns the selected currencies as a select option
   */
  const getSelectedCurrencyOption = () => getCurrencyOptions().find(option => option.value === values.currencyId) || null;

  /**
  * Returns the selected country as a select option
  */
  const getSelectedCountryOption = () => getCountryOptions().find(option => option.value === values.billingCountry) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Box sx={{ maxWidth: { md: 1000 } }}>
    <form noValidate onSubmit={handleSubmit}>
      <GridContainer spacing={4}>
        <Grid xs={12} md={6}>
          <GridContainer>
            <Grid xs={12}>
              <Typography sx={{ fontSize: { sm: "1rem", lg: "1.3rem" } }}>
                {t("generalInfo")}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <TextField name="companyName" label={t("companyName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.companyName} error={!!errors.companyName} helperText={errors.companyName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="vatNo" label={t("vatNumber")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatNo} error={!!errors.vatNo} helperText={errors.vatNo} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="contactFirstName" label={t("contactFirstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactFirstName} error={!!errors.contactFirstName} helperText={errors.contactFirstName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="contactLastName" label={t("contactLastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactLastName} error={!!errors.contactLastName} helperText={errors.contactLastName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField type="email" name="contactEmail" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactEmail} error={!!errors.contactEmail} helperText={errors.contactEmail} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="contactPhone" label={t("phone")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactPhone} error={!!errors.contactPhone} helperText={errors.contactPhone} sx={{ width: '100%' }} />
            </Grid>
          </GridContainer>
        </Grid>
        <Grid xs={12} md={6}>
          <GridContainer>
            <Grid xs={12}>
              <Typography sx={{ fontSize: { sm: "1rem", lg: "1.3rem" } }}>
                {t("billingInformation")}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <TextField name="billingAddress" label={t("address")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingAddress} error={!!errors.billingAddress} helperText={errors.billingAddress} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="billingCity" label={t("city")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCity} error={!!errors.billingCity} helperText={errors.billingCity} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="billingZip" label={t("zipCode")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingZip} error={!!errors.billingZip} helperText={errors.billingZip} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField name="billingCounty" label={t("county")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCounty} error={!!errors.billingCounty} helperText={errors.billingCounty} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('billingCountry', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('billingCountry');
                }}
                value={getSelectedCountryOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getCountryOptions()}
                renderInput={(params: any) => <TextField {...params} name="billingCountry" label={t("country")} error={!!errors.billingCountry} helperText={errors.billingCountry} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
            <Grid xs={12} >
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('currencyId', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('currencyId');
                }}
                value={getSelectedCurrencyOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getCurrencyOptions()}
                renderInput={(params: any) => <TextField {...params} name="currencyId" label={t("currency")} error={!!errors.currencyId} helperText={errors.currencyId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
            <Grid xs={12}>
              <TextField name="invoicePrefix" label={t("invoicePrefix")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.invoicePrefix} error={!!errors.invoicePrefix} helperText={errors.invoicePrefix} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12}>
              <TextField type="number" name="vatRate" label={t("vatRate")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatRate} error={!!errors.vatRate} helperText={errors.vatRate} sx={{ width: '100%' }} InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }} />
            </Grid>
          </GridContainer>
        </Grid>
        <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="secondary" onClick={() => setConfirmedTerms(false)} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
          <ProgressButton type="submit" name="submitBillingInfo" variant="contained" color="primary" sx={{ ml: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("next")}</ProgressButton>
        </Grid>
      </GridContainer>
    </form>
  </Box>
}

export default OrganizationInformation;