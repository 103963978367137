import React, { FocusEvent, useCallback, useState } from "react";
import { Autocomplete, Button, Link, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import Client from "model/client";
import * as Yup from "yup"
import { useFormik } from "formik";
import { inviteClient } from "helper/backend";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { routes } from "helper/route";
import { ValidationException } from "helper/error";
import { useNavigate } from "react-router-dom";
import { getTimezoneOptions } from "helper/date";
import ProgressButton from "component/common/ProgressButton";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";

const FormInvite = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * Event handler called whenever the user saves the form
  */
  const sendInviteClient = (values: any) => {
    setIsSubmitInProgress(true);
    inviteClient(values)
      .then(_response => {
        showSuccess(t("clientHasBeenInvited"));
        return navigate(routes.list_clients);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableInviteClient"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    businessModel: 0,
    timezone: undefined,
  };

  /**
  * Form validation rules
  */
  const validationSchema = {
    firstName: Yup.string().trim().required(t("fieldIsRequired")),
    lastName: Yup.string().trim().required(t("fieldIsRequired")),
    email: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    businessModel: Yup.number().positive(t("fieldIsRequired")),
    timezone: Yup.string().trim().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      sendInviteClient(values);
    },
  });

  /**
  * Converts the list of business model to select options
  */
  const getBusinessModelOptions = () => Client.BusinessModels.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedBusinessModelOption = () => getBusinessModelOptions().find(option => option.value === values.businessModel) || null;

  /**
   * Returns the selected timezone as a select option
   */
  const getSelectedTimezoneOption = () => getTimezoneOptions().find(option => option.value === values.timezone) || null;

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={t("generalInfo")} >
      <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("sendInvitation")}</ProgressButton>
      <Button variant="contained" color="secondary" component={Link} href={routes.list_clients} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="firstName" label={t("firstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.firstName} error={!!errors.firstName} helperText={errors.firstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="lastName" label={t("lastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.lastName} error={!!errors.lastName} helperText={errors.lastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="email" label={t("email")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('businessModel', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('businessModel');
          }}
          value={getSelectedBusinessModelOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getBusinessModelOptions()}
          renderInput={(params: any) => <TextField {...params} name="businessModel" label={t("businessModel")} error={!!errors.businessModel} helperText={errors.businessModel} inputProps={{ ...params.inputProps, autoComplete: 'new-business' }} />}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('timezone', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('timezone');
          }}
          value={getSelectedTimezoneOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getTimezoneOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("timezone")} error={!!errors.timezone} helperText={errors.timezone} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
    </GridContainer>
  </form>
}

export default FormInvite;