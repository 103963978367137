import {
  APPLY_DEVICES_DT_PARAMS,
  PATCH_DEVICES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyDeviceDtParams = (params: DtParams) => ({
  type: APPLY_DEVICES_DT_PARAMS,
  payload: { params },
});

export const patchDeviceDtParams = (params: DtParams) => ({
  type: PATCH_DEVICES_DT_PARAMS,
  payload: { params },
});