import React, { useState } from "react";
import Tour, { ReactourStep } from 'reactour'

import WelcomeStep from "./steps/WelcomeStep";
import DashboardStep from "./steps/DashboardStep";
import NetworkStep from "./steps/NetworkStep";
import ReportsStep from "./steps/ReportsStep";
import UserStep from "./steps/UserStep";
import { Button } from "@mui/material";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const steps: ReactourStep[] = [
  {
    content: <WelcomeStep />,
    style: {
      width: "50%"
    }
  },
  {
    selector: '.tutorial-dashboard',
    content: <DashboardStep isClient />,
    style: {
      transform: 'translate(330px,-50%)',
      top: "50%"
    }
  },
  {
    selector: '.tutorial-network',
    content: <NetworkStep isClient />,
    style: {
      transform: 'translate(330px,-50%)',
      top: "50%"
    }
  },
  {
    selector: '.tutorial-reports',
    content: <ReportsStep isClient />,
    style: {
      transform: 'translate(330px,-50%)',
      top: "50%"
    }
  },
  {
    selector: '.tutorial-user',
    content: <UserStep isClient />,
    style: {
      transform: 'translate(330px,-50%)',
      top: "50%"
    }
  },
]

type Props = {
  setTutorialComplete: Function;
}

const ClientTutorial = ({ setTutorialComplete }: Props) => {

  const { t } = useTranslation();

  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [step, setStep] = useState<number>(0);

  return (
    <>
      <Tour
        steps={steps}
        isOpen={stepsEnabled}
        onRequestClose={() => { setStepsEnabled(false); setTutorialComplete(false) }}
        disableInteraction
        showCloseButton={false}
        showNumber={false}
        closeWithMask={false}
        accentColor="#33AD93"
        maskSpace={step === 4 ? 40 : 10}
        nextButton={
          <Button variant="contained" component={Link}>{t("next")}</Button>
        }
        prevButton={
          <Button variant="contained" component={Link} disabled={step === 0 ? true : false}>{t("back")}</Button>
        }
        lastStepNextButton={
          <Button variant="contained" component={Link}>{t("done")}</Button>
        }
        getCurrentStep={(curr) => setStep(curr)}
      />
    </>
  )
}

export default ClientTutorial;