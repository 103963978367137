import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonGroup } from '@mui/material';
import React, { FormEvent, MouseEventHandler } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Check, Delete } from '@mui/icons-material';

const CustomButton = styled(Button)(() => ({
  '& .MuiButton-startIcon': {
    marginRight: '-3px',
  },
}));

type Props = {
  isFiltersVisible: boolean;
  hasFilters: boolean;
  onApplyFilters: (e?: FormEvent<HTMLFormElement>) => void;
  onClearFilters: MouseEventHandler<HTMLButtonElement>;
  onToggleFilters: MouseEventHandler<HTMLButtonElement>;
  sx: any;
};

const DtFilterControls = ({ isFiltersVisible, hasFilters, onApplyFilters, onClearFilters, onToggleFilters, sx }: Props) => {

  const { t } = useTranslation();
  const handleApplyFilters = () => onApplyFilters();

  return <React.Fragment>
    {isFiltersVisible && <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ verticalAlign: 'middle', marginBottom: { xs: "0px" }, ...sx }}>
      <Button onClick={handleApplyFilters} startIcon={<Check />}>{t("applyFilters")}</Button>
      <Button color="secondary" onClick={onClearFilters} startIcon={<Delete sx={{ color: "#FFFFFF" }} />}>{t("clearFilters")}</Button>
      <CustomButton color="light" startIcon={<CloseIcon />} aria-label="close" onClick={onToggleFilters} sx={{ color: "#000000 !important" }}></CustomButton>
    </ButtonGroup>}
    {!isFiltersVisible && <ButtonGroup variant="contained" color="info" aria-label="outlined info button group" sx={{ verticalAlign: 'middle', "@media (max-width:400px)": { marginBottom: { xs: "0px", md: "10px" } }, ...sx }}>
      <Button startIcon={<FilterListIcon />} onClick={onToggleFilters} color="primary" >{t("filter")}</Button>
      {hasFilters && <CustomButton startIcon={<DeleteIcon />} aria-label="clear" color="primary" onClick={onClearFilters}></CustomButton>}
    </ButtonGroup>}
  </React.Fragment>
}
export default DtFilterControls;