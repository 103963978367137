import React from "react";
import { Box, CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import EditableCard from "component/styled/EditableCard";
import ActivityLog from "model/activityLog";
import Device from "model/device";
import { routes, route } from "helper/route";

type Props = {
  activityLog: ActivityLog;
};

const SectionDevice = ({ activityLog }: Props) => {

  const { t } = useTranslation();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("device")} />
        {
          !!activityLog.device
            ?
            <TableNoBb>
              <TableBody>
                <TableRow>
                  <TableCell>{t("device")}</TableCell>
                  {
                    !!Device.DeviceTypes.find(el => el.id === activityLog?.device?.deviceType!) ?
                      <TableCell className="preview-value">
                        <Link href={route(routes.view_du_device, [activityLog.organization.id, activityLog.device.deviceId])}>{activityLog.device.deviceName}</Link>
                      </TableCell>
                      :
                      <TableCell className="preview-value">
                        <Link href={route(routes.view_standalone_device, activityLog.device.deviceId)}>{activityLog.device.deviceName}</Link>
                      </TableCell>
                  }
                </TableRow>
                <TableRow>
                  <TableCell>{t("deviceType")}</TableCell>
                  <TableCell className="preview-value">{Device.getDeviceTypeName(activityLog.device.deviceType)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("location")}</TableCell>
                  <TableCell className="preview-value">{activityLog.device.locationName}</TableCell>
                </TableRow>
              </TableBody>
            </TableNoBb>
            :
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {t("noDeviceFound")}
            </Box>
        }
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionDevice;