import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { getCountryName } from "helper/country";
import Client from "model/client";
import { perms, useAccess } from "context/access";
import { Edit } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";

type Props = {
  client: Client;
  onEdit: Function;
  isReloading: boolean;
}

const SectionBillingView = ({ client, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("billing")} >
      {isGranted(perms.edit_clients) && <IconButton onClick={() => onCancel(2)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("address")}</TableCell>
          <TableCell className="preview-value">{client.billingAddress}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("city")}</TableCell>
          <TableCell className="preview-value">{client.billingCity}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("zipCode")}</TableCell>
          <TableCell className="preview-value">{client.billingZip}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("county")}</TableCell>
          <TableCell className="preview-value">{client.billingCounty}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("country")}</TableCell>
          <TableCell className="preview-value">{getCountryName(client.billingCountry!)}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionBillingView;