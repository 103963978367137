import React, { FocusEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from 'asset/image/logo.png';
import background from 'asset/image/bgPattern.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { loginUser, saveReturnUrl } from 'store/actions';
import { AuthException, INVALID_CREDENTIALS, USER_NOT_FOUND, ValidationException } from 'helper/error';
import { routes } from 'helper/route';
import { useAppDispatch, useAppSelector } from 'hook/redux';
import { Card, CardContent, Link } from '@mui/material';
import env from 'env';
import { isErrType } from 'helper/util';
import { useTranslation } from 'react-i18next';

const Login = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // read return url from query string
  const ret = new URLSearchParams(window.location.search).get('ret');

  const { isLoginInProgress, isLoggedIn, loginError, returnUrl } = useAppSelector(state => state.Auth.Login);

  const [error, setError] = useState<string | null>(null);

  const formInitialValues = {
    email: '',
    passwd: '',
  };

  const { values, errors, setSubmitting, setFieldError, setStatus, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      email: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
      passwd: Yup.string().trim().required(t("fieldIsRequired")),
    }),
    onSubmit: values => {
      dispatch(loginUser(values));
    },
  });

  useEffect(() => {
    if (ret && !ret.includes('/logout')) {
      dispatch(saveReturnUrl(ret));
    }
  }, [dispatch, ret]);

  useEffect(() => {
    if (isLoggedIn === true) {
      const redirectTo = returnUrl || routes.home;
      window.location.replace(redirectTo);
    } else if (isLoggedIn === false) {
      setSubmitting(false);
      let errMessage = t("unableToLogin");
      if (!!loginError) {
        // see if the login failed due to validation
        if (isErrType(loginError, ValidationException)) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(loginError.fields)) {
            setFieldError(name, t(message));
          }
          // see if the login failed due to credentials
        } else if (isErrType(loginError, AuthException)) {
          switch (loginError.code) {
            case USER_NOT_FOUND:
              errMessage = t("userNotFound");
              break;
            case INVALID_CREDENTIALS:
              errMessage = t("invalidCredentials");
              break;
          }
        }
      }
      setError(errMessage);
    }
  }, [isLoggedIn, loginError, returnUrl, setFieldError, setSubmitting, t]);

  const onFieldFocused = (e: FocusEvent<HTMLInputElement>, fieldName?: string) => {
    const name = fieldName || e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }

  return <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
    <Card sx={{ maxWidth: 450 }}>
      <CardContent sx={{ p: 3, '&:last-child': { pb: 3 } }}>
        <Box sx={{ height: 100, display: "flex", justifyContent: "center", mb: 8 }}>
          <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
        <Box>
          <form noValidate onSubmit={handleSubmit}>
            {!!error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
            <TextField name="email" label={t("emailAddress")} variant="outlined" required onChange={handleChange} onFocus={onFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%', mb: 3 }} />
            <TextField name="passwd" label={t("password")} variant="outlined" type="password" required onChange={handleChange} onFocus={onFieldFocused} value={values.passwd} error={!!errors.passwd} helperText={errors.passwd} sx={{ width: '100%', mb: 1 }} />
            <Box sx={{ textAlign: 'right', mb: 3 }}>
              <Link href={routes.lost_password} sx={{ fontSize: ".75rem", color: "#98a6ad" }}>{t("forgotYourPassword")}</Link>
            </Box>
            <Button type="submit" className='buttonAlwaysText' variant="contained" size="large" disabled={isLoginInProgress} sx={{ width: '100%' }}>
              {isLoginInProgress && <CircularProgress color="white" size={18} sx={{ mr: 2 }} />}
              {t("signIn")}
            </Button>
          </form>
        </Box>
      </CardContent>
    </Card>
    <Box sx={{ textAlign: 'center', mt: 3, color: "#98a6ad" }}>
      <Typography variant="caption" display="block">
        © {new Date().getFullYear()} {env.APP_TITLE} by <a href="https://www.codeadept.ro" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#33AD93", fontWeight: 600 }}>CodeAdept</a>
      </Typography>
    </Box>
  </Box>
}

export default Login;
