import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import ProgressButton from "component/common/ProgressButton";
import { routes } from "helper/route";
import { showError } from "helper/util";
import withStripeElements from "hoc/stripeElements";
import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";

const PaymentForm = ({ onFinish }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false)
  // whether stripe elements are loading
  const [stripeIsLoading, setStripeIsLoading] = useState(true);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    setIsSubmitInProgress(true);
    const { error } = await stripe!.confirmSetup({
      // @ts-ignore
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_URL}${routes.my_organization}`,
      },
    });
    if (error) {
      // This point will only be reached if there is an immediate error (before redirect)
      showError(error.message!);
      setIsSubmitInProgress(false);
    }
    // user will be redirected to `return_url`
  }

  const isStripeReady = () => !!stripe && !!elements && !stripeIsLoading;

  return (
    <form onSubmit={handleSubmit}>
      <CardTitleBar title={t("payment")} sx={{ mb: 4 }} >
        <ProgressButton type="submit" name="submitPayment" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("savePayment")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <PaymentElement onReady={() => setStripeIsLoading(false)} />
      {!isStripeReady() && <Preloader container="parent" />}
    </form>
  )
}

export default withStripeElements(PaymentForm);