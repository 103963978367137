import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { route, routes } from "helper/route";
import { AppError } from "@type";
import { deleteDuArticle, getDuArticle } from "helper/backend";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess, toSimpleError } from "helper/util";
import Error from 'page/Error';
import SectionInfo from "./Partial/SectionInfo";
import DuArticle from "model/duArticle";
import { useTranslation } from "react-i18next";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { articleId, duId } = useParams();
  const recordId = parseInt(articleId!);
  const distributionUnitId = parseInt(duId!);

  // the article record fetched from the backend
  const [duArticle, setDuArticle] = useState<DuArticle | undefined>()
  // error encoutered while fetching the article (if any)
  const [duArticleError, setDuArticleError] = useState<AppError | undefined>();
  // whether the loading of the article is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the article is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the article record from the backend
  */
  const refreshArticle = useCallback(() => {
    setIsLoadInProgress(true)
    getDuArticle(recordId)
      .then(response => {
        setDuArticle(response.distributionUnitArticle);
      })
      .catch(ex => {
        setDuArticleError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId]);

  /**
  * Deletes the article record in the backend
  */
  const removeArticle = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteDuArticle(recordId)
      .then(_response => {
        showSuccess(t("articleHasBeenRemoved"));
        return navigate(route(routes.view_distirbution_unit, distributionUnitId));
      })
      .catch(_ex => {
        let errMessage = t("unableToRemoveArticle");
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, distributionUnitId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshArticle();
  }, [refreshArticle]);

  return (
    <>
      {isGranted(perms.view_distribution_unit_articles)
        &&
        <>
          {
            !!duArticle
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(duArticle)}>
                {isGranted(perms.delete_distribution_unit_articles) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("removeArticle")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={route(routes.view_distirbution_unit, distributionUnitId)} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo duArticle={duArticle} refreshHandler={refreshArticle} isLoadInProgress={isLoadInProgress} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("remove")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeArticle();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("removeArticleConfirmation")} &quot;{duArticle.articleName}&quot;?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !duArticle && <Preloader container="content" />}
          {!!duArticleError && <Error error={duArticleError} title404={t("articleNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_distribution_unit_articles) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (duArticle: DuArticle) => [
  {
    text: duArticle.articleName!,
  }];

export default Single;