import {
  APPLY_INVOICES_DT_PARAMS,
  PATCH_INVOICES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyInvoiceDtParams = (params: DtParams) => ({
  type: APPLY_INVOICES_DT_PARAMS,
  payload: { params },
});

export const patchInvoiceDtParams = (params: DtParams) => ({
  type: PATCH_INVOICES_DT_PARAMS,
  payload: { params },
});