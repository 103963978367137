import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { routes } from 'helper/route';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import AccessDenied from 'page/Error/AccessDenied';
import FormInvite from './Partial/Form/Invite';
import { useTranslation } from 'react-i18next';
import GridContainer from 'component/common/GridContainer';

const InviteOrganization = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    {isGranted(perms.invite_organizations) &&
      <Box>
        <PageTitleBar breadcrumbs={breadcrumbs(t)} />
        <GridContainer>
          <Grid xs={12} md={6} >
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <FormInvite />
              </CardContent>
            </Card>
          </Grid>
        </GridContainer>
      </Box>
    }
    {isNotGranted(perms.invite_organizations) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (t: Function) => [{
  text: t("organization"),
  url: routes.list_organizations,
}, {
  text: t("inviteOrganization"),
}];

export default InviteOrganization;