import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { AppError } from "@type";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import Spinner from "component/common/Spinner";
import { getDuArticles, getDuSupplyPDF } from "helper/backend";
import { showError, toSimpleError } from "helper/util";
import DuArticle from "model/duArticle";
import env from "env";
import { route, routes } from "helper/route";
import { useTranslation } from "react-i18next";
import ProgressButton from "component/common/ProgressButton";
import { FileDownload } from "@mui/icons-material";
import { formatTimestamp, formats } from "helper/date";
import moment from "moment";


type Props = {
  distributionUnitId: number;
  distributionUnitName: string;
}

const SectionStock = ({ distributionUnitId, distributionUnitName }: Props) => {

  const { t } = useTranslation();

  // the duArticles record fetched from the backend
  const [duArticles, setDuArticles] = useState([] as DuArticle[]);
  // whether the loading of the duArticles is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // error encoutered while fetching the duArticles (if any)
  const [error, setError] = useState<AppError>();
  // the number of seen articles in the Users Table
  const [numOfVisibleArticles, setNumOfVisibleArticles] = useState(env.COLLAPSED_TABLE_ROWS);
  // whether the loading of the download is in progress
  const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false)

  /**
  * Download the operation record from the backend
  */
  const downloadReport = useCallback(() => {
    setIsDownloadInProgress(true)
    getDuSupplyPDF(distributionUnitId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DistributionUnit_${distributionUnitName.replace(/\s/g, '')}_${formatTimestamp(moment().unix(), formats.DATE_SPLIT)}T${formatTimestamp(moment().unix(), formats.LONG_TIME)}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
      })
      .catch(_ex => {
        showError(t("unableToDownloadReport"));
      })
      .finally(() => {
        setIsDownloadInProgress(false);
      })
  }, [distributionUnitId, t, distributionUnitName])

  /**
  * On click function that shows all the users
  */
  const viewMore = () => {
    setNumOfVisibleArticles(duArticles.length);
  };

  /**
  * On click function that shows only the number of users set in the environment variable
  */
  const viewLess = () => {
    setNumOfVisibleArticles(env.COLLAPSED_TABLE_ROWS);
  };

  /**
  * Fetches the organization users from the backend
  */
  const getDuArticlesList = useCallback(() => {
    setIsLoadInProgress(true)
    getDuArticles(distributionUnitId)
      .then(response => {
        setDuArticles(response.articles);
      })
      .catch(ex => {
        setError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [distributionUnitId]);

  // This hook runs once on component mount
  useEffect(() => {
    getDuArticlesList();
  }, [getDuArticlesList]);

  const viewArticleLink = (duId: number, id: number) => route(routes.view_distribution_unit_article, [duId, id]);

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title={t("targetStockLevel")}>
          {
            !!duArticles.length &&
            <ProgressButton onClick={downloadReport} variant="contained" color="secondary" sx={{ mr: 1 }} isBusy={isDownloadInProgress} startIcon={<FileDownload />}>{t("downloadReport")}</ProgressButton>
          }
          <Button variant="contained" color="primary" component={Link} href={route(routes.new_distribution_unit_article, distributionUnitId)} startIcon={<PlaylistAddIcon />} sx={{ ml: 1 }}>{t("addArticle")}</Button>
        </CardTitleBar>
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>{t("article")}</TableCell>
              <TableCell>{t("minimum")}</TableCell>
              <TableCell>{t("current")}</TableCell>
              <TableCell>{t("balance")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!duArticles.length && duArticles.slice(0, numOfVisibleArticles).map((duArticle, idx) =>
              <TableRow key={idx}>
                <TableCell>
                  <Link href={viewArticleLink(distributionUnitId, duArticle.id)}>{duArticle.articleName} ({duArticle.articleCode})</Link>
                </TableCell>
                <TableCell>{duArticle.minStock}</TableCell>
                <TableCell>{duArticle.currentStock}</TableCell>
                <TableCell
                  sx={{
                    color: duArticle.currentStock - duArticle.minStock < 0 ? "#f34943" : "#31ce77"
                  }}
                >
                  {duArticle.currentStock - duArticle.minStock}</TableCell>
              </TableRow>)}
            {!duArticles.length && !isLoadInProgress && !error && <TableRow>
              <TableCell colSpan={4} align="center">{t("noStockLevels")}</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {duArticles.length > env.COLLAPSED_TABLE_ROWS && <Grid container justifyContent="center" alignItems="center">
          {numOfVisibleArticles === duArticles.length
            ?
            <Button startIcon={<KeyboardArrowUpIcon />} sx={{ mt: 2 }} onClick={viewLess}>
              {t("viewLess")}
            </Button>
            :
            <Button startIcon={<KeyboardArrowDownIcon />} sx={{ mt: 2 }} onClick={viewMore}>
              {t("viewMore")}
            </Button>
          }
        </Grid>}
        {!!error && <Alert severity="error" sx={{ justifyContent: 'center' }}>{t("unableToLoadDistributionUnitArticles")}</Alert>}
        {isLoadInProgress && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}><Spinner sx={{ width: 200 }} /></Box>}
      </CardContent>
    </EditableCard>
  </React.Fragment>
};

export default SectionStock;