import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, CardContent, Link, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import EditableCard from "component/styled/EditableCard";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import AccessDenied from "page/Error/AccessDenied";
import { useDebounce } from "hook/Debounce";
import { DtParams } from "@type";
import { perms, useAccess } from "context/access";
import { useAppDispatch, useAppSelector } from "hook/redux";
import { extractDtParamsFromUrl, insertDtParamsIntoUrl } from "helper/dt";
import { applyPackageParams, patchPackageParams } from "store/actions";
import { showError, toSimpleError } from "helper/util";
import { getPackages } from "helper/backend";
import Package from "model/package";
import { PACKAGE_NOT_FOUND } from "helper/error";
import { useTranslation } from "react-i18next";
import { formatTimestamp, formats } from "helper/date";
import Location from "model/location";
import { route, routes } from "helper/route";
import SectionTags from "./Partial/SectionTags";
import Tag from "model/tag";
import QRCode from "react-qr-code";
import CardTitleBar from "component/common/CardTitleBar";

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess()
  const { t } = useTranslation();

  // Package fetched from the backend
  const [packageFound, setPackage] = useState<Package | undefined>();
  // Location fetched from the backend
  const [location, setLocation] = useState<Location>();
  // Tags fetched from the backend
  const [tags, setTags] = useState<Tag[]>();
  // Package search params
  const searchParams = useAppSelector(store => store.Package.SearchRequest);
  // Search input value
  const [searchValue, setSearchValue] = useState<string>(searchParams.search!)
  // whether the loading of the package is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // Debounced searchParams
  const debouncedSearchParams = useDebounce(searchParams, 500)

  // This hook runs once on component mount
  useEffect(() => {
    /**
    * Search params may sometimes be passed in the url
    * so here we attempt to read any params from the url
    */
    const urlParams = extractDtParamsFromUrl();
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }

    // update the search params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchPackageParams(urlParams));
  }, [dispatch]);

  // This hook runs every time the search params change
  useEffect(() => {
    // We don't need to do extra calls if the input is empty
    if (searchValue.length === 0) {
      setPackage(undefined);
      return;
    }
    // fetch the list of search rows from the server
    setIsLoadInProgress(true);
    getPackages(searchParams)
      .then(response => {
        setPackage(response.package);
        setLocation(response.location);
        setTags(response.tags)
      })
      .catch(ex => {
        let errMessage = t("unableToFindPackage");
        const err = toSimpleError(ex)
        switch (err.code) {
          case PACKAGE_NOT_FOUND:
            setPackage(undefined);
            break;
          default:
            showError(errMessage);
        }
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
    // having 'searchParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'debouncedSearchParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchParams]);

  // Saves the search value from store
  useEffect(() => {
    setSearchValue(searchParams.search! || "")
  }, [searchParams])

  /**
  * Saves new Search params to the redux store
  * @param params
  */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyPackageParams(params));
  }, [dispatch]);

  // Handle search change
  const handleChange = (e: ChangeEvent<any>) => {
    updateDtParams({ search: e.target.value })
  }

  return (
    <>
      {isGranted(perms.view_packages) &&
        <>
          <PageTitleBar title={t("packageInfo")} />
          <GridContainer>
            <Grid xs={12} md={6}>
              <EditableCard>
                <CardContent>
                  <TextField name="package" label={t("searchPackage")} variant="outlined" onChange={handleChange} value={searchValue} sx={{ width: '100%', mb: 2 }} />
                  <Box position="relative">
                    {!!packageFound ?
                      <TableNoBb>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("packageCode")}</TableCell>
                            <TableCell className="preview-value">{packageFound?.code}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("noOfItems")}</TableCell>
                            <TableCell className="preview-value">{packageFound?.items}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("location")}</TableCell>
                            <TableCell className="preview-value">
                              <Link href={route(routes.view_location, location?.id)}>
                                {location?.name}
                              </Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("created")}</TableCell>
                            <TableCell className="preview-value">{formatTimestamp(packageFound.createdTs, formats.DATETIME)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </TableNoBb>
                      :
                      searchValue.length > 0 &&
                      t("noPackageFound")
                    }
                    {isLoadInProgress && <Preloader container="parent" />}
                  </Box>
                </CardContent>
              </EditableCard>
            </Grid>
            {!!packageFound && <Grid xs={12} md={6}>
              <EditableCard sx={{ height: "100%" }}>
                <CardContent>
                  <CardTitleBar title={t("qrCode")} />
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "12px" }}>
                    <Box
                      sx={{
                        mt: 2,
                        width: { xs: "100%", lg: "180px" },
                        height: { xs: "100%", lg: "180px" },
                      }}>
                      <QRCode
                        id="qr-code"
                        value={`${packageFound.code}` || ""}
                        style={{
                          height: "100%",
                          width: "100%"
                        }}
                      />
                    </Box>
                  </Box>
                </CardContent>
              </EditableCard>
            </Grid>}
            {!!packageFound && <Grid xs={12}>
              <SectionTags tags={tags} />
            </Grid>}
          </GridContainer>

        </>
      }
      {isNotGranted(perms.view_packages) && <AccessDenied />}
    </>
  )
}

export default List;