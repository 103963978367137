import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "store/actions";

const Logout = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch])

  return (
    <></>
  )
}

export default Logout;