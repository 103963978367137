import React from "react";
import { CardContent, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import AppVersion from "model/appVersion";
import Device from "model/device";
import EditableCard from "component/styled/EditableCard";
import { TaskAlt, Unpublished } from "@mui/icons-material";

type Props = {
  appVersion: AppVersion;
};

const SectionInfoView = ({ appVersion }: Props) => {

  const { t } = useTranslation();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("generalInfo")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("version")}</TableCell>
              <TableCell className="preview-value">{appVersion.name} {!!appVersion.isBeta ? "(beta)" : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("deviceType")}</TableCell>
              <TableCell className="preview-value">{Device.getDeviceTypeName(appVersion.deviceType)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("active")}</TableCell>
              <TableCell className="preview-value">{appVersion.isActive ? <TaskAlt /> : <Unpublished />}</TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionInfoView;