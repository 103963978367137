import React from "react";
import UserPicture1 from 'asset/image/UserPicture1.png';
import UserPicture2 from 'asset/image/UserPicture2.png';
import UserPicture3 from 'asset/image/UserPicture3.png';
import UserPicture4 from 'asset/image/UserPicture4.png';
import GridContainer from "component/common/GridContainer";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";

type Props = {
  isClient?: boolean
}

const UserStep = ({ isClient = false }: Props) => {

  const { t } = useTranslation();

  return (
    <GridContainer sx={{ overflow: "auto", maxHeight: "80vh" }}>
      <Grid xs={12}>
        <Box sx={{ fontSize: "22px", fontWeight: 600, marginTop: "12px" }}>
          {t("userMenu")}
        </Box>
      </Grid>
      <Grid md={12} lg={6}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "70%" }}>
          {
            isClient
              ?
              <img src={UserPicture3} alt="" style={{ maxWidth: "100%", maxHeight: "80%", height: "auto", objectFit: "contain" }} />
              :
              <img src={UserPicture1} alt="" style={{ maxWidth: "100%", maxHeight: "80%", height: "auto", objectFit: "contain" }} />
          }
        </Box>
        <p>
          {t("userMenuText1")}
        </p>
      </Grid>
      <Grid md={12} lg={6}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "70%" }}>
          {
            isClient
              ?
              <img src={UserPicture4} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
              :
              <img src={UserPicture2} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
          }
        </Box>
        <p>
          {
            isClient
              ?
              t("userMenuClientText2")
              :
              t("userMenuOrganizationText2")
          }
        </p>
      </Grid>
    </GridContainer>
  )
};

export default UserStep;