import React, { FocusEvent } from "react";
import { Autocomplete, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import Article from "model/article";
import { useTranslation } from "react-i18next";

const FormCreate = ({ formik }: any) => {
  const { values, errors, setStatus, handleChange, setFieldValue } = formik;

  const { t } = useTranslation();

  /**
  * Converts the list of distribution modes to select options
  */
  const getDistributionModeOptions = () => Article.DistributionModes.map(distrMode => ({ label: distrMode.name, value: distrMode.id }));

  /**
  * Returns the selected organization as a select option
  */
  const getSelectedDistributionModeOption = () => getDistributionModeOptions().find(option => option.value === values.distributionMode) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="rentPrice" label={t("rentPrice")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.rentPrice} error={!!errors.rentPrice} helperText={errors.rentPrice} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="purchasePrice" label={t("purchasePrice")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.purchasePrice} error={!!errors.purchasePrice} helperText={errors.purchasePrice} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="packageItemCount" label={t("numberOfItemsInPackage")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.packageItemCount} error={!!errors.packageItemCount} helperText={errors.packageItemCount} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('distributionMode', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('distributionMode');
          }}
          value={getSelectedDistributionModeOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getDistributionModeOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("distributionMode")} error={!!errors.distributionMode} helperText={errors.distributionMode} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
    </GridContainer >
  )
}

export default FormCreate;