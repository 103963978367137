import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';
import { getOrganizationDistributionUnits } from 'helper/backend';
import DistributionUnit from 'model/distributionUnit';
import MuiAutocomplete from 'component/common/MuiAutocomplete';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, setFieldValue, setFieldError }: Props) => {

  const { t } = useTranslation();

  const [distributionUnits, setDistributionUnits] = useState<DistributionUnit[]>([]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchDus = useCallback(() => {
    getOrganizationDistributionUnits()
      .then(response => {
        setDistributionUnits(response.distributionUnits);
      })
      .catch(_ex => {
        setFieldError('distributionUnitId', t("unableToLoadDistributionUnits"));
      });
  }, [setFieldError, t]);

  /**
   * Converts the list of distributionUnitId to select options
   */
  const getDuOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = distributionUnits.map(distributionUnit => ({ label: distributionUnit.name, value: distributionUnit.id }));
    options.push(...orgs);
    return options;
  }, [distributionUnits, t]);

  useEffect(() => {
    fetchDus();
  }, [fetchDus])

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('distributionUnitId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('distributionUnitId');
              }}
              value={getDuOptions().find(option => option.value === +values.distributionUnitId) || getDuOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              groupBy={(option: any) => option?.type!}
              options={getDuOptions()}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderInput={(params: any) => <TextField {...params} label={t("distributionUnit")} error={!!errors.distributionUnitId} helperText={errors.distributionUnitId} />}
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;