import { AuthRequest, AuthResponse, GetAuthUserResponse, LogoutResponse } from '@type/backend';
import {
  GET_AUTH_USER,
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  REFRESH_AUTH_USER,
  REFRESH_AUTH_USER_OK,
  REFRESH_AUTH_USER_ERR,
  LOGIN_USER,
  LOGIN_USER_OK,
  LOGIN_USER_ERR,
  SAVE_RETURN_URL,
  LOGOUT_USER,
  LOGOUT_USER_OK,
  LOGOUT_USER_ERR,
} from './actionTypes';
import { AuthLoginActionPayload } from './types';
import { toSimpleError } from '../../helper/util';

/********** LOGIN **********/

export const getAuthUser = () => ({
  type: GET_AUTH_USER,
  payload: {} as AuthLoginActionPayload,
});

export const getAuthUserOk = (response: GetAuthUserResponse) => ({
  type: GET_AUTH_USER_OK,
  payload: { response },
});

export const getAuthUserErr = (error: Error) => ({
  type: GET_AUTH_USER_ERR,
  payload: { error: toSimpleError(error) },
});

export const refreshAuthUser = () => ({
  type: REFRESH_AUTH_USER,
  payload: null,
});

export const refreshAuthUserOk = (response: GetAuthUserResponse) => ({
  type: REFRESH_AUTH_USER_OK,
  payload: { response },
});

export const refreshAuthUserErr = (error: Error) => ({
  type: REFRESH_AUTH_USER_ERR,
  payload: { error: toSimpleError(error) },
});

export const loginUser = (data: AuthRequest) => ({
  type: LOGIN_USER,
  payload: { data },
});

export const loginUserOk = (response: AuthResponse) => ({
  type: LOGIN_USER_OK,
  payload: { response },
});

export const loginUserErr = (error: Error) => ({
  type: LOGIN_USER_ERR,
  payload: { error: toSimpleError(error) },
});

export const saveReturnUrl = (url: string) => ({
  type: SAVE_RETURN_URL,
  payload: { url },
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: null,
});

export const logoutUserOk = (response: LogoutResponse) => ({
  type: LOGOUT_USER_OK,
  payload: { response },
});

export const logoutUserErr = (error: Error) => ({
  type: LOGOUT_USER_ERR,
  payload: { error: toSimpleError(error) },
});