import React from "react";
import { DryCleaning, LocalLaundryService } from "@mui/icons-material";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import DistributionUnit from "model/distributionUnit";
import { useTranslation } from "react-i18next";

type Props = {
  distributionUnit: DistributionUnit;
};

const SectionArticles = ({ distributionUnit }: Props) => {

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      <EditableCard sx={{ height: "110px", padding: 0 }}>
        <CardContent sx={{ padding: "0px !important" }}>
          <Box sx={{ background: "linear-gradient(15deg,#02a8b5 0,#31ce77 100%)", width: "100%", height: "100%", position: "relative", p: 3, color: "#FFFFFF", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Box sx={{ fontSize: "20px" }}>{t("cleanArticles")}</Box>
              <Box sx={{ fontSize: "24px" }}>
                {distributionUnit?.cleanItems}
              </Box>
            </Box>
            <DryCleaning sx={{ fontSize: "40px", opacity: "0.7" }} />
          </Box>
        </CardContent>
      </EditableCard >
      <EditableCard sx={{ height: "110px", padding: 0 }}>
        <CardContent sx={{ padding: "0px !important" }}>
          <Box sx={{ background: "linear-gradient(15deg,#f67773 0,#ff679b 100%)", width: "100%", height: "100%", position: "relative", p: 3, color: "#FFFFFF", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Box sx={{ fontSize: "20px" }}>{t("soiledArticles")}</Box>
              <Box sx={{ fontSize: "24px" }}>
                {distributionUnit?.soiledItems}
              </Box>
            </Box>
            <LocalLaundryService sx={{ fontSize: "40px", opacity: "0.7" }} />
          </Box>
        </CardContent>
      </EditableCard >
    </Box>
  )
}

export default SectionArticles;