import {
  APPLY_PICKUPS_DT_PARAMS,
  PATCH_PICKUPS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyPickupDtParams = (params: DtParams) => ({
  type: APPLY_PICKUPS_DT_PARAMS,
  payload: { params },
});

export const patchPickupDtParams = (params: DtParams) => ({
  type: PATCH_PICKUPS_DT_PARAMS,
  payload: { params },
});