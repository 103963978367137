import React, { useState } from 'react';
import { Box, Button, Link } from '@mui/material';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import AccessDenied from 'page/Error/AccessDenied';
import FormCreateStandaloneDevice from '../Partial/Form/CreateStandaloneDevice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ValidationException } from 'helper/error';
import { isErrType, showError, showSuccess, toSimpleError } from 'helper/util';
import { useNavigate } from 'react-router-dom';
import { createStandaloneDevice } from 'helper/backend';
import ProgressButton from 'component/common/ProgressButton';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { route, routes } from 'helper/route';
import { useTranslation } from 'react-i18next';

type Values = {
  name: string;
  code: string;
  organizationId: number | undefined;
  locationId: number | undefined;
};

const NewStandaloneDevice = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    name: '',
    code: '',
    organizationId: undefined,
    locationId: undefined,
  };

  /**
  * Form validation rules
  */
  const validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    code: Yup.string().trim().required(t("fieldIsRequired")),
    organizationId: Yup.number().required(t("fieldIsRequired")),
    locationId: Yup.number().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDevice(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDevice = (values: any) => {
    setIsSubmitInProgress(true);
    createStandaloneDevice(values)
      .then(_response => {
        showSuccess(t("deviceHasBeenSaved"));
        return navigate('/devices')
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDevice"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  return <React.Fragment>
    {isGranted(perms.create_devices) &&
      <>
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <PageTitleBar breadcrumbs={breadcrumbs(t)}>
              <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveDevice")}</ProgressButton>
              <Button variant="contained" color="secondary" component={Link} href={route(routes.list_devices)} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
            </PageTitleBar>
            <FormCreateStandaloneDevice formik={formik} />
          </form>
        </Box>
      </>
    }
    {isNotGranted(perms.create_devices) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (t: Function) => [
  {
    text: t("standaloneDevices"),
    url: routes.list_devices,
  },
  {
    text: t("newStandaloneDevice"),
  }
];

export default NewStandaloneDevice;
