export default class Device {
  id!: number;
  organizationId!: number;
  organizationName?: string | null;
  name!: string;
  code!: string;
  lastSyncTs!: number | null;
  createdTs!: number;
  updatedTs!: number;
  distributionUnitId!: number | null;
  distributionUnitName?: string | null;
  type!: number;
  locationId!: number;
  locationName!: string;
  vendor!: number;
  readerIp!: string;
  readerSensitivity!: number;
  readerPower!: number;
  lastLoginTs!: number;
  lastLoginUserId!: number;
  userFullName!: string;

  // Types

  static TYPE_TRACEABILITY = 1;
  static TYPE_SECURITY = 3;
  static TYPE_WAREHOUSE = 4;

  static DeviceTypes = [
    {
      id: this.TYPE_TRACEABILITY,
      name: "Traceability"
    },
    {
      id: this.TYPE_SECURITY,
      name: "Security"
    },
  ];

  static StandaloneDeviceTypes = [
    {
      id: this.TYPE_WAREHOUSE,
      name: "Warehouse"
    }
  ];

  static getDeviceTypeName(id: number) {
    const allDevices = [...this.DeviceTypes, ...this.StandaloneDeviceTypes]
    return allDevices.find((device) => device.id === id)?.name
  }
}