import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import Article from "model/article";
import { useAppSelector } from "hook/redux";
import { formatCurrency } from "helper/currency";
import { useTranslation } from "react-i18next";

type Props = {
  article: Article;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ article, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();
  const currency = useAppSelector(store => store.Auth.Login.user?.currency);

  return <React.Fragment>
    <CardTitleBar title={article.name!}>
      {isGranted(perms.edit_articles) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{article.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("code")}</TableCell>
          <TableCell className="preview-value">{article.code}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("rentPrice")}</TableCell>
          <TableCell className="preview-value">{formatCurrency(article.rentPrice, currency?.symbol!, currency?.position!, currency?.hasSpacing!)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("purchasePrice")}</TableCell>
          <TableCell className="preview-value">{formatCurrency(article.purchasePrice, currency?.symbol!, currency?.position!, currency?.hasSpacing!)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("numberOfItemsInPackage")}</TableCell>
          <TableCell className="preview-value">{article.packageItemCount || "--"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("distributionMode")}</TableCell>
          <TableCell className="preview-value">{Article.getDistributionModeName(article.distributionMode) || "--"}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;