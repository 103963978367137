import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { useTranslation } from "react-i18next";
import User from "model/user";

type Props = {
  driver: User;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ driver, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={driver.fullName!} {...(driver.isActive ? null : { chip: driver.isEmailVerified ? t("inactive") : t("pendingVerification"), bgcolor: 'warning.light' })}>
      {isGranted(perms.edit_drivers) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("firstName")}</TableCell>
          <TableCell className="preview-value">{driver.firstName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("lastName")}</TableCell>
          <TableCell className="preview-value">{driver.lastName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("email")}</TableCell>
          <TableCell className="preview-value">{driver.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("timezone")}</TableCell>
          <TableCell className="preview-value">{driver.timezone}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;