import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Link } from '@mui/material';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import Preloader from "component/common/Preloader";
import { useParams } from 'react-router-dom';
import { routes, route } from 'helper/route';
import { getDu } from 'helper/backend';
import { AppError } from '@type';
import AccessDenied from 'page/Error/AccessDenied';
import Error from 'page/Error';
import DistributionUnit from 'model/distributionUnit';
import FormCreateDuDevice from '../Partial/Form/CreateDuDevice';
import ProgressButton from 'component/common/ProgressButton';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { isErrType, showError, showSuccess, toSimpleError } from 'helper/util';
import { createDuDevice } from 'helper/backend';
import { ValidationException } from 'helper/error';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type Values = {
  name: string;
  code: string;
  type: number | undefined;
};

const NewDuDevice = () => {

  const navigate = useNavigate();
  const { allAreGranted, notAllAreGranted } = useAccess();
  const { id } = useParams();
  const { t } = useTranslation();
  const duId = parseInt(id!)

  // the distribution unit record fetched from the backend
  const [distributionUnit, setDistributionUnit] = useState<DistributionUnit>()
  // error encoutered while fetching the distribution unit (if any)
  const [distributionUnitError, setDistributionUnitError] = useState<AppError>();
  // whether the loading of the distribution unit is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    name: '',
    code: '',
    type: undefined,
  };

  /**
  * Form validation rules
  */
  const validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    code: Yup.string().trim().required(t("fieldIsRequired")),
    type: Yup.number().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDevice(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDevice = (values: any) => {
    setIsSubmitInProgress(true);
    createDuDevice(duId, values)
      .then(_response => {
        showSuccess(t("deviceHasBeenSaved"));
        return navigate(`/distribution-unit/${duId}`)
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDevice"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Fetches from the backend the distribution unit
  */
  const fetchDu = useCallback(() => {
    setIsLoadInProgress(true)
    getDu(duId)
      .then(response => {
        setDistributionUnit(response.distributionUnit);
      })
      .catch(ex => {
        setDistributionUnitError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [duId]);

  // This hook runs on first component mount to save the distribution unit in the state
  useEffect(() => {
    fetchDu()
  }, [fetchDu]);

  return <React.Fragment>
    {allAreGranted([perms.create_devices, perms.edit_distribution_units]) && <>
      {!!distributionUnit &&
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <PageTitleBar breadcrumbs={breadcrumbs(distributionUnit, t)}>
              <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveDevice")}</ProgressButton>
              <Button variant="contained" color="secondary" component={Link} href={route(routes.view_distirbution_unit, duId)} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
            </PageTitleBar>
            <FormCreateDuDevice formik={formik} />
          </form>
        </Box>
      }
      {isLoadInProgress && !distributionUnit && <Preloader container="parent" />}
      {!!distributionUnitError && <Error error={distributionUnitError} title404={t("distributionUnitNotFound")} />}
    </>}
    {notAllAreGranted([perms.create_devices, perms.edit_distribution_units]) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (distributionUnit: DistributionUnit | undefined, t: Function) => [
  {
    text: t("distributionUnits"),
    url: routes.list_distribution_units,
  },
  {
    text: distributionUnit?.name || "",
    url: route(routes.view_distirbution_unit, distributionUnit?.id),
  },
  {
    text: `${t("newDeviceFor")} ${distributionUnit?.name}`,
  }
];

export default NewDuDevice;
