// socket events
// they need to match the back-end values
export const socketEvent = {
  paymentSuccess: "paymentSuccess",
  paymentFailed: "paymentFailed",
  paymentUnhandled: "paymentUnhandled",
  intentProcessingFailed: "intentProcessingFailed",
  tagsChanged: "tagsChanged",
  subscribeToOwnOrganization: "subscribeToOwnOrganization",
  unsubscribeFromOwnOrganization: "unsubscribeFromOwnOrganization",
  duChanges: "duChanges",
  subscribeToMaster: "subscribeToMaster",
  unsubscribeFromMaster: "unsubscribeFromMaster",
}