import React, { useState, FocusEvent } from "react";
import { Button, TextField, FormControlLabel, Switch } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import Organization from "model/organization";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { regexPhone } from "constant/regex";
import { updateOrganization } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";

type Values = {
  companyName: string;
  vatNo: string | null;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  isActive: boolean;
};

type Props = {
  organization: Organization;
  onFinish: Function;
};

const SectionInfoEdit = ({ organization, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    companyName: '',
    vatNo: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    isActive: false
  };
  formInitialValues = {
    ...formInitialValues,
    companyName: organization.companyName,
    vatNo: organization.vatNo,
    contactFirstName: organization.contactFirstName,
    contactLastName: organization.contactLastName,
    contactEmail: organization.contactEmail,
    contactPhone: organization.contactPhone,
    isActive: organization.isActive
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    companyName: Yup.string().trim().required(t("fieldIsRequired")),
    isActive: Yup.boolean(),
    contactFirstName: Yup.string().trim().required(t("fieldIsRequired")),
    contactLastName: Yup.string().trim().required(t("fieldIsRequired")),
    contactEmail: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    contactPhone: Yup.string().trim().required(t("fieldIsRequired")).matches(regexPhone, t("invalidPhoneNumber")),
    vatNo: Yup.string().trim(),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveOrganization(values);
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveOrganization = (values: any) => {
    setIsSubmitInProgress(true)
    updateOrganization(organization.id!, values)
      .then(_response => {
        showSuccess(t("organizationHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableSaveOrganization"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={organization.companyName!} sx={{ mb: 4 }} >
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveGeneralInfo")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="companyName" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.companyName} error={!!errors.companyName} helperText={errors.companyName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="vatNo" label={t("vatNumber")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatNo} error={!!errors.vatNo} helperText={errors.vatNo} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="contactFirstName" label={t("contactFirstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactFirstName} error={!!errors.contactFirstName} helperText={errors.contactFirstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="contactLastName" label={t("contactLastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactLastName} error={!!errors.contactLastName} helperText={errors.contactLastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="contactEmail" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactEmail} error={!!errors.contactEmail} helperText={errors.contactEmail} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="contactPhone" label={t("phone")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactPhone} error={!!errors.contactPhone} helperText={errors.contactPhone} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={8}>
        <FormControlLabel control={<Switch name="isActive" onChange={handleChange} checked={values.isActive} />} label={t("active")} />
      </Grid>
    </GridContainer>
  </form>
}

export default SectionInfoEdit;
