import React, { useState, FocusEvent } from "react";
import { Alert, Box, Button, Card, CardContent, Divider, Link, TextField, Typography } from "@mui/material";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { routes } from "helper/route";
import { getPasswordRequirements, getPasswordValidationSchema, validatePassword } from "helper/validation";
import { updatePassword } from "helper/backend";
import { isErrType, showSuccess, toSimpleError } from "helper/util";
import { ValidationException } from "helper/error";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { useFormik } from 'formik';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Check from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

type Values = {
  oldPasswd: string;
  passwdConf: string;
  passwd: string;
};

const SectionPassword = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  // whether the saving of the password is in progress
  const [isChangingPassInProgress, setIsChangingPassInProgress] = useState(false);
  // error encoutered while changing the password (if any)
  const [error, setError] = useState<string | null>(null);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    oldPasswd: '',
    passwdConf: '',
    passwd: ''
  };

  /**
   * Form configuration
   */
  const { values, errors, setStatus, handleChange, handleSubmit, setFieldError } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: formInitialValues,
    validate: validatePassword(Yup.object(getPasswordValidationSchema(true))),
    onSubmit: values => {
      changePassword(values);
    },
  });

  /**
  * Event handler called whenever the user changes his password
  */
  const changePassword = (values: any) => {
    setIsChangingPassInProgress(true);
    updatePassword(values)
      .then(_response => {
        showSuccess(t("passwordHasBeenSaved"));
        navigate(routes.my_account)
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        setError(t("unableToChangePassword"));
      })
      .finally(() => {
        setIsChangingPassInProgress(false);
      });
  }

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <Card>
      <CardContent>
        <form noValidate onSubmit={handleSubmit}>
          <CardTitleBar sx={{ mb: 4 }}>
            <ProgressButton type="submit" variant="contained" color="primary" isBusy={isChangingPassInProgress} startIcon={<Check />} sx={{ mr: 1 }}>{t("changePassword")}</ProgressButton>
            <Button variant="contained" color="secondary" component={Link} href={routes.my_account} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
          </CardTitleBar>
          {/* Visible when account activation has failed */}
          {!!error && <Alert color="error" sx={{ mb: 3 }}>{error}</Alert>}
          <TextField type="password" name="oldPasswd" label={t("currentPassword")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.oldPasswd} error={!!errors.oldPasswd} helperText={errors.oldPasswd} sx={{ width: '100%', mb: 3 }} />
          <Divider sx={{ mb: 3 }} />
          <TextField type="password" name="passwd" label={t("newPassword")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.passwd} error={!!errors.passwd} sx={{ width: '100%' }} />
          <Box sx={{ p: 1 }}>
            {getPasswordRequirements().map(requirement => {
              const failed = errors.passwd?.includes(requirement);
              const passed = values.passwd && !failed;
              const color = passed ? 'success.dark' : 'inherit';
              const Icon = passed ? CheckBoxOutlined : CheckBoxOutlineBlankOutlined;
              return <Typography variant="caption" key={requirement} color={color} sx={{ display: 'block', mt: 1 }}>
                <Icon fontSize="small" sx={{ verticalAlign: 'middle', mt: '-3px' }} /> {requirement}
              </Typography>
            })}
          </Box>
          <TextField type="password" name="passwdConf" label={t("confirmNewPassword")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.passwdConf} error={!!errors.passwdConf} helperText={errors.passwdConf} sx={{ width: '100%', mt: 3 }} />
        </form>
      </CardContent>
    </Card>
  )
}

export default SectionPassword;