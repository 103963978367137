import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { Edit } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";
import Organization from "model/organization";

type Props = {
  organization: Organization;
  onEdit: Function;
  isReloading: boolean;
}

const SectionCardView = ({ organization, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("payment")}>
      {isGranted(perms.edit_own_organization) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("cardNumber")}</TableCell>
          <TableCell className="preview-value">{`**** **** **** ${organization.cardNum}`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("expirationDate")}</TableCell>
          <TableCell className="preview-value">{`${organization.cardExpMonth} / ${organization.cardExpYear}`}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionCardView;