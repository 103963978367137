import React, { useState } from "react";
import { Button, CardContent, TableBody, TableCell, TableRow } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { getMasterCode } from "helper/backend";
import { showError, showSuccess } from "helper/util";
import Preloader from "component/common/Preloader";
import ConfirmDialog from "component/common/ConfirmDialog";
import { useTranslation } from "react-i18next";

const SectionMasterCode = () => {

  const { t } = useTranslation();

  const [masterCode, setMasterCode] = useState();
  // whether the chagning confirmation is visible
  const [isChangeConfOpen, setIsChangeConfOpen] = useState(false);
  // whether the changing of the code is in progress
  const [isChangeInProgress, setIsChangeInProgress] = useState(false);


  /**
  * Event handler called whenever the user changes the code
  */
  const refreshMasterCode = () => {
    setIsChangeInProgress(true);
    getMasterCode()
      .then((response: any) => {
        showSuccess(t("masterCodeHasChange"));
        setMasterCode(response.code)
      })
      .catch(_ex => {
        showError(t("masterCodeUnableChange"));
      })
      .finally(() => {
        setIsChangeInProgress(false);
      });
  };

  return (
    <>
      <EditableCard sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={"Master code"}>
            <Button variant="contained" color="secondary" startIcon={<RefreshIcon />} onClick={() => setIsChangeConfOpen(true)}>{t("generateMasterCode")}</Button>
          </CardTitleBar>
          <TableNoBb sx={{ overflow: "hidden" }}>
            <TableBody>
              <TableRow>
                <TableCell>{t("masterCode")}</TableCell>
                <TableCell className="preview-value">{masterCode || "--"}</TableCell>
              </TableRow>
            </TableBody>
          </TableNoBb>
          {isChangeInProgress && <Preloader container="parent" />}
        </CardContent>
      </EditableCard>
      <ConfirmDialog
        isOpen={isChangeConfOpen}
        yesButton={t("confirm")}
        onConfirm={() => {
          setIsChangeConfOpen(false);
          refreshMasterCode();
        }}
        onCancel={() => {
          setIsChangeConfOpen(false);
        }}
      >
        {t("masterCodeConfirmation")}
      </ConfirmDialog>
    </>
  )
}

export default SectionMasterCode;