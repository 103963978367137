import React from "react";
import { CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import Tag from "model/tag";
import { route, routes } from "helper/route";

type Props = {
  tags: Tag[] | undefined,
};

const SectionTags = ({ tags }: Props) => {

  const { t } = useTranslation();

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title={t("tagsPackage")} />
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>{t("epc")}</TableCell>
              <TableCell>{t("articleName")}</TableCell>
              <TableCell>{t("articleCode")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!tags && tags.map((tag, idx) =>
              <TableRow key={idx}>
                <TableCell>{tag.epc}</TableCell>
                <TableCell><Link href={route(routes.view_article, tag.articleId)}>{tag.articleName}</Link></TableCell>
                <TableCell>{tag.articleCode}</TableCell>
              </TableRow>)}
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment>
};

export default SectionTags;