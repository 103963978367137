import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import GridContainer from 'component/common/GridContainer';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import OutOfSupplyDus from 'component/dashboard/OutOfSupplyDus';
import { perms, useAccess } from 'context/access';
import OverDuePickups from 'component/dashboard/OverDuePickups';
import NextHandover from 'component/dashboard/NextHandover';
import UpcomingHandovers from 'component/dashboard/UpcomingHandovers';
import OverdueHandovers from 'component/dashboard/OverdueHandovers';
import PageTitleBar from 'component/common/PageTitleBar';
import { useTranslation } from 'react-i18next';
import OrganizationTutorial from 'component/tutorial/OrganizationTutorial';
import ClientTutorial from 'component/tutorial/ClientTutorial';
import { useAuth } from 'context/auth';
import { tutorialComplete } from 'helper/backend';
import { showError } from 'helper/util';
import Account from 'component/dashboard/Account';
import BusinessModels from 'component/dashboard/BusinessModels';
import Revenue from 'component/dashboard/Revenue';
import ClientCorrections from 'component/dashboard/ClientCorrections';
import Articles from 'component/dashboard/Articles';
import HighestRevenue from 'component/dashboard/HighestRevenue';
import GeoDistribution from 'component/dashboard/GeoDistribution';
import ArticleUsage from 'component/dashboard/ArticleUsage';
import ClientInHouseArticles from 'component/dashboard/ClientInHouseArticles';
import NextExpeditions from 'component/dashboard/NextExpeditions';

const Home = () => {

  const { isGranted, anyIsGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser, refreshAuthUser } = useAuth();

  const setTutorialComplete = () => {
    tutorialComplete()
      .then(_response => {
        refreshAuthUser();
      })
      .catch(_ex => {
        showError("Unable to complete the tutorial");
      });
  }

  useEffect(() => {
    if (window.innerWidth <= 900) {
      setTutorialComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitleBar breadcrumbs={breadcrumbs(t)} />
      <Box>
        <GridContainer>
          <Grid xs={12} md={4}>
            <Account />
            {
              isGranted(perms.view_article_usage) &&
              <ArticleUsage />
            }
          </Grid>
          {
            isGranted(perms.view_du_stock_level) &&
            <Grid xs={12} md={4}>
              <OutOfSupplyDus />
            </Grid>
          }
          {
            (authUser.isOrganizationAdmin() || authUser.isMasterAdmin()) &&
            <>
              <Grid xs={12} md={4}>
                <Revenue />
              </Grid>
            </>
          }
          {
            anyIsGranted([perms.view_next_handover, perms.view_in_house_articles, perms.view_next_expedition]) &&
            <Grid xs={12} md={4}>
              {
                isGranted(perms.view_next_handover) &&
                <NextHandover />
              }
              {
                isGranted(perms.view_next_expedition) &&
                <NextExpeditions />
              }
              {
                isGranted(perms.view_in_house_articles) &&
                <ClientInHouseArticles />
              }
            </Grid>
          }
          {
            authUser.isOrganizationAdmin() &&
            <>
              <Grid xs={12} md={4}>
                <ClientCorrections />
              </Grid>
              <Grid xs={12} md={4}>
                <Articles />
              </Grid>
            </>
          }
          {
            (authUser.isOrganizationAdmin() || authUser.isMasterAdmin()) &&
            <>
              <Grid xs={12} md={4}>
                <BusinessModels />
              </Grid>
              <Grid xs={12} md={4}>
                <HighestRevenue />
              </Grid>
            </>
          }
          {
            isGranted(perms.view_due_pickups) &&
            <Grid xs={12} md={4}>
              <OverDuePickups />
            </Grid>
          }
          {
            authUser.isMasterAdmin() &&
            <Grid xs={12} md={8}>
              <GeoDistribution />
            </Grid>
          }
          <Grid xs={12} md={4} sx={{ padding: 0 }}>
            {
              isGranted(perms.view_overdue_handovers) &&
              <Grid xs={12}>
                <OverdueHandovers />
              </Grid>
            }
            {
              isGranted(perms.view_upcoming_handovers) &&
              <Grid xs={12}>
                <UpcomingHandovers />
              </Grid>
            }
          </Grid>
        </GridContainer>
      </Box>
      {
        (!authUser.isTutorialComplete) && window.innerWidth > 900 &&
        <>
          {
            isGranted(perms.view_organization_tutorial) && authUser.isOrganizationAdmin() &&
            <OrganizationTutorial setTutorialComplete={setTutorialComplete} />
          }
          {
            isGranted(perms.view_client_tutorial) && authUser.isSsdClient() &&
            <ClientTutorial setTutorialComplete={setTutorialComplete} />
          }
        </>
      }
    </>
  )
}

const breadcrumbs = (t: Function) => [{
  text: t("dashboard"),
}];

export default Home;
