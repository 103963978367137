import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CardTitleBar from 'component/common/CardTitleBar';
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import Organization from "model/organization";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";

type Props = {
  organization: Organization;
  onEdit: Function;
  isReloading: boolean;
};

const SectionPaymentPlansView = ({ organization, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("paymentPlans")}>
      {isGranted(perms.edit_organizations) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("paymentPlan")}</TableCell>
          <TableCell className="preview-value">{organization.paymentPlanName}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionPaymentPlansView;