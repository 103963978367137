export const saveAccessToken = (token: string) => window.localStorage.setItem('acc', token);

export const saveRefreshToken = (token: string) => window.localStorage.setItem('rfr', token);

export const getAccessToken = () => window.localStorage.getItem('acc');

export const getRefreshToken = () => window.localStorage.getItem('rfr');

export const removeAccessToken = () => window.localStorage.removeItem('acc');

export const removeRefreshToken = () => window.localStorage.removeItem('rfr');