import React, { FocusEvent } from "react";
import { Card, CardContent, FormControlLabel, Switch, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";

const FormCreate = ({ formik }: any) => {

  const { t } = useTranslation();

  const { values, errors, setStatus, setFieldValue, handleChange } = formik;

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <GridContainer>
    <Grid xs={12} md={6} >
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <CardTitleBar title={t("generalInfo")} />
          <GridContainer spacing={2}>
            <Grid xs={12} md={6}>
              <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="price" type="number" label={t("price")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.price} error={!!errors.price} helperText={errors.price} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="tagLimit" type="number" label={t("tagLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.tagLimit} error={!!errors.tagLimit} helperText={errors.tagLimit} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="userLimit" type="number" label={t("userLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.userLimit} error={!!errors.userLimit} helperText={errors.contactLuserLimitastName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField type="number" name="clientLimit" label={t("clientLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.clientLimit} error={!!errors.clientLimit} helperText={errors.clientLimit} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!values.isPublic}
                    onChange={(e) => {
                      setFieldValue('isPublic', e.target.checked ? 1 : 0)
                    }}
                    name="isPublic"
                    value={values.isPublic}
                  />
                }
                label={t("public")}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
  </GridContainer>
}

export default FormCreate;