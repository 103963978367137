import { useEffect, useCallback } from 'react';
import { useSocket } from 'context/socket';
import { socketEvent } from 'constant/socketEvent';
import User from 'model/user';

export const useSocketOn = (eventName: string, eventHandler: Function, condition?: any) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled()) {
      socket.on(eventName, eventHandler);
    }
    return () => {
      if (isEnabled()) {
        socket.off(eventName, eventHandler);
      }
    }
  }, [eventName, eventHandler, isEnabled, socket]);
}

export const useSubscribeToOfflineLocations = (user: User) => {

  const { socket } = useSocket();

  useEffect(() => {
    if (user.isMasterAdmin()) {
      if (socket.connected) {
        socket.emit(socketEvent.subscribeToMaster);
      }
      return () => {
        if (socket.connected) {
          socket.emit(socketEvent.unsubscribeFromMaster);
        }
      }
    } else {
      if (socket.connected) {
        socket.emit(socketEvent.subscribeToOwnOrganization);
      }
      return () => {
        if (socket.connected) {
          socket.emit(socketEvent.unsubscribeFromOwnOrganization);
        }
      }
    }
  }, [socket.id, socket, user]);
}