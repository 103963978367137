import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

type Props = {
  isBusy: boolean;
};

const ProgressButton = ({ isBusy, ...props }: ButtonProps & Props) => {

  if (isBusy) {
    props.startIcon = <CircularProgress color="white" size={18} />;
  }

  return <Button
    {...props}
    disabled={isBusy}
  >{props.children}</Button>
}

export default ProgressButton;