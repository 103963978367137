import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent, Link, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { getOverdueHandovers } from "helper/backend";
import { useTranslation } from "react-i18next";
import { buildOperationNumber, showDaysText, showError, toSimpleError } from "helper/util";
import TableNoBb from "component/styled/TableNoBb";
import { OPERATION_NOT_FOUND } from "helper/error";
import { Pickups } from "@type/backend";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { Error } from "@mui/icons-material";
import { useAuth } from "context/auth";
import Client from "model/client";
import { useAppSelector } from "hook/redux";
import { formatCurrency } from "helper/currency";

const OverdueHandovers = () => {

  const { t } = useTranslation();
  const { authUser } = useAuth();
  const currency = useAppSelector(store => store.Auth.Login.user?.currency);

  // Holds the overdue handovers received from backend
  const [overdueHandovers, setOverdueHandovers] = useState<Pickups[]>([])
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchOverdueHandovers = useCallback(() => {
    setIsLoadInProgress(true)
    getOverdueHandovers()
      .then(response => {
        setOverdueHandovers(response.pickups);
      })
      .catch(ex => {
        const err = toSimpleError(ex)
        if (err.code !== OPERATION_NOT_FOUND) {
          showError(t("unableToLoadOverdueHandover"));
        }
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchOverdueHandovers();
  }, [fetchOverdueHandovers])

  const getRemainingTimeColor = (overdueDays: number) => {
    if (overdueDays > 0) {
      return "error.light";
    } else {
      return "#6DC594";
    }
  }

  return (
    <EditableCard sx={{ height: "360px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={t("overdueHandovers")} {...({ bgcolor: getRemainingTimeColor(overdueHandovers.length) })} />
        {
          overdueHandovers.length > 0
            ?
            <TableNoBb noHorizontalScroll>
              <TableHead>
                <TableRow>
                  <TableCell>{t("dueDate")}</TableCell>
                  <TableCell>{t("overdueBy")}</TableCell>
                  {
                    authUser.clientBusinessModel !== Client.BUSINESS_MODEL_FREE_SSD &&
                    <TableCell>{t("penalty")}</TableCell>
                  }
                  <TableCell>{t("pickup")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  overdueHandovers.map((el, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>{formatTimestamp(el.handoverBy, formats.DATETIME)}</TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>
                              - {el.overdueDays} {t(showDaysText(el.overdueDays))}
                            </Typography>
                            <Error color="error" sx={{ ml: 0.5, mb: 0.3 }} />
                          </Box>
                        </TableCell>
                        {
                          authUser.clientBusinessModel !== Client.BUSINESS_MODEL_FREE_SSD &&
                          <TableCell>{formatCurrency(el.penalty, currency?.symbol!, currency?.position!, currency?.hasSpacing!)}</TableCell>
                        }
                        <TableCell><Link href={route(routes.view_pickup_operation, el.operationId)}>{buildOperationNumber(el.id, el.type, el.handoverBy)}</Link></TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </TableNoBb>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noOverdueHandoversFound")}
            </Box>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard>
  )
};

export default OverdueHandovers;