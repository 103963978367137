import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import DistributionUnit from "model/distributionUnit";
import { useAuth } from "context/auth";
import AccessRole from "model/accessRole";
import { useTranslation } from "react-i18next";

type Props = {
  distributionUnit: DistributionUnit;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ distributionUnit, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { authUser } = useAuth();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={distributionUnit.name!}>
      {isGranted(perms.edit_distribution_units) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{distributionUnit.name}</TableCell>
        </TableRow>
        {authUser.accessRoleId !== AccessRole.ID_ORGANIZATION_ADMIN &&
          <TableRow>
            <TableCell>{t("organization")}</TableCell>
            <TableCell className="preview-value">{distributionUnit.organizationName}</TableCell>
          </TableRow>
        }
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;