import React from 'react';
import Box from '@mui/material/Box';
import Spinner from './Spinner';
import env from 'env';

const appBarHeight = 64;

type Props = {
  container?: 'full' | 'content' | 'parent';
};

const Preloader = ({ container: variant }: Props) => {

  return <Box sx={{
    position: variant === 'parent' ? 'absolute' : 'fixed',
    top: variant === 'content' ? appBarHeight : 0,
    bottom: 0,
    left: variant === 'content' ? env.SIDEBAR_WIDTH : 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2000
  }}>
    <Spinner sx={{
      width: 200,
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#33AD93'
      }
    }} />
  </Box>
}

export default Preloader;