import React, { useState, useCallback, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import Organization from "model/organization";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getOrganizationPaymentPlans, updateOrganization } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import PaymentPlan from "model/paymentPlan";
import MuiAutocomplete from "component/common/MuiAutocomplete";

type Values = {
  paymentPlanId: number;
};

type Props = {
  organization: Organization;
  onFinish: Function;
};

const SectionPaymentPlansEdit = ({ organization, onFinish }: Props) => {

  const { t } = useTranslation();

  // list of payment plans that populate the select field
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([]);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    paymentPlanId: Number.MIN_SAFE_INTEGER,
  };
  formInitialValues = {
    ...formInitialValues,
    paymentPlanId: organization.paymentPlanId,
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    paymentPlanId: Yup.number().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, setStatus, errors, setFieldValue, setFieldError, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveOrganization(values);
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveOrganization = (values: any) => {
    setIsSubmitInProgress(true)
    updateOrganization(organization.id!, values)
      .then(_response => {
        showSuccess(t("organizationHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableSaveOrganization"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
* Fetches from the backend the list of currencies this user is allowed to see
*/
  const fetchPaymentPlans = useCallback(() => {
    getOrganizationPaymentPlans()
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadPaymentPlans"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchPaymentPlans();
  }, [fetchPaymentPlans])

  /**
* Converts the list of currencies to select options
*/
  const getPaymentPlanOptions = () => paymentPlans.map(paymentPlan => ({ label: paymentPlan.name, value: paymentPlan.id }));

  /**
   * Returns the selected currencies as a select option
   */
  const getSelectedPaymentPlanOption = () => getPaymentPlanOptions().find(option => option.value === values.paymentPlanId) || null;

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={t("settings")} sx={{ mb: 4 }} >
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveSettings")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <MuiAutocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('paymentPlanId', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('paymentPlanId');
          }}
          value={getSelectedPaymentPlanOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          getOptionDisabled={(option) =>
            option.value === organization.paymentPlanId
          }
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getPaymentPlanOptions()}
          renderInput={(params: any) => <TextField {...params} name="paymentPlanId" label={t("paymentPlans")} error={!!errors.paymentPlanId} helperText={errors.paymentPlanId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
    </GridContainer>
  </form>
}

export default SectionPaymentPlansEdit;
