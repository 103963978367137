import React, { FocusEvent, useCallback } from "react";
import { Autocomplete, Card, CardContent, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import Currency from "model/currency";
import { getYesNoOptions } from "helper/util";
import { useTranslation } from "react-i18next";

const FormCreate = ({ formik }: any) => {

  const { t } = useTranslation();

  const { values, errors, setStatus, setFieldValue, handleChange } = formik;

  /**
  * Converts the list of positions to select options
  */
  const getPositionOptions = () => Currency.PositionTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected position types as a select option
  */
  const getSelectedPositionOption = () => getPositionOptions().find(option => option.value === values.position) || null;

  /**
  * Converts the list of positions to select options
  */
  const getDecimalOptions = () => Currency.DecimalTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected position types as a select option
  */
  const getSelectedDecimalOption = () => getDecimalOptions().find(option => option.value === values.decimals) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <GridContainer>
    <Grid xs={12} md={6} >
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <GridContainer spacing={2}>
            <Grid xs={12} md={6}>
              <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="symbol" label={t("symbol")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.symbol} error={!!errors.symbol} helperText={errors.symbol} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('position', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('position');
                }}
                value={getSelectedPositionOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getPositionOptions()}
                renderInput={(params: any) => <TextField {...params} name="position" label={t("position")} error={!!errors.position} helperText={errors.position} inputProps={{ ...params.inputProps }} />}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('hasSpacing', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('hasSpacing');
                }}
                value={getYesNoOptions().find(option => option.value === values.hasSpacing) || null}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getYesNoOptions()}
                renderInput={(params: any) => <TextField {...params} name="hasSpacing" label={t("spacing")} error={!!errors.hasSpacing} helperText={errors.hasSpacing} inputProps={{ ...params.inputProps }} />}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('decimals', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('decimals');
                }}
                value={getSelectedDecimalOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getDecimalOptions()}
                renderInput={(params: any) => <TextField {...params} name="decimals" label={t("decimals")} error={!!errors.decimals} helperText={errors.decimals} inputProps={{ ...params.inputProps }} />}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
  </GridContainer>
}

export default FormCreate;