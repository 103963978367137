import { CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import { useAuth } from "context/auth";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { buildOperationNumber } from "helper/util";
import Operation from "model/operation";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  operation: Operation;
}

const SectionExpeditionDetails = ({ operation }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  return (
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("dispatchDetails")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("number")}</TableCell>
              <TableCell className="preview-value">{buildOperationNumber(operation.id, operation.type, operation.createdTs)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("date")}</TableCell>
              <TableCell className="preview-value">{formatTimestamp(operation.createdTs, formats.DATETIME)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("confirmed")}</TableCell>
              <TableCell className="preview-value">{!!operation.confirmedTs ? formatTimestamp(operation.confirmedTs, formats.DATETIME) : "--"}</TableCell>
            </TableRow>
            {
              !!operation.confirmedTs && authUser.isDriverType() && !operation.packageCount &&
              <TableRow>
                <TableCell>{t("confirmedByClient")}</TableCell>
                <TableCell className="preview-value">{!!operation.isConfirmedByClient ? t("yes") : t("no")}</TableCell>
              </TableRow>
            }
            {
              !authUser.isDriverType() &&
              <TableRow>
                <TableCell>{t("confirmationCode")}</TableCell>
                <TableCell className="preview-value">{operation.confirmationCode}</TableCell>
              </TableRow>
            }
            {
              !authUser.isClientType() &&
              <TableRow>
                <TableCell>{t("destination")}</TableCell>
                <TableCell className="preview-value">{operation.destinationName}</TableCell>
              </TableRow>
            }
            {
              authUser.isOrganizationAdmin() &&
              <TableRow>
                <TableCell>{t("driver")}</TableCell>
                <TableCell className="preview-value">
                  <Link href={route(routes.view_driver, operation.driverId)}>{operation.driverName}</Link>
                </TableCell>
              </TableRow>
            }
            {
              !!operation.packageCount &&
              <TableRow>
                <TableCell>{t("packages")}</TableCell>
                <TableCell className="preview-value">{operation.packageCount}</TableCell>
              </TableRow>
            }
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  )
}

export default SectionExpeditionDetails;