import React, { FocusEvent } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, FormControlLabel, Slider, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';
import Reader from 'model/reader';
import { ExpandMore } from '@mui/icons-material';

type Props = {
  formik: any;
};

const FormCreateReader = ({ formik }: Props) => {

  const { values, errors, setStatus, setFieldValue, handleChange } = formik;

  const { t } = useTranslation();

  /**
  * Converts the list of vendors to select options
  */
  const getVendorOptions = () => Reader.VendorTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected vendor as a select option
  */
  const getSelectedVendorOption = () => getVendorOptions().find(option => option.value === values.vendor) || null;

  /**
  * Converts the list of zone to select options
  */
  const getZoneOptions = () => Reader.ZoneTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected zone as a select option
  */
  const getSelectedZoneOption = () => getZoneOptions().find(option => option.value === values.zone) || null;

  const handleAntennaChange = (_e: React.MouseEvent<HTMLElement>, newAntennas: string[]) => {
    formik.setFieldValue("antennas", newAntennas.toString())
  }

  /**
  * Converts the list of reader mode to select options
  */
  const getReaderModeOptions = () => Reader.ReaderModeTypes.map(mode => ({ label: mode.name, value: mode.id }));

  /**
  * Returns the selected reader mode as a select option
  */
  const getSelectedReaderModeOption = () => getReaderModeOptions().find(option => option.value === values.readerMode) || null;

  /**
  * Converts the list of sessions to select options
  */
  const sessionOptions = [
    {
      label: "0",
      value: 0
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2
    },
    {
      label: "3",
      value: 3
    }
  ]

  /**
  * Returns the selected session as a select option
  */
  const getSelectedSessionOption = () => sessionOptions.find(option => option.value === values.session) || null;

  /**
  * Converts the list of search mode to select options
  */
  const getSearchModeOptions = () => Reader.SearchModeTypes.map(mode => ({ label: mode.name, value: mode.id }));

  /**
  * Returns the selected search mode as a select option
  */
  const getSelectedSearchModeOption = () => getSearchModeOptions().find(option => option.value === values.searchMode) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <>
    <Grid container spacing={2} xs={12}>
      <Grid xs={12} md={6}>
        <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('zone', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('zone');
          }}
          value={getSelectedZoneOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getZoneOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("zone")} error={!!errors.zone} helperText={errors.zone} inputProps={{ ...params.inputProps, autoComplete: 'new-type' }} />}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('vendor', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('vendor');
          }}
          value={getSelectedVendorOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getVendorOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("vendor")} error={!!errors.vendor} helperText={errors.vendor} inputProps={{ ...params.inputProps, autoComplete: 'new-type' }} />}
        />
      </Grid>
      {
        values.vendor === Reader.VENDOR_IMPINJ &&
        <Grid xs={12} md={6}>
          <TextField name="ip" label={t("ip")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.ip} error={!!errors.ip} helperText={errors.ip} sx={{ width: '100%' }} />
        </Grid>
      }
    </Grid>
    {
      values.vendor === Reader.VENDOR_IMPINJ &&
      <>
        <Grid container spacing={2} xs={12}>
          <Grid xs={12} md={2} alignItems={"center"} display={"flex"} justifyContent={"flex-start"}>
            <Typography sx={{ color: "rgb(118, 118, 118)" }}>
              {t("sensitivity")}
            </Typography>
          </Grid>
          <Grid xs={9} md={8} alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <Slider
              name="sensitivity"
              sx={{
                width: "100%",
                marginRight: "10px"
              }}
              value={values.sensitivity!}
              onChange={handleChange}
              min={Reader.SENSITIVITY_MIN_RANGE}
              max={Reader.SENSITIVITY_MAX_RANGE}
              step={Reader.SENSITIVITY_STEP}
            />
          </Grid>
          <Grid xs={3} md={2}>
            <TextField
              name="sensitivity"
              type='number'
              variant="outlined"
              onChange={(e) => {
                setFieldValue('sensitivity', +e.target.value);
              }}
              onFocus={onTextFieldFocused}
              value={values.sensitivity}
              error={!!errors.sensitivity}
              helperText={errors.sensitivity}
              disabled
              sx={{
                width: "100%"
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12}>
          <Grid xs={12} md={2} alignItems={"center"} display={"flex"} justifyContent={"flex-start"}>
            <Typography sx={{ color: "rgb(118, 118, 118)" }}>
              {t("power")}
            </Typography>
          </Grid>
          <Grid xs={9} md={8} alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <Slider
              name="power"
              sx={{
                width: "100%",
                marginRight: "10px"
              }}
              value={values.power!}
              onChange={handleChange}
              min={Reader.POWER_MIN_RANGE}
              max={Reader.POWER_MAX_RANGE}
              step={Reader.POWER_STEP}
            />
          </Grid>
          <Grid xs={3} md={2}>
            <TextField
              name="power"
              type='number'
              variant="outlined"
              onChange={(e) => {
                setFieldValue('power', +e.target.value);
              }}
              onFocus={onTextFieldFocused}
              value={values.power}
              error={!!errors.power}
              helperText={errors.power}
              sx={{ width: "100%" }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12}>
          <Grid xs={12}>
            <Typography sx={{ color: "rgb(118, 118, 118)" }}>
              {t("antennas")}
            </Typography>
            <ToggleButtonGroup
              value={values.antennas.split(",")}
              onChange={handleAntennaChange}
              sx={{
                mt: 1,
                gap: 1,
                "& .Mui-selected": {
                  backgroundColor: "#33AD93 !important",
                  color: "#FFFFFF !important"
                }
              }}
            >
              <ToggleButton value="1" sx={{
                padding: "10px 20px", border: "1px solid #80808052 !important", borderRadius: "5px !important", marginLeft: "0 !important"
              }}>
                1
              </ToggleButton>
              <ToggleButton value="2" sx={{ padding: "10px 20px", border: "1px solid #80808052 !important", borderRadius: "5px !important", marginLeft: "0 !important" }} >
                2
              </ToggleButton>
              <ToggleButton value="3" sx={{ padding: "10px 20px", border: "1px solid #80808052 !important", borderRadius: "5px !important", marginLeft: "0 !important" }} >
                3
              </ToggleButton>
              <ToggleButton value="4" sx={{ padding: "10px 20px", border: "1px solid #80808052 !important", borderRadius: "5px !important", marginLeft: "0 !important" }}>
                4
              </ToggleButton>
            </ToggleButtonGroup>
            {!!errors.antennas &&
              <Typography color={"error"} sx={{ mt: 1, fontSize: "12px" }}>
                {errors.antennas}
              </Typography>
            }
          </Grid>
          <Accordion sx={{ width: "100%", mt: 2, border: 0, boxShadow: "none", "&::before": { height: "0px" }, "& .Mui-expanded": { margin: 0 } }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ border: 0, boxShadow: "none" }}
            >
              <Box sx={{ color: "rgb(118, 118, 118)" }}>
                {t("advanced")}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Grid container spacing={3} xs={12}>
                <Grid xs={12} md={6}>
                  <Autocomplete
                    onChange={(_e, selectedOption) => {
                      setFieldValue('readerMode', selectedOption?.value);
                    }}
                    onFocus={_e => {
                      onSelectFieldFocused('readerMode');
                    }}
                    value={getSelectedReaderModeOption()}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={getReaderModeOptions()}
                    renderInput={(params: any) => <TextField {...params} label={t("readerMode")} error={!!errors.readerMode} helperText={errors.readerMode} inputProps={{ ...params.inputProps, autoComplete: 'new-mode' }} />}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField type="number" name="estimatedPopulation" label={t("estimatedPopulation")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.estimatedPopulation} error={!!errors.estimatedPopulation} helperText={errors.estimatedPopulation} sx={{ width: '100%' }} />
                </Grid>
                <Grid xs={12} md={6}>
                  <Autocomplete
                    onChange={(_e, selectedOption) => {
                      setFieldValue('session', selectedOption?.value);
                    }}
                    onFocus={_e => {
                      onSelectFieldFocused('session');
                    }}
                    value={getSelectedSessionOption()}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={sessionOptions}
                    renderInput={(params: any) => <TextField {...params} label={t("session")} error={!!errors.session} helperText={errors.session} inputProps={{ ...params.inputProps, autoComplete: 'new-mode' }} />}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <Autocomplete
                    onChange={(_e, selectedOption) => {
                      setFieldValue('searchMode', selectedOption?.value);
                    }}
                    onFocus={_e => {
                      onSelectFieldFocused('searchMode');
                    }}
                    value={getSelectedSearchModeOption()}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={getSearchModeOptions()}
                    renderInput={(params: any) => <TextField {...params} label={t("searchMode")} error={!!errors.searchMode} helperText={errors.searchMode} inputProps={{ ...params.inputProps, autoComplete: 'new-mode' }} />}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!values.hasLocalControl}
                        onChange={(e) => {
                          setFieldValue('hasLocalControl', e.target.checked)
                        }}
                        name="hasLocalControl"
                        value={values.hasLocalControl}
                      />
                    }
                    label={t("hasLocalControl")}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </>
    }
  </>
};

export default FormCreateReader;