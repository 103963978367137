import { removeAccessToken, removeRefreshToken, saveAccessToken, saveRefreshToken } from 'helper/storage';
import { combineReducers } from 'redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  REFRESH_AUTH_USER_OK,
  REFRESH_AUTH_USER_ERR,
  LOGIN_USER,
  LOGIN_USER_OK,
  LOGIN_USER_ERR,
  SAVE_RETURN_URL,
  LOGOUT_USER,
  LOGOUT_USER_OK,
  LOGOUT_USER_ERR,
} from './actionTypes';
import { AuthLoginActionPayload, AuthLoginState } from './types';
import { AuthResponse, GetAuthUserResponse } from '@type/backend';
import { route, routes } from 'helper/route';

/********** LOGIN **********/

const defaultLoginState: AuthLoginState = {
  user: undefined,
  userError: undefined,
  isAuthenticated: undefined,
  isLoggedIn: undefined,
  loginError: undefined,
  isLoginInProgress: false,
  returnUrl: undefined,
  isLoggedOut: undefined,
  logoutError: undefined,
  currency: undefined,
};

const Login = (state = defaultLoginState, action: PayloadAction<AuthLoginActionPayload>): AuthLoginState => {
  switch (action.type) {
    case GET_AUTH_USER_OK:
      const response1 = action.payload.response as GetAuthUserResponse;
      return {
        ...state,
        isAuthenticated: true,
        user: response1.user,
        currency: response1.currency
      };
    case GET_AUTH_USER_ERR:
      return {
        ...state,
        isAuthenticated: false,
        userError: action.payload.error,
      };
    case REFRESH_AUTH_USER_OK:
      const response2 = action.payload.response as GetAuthUserResponse;
      return {
        ...state,
        user: response2.user,
      };
    case REFRESH_AUTH_USER_ERR:
      return {
        ...state,
        user: undefined,
        userError: action.payload.error,
        isAuthenticated: false,
      };
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: undefined,
        loginError: undefined,
        isLoginInProgress: true,
      };
    case LOGIN_USER_OK:
      const response3 = action.payload.response as AuthResponse;
      saveAccessToken(response3.access.token);
      saveRefreshToken(response3.refresh.token);
      return {
        ...state,
        isLoggedIn: true,
        isLoginInProgress: false,
      };
    case LOGIN_USER_ERR:
      return {
        ...state,
        isLoggedIn: false,
        loginError: action.payload.error,
        isLoginInProgress: false,
      };
    case SAVE_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload.url,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isLoggedOut: undefined,
        logoutError: undefined,
      };
    case LOGOUT_USER_OK:
      removeAccessToken();
      removeRefreshToken();
      const response4 = action.payload.response as AuthResponse;
      // response will be of type 'string' for a regular logout
      // response will be of type 'object' (AuthResponse) for an impersonation logout
      // response can also be an empty object in case of a forced logout due to an expired refresh token
      if (typeof response4 === 'object' && !!response4.access) {
        // this is an impersonation logout
        // so besides terminating the current session (impersonated)
        // we also need to re-create the previous session (impersonator)
        saveAccessToken(response4.access.token);
        saveRefreshToken(response4.refresh.token);
        // when signing out of impersonation, we want to redirect to the preview screen of the impersonated user
        // therefore here we change the url
        // the update of state will trigger a render of the auth context
        // which in turn will re-evaluate the route
        // leading to the intended screen being displayed
        window.history.pushState(null, '', route(routes.view_user, state.user?.id));
      }
      return {
        ...state,
        user: undefined,
        // trigger an auth re-check to detect the new user in case of an impersonation logout
        isAuthenticated: undefined,
        isLoggedOut: true,
      };
    case LOGOUT_USER_ERR:
      return {
        ...state,
        isLoggedOut: false,
        logoutError: action.payload.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  Login,
});
