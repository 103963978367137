import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

type Props = {
  sx: any;
};

const Spinner = (props: Props) => {

  return <LinearProgress {...props} />
}

export default Spinner;