import React, { FocusEvent, useCallback } from "react";
import { Autocomplete, Card, CardContent, FormControlLabel, Switch, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import { getCountryOptions } from "helper/country";
import GridContainer from "component/common/GridContainer";
import Client from "model/client";
import { useTranslation } from "react-i18next";

const FormCreate = ({ formik }: any) => {

  const { values, errors, setStatus, setFieldValue, handleChange } = formik;

  const { t } = useTranslation();

  /**
  * Returns the selected country as a select option
  */
  const getSelectedCountryOption = () => getCountryOptions().find(option => option.value === values.billingCountry) || null;

  /**
  * Converts the list of entity types to select options
  */
  const getEntityTypeOptions = () => Client.EntityTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedEntityTypeOption = () => getEntityTypeOptions().find(option => option.value === values.entityType) || null;

  /**
  * Converts the list of business model to select options
  */
  const getBusinessModelOptions = () => Client.BusinessModels.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedBusinessModelOption = () => getBusinessModelOptions().find(option => option.value === values.businessModel) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <GridContainer>
    <Grid xs={12} md={6} >
      <Card>
        <CardContent>
          <CardTitleBar title={t("generalInfo")} />
          <GridContainer spacing={2}>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('entityType', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('entityType');
                }}
                value={getSelectedEntityTypeOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getEntityTypeOptions()}
                renderInput={(params: any) => <TextField {...params} name="entityType" label={t("entityType")} error={!!errors.entityType} helperText={errors.entityType} inputProps={{ ...params.inputProps, autoComplete: 'new-entity' }} />}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('businessModel', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('businessModel');
                }}
                value={getSelectedBusinessModelOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getBusinessModelOptions()}
                renderInput={(params: any) => <TextField {...params} name="businessModel" label={t("businessModel")} error={!!errors.businessModel} helperText={errors.businessModel} inputProps={{ ...params.inputProps, autoComplete: 'new-business' }} />}
              />
            </Grid>
            {
              values.entityType === Client.ENTITY_TYPE_COMPANY &&
              <Grid xs={12} md={6}>
                <TextField name="companyName" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.companyName} error={!!errors.companyName} helperText={errors.companyName} sx={{ width: '100%' }} />
              </Grid>
            }
            {
              values.entityType === Client.ENTITY_TYPE_COMPANY &&
              <Grid xs={12} md={6}>
                <TextField name="vatNo" label={t("vatNumber")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatNo} error={!!errors.vatNo} helperText={errors.vatNo} sx={{ width: '100%' }} />
              </Grid>
            }
            <Grid xs={12} md={6}>
              <TextField name="contactFirstName" label={t("contactFirstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactFirstName} error={!!errors.contactFirstName} helperText={errors.contactFirstName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="contactLastName" label={t("contactLastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactLastName} error={!!errors.contactLastName} helperText={errors.contactLastName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField type="email" name="contactEmail" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactEmail} error={!!errors.contactEmail} helperText={errors.contactEmail} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="contactPhone" label={t("phone")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactPhone} error={!!errors.contactPhone} helperText={errors.contactPhone} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!values.isActive}
                    onChange={(e) => {
                      setFieldValue('isActive', e.target.checked ? 1 : 0)
                    }}
                    name="isActive"
                    value={values.isActive}
                  />
                }
                label={t("active")}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
    <Grid xs={12} md={6} >
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={t("billing")} />
          <GridContainer spacing={2}>
            <Grid xs={12}>
              <TextField name="billingAddress" label={t("address")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingAddress} error={!!errors.billingAddress} helperText={errors.billingAddress} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="billingCity" label={t("city")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCity} error={!!errors.billingCity} helperText={errors.billingCity} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="billingZip" label={t("zipCode")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingZip} error={!!errors.billingZip} helperText={errors.billingZip} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="billingCounty" label={t("county")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCounty} error={!!errors.billingCounty} helperText={errors.billingCounty} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('billingCountry', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('billingCountry');
                }}
                value={getSelectedCountryOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getCountryOptions()}
                renderInput={(params: any) => <TextField {...params} name="billingCountry" label={t("country")} error={!!errors.billingCountry} helperText={errors.billingCountry} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
  </GridContainer>
}

export default FormCreate;