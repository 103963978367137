import CardTitleBar from 'component/common/CardTitleBar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { FocusEvent, useState } from 'react';
import { Autocomplete, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { isErrType, showError, showSuccess, toSimpleError, nullsToEmptyStrings } from 'helper/util';
import { updateDriver } from 'helper/backend';
import { ValidationException } from 'helper/error';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ProgressButton from 'component/common/ProgressButton';
import User from 'model/user';
import { getTimezoneOptions } from 'helper/date';
import ConfirmDialog from 'component/common/ConfirmDialog';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';

type Values = {
  firstName: string;
  lastName: string;
  email: string;
  timezone: string | undefined;
  isActive: boolean;
};

type Props = {
  driver: User;
  onFinish: Function;
};

const SectionInfoEdit = ({ driver, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  // whether the change-email confirmation is visible
  const [isChangeEmailConfOpen, setIsChangeEmailConfOpen] = useState(false);
  // holds form values after validation and until the user accepts the confirmation
  const [tempFormValues, setTempFormValues] = useState<Values | null>(null);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    firstName: '',
    lastName: '',
    email: '',
    timezone: undefined,
    isActive: false
  };
  formInitialValues = {
    ...formInitialValues,
    ...nullsToEmptyStrings(driver),
  };

  /**
   * Form validation rules
   */
  let validationSchema: any = {
    firstName: Yup.string().trim().required(t("fieldIsRequired")),
    lastName: Yup.string().trim().required(t("fieldIsRequired")),
    email: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    timezone: Yup.string().trim().required(t("fieldIsRequired")),
    isActive: Yup.boolean(),
  };

  /**
   * Form configuration
   */
  const { values, errors, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      if (values.email !== driver.email) {
        setTempFormValues(values);
        setIsChangeEmailConfOpen(true);
      } else {
        saveDriver(values);
      }
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveDriver = (values: any) => {
    setIsSubmitInProgress(true);
    updateDriver(driver.id!, values)
      .then(_response => {
        showSuccess(t("driverHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDriver"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
   * Returns the selected timezone as a select option
   */
  const getSelectedTimezoneOption = () => getTimezoneOptions().find(option => option.value === values.timezone) || null;

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={driver.fullName!} sx={{ mb: 4 }}>
      <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveDriver")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="firstName" label={t("firstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.firstName} error={!!errors.firstName} helperText={errors.firstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="lastName" label={t("lastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.lastName} error={!!errors.lastName} helperText={errors.lastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="email" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('timezone', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('timezone');
          }}
          value={getSelectedTimezoneOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getTimezoneOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("timezone")} error={!!errors.timezone} helperText={errors.timezone} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
      {!!driver.isEmailVerified &&
        <Grid xs={12} md={6}>
          <FormControlLabel control={<Switch name="isActive" onChange={handleChange} checked={values.isActive} />} label={t("active")} />
        </Grid>
      }
    </GridContainer>
    <ConfirmDialog
      isOpen={isChangeEmailConfOpen}
      yesButton={t("confirm")}
      onConfirm={() => {
        setIsChangeEmailConfOpen(false);
        saveDriver(tempFormValues);
        setTempFormValues(null);
      }}
      onCancel={() => {
        setIsChangeEmailConfOpen(false);
        setTempFormValues(null);
      }}
    >{t("changeEmailConfirmation")}</ConfirmDialog>
  </form>
}

export default SectionInfoEdit;
