import {
  APPLY_ARTICLES_DT_PARAMS,
  PATCH_ARTICLES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyArticleDtParams = (params: DtParams) => ({
  type: APPLY_ARTICLES_DT_PARAMS,
  payload: { params },
});

export const patchArticleDtParams = (params: DtParams) => ({
  type: PATCH_ARTICLES_DT_PARAMS,
  payload: { params },
});