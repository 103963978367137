import React from 'react';
import { closeSnackbar, SnackbarKey } from 'notistack';
import { Button } from '@mui/material';

type Props = {
  snackbarKey: SnackbarKey;
};

const SnackbarCloseButton = ({ snackbarKey }: Props) => {

  return <Button onClick={() => closeSnackbar(snackbarKey)} sx={{ color: 'inherit' }}>Dismiss</Button>
}

export default SnackbarCloseButton;