import ioClient from 'socket.io-client';
import env from 'env';
import { buildUrl } from './util';

export const openSocket = (namespace?: any, options?: any) => {
  if (!namespace) {
    namespace = env.SOCKET_NAMESPACE;
  }
  const url = buildUrl(env.API_URL, namespace);
  return ioClient(url, {
    transports: ['websocket'],
    secure: true,
    path: env.SOCKET_PATH,
    ...options,
  });
}

export const closeSocket = (client: any) => {
  if (!client) {
    return;
  }
  client.removeAllListeners();
  client.close();
}