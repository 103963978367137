import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { routes } from 'helper/route';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import AccessDenied from 'page/Error/AccessDenied';
import { useTranslation } from 'react-i18next';
import FormInvite from './Partial/Form/Invite';
import GridContainer from 'component/common/GridContainer';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

const InviteClient = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    {isGranted(perms.invite_clients) && <Box>
      <PageTitleBar breadcrumbs={breadcrumbs(t)} />
      <GridContainer>
        <Grid xs={12} md={6} >
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <FormInvite />
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </Box>
    }
    {isNotGranted(perms.invite_clients) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (t: Function) => [{
  text: t("client"),
  url: routes.list_clients,
}, {
  text: t("inviteClient"),
}];

export default InviteClient;