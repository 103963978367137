import React, { FocusEvent, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Autocomplete, Button, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import ProgressButton from "component/common/ProgressButton";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ValidationException } from "helper/error";
import { updateDuDevice } from "helper/backend";
import Device from "model/device";
import { useTranslation } from "react-i18next";

type Values = {
  name: string,
  code: string,
  type: number | undefined,
};

type Props = {
  device: Device;
  onFinish: Function;
};

const SectionInfoEdit = ({ device, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    name: '',
    code: '',
    type: undefined,
  };
  formInitialValues = {
    ...formInitialValues,
    name: device.name!,
    code: device.code!,
    type: device.type!,
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    code: Yup.string().trim().required(t("fieldIsRequired")),
    type: Yup.number().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldError, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDevice(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDevice = (values: any) => {
    setIsSubmitInProgress(true);
    updateDuDevice(device.id!, device.distributionUnitId!, values)
      .then(_response => {
        showSuccess(t("deviceHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDevice"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Converts the list of types to select options
  */
  const getTypeOptions = () => Device.DeviceTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedTypeOption = () => getTypeOptions().find(option => option.value === values.type) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar title={device.name!} sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveDevice")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12} md={6}>
          <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <Autocomplete
            onChange={(_e, selectedOption) => {
              setFieldValue('type', selectedOption?.value);
            }}
            onFocus={_e => {
              onSelectFieldFocused('type');
            }}
            value={getSelectedTypeOption()}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={getTypeOptions()}
            renderInput={(params: any) => <TextField {...params} label={t("type")} error={!!errors.type} helperText={errors.type} inputProps={{ ...params.inputProps, autoComplete: 'new-type' }} />}
          />
        </Grid>
      </GridContainer>
    </form>
  )
};

export default SectionInfoEdit;