import {
  APPLY_ARTICLE_IN_HOUSE_DT_PARAMS,
  PATCH_ARTICLE_IN_HOUSE_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyArticleInHouseDtParams = (params: DtParams) => ({
  type: APPLY_ARTICLE_IN_HOUSE_DT_PARAMS,
  payload: { params },
});

export const patchArticleInHouseDtParams = (params: DtParams) => ({
  type: PATCH_ARTICLE_IN_HOUSE_DT_PARAMS,
  payload: { params },
});