import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { GridColDef, GridFilterItem, GridFilterModel, GridRenderCellParams } from '@mui/x-data-grid';
import { Alert, Box, Button, Link, Paper, TableContainer } from '@mui/material';
import { route, routes } from 'helper/route';
import { getUserDt } from 'helper/backend';
import User from 'model/user';
import { handleGridFilterModel, insertDtParamsIntoUrl } from 'helper/dt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DtFilterControls from 'component/common/DtFilterControls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { objectIsEmpty, toSimpleError } from 'helper/util';
import { AppError, DtParams } from '@type';
import { useAppDispatch, useAppSelector } from 'hook/redux';
import { applyUserDtParams, patchUserDtParams } from 'store/actions';
import Dt from 'component/common/Dt';
import { perms, useAccess } from 'context/access';
import AccessDenied from 'page/Error/AccessDenied';
import PageTitleBar from 'component/common/PageTitleBar';
import Filters from './Partial/Filters';
import useDtUrlParams from 'hook/dtUrlParams';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import Client from 'model/client';
import { useAuth } from 'context/auth';
import AccessRole from 'model/accessRole';
import { useTranslation } from 'react-i18next';

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess();
  const { authUser } = useAuth();
  const { t } = useTranslation();

  /**
   * DataGrid params may sometimes be passed in the url
   * so here we attempt to read any params from the url
   */
  const urlParams = useDtUrlParams();

  // DataGrid rows
  const [dtRows, setDtRows] = useState([] as User[]);
  // DataGrid total number of rows
  const [dtRowCount, setDtRowCount] = useState(0);
  // DataGrid sorting, filtering, pagination, etc
  const dtParams = useAppSelector(store => store.User.DtRequest);
  // whether the loading of the DataGrid rows is in progress
  const [isDtLoadInProgress, setIsDtLoadInProgress] = useState(false);
  // DataGrid error encoutered while fetching the rows (if any)
  const [dtError, setDtError] = useState<AppError | null>(null);
  // whether the filters panel is expanded
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  /**
   * Filter form default values
   * These are the values the form will be reset to when you click "Clear Filters"
   * Thy should be "empty" unless you have a clear need to force default values on specific fields
   */
  const filterFormDefaultValues = useMemo(() => {
    let formValues: any = {
      name: '',
      email: '',
      accessRoleId: Number.MIN_SAFE_INTEGER,
      isActive: Number.MIN_SAFE_INTEGER,
    };
    if (isGranted(perms.create_organizations)) {
      formValues = {
        ...formValues,
        organizationId: Number.MIN_SAFE_INTEGER,
      };
    }
    if (isGranted(perms.create_clients)) {
      formValues = {
        ...formValues,
        clientId: Number.MIN_SAFE_INTEGER,
      };
    }
    return formValues;
  }, [isGranted]);

  /**
   * Filter form initial values
   * These are the values loaded into the form as the component mounts (usually from redux store)
   * Datagrid parameters are stored in redux so they survive component unmount
   * Therefore when the user returns to the same DataGrid we can restore the previous parameters
   */
  const filterFormInitialValues = useMemo(() => ({
    ...filterFormDefaultValues,
    ...dtParams.filters,
  }), [dtParams.filters, filterFormDefaultValues]);

  /**
   * Filter form validation rules
   */
  const validationSchema = useMemo(() => {
    let schema: any = {
      name: Yup.string().trim(),
      email: Yup.string().trim(),
      accessRoleId: Yup.number(),
      isActive: Yup.number(),
    };
    if (isGranted(perms.create_organizations)) {
      schema = {
        ...schema,
        organizationId: Yup.number(),
      };
    }
    if (isGranted(perms.create_clients)) {
      schema = {
        ...schema,
        clientId: Yup.number(),
      };
    }
    return schema;
  }, [isGranted]);

  /**
   * Filter form configuration
   */
  const { values, errors, setValues, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filterFormInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      applyFilters(values);
    },
  });

  // This hook runs once on component mount
  useEffect(() => {
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }
    // update the DataGrid params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchUserDtParams(urlParams));
  }, [dispatch, urlParams]);

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    if (urlParams === null) {
      // abort if the url filters have not been evaluated yet
      // this is in order to avoid making 2 requests:
      // a) the initial one and b) another one after the url filters have been evaluated
      // better to wait until the url is parsed and make a single request
      return;
    }
    // fetch the list of DataGrid rows from the server
    setIsDtLoadInProgress(true);
    getUserDt(dtParams)
      .then(response => {
        setDtRows(response.rows);
        setDtRowCount(response.totalCount);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      })
      .finally(() => {
        setIsDtLoadInProgress(false);
      });
    // having 'urlParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'dtParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtParams]);

  /**
   * Saves new DataGrid params to the redux store
   * @param params
   */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyUserDtParams(params));
  }, [dispatch]);

  /**
   * Event handler called whenever the user applies new filters
   * @param values
   */
  const applyFilters = useCallback((values: any) => {
    // prepare the filters in the format expected by the DataGrid
    const filterModel = { items: [] } as GridFilterModel;
    // add all new filters
    for (const [key, value] of Object.entries(values)) {
      filterModel.items.push({ id: key, field: key, value } as GridFilterItem);
    }
    // convert the DataGrid format to DtParams
    const newParams = handleGridFilterModel(filterModel, dtParams);
    // save filters in redux store
    updateDtParams(newParams);
  }, [dtParams, updateDtParams]);

  /**
   * Event handler called whenever the user clears the filters
   */
  const clearFilters = useCallback(() => {
    // reset the form to the default values
    setValues(filterFormDefaultValues);
    // submit the form so the new filters are applied
    handleSubmit();
  }, [filterFormDefaultValues, handleSubmit, setValues]);

  /**
   * Event handler called whenever the user shows or hides the filters panel
   */
  const toggleFilters = useCallback(() => {
    setIsFiltersVisible(val => !val);
  }, []);

  const getHiddenColumns = () => {
    const hiddenColumns = ['updatedTs'];
    if (isGranted(perms.create_organizations)) {
      hiddenColumns.push('clientCompanyName');
    }
    if (isGranted(perms.view_own_organization)) {
      hiddenColumns.push('organizationCompanyName');
    }
    if (isGranted(perms.view_own_client)) {
      hiddenColumns.push('organizationCompanyName');
      hiddenColumns.push('clientCompanyName');
    }
    return hiddenColumns;
  }

  // Limit the number of users if the user is a Client Admin
  const limitNumberUsers = () => authUser.accessRoleId === AccessRole.ID_CLIENT_ADMIN && dtRows.length >= Client.CLIENT_USERS_LIMIT;

  return <React.Fragment>
    {isGranted(perms.view_users) && <Box>

      {/********** Page Title and Actions Toolbar **********/}
      <PageTitleBar title={t("users")}>
        <DtFilterControls
          isFiltersVisible={isFiltersVisible}
          hasFilters={!objectIsEmpty(dtParams.filters, true)}
          onApplyFilters={handleSubmit}
          onClearFilters={clearFilters}
          onToggleFilters={toggleFilters}
          sx={{ mr: 1 }}
        ></DtFilterControls>
        {isGranted(perms.create_users) && <Button variant="contained" color="dark" startIcon={<PlaylistAddIcon />} component={Link} href={routes.new_user} disabled={limitNumberUsers()}>{t("addNewUser")}</Button>}
      </PageTitleBar>

      {/********** Filters Panel **********/}
      {isFiltersVisible && <Filters
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        handleChange={handleChange}
        setStatus={setStatus}
      />}

      {/********** DataGrid **********/}
      <TableContainer component={Paper}>
        {!!dtError && <Alert severity="error" sx={{ mb: 1 }}>{t("unableToLoadUsers")}</Alert>}
        <Dt
          rows={dtRows}
          rowCount={dtRowCount}
          columns={columns(t)}
          params={dtParams}
          isBusy={isDtLoadInProgress}
          localeText={{
            noRowsLabel: t("noUsersFound"),
            noResultsOverlayLabel: t("noUsersFound"),
          }}
          onPaginationChange={updateDtParams}
          onSearchChange={updateDtParams}
          onSortChange={updateDtParams}
          hiddenColumns={getHiddenColumns()}
        />
      </TableContainer>

    </Box>}
    {isNotGranted(perms.view_users) && <AccessDenied />}
  </React.Fragment>
}

const columns = (t: Function) => {
  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: t("name"),
      minWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_user, params.row.id)}>{params.value}</Link>
      },
    },
    {
      field: 'email',
      headerName: t("email"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'organizationCompanyName',
      headerName: t("organization"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => !!params.value ? <Link href={route(routes.view_organization, params.row.organizationId)}>{params.value}</Link> : '--',
    },
    {
      field: 'clientCompanyName',
      headerName: t("client"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => params.value || '--',
    },
    {
      field: 'accessRoleName',
      headerName: t("role"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'isActive',
      headerName: t("active"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return !params.row.isEmailVerified ? <HourglassBottomOutlinedIcon /> : (!!params.value ? <TaskAltIcon /> : <UnpublishedIcon />)
      },
    },
    {
      field: 'updatedTs',
      flex: 0,
    },
  ];
  return columns;
}

export default List;
