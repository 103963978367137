import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import Client from "model/client";
import { perms, useAccess } from "context/access";
import { Edit } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";

type Props = {
  client: Client;
  onEdit: Function;
  isReloading: boolean;
}

const SectionInfoView = ({ client, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("generalInfo")} {...(client.isActive ? null : { chip: 'Inactive', bgcolor: 'warning.light' })}>
      {isGranted(perms.edit_clients) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("entityType")}</TableCell>
          <TableCell className="preview-value">{Client.getEntityTypeName(client.entityType!)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("businessModel")}</TableCell>
          <TableCell className="preview-value">{Client.getBusinessModelName(client.businessModel!)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("companyName")}</TableCell>
          <TableCell className="preview-value">{client.companyName || "--"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("vatNumber")}</TableCell>
          <TableCell className="preview-value">{client.vatNo || "--"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("contactFirstName")}</TableCell>
          <TableCell className="preview-value">{client.contactFirstName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("contactLastName")}</TableCell>
          <TableCell className="preview-value">{client.contactLastName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("email")}</TableCell>
          <TableCell className="preview-value">{client.contactEmail}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("phone")}</TableCell>
          <TableCell className="preview-value">{client.contactPhone}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionInfoView;