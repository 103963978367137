
export default class Intent {
  id!: number;
  clientId!: number;
  clientName!: string | null;
  type!: number;
  date!: number;
  totalAmount!: number;
  stripeId!: string;
  createdTs!: number;
  updatedTs!: number;

  static INTENT_TYPE_PICKUP = 1;
  static INTENT_TYPE_HANDOVER = 2;
  static INTENT_TYPE_MAX_PENALTY = 3;

  static IntentTypes = [
    {
      id: this.INTENT_TYPE_PICKUP,
      name: "Pickup"
    },
    {
      id: this.INTENT_TYPE_HANDOVER,
      name: "Handover"
    },
    {
      id: this.INTENT_TYPE_MAX_PENALTY,
      name: "Penalty"
    },
  ];

  static getTypeName(id: number) {
    return this.IntentTypes.find((intentType) => intentType.id === id)?.name
  }
}