import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import CardTitleBar from 'component/common/CardTitleBar';
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { Edit } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";
import { Billing } from "@type/backend";

type Props = {
  billingInfo: Billing;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ billingInfo, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("generalInfo")}>
      {isGranted(perms.edit_master) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{billingInfo.companyName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("vatNumber")}</TableCell>
          <TableCell className="preview-value">{billingInfo.vatNo || "--"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("contactFirstName")}</TableCell>
          <TableCell className="preview-value">{billingInfo.contactFirstName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("contactLastName")}</TableCell>
          <TableCell className="preview-value">{billingInfo.contactLastName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("email")}</TableCell>
          <TableCell className="preview-value">{billingInfo.contactEmail}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("phone")}</TableCell>
          <TableCell className="preview-value">{billingInfo.contactPhone}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionInfoView;