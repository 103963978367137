import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { getOwnClient } from "helper/backend";
import { toSimpleError } from "helper/util";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import Client from "model/client";
import SectionInfo from "./Partial/SectionInfo";
import SectionBilling from "./Partial/SectionBilling";
import { useTranslation } from "react-i18next";
import SectionCard from "./Partial/SectionCard";

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  // the client record fetched from the backend
  const [client, setClient] = useState<Client | undefined>()
  // error encoutered while fetching the client (if any)
  const [clientError, setClienterror] = useState<AppError | undefined>();
  // whether the loading of the client is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the client record from the backend
  */
  const refreshClient = useCallback(() => {
    setIsLoadInProgress(true)
    getOwnClient()
      .then(response => {
        setClient(response.client);
      })
      .catch(ex => {
        setClienterror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [])

  // This hook runs once on component mount
  useEffect(() => {
    refreshClient();
  }, [refreshClient]);

  return (
    <>
      {isGranted(perms.view_own_client)
        &&
        <>
          {
            !!client
            &&
            <Box>
              <PageTitleBar title={client.companyName! || `${client.contactFirstName} ${client.contactLastName}`} />
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo client={client} refreshHandler={refreshClient} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionBilling client={client} refreshHandler={refreshClient} isLoadInProgress={isLoadInProgress} />
                </Grid>
                {
                  client.businessModel === Client.BUSINESS_MODEL_SSD &&
                  <Grid xs={12} md={6}>
                    <SectionCard client={client} refreshHandler={refreshClient} isLoadInProgress={isLoadInProgress} />
                  </Grid>
                }
              </GridContainer>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !client && <Preloader container="content" />}
          {!!clientError && <Error error={clientError} title404={t("clientNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_own_client) && <AccessDenied />}
    </>
  )
}

export default Single;