import L from "leaflet"
import pinImage from 'asset/image/pinIcon.png'
import warningPinImage from 'asset/image/pinIconWarning.png'

export const PinIcon = new L.Icon({
  iconUrl: pinImage,
  iconSize: [34, 45],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
})

export const WarningPinIcon = new L.Icon({
  iconUrl: warningPinImage,
  iconSize: [34, 45],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
})