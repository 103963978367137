import React from 'react';
import { routes } from 'helper/route';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import logo from 'asset/image/logo.png';
import background from 'asset/image/bgPattern.png';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const defaultTitle = 'Access denied';

type Props = {
  title?: string | undefined,
};

const AccessDenied = ({ title }: Props) => {

  const { t } = useTranslation();

  return <Box sx={{
    display: 'flex',
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2000,
    width: "100%",
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: "#343b4a",
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }}>
    <Box sx={{ textAlign: 'center' }}>
      <Card sx={{ maxWidth: "475px" }}>
        <CardContent sx={{ padding: "2.25rem" }}>
          <Box sx={{
            mt: 2,
            mb: 2,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ height: "150px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", mb: 8 }}>
                <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
              </Box>
              <Typography
                variant="h1"
                sx={{
                  mb: 2,
                  color: "#33AD93",
                  textShadow: "rgba(24,138,226,.3) 5px 1px, rgba(24,138,226,.2) 10px 3px",
                  fontWeight: 500
                }}
              >
                403
              </Typography>
              <Typography variant="h6" sx={{ mb: 3, color: "#f34943", textTransform: "uppercase" }}>{title || defaultTitle}</Typography>
              <Typography sx={{ mb: 3, color: "#98a6ad", fontSize: ".9rem" }}>{t("accessDeniedMessage")}</Typography>
              <Button variant="contained" component={Link} href={routes.home} startIcon={<HomeIcon />} sx={{ mt: 3 }}>{t("returnHome")}</Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
}

export default AccessDenied;
