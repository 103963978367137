import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import { getArticlesReport } from "helper/backend";
import Preloader from "component/common/Preloader";
import { showError } from "helper/util";
import { useTranslation } from "react-i18next";
import { LocalOffer, LocationOff } from "@mui/icons-material";

export type ArticlesReport = {
  activeArticles: number;
  lostArticles: number;
}

const Articles = () => {

  const { t } = useTranslation();

  const [articles, setArticles] = useState<ArticlesReport>();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchDashboardArticles = useCallback(() => {
    setIsLoadInProgress(true)
    getArticlesReport()
      .then(response => {
        setArticles(response);
      })
      .catch(_ex => {
        showError(t("unableToLoadArticlesReport"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchDashboardArticles();
  }, [fetchDashboardArticles])

  return (
    <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      <EditableCard sx={{ height: "190px", padding: 0 }}>
        <CardContent sx={{ padding: "0px !important", height: "100%" }}>
          <Box sx={{ background: "linear-gradient(15deg,#f67773 0,#ff679b 100%)", width: "100%", height: "100%", position: "relative", p: 3, color: "#FFFFFF", display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box sx={{ fontSize: "20px" }}>{t("lostArticles")}</Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ fontSize: "24px", border: "3px solid #ffffff78", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "70px", height: "70px" }}>
                {articles?.lostArticles}
              </Box>
              <LocationOff sx={{ fontSize: "40px", opacity: "0.7" }} />
            </Box>
          </Box>
        </CardContent>
      </EditableCard >
      <EditableCard sx={{ height: "110px", padding: 0 }}>
        <CardContent sx={{ padding: "0px !important" }}>
          <Box sx={{ background: "linear-gradient(15deg,#02a8b5 0,#31ce77 100%)", width: "100%", height: "100%", position: "relative", p: 3, color: "#FFFFFF", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Box sx={{ fontSize: "20px" }}>{t("activeArticles")}</Box>
              <Box sx={{ fontSize: "24px" }}>
                {articles?.activeArticles}
              </Box>
            </Box>
            <LocalOffer sx={{ fontSize: "40px", opacity: "0.7" }} />
          </Box>
        </CardContent>
        {isLoadInProgress && <Preloader container="parent" />}
      </EditableCard >
    </Box>
  )
};

export default Articles;