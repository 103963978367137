import React, { FocusEvent, useState } from "react";
import { Button, ButtonGroup, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material";
import ProgressButton from "./ProgressButton";
import { ArrowDropDown } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { useTranslation } from "react-i18next";
import Operation from "model/operation";

type Props = {
  operation: Operation;
  isConfirmInProgress: boolean;
  setIsConfOpen: Function;
  isConfOpen: boolean;
  handleConfirmDispatch: Function;
}

const ExpeditionConfirmation = ({ operation, isConfirmInProgress, setIsConfOpen, isConfOpen, handleConfirmDispatch }: Props) => {

  const { t } = useTranslation();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  // whether the confirmation is visible
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues = {
    expeditionId: operation?.id || 0,
    confirmationCode: ""
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    expeditionId: Yup.number(),
    confirmationCode: Yup.string().when("isDropdownOpen", {
      is: () => !isDropdownOpen,
      then: schema => schema.required(t("fieldIsRequired"))
    })
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      handleConfirmDispatch(values);
    },
  });

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  // Handle the dropdown closing from confirmation modal
  const handleDropdownClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsDropdownOpen(false);
  };

  return (
    <Dialog
      open={isConfOpen}
      onClose={() => setIsConfOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form noValidate onSubmit={handleSubmit}>
        <DialogTitle>{t("confirmDispatch")}</DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <DialogContentText>
            {t("confirmDispatchChangeText")}
          </DialogContentText>
          <TextField variant="outlined" name="confirmationCode" onChange={handleChange} value={values.confirmationCode || ""} error={!!errors.confirmationCode} helperText={errors.confirmationCode} onFocus={onTextFieldFocused} label={t("confirmationCode")} sx={{ width: "100%", mt: 2 }} />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" >
            <ProgressButton className='buttonAlwaysText' isBusy={isConfirmInProgress} color="primary" variant="contained" type="submit">{t("confirm")}</ProgressButton>
            <Button
              aria-controls={isDropdownOpen ? 'split-button-menu' : undefined}
              aria-expanded={isDropdownOpen ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={() => setIsDropdownOpen((prevOpen) => !prevOpen)}
            >
              <ArrowDropDown />
            </Button>
            <Button variant="contained" color="dark" className='buttonAlwaysText' onClick={() => setIsConfOpen(false)} autoFocus sx={{ ml: 2 }}>{t("cancel")}</Button>
          </ButtonGroup>
          <Popper open={isDropdownOpen} anchorEl={anchorRef.current} transition disablePortal role={undefined} sx={{ zIndex: 2000, ml: null }}>
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'top',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleDropdownClose}>
                    <MenuList>
                      <MenuItem
                        onClick={() => { setFieldValue("confirmationCode", undefined); handleSubmit() }}
                      >
                        {t("confirmWithoutCode")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </DialogActions>
      </form>
    </Dialog>

  )
};

export default ExpeditionConfirmation;