import React, { useState } from "react";
import { Box, Button, Card, CardContent, Link } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import GridContainer from "component/common/GridContainer";
import CardTitleBar from "component/common/CardTitleBar";
import { createArticle } from "helper/backend";
import { ValidationException } from "helper/error";
import { routes } from "helper/route";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { perms, useAccess } from "context/access";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import FormCreate from "./Partial/Form/Create";
import AccessDenied from "page/Error/AccessDenied";
import { useTranslation } from "react-i18next";

type Values = {
  name: string,
  code: string,
  rentPrice: string,
  purchasePrice: string,
  packageItemCount: string,
  distributionMode: string,
}

const New = () => {

  const { isGranted, isNotGranted } = useAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    name: '',
    code: '',
    rentPrice: '',
    purchasePrice: '',
    packageItemCount: '',
    distributionMode: '',
  };

  /**
   * Form validation rules
   */
  const validationSchema = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    code: Yup.string().trim().required(t("fieldIsRequired")),
    rentPrice: Yup.number().typeError(t("rentPriceNumber")).required(t("fieldIsRequired")),
    purchasePrice: Yup.number().typeError(t("purchasePriceNumber")).required(t("fieldIsRequired")),
    distributionMode: Yup.number().required(t("fieldIsRequired"))
  };

  /**
   * Form configuration
   */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveArticle(values);
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveArticle = (values: any) => {
    setIsSubmitInProgress(true);
    createArticle(values)
      .then(_response => {
        showSuccess(t("articleHasBeenSaved"));
        return navigate(routes.list_articles);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveArticle"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  return (
    <>
      {isGranted(perms.create_articles) && <Box>
        <form noValidate onSubmit={formik.handleSubmit}>
          <PageTitleBar breadcrumbs={breadcrumbs(t)} />
          <GridContainer>
            <Grid xs={12} md={6} >
              <Card>
                <CardContent>
                  <CardTitleBar sx={{ mb: 3 }}>
                    <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveArticle")}</ProgressButton>
                    <Button variant="contained" color="secondary" component={Link} href={routes.list_articles} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
                  </CardTitleBar>
                  <FormCreate formik={formik} />
                </CardContent>
              </Card>
            </Grid>
          </GridContainer>
        </form >
      </Box>
      }
      {isNotGranted(perms.create_articles) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (t: Function) => [{
  text: t("articles"),
  url: routes.list_articles,
}, {
  text: t("newArticle"),
}];

export default New;