import React from 'react';
import { SvgIcon } from "@mui/material";

type Props = {
  path: string;
};

const Mdi = ({ path }: Props) => {
  return <SvgIcon><path d={path} /></SvgIcon>
}

export default Mdi;