import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { confirmExpedition, getNextExpedition } from "helper/backend";
import { useTranslation } from "react-i18next";
import { buildOperationNumber, showError, showSuccess, toSimpleError } from "helper/util";
import TableNoBb from "component/styled/TableNoBb";
import { formatTimestamp, formats } from "helper/date";
import Operation from "model/operation";
import { OPERATION_NOT_FOUND } from "helper/error";
import { useAuth } from "context/auth";
import ExpeditionConfirmation from "component/common/ExpeditionConfirmation";
import { Check } from "@mui/icons-material";
import { routes, route } from "helper/route";

const NextExpeditions = () => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  // Holds the next expedition received from backend
  const [nextExpeditions, setNextExpeditions] = useState<Operation>()
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // whether the confirmation is visible
  const [isConfOpen, setIsConfOpen] = useState(false);
  // whether the loading of the dispatch confirmation is in progress
  const [isConfirmInProgress, setIsConfirmInProgress] = useState<boolean>(false)

  /**
  * Fetches the record from the backend
  */
  const fetchNextExpeditions = useCallback(() => {
    setIsLoadInProgress(true)
    getNextExpedition()
      .then(response => {
        setNextExpeditions(response.expedition);
      })
      .catch(ex => {
        const err = toSimpleError(ex)
        if (err.code !== OPERATION_NOT_FOUND) {
          showError(t("unableToLoadNextDispatch"));
        }
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  /**
  * Opens the confirm modal
  */
  const confirmDispatch = () => {
    setIsConfOpen(true);
  }

  /**
  * Confirms the dispatch in the backend
  */
  const handleConfirmDispatch = (values: any) => {
    setIsConfirmInProgress(true)
    confirmExpedition(values)
      .then(_response => {
        fetchNextExpeditions()
        setIsConfOpen(false)
        showSuccess(t("dispatchHasBeenConfirmed"))
      })
      .catch(_ex => {
        showError(t("unableToConfirmDispatch"));
      })
      .finally(() => {
        setIsConfirmInProgress(false)
      })
  }


  useEffect(() => {
    fetchNextExpeditions();
  }, [fetchNextExpeditions])

  return (
    <EditableCard sx={{ height: "610px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={!!nextExpeditions ? `${t("nextDispatch")} ${formatTimestamp(nextExpeditions.createdTs, formats.DATETIME)}` : t("nextDispatch")} >
          {
            authUser.isDriverType() && !nextExpeditions?.confirmedTs && !nextExpeditions?.packageCount && nextExpeditions?.type === Operation.TYPE_EXPEDITION &&
            <Button onClick={confirmDispatch} variant="contained" color="primary" sx={{ mr: 1 }} startIcon={<Check />}>{t("confirm")}</Button>
          }
        </CardTitleBar>
        {
          !!nextExpeditions
            ?
            <>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                  {t("number")}:
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "0.960rem" }}><Link href={route(routes.view_expedition_operation, nextExpeditions.id)}>{buildOperationNumber(nextExpeditions.id, nextExpeditions.type, nextExpeditions.createdTs)}</Link></Typography>
              </Box>
              {
                authUser.isDriverType() &&
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                    {t("destination")}:
                  </Typography>
                  <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{nextExpeditions.destinationName}</Typography>
                </Box>
              }
              {
                authUser.isNonSsdClient() &&
                <>
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                      {t("driver")}:
                    </Typography>
                    <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{nextExpeditions.driverName}</Typography>
                  </Box>
                </>
              }
              {
                !!nextExpeditions.packageCount &&
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                    {t("packages")}:
                  </Typography>
                  <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{nextExpeditions.packageCount}</Typography>
                </Box>
              }
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                  {t("noOfItems")}:
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{nextExpeditions.itemCount}</Typography>
              </Box>
              {
                authUser.isNonSsdClient() &&
                <>
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "block", mr: 1 }}>
                      {t("confirmationCode")}:
                    </Typography>
                    <Typography sx={{ display: "block", fontSize: "1.4rem", mt: 1 }}>{nextExpeditions.confirmationCode}</Typography>
                  </Box>
                </>
              }
              <Box sx={{ height: "360px", mt: 0.5 }}>
                <TableNoBb sx={{ mt: 3, height: "200px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("article")}</TableCell>
                      <TableCell>{t("noOfItems")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      nextExpeditions.items.map((el: any, idx: number) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{el.name} ({el.code})</TableCell>
                            <TableCell>{el.items}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </TableNoBb>
              </Box>
              {/* Expedition Confirmation Modal */}
              <ExpeditionConfirmation operation={nextExpeditions} isConfirmInProgress={isConfirmInProgress} setIsConfOpen={setIsConfOpen} isConfOpen={isConfOpen} handleConfirmDispatch={handleConfirmDispatch} />
            </>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noDispatchFound")}
            </Box>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default NextExpeditions;