import React, { useState } from "react";
import { Button, CardContent, TableBody, TableCell, TableRow } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import EditableCard from "component/styled/EditableCard";
import User from "model/user";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { changeAccessCode } from "helper/backend";
import { showError, showSuccess } from "helper/util";
import Preloader from "component/common/Preloader";
import QRCode from "react-qr-code"
import { downloadQrCode } from "helper/qr";
import GridContainer from "component/common/GridContainer";
import ConfirmDialog from "component/common/ConfirmDialog";
import { useTranslation } from "react-i18next";

type Props = {
  user: User;
  refreshHandler: Function;
};

const SectionAccess = ({ user, refreshHandler }: Props) => {

  const { t } = useTranslation();

  // whether the chagning confirmation is visible
  const [isChangeConfOpen, setIsChangeConfOpen] = useState(false);
  // whether the changing of the code is in progress
  const [isChangeInProgress, setIsChangeInProgress] = useState(false);


  /**
  * Event handler called whenever the user changes the code
  */
  const refreshAccessCode = () => {
    setIsChangeInProgress(true);
    changeAccessCode()
      .then(_response => {
        showSuccess(t("accessCodeHasChange"));
        refreshHandler();
      })
      .catch(_ex => {
        showError(t("accessCodeUnableChange"));
      })
      .finally(() => {
        setIsChangeInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user download the QR code
  */
  const handleDownload = async () => {
    const svg = document.getElementById("qr-code") as HTMLElement;
    downloadQrCode(svg);
  }

  return (
    <>
      <EditableCard sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={"Access"}>
            <Button variant="contained" color="secondary" startIcon={<RefreshIcon />} onClick={() => setIsChangeConfOpen(true)}>{t("changeAccessCode")}</Button>
          </CardTitleBar>
          <TableNoBb sx={{ overflow: "hidden" }}>
            <TableBody>
              <TableRow>
                <TableCell>{t("accessCode")}</TableCell>
                <TableCell className="preview-value">{user?.accessCode || "--"}</TableCell>
              </TableRow>
              {user?.accessCode &&
                <TableRow sx={{ width: { xs: "100%" } }}>
                  <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>{t("qrCode")}</TableCell>
                  <TableCell className="preview-value" colSpan={2}>
                    <GridContainer>
                      <Grid xs={12}
                        sx={{
                          mt: 2,
                          width: { xs: "100%", lg: "180px" },
                          height: { xs: "100%", lg: "180px" },
                        }}>
                        <QRCode
                          id="qr-code"
                          value={user?.accessCode!.toString() || ""}
                          size={400}
                          style={{
                            height: "100%",
                            width: "100%"
                          }}
                        />
                      </Grid>
                      <Grid xs={12} sx={{ display: { xs: "flex" }, justifyContent: { xs: "center", lg: "flex-start" } }}>
                        <Button variant="contained" color="primary" onClick={handleDownload} startIcon={<DownloadIcon />}>{t("downloadQrCode")}</Button>
                      </Grid>
                    </GridContainer>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </TableNoBb>
          {isChangeInProgress && <Preloader container="parent" />}
        </CardContent>
      </EditableCard>
      <ConfirmDialog
        isOpen={isChangeConfOpen}
        yesButton={t("confirm")}
        onConfirm={() => {
          setIsChangeConfOpen(false);
          refreshAccessCode();
        }}
        onCancel={() => {
          setIsChangeConfOpen(false);
        }}
      >
        {t("accessCodeConfirmation")}
      </ConfirmDialog>
    </>
  )
}

export default SectionAccess;