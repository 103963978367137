import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, Link, Paper, TableContainer, Tabs, Tab } from "@mui/material";
import { GridColDef, GridFilterItem, GridFilterModel, GridRenderCellParams } from "@mui/x-data-grid";
import { AppError, DtParams } from "@type";
import { perms, useAccess } from "context/access";
import { handleGridFilterModel, insertDtParamsIntoUrl } from "helper/dt";
import { objectIsEmpty, toSimpleError } from "helper/util";
import { getAllDuDt, getDuDt } from "helper/backend";
import useDtUrlParams from "hook/dtUrlParams";
import { useAppDispatch, useAppSelector } from "hook/redux";
import { useFormik } from "formik";
import * as Yup from 'yup'
import AccessDenied from "page/Error/AccessDenied";
import Dt from "component/common/Dt";
import PageTitleBar from "component/common/PageTitleBar";
import DtFilterControls from "component/common/DtFilterControls";
import { applyDuDtParams, patchDuDtParams } from "store/actions";
import { useAuth } from "context/auth";
import { route, routes } from "helper/route";
import DistributionUnit from "model/distributionUnit";
import TabPanel from "component/common/TabPanel";
import Maps from "component/map/Map";
import { MAP_CENTER_BRASOV } from "constant/map";
import MapAllPins from "component/map/MapAllPins";
import { CheckCircle, Warning, Error, CloudOff, TapAndPlay, MobileOff, CloudDone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Filters from "../Filters";

const ListOrg = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess();
  const { authUser } = useAuth();
  const { t } = useTranslation();

  /**
  * DataGrid params may sometimes be passed in the url
  * so here we attempt to read any params from the url
  */
  const urlParams = useDtUrlParams();

  // All Distribution Units
  const [totalDu, setTotalDu] = useState([] as DistributionUnit[]);
  // DataGrid rows
  const [dtRows, setDtRows] = useState([] as DistributionUnit[]);
  // DataGrid total number of rows
  const [dtRowCount, setDtRowCount] = useState(0);
  // DataGrid sorting, filtering, pagination, etc
  const dtParams = useAppSelector(store => store.DistributionUnit.DtRequest);
  // whether the loading of the DataGrid rows is in progress
  const [isDtLoadInProgress, setIsDtLoadInProgress] = useState(false);
  // DataGrid error encoutered while fetching the rows (if any)
  const [dtError, setDtError] = useState<AppError | null>(null);
  // whether the filters panel is expanded
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  // hold the tab selected
  const [tabValue, setTabValue] = useState<number>(0)

  /**
  * Filter form default values
  * These are the values the form will be reset to when you click "Clear Filters"
  * Thy should be "empty" unless you have a clear need to force default values on specific fields
  */
  const filterFormDefaultValues = useMemo(() => ({
    name: '',
    organizationId: Number.MIN_SAFE_INTEGER,
  }), []);

  /**
  * Filter form initial values
  * These are the values loaded into the form as the component mounts (usually from redux store)
  * Datagrid parameters are stored in redux so they survive component unmount
  * Therefore when the user returns to the same DataGrid we can restore the previous parameters
  */
  const filterFormInitialValues = useMemo(() => ({
    ...filterFormDefaultValues,
    ...dtParams.filters,
  }), [dtParams.filters, filterFormDefaultValues]);

  /**
  * Filter form validation rules
  */
  const validationSchema = useMemo(() => ({
    name: Yup.string().trim(),
    organization: Yup.number(),
  }), []);

  /**
  * Filter form configuration
  */
  const { values, errors, setValues, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filterFormInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      applyFilters(values);
    },
  });

  // This hook runs once on component mount
  useEffect(() => {
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }
    // update the DataGrid params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchDuDtParams(urlParams));
  }, [dispatch, urlParams]);

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    if (urlParams === null) {
      // abort if the url filters have not been evaluated yet
      // this is in order to avoid making 2 requests:
      // a) the initial one and b) another one after the url filters have been evaluated
      // better to wait until the url is parsed and make a single request
      return;
    }
    // fetch the list of DataGrid rows from the server
    setIsDtLoadInProgress(true);
    getDuDt(dtParams)
      .then(response => {
        setDtRows(response.rows);
        setDtRowCount(response.totalCount);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      });
    getAllDuDt({ filters: { ...dtParams.filters } })
      .then(response => {
        setTotalDu(response.rows);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      })
      .finally(() => {
        setIsDtLoadInProgress(false);
      });
    // having 'urlParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'dtParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtParams]);

  /**
  * Saves new DataGrid params to the redux store
  * @param params
  */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyDuDtParams(params));
  }, [dispatch]);

  /**
  * Event handler called whenever the user applies new filters
  * @param values
  */
  const applyFilters = useCallback((values: any) => {
    // prepare the filters in the format expected by the DataGrid
    const filterModel = { items: [] } as GridFilterModel;
    // add all new filters
    for (const [key, value] of Object.entries(values)) {
      filterModel.items.push({ id: key, field: key, value } as GridFilterItem);
    }
    // convert the DataGrid format to DtParams
    const newParams = handleGridFilterModel(filterModel, dtParams);
    // save filters in redux store
    updateDtParams(newParams);
  }, [dtParams, updateDtParams]);

  /**
  * Event handler called whenever the user clears the filters
  */
  const clearFilters = useCallback(() => {
    // reset the form to the default values
    setValues(filterFormDefaultValues);
    // submit the form so the new filters are applied
    handleSubmit();
  }, [filterFormDefaultValues, handleSubmit, setValues]);

  /**
  * Event handler called whenever the user shows or hides the filters panel
  */
  const toggleFilters = useCallback(() => {
    setIsFiltersVisible(val => !val);
  }, []);

  /**
  * Event handler called the tab is changed
  */
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <React.Fragment>
      {isGranted(perms.view_distribution_units) && <Box>

        {/********** Page Title and Actions Toolbar **********/}
        <PageTitleBar title={t("distributionUnits")}>
          <DtFilterControls
            isFiltersVisible={isFiltersVisible}
            hasFilters={!objectIsEmpty(dtParams.filters, true)}
            onApplyFilters={handleSubmit}
            onClearFilters={clearFilters}
            onToggleFilters={toggleFilters}
            sx={{ mr: 1 }}
          ></DtFilterControls>
        </PageTitleBar>

        {/********** Filters Panel **********/}
        {isFiltersVisible && <Filters
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleChange={handleChange}
          setStatus={setStatus}
          authUser={authUser}
        />}

        {/********** DataGrid **********/}
        <TableContainer component={Paper} sx={{ padding: 2 }}>
          {!!dtError && <Alert severity="error" sx={{ mb: 1 }}>{t("unableToLoadDistributionUnits")}</Alert>}
          <Box sx={{ zIndex: 200, position: "relative" }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{ marginLeft: "18px" }}>
              <Tab label={t("list")} />
              <Tab label={t("map")} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Box sx={{ marginTop: { xs: "-32px", md: "-92px" } }}>
              <Dt
                rows={dtRows}
                rowCount={dtRowCount}
                columns={columns(t)}
                params={dtParams}
                isBusy={isDtLoadInProgress}
                localeText={{
                  noRowsLabel: t("noDistributionUnitsFound"),
                  noResultsOverlayLabel: t("noDistributionUnitsFound"),
                }}
                onPaginationChange={updateDtParams}
                onSearchChange={updateDtParams}
                onSortChange={updateDtParams}
                hiddenColumns={['updatedTs']}
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box sx={{ borderTop: "1px solid #dee2e6", paddingTop: "20px", marginTop: "-42px", marginLeft: "18px", marginRight: "18px" }}>

              {totalDu.filter((du) => du.latitude !== null).length > 0
                ?
                <Maps
                  defaultMapPosition={MAP_CENTER_BRASOV}
                  zoom={14}
                >
                  <MapAllPins allPins={totalDu} />
                </Maps>
                :
                <Box sx={{ textAlign: "center" }}>
                  {t("noLocationsFound")}
                </Box>
              }
            </Box>
          </TabPanel>
        </TableContainer>
      </Box>}
      {isNotGranted(perms.view_distribution_units) && <AccessDenied />}
    </React.Fragment >
  )
}

const columns = (t: Function) => {
  let columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t("name"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_distirbution_unit, params.row.id)}>{params.value}</Link>
      },
    },
    {
      field: 'minStockPercentage',
      headerName: t("stock"),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value >= DistributionUnit.STOCK_LIMIT_HIGH) {
          return <CheckCircle color="success" />
        } else if (params.value >= DistributionUnit.STOCK_LIMIT_LOW) {
          return <Warning color="warning" />
        } else {
          return <Error color="error" />
        }
      },
    },
    {
      field: 'isSecurityActive',
      headerName: t("security"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return (!!params.value ? <CloudDone color="primary" /> : <CloudOff color="error" />)
      },
    },
    {
      field: 'isReaderActive',
      headerName: t("reader"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return (!!params.value ? <TapAndPlay color="primary" /> : <MobileOff color="error" />)
      },
    }
  ]


  return columns
}

export default ListOrg;