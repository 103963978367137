import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import { getInHouseArticles } from "helper/backend";
import Preloader from "component/common/Preloader";
import { showError } from "helper/util";
import { useTranslation } from "react-i18next";
import { Checkroom } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { routes } from "helper/route";

const ClientInHouseArticles = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [articles, setArticles] = useState<number>();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchDashboardArticles = useCallback(() => {
    setIsLoadInProgress(true)
    getInHouseArticles()
      .then(response => {
        setArticles(response.inHouseArticles);
      })
      .catch(_ex => {
        showError(t("unableToLoadInHouseArticles"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchDashboardArticles();
  }, [fetchDashboardArticles])

  return (
    <EditableCard sx={{ height: "110px", padding: 0, mt: 3, cursor: "pointer" }} onClick={() => navigate(routes.list_in_house_articles)}>
      <CardContent sx={{ padding: "0px !important" }}>
        <Box sx={{ background: "linear-gradient(15deg,#02a8b5 0,#31ce77 100%)", width: "100%", height: "100%", position: "relative", p: 3, color: "#FFFFFF", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Box sx={{ fontSize: "20px" }}>{t("inHouseArticles")}</Box>
            <Box sx={{ fontSize: "24px" }}>
              {articles}
            </Box>
          </Box>
          <Checkroom sx={{ fontSize: "40px", opacity: "0.7" }} />
        </Box>
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default ClientInHouseArticles;