import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { getYesNoOptions } from 'helper/util';
import { getAccessRoleList, getClientList, getOrganizationList } from 'helper/backend';
import { perms, useAccess } from 'context/access';
import Organization from 'model/organization';
import AccessRole from 'model/accessRole';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';
import MuiAutocomplete from 'component/common/MuiAutocomplete';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setFieldValue, setFieldError, setStatus, handleChange }: Props) => {

  const { t } = useTranslation();
  const { isGranted } = useAccess();

  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  // list of clients that populate the select field
  const [clients, setClients] = useState<Organization[]>([]);
  // list of access roles that populate the select field
  const [accessRoles, setAccessRoles] = useState<AccessRole[]>([]);

  /**
   * Fetches from the backend the list of access roles this user is allowed to see
   */
  const fetchAccessRoles = useCallback(() => {
    getAccessRoleList()
      .then(response => {
        setAccessRoles(response.accessRoles);
      })
      .catch(_ex => {
        setFieldError('accessRoleId', t("unableToLoadRoles"));
      });
  }, [setFieldError, t]);

  /**
   * Fetches from the backend the list of organizations this user is allowed to see
   */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  /**
   * Fetches from the backend the list of clients this user is allowed to see
   */
  const fetchClients = useCallback(() => {
    getClientList()
      .then(response => {
        setClients(response.clients);
      })
      .catch(_ex => {
        setFieldError('clientId', t("unableToLoadClients"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchAccessRoles();
    if (isGranted(perms.create_organizations)) {
      fetchOrganizations();
    }
    if (isGranted(perms.create_clients)) {
      fetchClients();
    }
  }, [fetchAccessRoles, fetchClients, fetchOrganizations, isGranted]);

  /**
   * Converts the list of organizations to select options
   */
  const getOrganizationOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = organizations.map(org => ({ label: org.companyName, value: org.id }));
    options.push(...orgs);
    return options;
  }, [organizations, t]);

  /**
   * Converts the list of clients to select options
   */
  const getClientOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = clients.map(cli => ({ label: cli.companyName, value: cli.id }));
    options.push(...orgs);
    return options;
  }, [clients, t]);

  /**
   * Converts the list of access roles to select options
   */
  const getAccessRoleOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const roles = accessRoles.filter(role => (role.id !== AccessRole.ID_ORGANIZATION_DRIVER)).map(role => ({ label: role.name, value: role.id }));
    options.push(...roles);
    return options;
  }, [accessRoles, t]);

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <GridContainer spacing={2}>
          <Grid xs={12} md={3}>
            <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <TextField name="email" label={t("email")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
          </Grid>
          {isGranted(perms.create_organizations) && <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('organizationId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('organizationId');
              }}
              value={getOrganizationOptions().find(option => option.value === values.organizationId)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getOrganizationOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} />}
            />
          </Grid>}
          {isGranted(perms.create_clients) && <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('clientId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('clientId');
              }}
              value={getClientOptions().find(option => option.value === +values.clientId) || getClientOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getClientOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("client")} error={!!errors.clientId} helperText={errors.clientId} />}
            />
          </Grid>}
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('accessRoleId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('accessRoleId');
              }}
              value={getAccessRoleOptions().find(option => option.value === values.accessRoleId)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getAccessRoleOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("role")} error={!!errors.accessRoleId} helperText={errors.accessRoleId} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('isActive', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('isActive');
              }}
              value={getYesNoOptions(t("all")).find(option => option.value === values.isActive)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getYesNoOptions(t("all"))}
              renderInput={(params: any) => <TextField {...params} label={t("active")} error={!!errors.isActive} helperText={errors.isActive} />}
            />
          </Grid>
        </GridContainer>
      </form>
    </CardContent>
  </Card>
}

export default Filters;