import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { AppError } from "@type";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { getOverDuePickup } from "helper/backend";
import { useTranslation } from "react-i18next";
import { showDaysText, toSimpleError } from "helper/util";
import TableNoBb from "component/styled/TableNoBb";
import { route, routes } from "helper/route";
import { Pickups } from "@type/backend";
import { formatTimestamp, formats } from "helper/date";

const OverDuePickups = () => {
  const { t } = useTranslation();

  // Holds the due pickups received from backend
  const [duePickups, setDuePickups] = useState<Pickups[]>([])
  // error encoutered while fetching the due pickups (if any)
  const [duePickupsError, setDuePickupsError] = useState<AppError | undefined>();
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchDuePickups = useCallback(() => {
    setIsLoadInProgress(true)
    getOverDuePickup()
      .then(response => {
        setDuePickups(response.pickups);
      })
      .catch(ex => {
        setDuePickupsError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, []);

  useEffect(() => {
    fetchDuePickups();
  }, [fetchDuePickups])

  return (
    <EditableCard sx={{ height: "360px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={t("overdueHandovers")} />
        {
          duePickups.length > 0
            ?
            <TableNoBb>
              <TableHead>
                <TableRow>
                  <TableCell>{t("client")}</TableCell>
                  <TableCell style={{ width: "20px" }}>{t("dueDate")}</TableCell>
                  <TableCell>{t("overdueBy")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  duePickups.map((el, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell className="preview-value"><Link href={route(routes.view_client, el.clientId)}>{el.companyName}</Link></TableCell>
                        <TableCell>{formatTimestamp(el.handoverBy, formats.DATE)}</TableCell>
                        <TableCell className="preview-value">- {el.overdueDays} {t(showDaysText(el.overdueDays))}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </TableNoBb>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noItems")}
            </Box>
        }
        {!!duePickupsError &&
          <Alert severity="error">
            {duePickupsError.message}
          </Alert>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard>
  )
};

export default OverDuePickups;