import React, { useEffect, useState } from "react";
import EditableCard from "component/styled/EditableCard";
import { CardContent } from "@mui/material";
import SectionInfoView from "./View";
import Client from "model/client";
import SectionInfoEdit from "./Edit";

type Props = {
  client: Client;
  refreshHandler: Function;
  isLoadInProgress: boolean;
};

const SectionInfo = ({ client, refreshHandler, isLoadInProgress }: Props) => {

  // whether the edit mode is active for this section
  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);
  // whether the edit form has just been saved and the user record is being reloaded from the backend
  const [isReloading, setIsReloading] = useState(false);

  // This hook runs every time the loading status changes
  useEffect(() => {
    if (!isLoadInProgress) {
      // the loading has finished so change the flag
      setIsReloading(false);
    }
  }, [isLoadInProgress]);

  /**
  * Event handler called whenever the user shows or hides the edit form
  * a) clicks on the edit button to edit the section
  * b) clicks on the cancel button to close the form
  * c) clicks on the save button to apply the changes
  */
  const switchEditMode = (shouldRefresh: boolean = false) => {
    setIsEditModeActive(mode => !mode);
    if (shouldRefresh) {
      // edit form has been saved so we need to refresh the data
      setIsReloading(true);
      refreshHandler();
    };
  };

  return <EditableCard sx={{ height: '100%' }}>
    <CardContent>
      {!isEditModeActive && <SectionInfoView client={client} onEdit={switchEditMode} isReloading={isReloading} />}
      {isEditModeActive && <SectionInfoEdit client={client} onFinish={switchEditMode} />}
    </CardContent>
  </EditableCard>
}

export default SectionInfo;