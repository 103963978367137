import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import Preloader from 'component/common/Preloader';
import ConfirmDialog from "component/common/ConfirmDialog";
import { perms, useAccess } from "context/access";
import { deleteOrganization, getOrganization } from "helper/backend";
import { routes } from "helper/route";
import { showError, showSuccess, toSimpleError } from "helper/util";
import { useNavigate, useParams } from "react-router-dom";
import { AppError } from "@type";
import Organization from "model/organization";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import SectionInfo from "./Partial/SectionInfo";
import SectionBilling from "./Partial/SectionBilling";
import SectionUsers from "./Partial/SectionUsers";
import GridContainer from "component/common/GridContainer";
import SectionSettings from "./Partial/SectionSettings";
import { useTranslation } from "react-i18next";
import SectionStripe from "./Partial/SectionStripe";
import SectionPaymentPlans from "./Partial/SectionPaymentPlans";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the organization record fetched from the backend
  const [organization, setOrganization] = useState<Organization | undefined>()
  // error encoutered while fetching the organization (if any)
  const [organizationError, setOrganizationerror] = useState<AppError | undefined>();
  // whether the loading of the organization is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the organization is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the organization record from the backend
  */
  const refreshOrganization = useCallback(() => {
    setIsLoadInProgress(true)
    getOrganization(recordId)
      .then(response => {
        setOrganization(response.organization);
      })
      .catch(ex => {
        setOrganizationerror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  /**
   * Deletes the organization record in the backend
   */
  const removeOrganization = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteOrganization(recordId)
      .then(_response => {
        showSuccess(t("organizationHasBeenDeleted"));
        return navigate(routes.list_organizations);
      })
      .catch(_ex => {
        showError(t("unableToDeleteOrganization"));
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshOrganization();
  }, [refreshOrganization]);

  return (
    <>
      {isGranted(perms.view_organizations)
        &&
        <>
          {
            !!organization
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(organization, t)}>
                {isGranted(perms.delete_organizations) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteOrganization")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_organizations} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionBilling organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionSettings organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionStripe organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionPaymentPlans organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12}>
                  <SectionUsers
                    organizationId={recordId}
                  />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton="Delete"
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeOrganization();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteOrganizationConfirmation")} &quot;{organization.companyName}&quot;?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !organization && <Preloader container="content" />}
          {!!organizationError && <Error error={organizationError} title404={t("organizationNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_organizations) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (organization: Organization, t: Function) => [{
  text: t("organizations"),
  url: routes.list_organizations,
}, {
  text: organization.companyName!,
}];

export default Single;