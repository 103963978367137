import { CardContent } from '@mui/material';
import EditableCard from 'component/styled/EditableCard';
import User from 'model/user';
import React, { useEffect, useState } from 'react';
import SectionInfoEdit from './Edit';
import SectionInfoView from './View';

type Props = {
  user: User;
  refreshHandler: Function;
  isLoadInProgress: boolean;
};

const SectionInfo = ({ user, refreshHandler, isLoadInProgress }: Props) => {

  // whether the edit mode is active for this section (edit form is visible)
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // whether the edit form has just been saved and the user record is being reloaded from the backend
  const [isReloading, setIsReloading] = useState(false);

  // This hook runs every time the loading status changes
  useEffect(() => {
    if (!isLoadInProgress) {
      // the loading has finished so change the flag
      setIsReloading(false);
    }
  }, [isLoadInProgress]);

  /**
   * Event handler called whenever the user shows or hides the edit form
   * a) clicks on the edit button to edit the section
   * b) clicks on the cancel button to close the form
   * c) clicks on the save button to apply the changes
   */
  const switchEditMode = (shouldRefresh: boolean = false) => {
    setIsEditModeActive(mode => !mode);
    if (shouldRefresh) {
      // edit form has been saved so we need to refresh the data
      setIsReloading(true);
      refreshHandler();
    }
  }

  return <EditableCard>
    <CardContent>
      {!isEditModeActive && <SectionInfoView user={user} onEdit={switchEditMode} isReloading={isReloading} />}
      {isEditModeActive && <SectionInfoEdit user={user} onFinish={switchEditMode} />}
    </CardContent>
  </EditableCard>
}

export default SectionInfo;
