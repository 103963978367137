import React from 'react';
import Box from '@mui/material/Box';
import QuickNotifications from './QuickNotifications';
import QuotaIndicator from './QuotaIndicator';
import { perms, useAccess } from 'context/access';

const QuickMenu = () => {

  const { isGranted } = useAccess();

  return <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
    {
      isGranted(perms.view_quota_indicator) &&
      <QuotaIndicator />
    }
    <QuickNotifications />
  </Box>
}

export default QuickMenu;