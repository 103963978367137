import React, { FocusEvent } from 'react';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from 'component/common/CardTitleBar';
import Device from 'model/device';
import { useTranslation } from 'react-i18next';

type Props = {
  formik: any;
};

const FormCreateDuDevice = ({ formik }: Props) => {

  const { values, errors, setStatus, setFieldValue, handleChange } = formik;

  const { t } = useTranslation();

  /**
  * Converts the list of types to select options
  */
  const getTypeOptions = () => Device.DeviceTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedTypeOption = () => getTypeOptions().find(option => option.value === values.type) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <Grid container spacing={2}>
    <Grid xs={12} md={6}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <CardTitleBar sx={{ mb: 4 }} title={t("generalInfo")} />
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('type', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('type');
                }}
                value={getSelectedTypeOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getTypeOptions()}
                renderInput={(params: any) => <TextField {...params} label={t("type")} error={!!errors.type} helperText={errors.type} inputProps={{ ...params.inputProps, autoComplete: 'new-type' }} />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
};

export default FormCreateDuDevice;