import React from "react";
import { IconButton, Link, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import Device from "model/device";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth";

type Props = {
  device: Device;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ device, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  return <React.Fragment>
    <CardTitleBar title={device.name!}>
      {isGranted(perms.edit_devices) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{device.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("code")}</TableCell>
          <TableCell className="preview-value">{device.code}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("lastSync")}</TableCell>
          <TableCell className="preview-value">{device.lastSyncTs ? formatTimestamp(device.lastSyncTs, formats.DATETIME) : 'Never'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("organization")}</TableCell>
          <TableCell className="preview-value"><Link href={route(routes.view_organization, device.organizationId)}>{device.organizationName}</Link></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("location")}</TableCell>
          <TableCell className="preview-value">{device.locationName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("lastLogin")}</TableCell>
          <TableCell className="preview-value">{!!device.lastLoginTs ? formatTimestamp(device.lastLoginTs, formats.DATETIME) : t("never")}
          </TableCell>
        </TableRow>
        {
          !!device.lastLoginUserId && authUser.isOrganizationAdmin() &&
          <TableRow>
            <TableCell>{t("lastLoginUser")}</TableCell>
            <TableCell className="preview-value">
              <Link href={route(routes.view_user, device.lastLoginUserId)}>{device.userFullName}</Link>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;