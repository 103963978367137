// eslint-disable-next-line no-extend-native
Error.prototype.className = 'Error';

export class BeException extends Error {

  // Make sure this is hard-coded as a string
  // BeException.name will not work in production
  // because class names are changed when minified
  className: string = 'BeException';
  code: number | undefined;
  prev: Error | undefined;

  constructor(message?: string, code?: number, prev?: Error) {
    super(message);
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor);
    };
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class AuthException extends BeException {

  className: string = 'AuthException';

  constructor(message?: string, code?: number, prev?: Error) {
    super(message || 'Access denied', code, prev);
  }
}

export class AccessDeniedException extends BeException {

  className: string = 'AccessDeniedException';

  constructor(message?: string, code?: number, prev?: Error) {
    super(message || 'Access denied', code, prev);
  }
}

export class NotFoundException extends BeException {

  className: string = 'NotFoundException';

  constructor(message?: string, code?: number, prev?: Error) {
    super(message || 'Resource not found', code, prev);
  }
}

export class BadRequestException extends BeException {

  className: string = 'BadRequestException';

  constructor(message?: string, code?: number, prev?: Error) {
    super(message || 'Bad request', code, prev);
  }
}

export class ValidationException extends BadRequestException {

  className: string = 'ValidationException';

  fields: any;

  constructor(message?: string, fields?: any) {
    super(message);
    this.fields = fields;
  }
}

export class ServerErrorException extends BeException {

  className: string = 'ServerErrorException';

  constructor(message?: string, code?: number, prev?: Error) {
    super(message || 'Internal server error', code, prev);
  }
}

export class LocalException extends Error {

  className: string = 'LocalException';
  code: number | undefined;
  prev: Error | undefined;

  constructor(message?: string, code?: number, prev?: Error) {
    super(message);
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class NetworkErrorException extends LocalException {

  className: string = 'NetworkErrorException';

  constructor(message?: string, code?: number, prev?: Error) {
    super(message || 'Network error', code, prev);
  }
}

export const ACC_TOKEN_MISSING = 1001;
export const ACC_TOKEN_JWT_EXPIRED = 1003;
export const USER_NOT_FOUND = 1015;
export const INVALID_CREDENTIALS = 1016;
export const UNABLE_SEND_PASSWORD_RESET_MAIL = 1023;
export const EMAIL_VERIFICATION_INVALID_TOKEN = 1024;
export const EMAIL_ALREADY_VERIFIED = 1025;
export const ACCOUNT_ALREADY_ACTIVATED = 1026;
export const UNABLE_DELETE_ARTICLE = 1038;
export const UNABLE_DELETE_CLIENT = 1039;
export const UNABLE_DELETE_DU = 1041;
export const CLIENT_USERS_LIMIT_REACHED = 1044;
export const PACKAGE_NOT_FOUND = 1049;
export const TAG_NOT_FOUND = 1050;
export const UNABLE_DELETE_CURRENCY = 1055;
export const OPERATION_NOT_FOUND = 1048;
export const REGISTRATION_INVALID_TOKEN = 1075;
export const ORGANIZATION_ALREADY_REGISTERED = 1078;
export const STRIPE_CUSTOMER_ID_ALREADY_SET = 1071;
export const UNABLE_DELETE_PAYMENT_PLAN_DUE_TO_FOREIGN_CONSTRAINTS = 1096;