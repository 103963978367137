import React, { ChangeEvent, useCallback } from 'react';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { getYesNoOptions } from 'helper/util';
import { useTranslation } from 'react-i18next';


type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setFieldValue, setStatus }: Props) => {

  const { t } = useTranslation();

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('isPublic', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('isPublic');
              }}
              value={getYesNoOptions(t("all")).find(option => option.value === values.isActive)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getYesNoOptions(t("all"))}
              renderInput={(params: any) => <TextField {...params} label={t("public")} error={!!errors.isActive} helperText={errors.isActive} />}
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;