import React, { FocusEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from 'component/common/CardTitleBar';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';
import Device from 'model/device';

const FormCreate = ({ formik }: any) => {

  const { t } = useTranslation();

  const { values, errors, setStatus, setFieldValue, handleChange } = formik;

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Converts the list of types to select options
  */
  const getTypeOptions = () => Device.DeviceTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedTypeOption = () => getTypeOptions().find(option => option.value === values.deviceType) || null;

  return <>
    <CardTitleBar sx={{ mb: 4 }} title={t("generalInfo")} />
    <GridContainer spacing={2}>
      <Grid xs={12}>
        <TextField name="name" label={t("version")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('deviceType', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('deviceType');
          }}
          value={getSelectedTypeOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getTypeOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("deviceType")} error={!!errors.deviceType} helperText={errors.deviceType} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
        />
      </Grid>
      <Grid xs={12}>
        <TextField name="description" label={t("description")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.description} error={!!errors.description} helperText={errors.description} rows={6} multiline sx={{ width: '100%' }} />
      </Grid>
    </GridContainer>
  </>
}

export default FormCreate;
