import React, { useState, FocusEvent, useCallback } from "react";
import { Button, TextField, Autocomplete } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { regexZip } from "constant/regex";
import { updateOwnClient } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { getCountryOptions } from "helper/country";
import GridContainer from "component/common/GridContainer";
import Client from "model/client";
import { useTranslation } from "react-i18next";


type Values = {
  billingAddress: string;
  billingCity: string;
  billingZip: string;
  billingCounty: string;
  billingCountry: string | undefined;
};

type Props = {
  client: Client;
  onFinish: Function;
};

const SectionBillingEdit = ({ client, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    billingAddress: '',
    billingCity: '',
    billingZip: '',
    billingCounty: '',
    billingCountry: undefined
  };
  formInitialValues = {
    ...formInitialValues,
    billingAddress: client.billingAddress!,
    billingCity: client.billingCity!,
    billingZip: client.billingZip!,
    billingCounty: client.billingCounty!,
    billingCountry: client.billingCountry!
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    billingAddress: Yup.string().trim().required(t("fieldIsRequired")),
    billingCity: Yup.string().trim().required(t("fieldIsRequired")),
    billingZip: Yup.string().trim().required(t("fieldIsRequired")).matches(regexZip, t("invalidZipCode")),
    billingCounty: Yup.string().trim().required(t("fieldIsRequired")),
    billingCountry: Yup.string().trim().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveClient(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveClient = (values: any) => {
    setIsSubmitInProgress(true)
    updateOwnClient(values)
      .then(_response => {
        showSuccess(t("clientHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        showError(t("unableToSaveClient"));
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Returns the selected country as a select option
  */
  const getSelectedCountryOption = () => getCountryOptions().find(option => option.value === values.billingCountry) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);


  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar title={t("billing")} sx={{ mb: 4 }} >
        <ProgressButton type="submit" name="submitBillingInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveBillingInfo")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12}>
          <TextField name="billingAddress" label={t("address")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingAddress} error={!!errors.billingAddress} helperText={errors.billingAddress} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="billingCity" label={t("city")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCity} error={!!errors.billingCity} helperText={errors.billingCity} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="billingZip" label={t("zipCode")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingZip} error={!!errors.billingZip} helperText={errors.billingZip} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="billingCounty" label={t("county")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCounty} error={!!errors.billingCounty} helperText={errors.billingCounty} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} md={6}>
          <Autocomplete
            onChange={(_e, selectedOption) => {
              setFieldValue('billingCountry', selectedOption?.value);
            }}
            onFocus={_e => {
              onSelectFieldFocused('billingCountry');
            }}
            value={getSelectedCountryOption()}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={getCountryOptions()}
            renderInput={(params: any) => <TextField {...params} name="billingCountry" label={t("country")} error={!!errors.billingCountry} helperText={errors.billingCountry} inputProps={{ ...params.inputProps, autoComplete: 'new-country' }} />}
          />
        </Grid>
      </GridContainer>
    </form >
  )
}

export default SectionBillingEdit;
