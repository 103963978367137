let env: any = {};

const prefix = 'REACT_APP_';

for (const [key, value] of Object.entries(process.env)) {
  if (!key.startsWith(prefix)) {
    continue;
  }
  const confKey = key.replace(prefix, '');
  env[confKey] = value;
}

env = {
  ...env,
  APP_VERSION: '1.0.1',
  APP_TITLE: '24 Linen',
  // socket connection requests are sent to this backend path
  // must match backend value
  SOCKET_PATH: '/be/socket.io/',
  // socket client will connect to this namespace by default
  // must be a valid backend namespace
  SOCKET_NAMESPACE: '/be',
  // api urls
  API_BE_URL: env.API_URL + '/be',
  SIDEBAR_WIDTH: 300,
  // number of rows to show in nested lists before displaying a 'View more' button
  COLLAPSED_TABLE_ROWS: 6,
  // base currency code for exchange rates
  BASE_CURRENCY_CODE: 'RON'
};

export default env;