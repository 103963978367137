import React, { useState } from "react";
import { Box, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CardTitleBar from 'component/common/CardTitleBar';
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import Organization from "model/organization";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";

type Props = {
  organization: Organization;
  onEdit: Function;
  isReloading: boolean;
};

const SectionSettingsView = ({ organization, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  const [hasLogo, setHasLogo] = useState(true);

  return <React.Fragment>
    <CardTitleBar title={t("settings")}>
      {isGranted(perms.edit_organizations) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("penaltyPercent")}</TableCell>
          <TableCell className="preview-value">{organization.penaltyPercent}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("invoicePrefix")}</TableCell>
          <TableCell className="preview-value">{organization.invoicePrefix}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Logo</TableCell>
          <TableCell className="preview-value">
            {
              hasLogo ?
                <Box
                  sx={{
                    height: '80px',
                    objectFit: 'contain',
                    margin: "16px 16px 16px 0px"
                  }}
                >
                  <img src={`${process.env.REACT_APP_API_URL}/static/logo/${organization.id}/logo.png?${Date.now()}`} alt="" onError={() => { setHasLogo(false) }} style={{ height: "100%" }} />
                </Box>
                :
                <Box>
                  {t("noLogoUploaded")}
                </Box>
            }
          </TableCell>
        </TableRow>

      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionSettingsView;