import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { useTranslation } from "react-i18next";
import ExchangeRate from "model/exchangeRate";
import Currency from "model/currency";

type Props = {
  exchangeRate: ExchangeRate[];
  onEdit: Function;
  isReloading: boolean;
  baseCurrency: Currency;
};

const SectionInfoView = ({ exchangeRate, onEdit: onCancel, isReloading, baseCurrency }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar>
      {isGranted(perms.edit_exchange_rates) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("baseCurrency")}</TableCell>
          <TableCell className="preview-value">{baseCurrency.name} ({baseCurrency.code})</TableCell>
        </TableRow>
        {
          exchangeRate.map((el, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell>1 {`${el.currencyName} (${el.currencyCode})`}</TableCell>
                <TableCell className="preview-value">{el.value}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;