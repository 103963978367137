export default class AccessRole {

  id!: number;
  name!: string;
  type!: number;
  createdTs!: number;
  updatedTs!: number;

  static ID_MASTER_ADMIN = 100;
  static ID_ORGANIZATION_ADMIN = 200;
  static ID_ORGANIZATION_DRIVER = 201;
  static ID_ORGANIZATION_SECURITY = 202;
  static ID_CLIENT_ADMIN = 300;

  static TYPE_MASTER = 100;
  static TYPE_ORGANIZATION = 200;
  static TYPE_CLIENT = 300;

  static masterTypeRoleIds = [
    this.ID_MASTER_ADMIN,
  ];

  static organizationTypeRoleIds = [
    this.ID_ORGANIZATION_ADMIN,
    this.ID_ORGANIZATION_DRIVER,
    this.ID_ORGANIZATION_SECURITY
  ];

  static clientTypeRoleIds = [
    this.ID_CLIENT_ADMIN,
  ];

  static isMasterAdmin = (roleId: number) => roleId === this.ID_MASTER_ADMIN;

  static isOrganizationAdmin = (roleId: number) => roleId === this.ID_ORGANIZATION_ADMIN;

  static isDriverType = (roleId: number) => roleId === this.ID_ORGANIZATION_DRIVER;

  static isClientAdmin = (roleId: number) => roleId === this.ID_CLIENT_ADMIN;

  static isMasterType = (roleId: number) => this.masterTypeRoleIds.includes(roleId);

  static isOrganizationType = (roleId: number) => this.organizationTypeRoleIds.includes(roleId);

  static isClientType = (roleId: number) => this.clientTypeRoleIds.includes(roleId);

  static getType = (roleId: number) => {
    if (this.isMasterType(roleId)) {
      return this.TYPE_MASTER;
    } else if (this.isOrganizationType(roleId)) {
      return this.TYPE_ORGANIZATION;
    } else if (this.isClientType(roleId)) {
      return this.TYPE_CLIENT;
    }
    return null;
  }

  static isSameType = (roleId1: number, roleId2: number) => this.getType(roleId1) === this.getType(roleId2);
}