import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import CardTitleBar from 'component/common/CardTitleBar';
import TableNoBb from "component/styled/TableNoBb";
import { getCountryName } from "helper/country";
import { perms, useAccess } from "context/access";
import Preloader from "component/common/Preloader";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Billing } from "@type/backend";

type Props = {
  billingInfo: Billing;
  onEdit: Function;
  isReloading: boolean;
};

const SectionBillingView = ({ billingInfo, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("billing")}>
      {isGranted(perms.edit_master) && <IconButton onClick={() => onCancel(2)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("address")}</TableCell>
          <TableCell className="preview-value">{billingInfo.billingAddress}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("city")}</TableCell>
          <TableCell className="preview-value">{billingInfo.billingCity}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("zipCode")}</TableCell>
          <TableCell className="preview-value">{billingInfo.billingZip}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("county")}</TableCell>
          <TableCell className="preview-value">{billingInfo.billingCounty}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("country")}</TableCell>
          <TableCell className="preview-value">{getCountryName(billingInfo.billingCountry || "")}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("currency")}</TableCell>
          <TableCell className="preview-value">{billingInfo.currencyName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("vatRate")}</TableCell>
          <TableCell className="preview-value">{billingInfo.vatRate}%</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionBillingView;