import React from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import ActivityLog from "model/activityLog";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";

type Props = {
  activityLog: ActivityLog;
};

const SectionException = ({ activityLog }: Props) => {

  const { t } = useTranslation();

  return (
    <EditableCard sx={{ height: "100%", maxHeight: "600px", overflow: "auto" }}>
      <CardContent sx={{ width: "95%" }}>
        <CardTitleBar title={t("request")} />
        {
          !!activityLog.request
            ?
            <pre>
              {JSON.stringify(activityLog.request, null, 4)}
            </pre>
            :
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>{t("noRequestFound")}</Box>
        }
      </CardContent>
    </EditableCard>
  )
};

export default SectionException;