import React, { FocusEvent, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Button, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import ProgressButton from "component/common/ProgressButton";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ValidationException } from "helper/error";
import { updateDuArticle } from "helper/backend";
import DuArticle from "model/duArticle";
import { useTranslation } from "react-i18next";

type Values = {
  minStock: number;
};

type Props = {
  duArticle: DuArticle;
  onFinish: Function;
};

const SectionInfoEdit = ({ duArticle, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    minStock: 0
  };
  formInitialValues = {
    ...formInitialValues,
    minStock: duArticle.minStock!,
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    minStock: Yup.number().typeError(t("minimumStockNumber")).required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDuArticle(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDuArticle = (values: any) => {
    setIsSubmitInProgress(true);
    updateDuArticle(duArticle.id!, values)
      .then(_response => {
        showSuccess(t("articleHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveArticle"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveArticle")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12} md={6}>
          <TextField name="minStock" label={t("minimumStock")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.minStock} error={!!errors.minStock} helperText={errors.minStock} sx={{ width: '100%' }} />
        </Grid>
      </GridContainer>
    </form>
  )
};

export default SectionInfoEdit;