import React, { useState, FocusEvent } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Check, Delete, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import Organization from "model/organization";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateOwnOrganization } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import UploadPicture from "component/common/UploadPicture";
import { useAuth } from "context/auth";

type Values = {
  penaltyPercent: number;
  invoicePrefix: string;
  logo: any;
};

type Props = {
  organization: Organization;
  onFinish: Function;
};

const SectionSettingsEdit = ({ organization, onFinish }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    penaltyPercent: Number.MIN_SAFE_INTEGER,
    logo: null,
    invoicePrefix: ''
  };
  formInitialValues = {
    ...formInitialValues,
    penaltyPercent: organization.penaltyPercent,
    invoicePrefix: organization.invoicePrefix,
    logo: authUser.hasCustomLogo ? `${process.env.REACT_APP_API_URL}/static/logo/${organization.id}/logo.png` : null
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    penaltyPercent: Yup.number().typeError(t("penaltyPercentNumber")).required(t("fieldIsRequired")),
    invoicePrefix: Yup.string()
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveOrganization(values);
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveOrganization = (values: any) => {
    setIsSubmitInProgress(true)
    let formData = new FormData();
    if (values.logo instanceof File) {
      formData.append("logo", values.logo);
    }
    formData.append("penaltyPercent", values.penaltyPercent);
    formData.append("invoicePrefix", values.invoicePrefix);
    updateOwnOrganization(formData)
      .then(_response => {
        showSuccess(t("organizationHasBeenSaved"));
        onFinish(true);
        if (!!formData.get("logo") || formData.get("logo") === null) {
          window.location.reload();
        }
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableSaveOrganization"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user remove organization logo
  */
  const removeLogo = () => {
    setFieldValue("logo", null);
  }

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={t("settings")} sx={{ mb: 4 }} >
      {!!values.logo &&
        <Button variant="contained" color="error" onClick={removeLogo} startIcon={<Delete />} sx={{ mr: 1 }}>Remove logo</Button>
      }
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveSettings")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField type="number" name="penaltyPercent" label={t("penaltyPercent")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.penaltyPercent} error={!!errors.penaltyPercent} helperText={errors.penaltyPercent} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="invoicePrefix" label={t("invoicePrefix")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.invoicePrefix} error={!!errors.invoicePrefix} helperText={errors.invoicePrefix} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12}>
        <Typography
          sx={{
            color: '#5d6575',
            mb: 1
          }}
        >
          Logo
        </Typography>
        <UploadPicture logo={values.logo} setFieldValue={setFieldValue} />
      </Grid>
    </GridContainer>
  </form>
}

export default SectionSettingsEdit;
