import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { AppError } from "@type";
import { deleteDevice, getDevice } from "helper/backend";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess, toSimpleError } from "helper/util";
import Error from 'page/Error';
import Device from "model/device";
import SectionInfo from "../Partial/SectionInfo";
import { useTranslation } from "react-i18next";
import SectionReaders from "../Partial/SectionReaders";
import { route, routes } from "helper/route";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { deviceId, duId } = useParams();
  const recordId = parseInt(deviceId!);
  const distributionUnitId = parseInt(duId!);

  // the device record fetched from the backend
  const [device, setDevice] = useState<Device | undefined>()
  // error encoutered while fetching the Device (if any)
  const [deviceError, setDeviceError] = useState<AppError | undefined>();
  // whether the loading of the device is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the Du is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the device record from the backend
  */
  const refreshDevice = useCallback(() => {
    setIsLoadInProgress(true)
    getDevice(recordId)
      .then(response => {
        setDevice(response.device);
      })
      .catch(ex => {
        setDeviceError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId]);

  /**
  * Deletes the Du record in the backend
  */
  const removeDevice = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteDevice(recordId)
      .then(_response => {
        showSuccess(t("deviceHasBeenDeleted"));
        return navigate(route(routes.view_distirbution_unit, distributionUnitId));
      })
      .catch(_ex => {
        let errMessage = t("unableToDeleteDevice");
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t, distributionUnitId]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshDevice();
  }, [refreshDevice]);

  return (
    <>
      {isGranted(perms.view_devices)
        &&
        <>
          {
            !!device
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(device, t)}>
                {isGranted(perms.delete_devices) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteDevice")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={route(routes.view_distirbution_unit, distributionUnitId)} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo device={device} refreshHandler={refreshDevice} isLoadInProgress={isLoadInProgress} ret={route(routes.view_distirbution_unit, distributionUnitId)} />
                </Grid>
                <Grid xs={12}>
                  <SectionReaders deviceId={device.id} standalone={false} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeDevice();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteDeviceConfirmation")} &quot;{device.name}&quot;?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !device && <Preloader container="content" />}
          {!!deviceError && <Error error={deviceError} title404={t("deviceNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_devices) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (device: Device, t: Function) => [{
  text: t("distributionUnits"),
  url: routes.list_distribution_units
},
{
  text: device.distributionUnitName,
  url: route(routes.view_distirbution_unit, device.distributionUnitId)
},
{
  text: device.name!
}];

export default Single;