import {
  APPLY_APP_VERSION_DT_PARAMS,
  PATCH_APP_VERSION_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyAppVersionDtParams = (params: DtParams) => ({
  type: APPLY_APP_VERSION_DT_PARAMS,
  payload: { params },
});

export const patchAppVersionDtParams = (params: DtParams) => ({
  type: PATCH_APP_VERSION_DT_PARAMS,
  payload: { params },
});