import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit, TaskAlt, Unpublished } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { useTranslation } from "react-i18next";
import Reader from "model/reader";
import IconTableCell from "component/common/IconCell";

type Props = {
  reader: Reader;
  onEdit: Function;
  isReloading: boolean;
};

const SectionReaderView = ({ reader, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title="Reader">
      {isGranted(perms.edit_devices) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{reader.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("zone")}</TableCell>
          <TableCell className="preview-value">{Reader.getZoneName(reader.zone)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("vendor")}</TableCell>
          <TableCell className="preview-value">{Reader.getVendorName(reader.vendor)}</TableCell>
        </TableRow>
        {
          reader.vendor === Reader.VENDOR_IMPINJ &&
          <>
            <TableRow>
              <TableCell>{t("ip")}</TableCell>
              <TableCell className="preview-value">{reader.ip}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("sensitivity")}</TableCell>
              <TableCell className="preview-value">{reader.sensitivity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("power")}</TableCell>
              <TableCell className="preview-value">{reader.power}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("antennas")}</TableCell>
              <TableCell className="preview-value">{reader.antennas}</TableCell>
            </TableRow>
            {
              !!reader.readerMode &&
              <TableRow>
                <TableCell>{t("readerMode")}</TableCell>
                <TableCell className="preview-value">{Reader.getReaderModeName(reader.readerMode)}</TableCell>
              </TableRow>
            }
            {
              !!reader.estimatedPopulation &&
              <TableRow>
                <TableCell>{t("estimatedPopulation")}</TableCell>
                <TableCell className="preview-value">{reader.estimatedPopulation}</TableCell>
              </TableRow>
            }
            {
              !!reader.session &&
              <TableRow>
                <TableCell>{t("session")}</TableCell>
                <TableCell className="preview-value">{reader.session}</TableCell>
              </TableRow>
            }
            {
              reader.searchMode > -1 &&
              <TableRow>
                <TableCell>{t("searchMode")}</TableCell>
                <TableCell className="preview-value">{Reader.getSearchModeName(reader.searchMode)}</TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell>{t("hasLocalControl")}</TableCell>
              <IconTableCell className="preview-value">{reader.hasLocalControl ? <TaskAlt /> : <Unpublished />}</IconTableCell>
            </TableRow>
          </>
        }
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionReaderView;