import { CloudDone, CloudOff, MobileOff, TapAndPlay } from "@mui/icons-material";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import { formatTimestamp, formats, getTimePassed } from "helper/date";
import DistributionUnit from "model/distributionUnit";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  distributionUnit: DistributionUnit;
};

const SectionStatus = ({ distributionUnit }: Props) => {

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      <EditableCard sx={{ height: "270px", padding: 0 }}>
        <CardContent sx={{ padding: "0px !important", height: "100%" }}>
          <Box sx={{ backgroundColor: "#343b4a", width: "100%", height: "100%", position: "relative", p: 2, color: "#FFFFFF", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ fontSize: "24px" }}>{t("security")}</Box>
            {
              !!distributionUnit.isSecurityActive
                ?
                <CloudDone sx={{ fontSize: "70px" }} color="primary" />
                :
                <CloudOff sx={{ fontSize: "70px" }} color="error" />
            }
            <Box>
              {
                !!distributionUnit.isSecurityActive
                  ?
                  <>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("onlineSince")} {formatTimestamp(distributionUnit.lastSecurityStatusTs, formats.DATETIME)}
                    </Box>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("upTime")} : {getTimePassed(moment().unix() - distributionUnit.lastSecurityStatusTs)}
                    </Box>
                  </>
                  :
                  <>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("offlineSince")} {formatTimestamp(distributionUnit.lastSecurityStatusTs, formats.DATETIME)}
                    </Box>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("downTime")}: {getTimePassed(moment().unix() - distributionUnit.lastSecurityStatusTs)}
                    </Box>
                  </>
              }
            </Box>
          </Box>
        </CardContent>
      </EditableCard >
      <EditableCard sx={{ height: "270px", padding: 0 }}>
        <CardContent sx={{ padding: "0px !important", height: "100%" }}>
          <Box sx={{ backgroundColor: "#343b4a", width: "100%", height: "100%", position: "relative", p: 2, color: "#FFFFFF", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ fontSize: "24px" }}>{t("reader")}</Box>
            {
              !!distributionUnit.isReaderActive
                ?
                <TapAndPlay sx={{ fontSize: "70px" }} color="primary" />
                :
                <MobileOff sx={{ fontSize: "70px" }} color="error" />
            }
            <Box>
              {
                !!distributionUnit.isReaderActive
                  ?
                  <>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("activeSince")} {formatTimestamp(distributionUnit.lastReaderStatusTs, formats.DATETIME)}
                    </Box>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("upTime")} : {getTimePassed(moment().unix() - distributionUnit.lastReaderStatusTs)}
                    </Box>
                  </>
                  :
                  <>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("inactiveSince")} {formatTimestamp(distributionUnit.lastReaderStatusTs, formats.DATETIME)}
                    </Box>
                    <Box sx={{ fontSize: "18px", textAlign: "center" }}>
                      {t("downTime")}: {getTimePassed(moment().unix() - distributionUnit.lastReaderStatusTs)}
                    </Box>
                  </>
              }
            </Box>
          </Box>
        </CardContent>
      </EditableCard >
    </Box>
  )
};

export default SectionStatus;