import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CardTitleBar from 'component/common/CardTitleBar';
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import Organization from "model/organization";
import TableNoBb from "component/styled/TableNoBb";
import { getCountryName } from "helper/country";
import { useTranslation } from "react-i18next";

type Props = {
  organization: Organization;
  onEdit: Function;
  isReloading: boolean;
};

const SectionBillingView = ({ organization, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("billing")}>
      {isGranted(perms.edit_organizations) && <IconButton onClick={() => onCancel(2)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("address")}</TableCell>
          <TableCell className="preview-value">{organization.billingAddress}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("city")}</TableCell>
          <TableCell className="preview-value">{organization.billingCity}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("zipCode")}</TableCell>
          <TableCell className="preview-value">{organization.billingZip}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("county")}</TableCell>
          <TableCell className="preview-value">{organization.billingCounty}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("country")}</TableCell>
          <TableCell className="preview-value">{getCountryName(organization.billingCountry || "")}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("currency")}</TableCell>
          <TableCell className="preview-value">{organization.currencyName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("vatRate")}</TableCell>
          <TableCell className="preview-value">{organization.vatRate}%</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionBillingView;