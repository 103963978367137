import {
  APPLY_RECEPTION_DT_PARAMS,
  PATCH_RECEPTION_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyReceptionDtParams = (params: DtParams) => ({
  type: APPLY_RECEPTION_DT_PARAMS,
  payload: { params },
});

export const patchReceptionDtParams = (params: DtParams) => ({
  type: PATCH_RECEPTION_DT_PARAMS,
  payload: { params },
});