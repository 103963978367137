import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import DuArticle from "model/duArticle";
import { useTranslation } from "react-i18next";

type Props = {
  duArticle: DuArticle;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ duArticle, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar>
      {isGranted(perms.edit_distribution_unit_articles) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("articleName")}</TableCell>
          <TableCell className="preview-value">{duArticle.articleName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("articleCode")}</TableCell>
          <TableCell className="preview-value">{duArticle.articleCode}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("minimumStock")}</TableCell>
          <TableCell className="preview-value">{duArticle.minStock}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;