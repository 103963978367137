import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { FileDownload, KeyboardArrowLeft } from "@mui/icons-material";
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { getInvoice, getInvoicePDF } from "helper/backend";
import { showError, toSimpleError } from "helper/util";
import { useParams } from "react-router-dom";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import Invoice from "model/invoice";
import { routes } from "helper/route";
import ProgressButton from "component/common/ProgressButton";
import { formatTimestamp, formats } from "helper/date";
import Invoices from "component/invoice/Invoice";

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the invoice record fetched from the backend
  const [invoice, setInvoice] = useState<Invoice | undefined>()
  // error encoutered while fetching the invoice (if any)
  const [invoiceError, setInvoiceError] = useState<AppError | undefined>();
  // whether the loading of the invoice is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the loading of the download is in progress
  const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false)

  /**
  * Download the operation record from the backend
  */
  const downloadReport = useCallback(() => {
    setIsDownloadInProgress(true)
    getInvoicePDF(recordId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Invoice${!!invoice?.number ? `_${invoice.number}` : ""}_${formatTimestamp(invoice?.createdTs!, formats.DATE_SPLIT)}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
      })
      .catch(_ex => {
        showError(t("unableToDownloadReport"));
      })
      .finally(() => {
        setIsDownloadInProgress(false);
      })
  }, [recordId, invoice, t])

  /**
  * Fetches the invoice record from the backend
  */
  const refreshInvoice = useCallback(() => {
    setIsLoadInProgress(true)
    getInvoice(recordId)
      .then(response => {
        setInvoice(response.invoice);
      })
      .catch(ex => {
        setInvoiceError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  // This hook runs once on component mount
  useEffect(() => {
    refreshInvoice();
  }, [refreshInvoice]);

  return (
    <>
      {isGranted(perms.view_my_invoices)
        &&
        <>
          {
            !!invoice
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(invoice, t)}>
                <ProgressButton onClick={downloadReport} variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isDownloadInProgress} startIcon={<FileDownload />}>{t("downloadPDF")}</ProgressButton>
                <Button variant="contained" color="secondary" component={Link} href={routes.list_my_invoice} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12}>
                  <Invoices invoice={invoice} />
                </Grid>
              </GridContainer>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !invoice && <Preloader container="content" />}
          {!!invoiceError && <Error error={invoiceError} title404={t("invoiceNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_my_invoices) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (invoice: Invoice, t: Function) => [{
  text: t("myInvoices"),
  url: routes.list_my_invoice,
}, {
  text: `#${invoice.number}`,
}];

export default Single;