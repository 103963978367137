import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import { toSimpleError } from "helper/util";
import { route, routes } from "helper/route";
import { getOrganizationAdmins } from "helper/backend";
import { AppError } from "@type";
import User from "model/user";
import TableNoBb from "component/styled/TableNoBb";
import Spinner from "component/common/Spinner";
import env from "env";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import IconTableCell from "component/common/IconCell";

type Props = {
  organizationId: number,
};

const SectionUsers = ({ organizationId }: Props) => {

  // the organization users record fetched from the backend
  const [users, setUsers] = useState([] as User[]);
  // whether the loading of the organization users is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // error encoutered while fetching the organization users (if any)
  const [error, setError] = useState<AppError>();
  // the number of seen users in the Users Table
  const [numOfVisibleUsers, setNumOfVisibleUsers] = useState(env.COLLAPSED_TABLE_ROWS);

  /**
  * On click function that shows all the users
  */
  const viewMore = () => {
    setNumOfVisibleUsers(users.length);
  };

  /**
  * On click function that shows only the number of users set in the environment variable
  */
  const viewLess = () => {
    setNumOfVisibleUsers(env.COLLAPSED_TABLE_ROWS);
  };

  /**
  * Fetches the organization users from the backend
  */
  const getOrganizationUsersList = useCallback(() => {
    setIsLoadInProgress(true)
    getOrganizationAdmins(organizationId)
      .then(response => {
        setUsers(response.users);
      })
      .catch(ex => {
        setError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [organizationId]);

  // This hook runs once on component mount
  useEffect(() => {
    getOrganizationUsersList();
  }, [getOrganizationUsersList]);

  const addUserLink = () => route(routes.new_organization_user, organizationId);
  const viewUserLink = (id: number) => route(routes.view_user, id, { ret: route(routes.view_organization, organizationId) });

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title="Users">
          <Button variant="contained" color="primary" component={Link} href={addUserLink()} startIcon={<PlaylistAddIcon />}>Add New User</Button>
        </CardTitleBar>
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!users.length && users.slice(0, numOfVisibleUsers).map((user, idx) =>
              <TableRow key={idx}>
                <TableCell><Link href={viewUserLink(user.id!)}>{user.fullName}</Link></TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.accessRoleName}</TableCell>
                <IconTableCell>{user.isActive ? <TaskAltIcon /> : <UnpublishedIcon />}</IconTableCell>
              </TableRow>)}
            {!users.length && !isLoadInProgress && !error && <TableRow>
              <TableCell colSpan={4} align="center">No users found</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {users.length > env.COLLAPSED_TABLE_ROWS && <Grid container justifyContent="center" alignItems="center">
          {numOfVisibleUsers === users.length
            ?
            <Button startIcon={<KeyboardArrowUpIcon />} sx={{ mt: 2 }} onClick={viewLess}>
              View less
            </Button>
            :
            <Button startIcon={<KeyboardArrowDownIcon />} sx={{ mt: 2 }} onClick={viewMore}>
              View more
            </Button>
          }
        </Grid>}
        {!!error && <Alert severity="error" sx={{ justifyContent: 'center' }}>Unable to load users</Alert>}
        {isLoadInProgress && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}><Spinner sx={{ width: 200 }} /></Box>}
      </CardContent>
    </EditableCard>
  </React.Fragment>
};

export default SectionUsers;