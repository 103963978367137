import React from 'react';
import { Box } from "@mui/material";
import Spinner from "./Spinner";

const DtLoader = () => {
  return <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <Box sx={{ p: '23px 30px', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
      <Spinner sx={{ width: 200 }} />
    </Box>
  </Box>
}

export default DtLoader;