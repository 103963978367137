import React, { useState } from "react";
import { Button } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { useFormik } from 'formik';
import { updateMasterSettings } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import { MasterSettings } from "@type/backend";
import UploadFile from "component/common/UploadFile";

type Props = {
  settings: MasterSettings;
  onFinish: Function;
};

const SectionTermsEdit = ({ onFinish, settings }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues = {
    termsAndConditions: null
  };
  formInitialValues = {
    ...formInitialValues,
    termsAndConditions: settings.clientTermsAndConditions
  }

  /**
  * Form configuration
  */
  const { setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: values => {
      saveSettings(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveSettings = (values: any) => {
    setIsSubmitInProgress(true)
    let formData = new FormData();
    if (values.termsAndConditions instanceof File) {
      formData.append("clientTermsAndConditions", values.termsAndConditions);
    }
    updateMasterSettings(formData)
      .then(_response => {
        showSuccess(t("settingsSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        showError(t("unableToSaveSettings"));
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar title={t("clientTermsAndConditions")} sx={{ mb: 4 }} >
        <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("save")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12}>
          <UploadFile pdf={settings.clientTermsAndConditions} setFieldValue={setFieldValue} />
        </Grid>
      </GridContainer>
    </form>
  )
}

export default SectionTermsEdit;



