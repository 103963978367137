import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { Edit } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import Currency from "model/currency";
import { useTranslation } from "react-i18next";

type Props = {
  currency: Currency;
  onEdit: Function;
  isReloading: boolean;
}

const SectionInfoView = ({ currency, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("generalInfo")}>
      {isGranted(perms.edit_currency) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{currency.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("code")}</TableCell>
          <TableCell className="preview-value">{currency.code}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("symbol")}</TableCell>
          <TableCell className="preview-value">{currency.symbol}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("position")}</TableCell>
          <TableCell className="preview-value">{Currency.getPositionTypeName(currency.position!)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("spacing")}</TableCell>
          <TableCell className="preview-value">{currency.hasSpacing ? t("yes") : t("no")}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("decimals")}</TableCell>
          <TableCell className="preview-value">{currency.decimals}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionInfoView;