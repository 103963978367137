import GridContainer from "component/common/GridContainer";
import DuArticle from "model/duArticle";
import React, { useCallback, useEffect, useState, FocusEvent } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Autocomplete, TextField } from "@mui/material";
import { getUnusedDuArticles } from "helper/backend";
import { useTranslation } from "react-i18next";

const FormAddDuArticle = ({ formik }: any) => {
  const { values, errors, setStatus, handleChange, setFieldValue, setFieldError } = formik;

  const { t } = useTranslation();

  // list of articles that populate the select field
  const [articles, setOrganizations] = useState<DuArticle[]>([]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchArticles = useCallback(() => {
    getUnusedDuArticles(values.distributionUnitId)
      .then(response => {
        setOrganizations(response.articles);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, values.distributionUnitId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    // fetch the list of organizations
    fetchArticles();
  }, [fetchArticles]);

  /**
  * Converts the list of organizations to select options
  */
  const getArticleOptions = () => articles.map(article => ({ label: article.articleName, value: article.id, key: article.articleCode }));

  /**
  * Returns the selected organization as a select option
  */
  const getSelectedArticleOption = () => getArticleOptions().find(option => option.value === values.articleId) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <>
      <GridContainer spacing={2}>
        <Grid xs={12} md={6}>
          <Autocomplete
            onChange={(_e, selectedOption) => {
              setFieldValue('articleId', selectedOption?.value);
            }}
            onFocus={_e => {
              onSelectFieldFocused('articleId');
            }}
            value={getSelectedArticleOption()}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            renderOption={(props: any, option: any) => (
              <li {...props} key={option.key}>
                {option.label}
              </li>
            )}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={getArticleOptions()}
            renderInput={(params: any) => <TextField {...params} label={t("article")} error={!!errors.articleId} helperText={errors.articleId} inputProps={{ ...params.inputProps, autoComplete: 'new-article' }} />}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextField name="minStock" label={t("minimumStock")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.minStock} error={!!errors.minStock} helperText={errors.minStock} sx={{ width: '100%' }} />
        </Grid>
      </GridContainer>
    </>
  )
}

export default FormAddDuArticle;