import React from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(() => ({
  "& .MuiDataGrid-columnHeader": {
    borderTop: "1px solid #dee2e6",
    borderBottom: "2px solid #dee2e6"
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    display: "flex",
    justifyContent: "space-between"
  },
  '& .MuiDataGrid-virtualScroller': {
    '@media (min-width:900px)': {
      overflow: "hidden"
    }
  },
  fontSize: "15px"
}));

const StyledDt = (props: DataGridProps) => {
  return (
    <StyledDataGrid
      {...props}
    />
  )
}

export default StyledDt;