import {
  APPLY_HANDOVER_CORRECTIONS_DT_PARAMS,
  PATCH_HANDOVER_CORRECTIONS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyHandoverCorrectionsDtParams = (params: DtParams) => ({
  type: APPLY_HANDOVER_CORRECTIONS_DT_PARAMS,
  payload: { params },
});

export const patchHandoverCorrectionsDtParams = (params: DtParams) => ({
  type: PATCH_HANDOVER_CORRECTIONS_DT_PARAMS,
  payload: { params },
});