import React, { useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { logoutUser } from 'store/actions';
import { useAuth } from 'context/auth';
import { routes } from 'helper/route';
import { Divider, Link } from '@mui/material';
import { AccountBalanceWallet, AccountBox, Business, CardMembership, People } from '@mui/icons-material';
import { perms, useAccess } from 'context/access';
import { useTranslation } from 'react-i18next';

const UserMenu = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authUser } = useAuth();
  const { isGranted } = useAccess();

  const [userMenuAnchorElem, setUserMenuAnchorElem] = useState<(EventTarget & Element) | null>(null);

  const toggleUserMenu = (e: MouseEvent) => setUserMenuAnchorElem(prevElem => !!prevElem ? null : e.currentTarget);

  const logUserOut = () => {
    setUserMenuAnchorElem(null);
    dispatch(logoutUser());
  }

  return <Box>
    <Tooltip title={t("openUserMenu")}>
      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleUserMenu}>
        <IconButton color='white' edge="end" sx={{ mr: 0.2 }}>
          <AccountBox fontSize='large' />
        </IconButton>
        <Typography sx={{ display: { xs: 'none', lg: 'block' } }}>{authUser.fullName}</Typography>
        <KeyboardArrowDownIcon />
      </Box>
    </Tooltip >
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={userMenuAnchorElem}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(userMenuAnchorElem)}
      onClose={toggleUserMenu}
    >
      <MenuItem component={Link} href={routes.my_account} onClick={toggleUserMenu} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }}>
        <AccountCircleIcon sx={{ mr: 1 }} />
        <Typography textAlign="center">{t("myAccount")}</Typography>
      </MenuItem>
      {isGranted(perms.view_master) &&
        <MenuItem component={Link} href={routes.my_billing} onClick={toggleUserMenu} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }}>
          <AccountBalanceWallet sx={{ mr: 1 }} />
          <Typography textAlign="center">{t("myBillingInfo")}</Typography>
        </MenuItem>
      }
      {isGranted(perms.view_own_organization) &&
        <MenuItem component={Link} href={routes.my_organization} onClick={toggleUserMenu} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }}>
          <Business sx={{ mr: 1 }} />
          <Typography textAlign="center">{t("myOrganization")}</Typography>
        </MenuItem>
      }
      {isGranted(perms.view_own_client) &&
        <MenuItem component={Link} href={routes.my_client} onClick={toggleUserMenu} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }}>
          <People sx={{ mr: 1 }} />
          <Typography textAlign="center">{t("myClient")}</Typography>
        </MenuItem>
      }
      {isGranted(perms.view_master) &&
        <MenuItem component={Link} href={routes.master_settings} onClick={toggleUserMenu} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }} >
          <SettingsIcon sx={{ mr: 1 }} />
          <Typography textAlign="center">{t("settings")}</Typography>
        </MenuItem>
      }
      {isGranted(perms.view_my_payment_plans) &&
        <MenuItem component={Link} href={routes.payment_plans} onClick={toggleUserMenu} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }} >
          <CardMembership sx={{ mr: 1 }} />
          <Typography textAlign="center">{t("billing")}</Typography>
        </MenuItem>
      }
      <Divider />
      <MenuItem onClick={logUserOut} sx={{ color: "#6c757d", "&:hover": { color: "#272e37" } }} >
        <PowerSettingsNewIcon sx={{ mr: 1 }} />
        <Typography textAlign="center">{t("signOut")}</Typography>
      </MenuItem>
    </Menu>
  </Box>
}

export default UserMenu;