import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";
import { getArticleUsage } from "helper/backend";
import { showError } from "helper/util";
import Preloader from "component/common/Preloader";
import { StopCircle } from "@mui/icons-material";

const colors = ["#02A8B5", "#FA5C7C", "#33AD93", "#fbcc5c"];

const ArticleUsage = () => {

  const { t } = useTranslation();

  const [articleUsage, setArticleUsage] = useState<any>();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchArticleUsage = useCallback(() => {
    setIsLoadInProgress(true)
    getArticleUsage()
      .then(response => {
        let allArticles = [...response.articles];
        let models = allArticles.splice(0, 3).map((el) => { return [el.articleName, (+el.items / response.total) * 100] }).sort((a, b) => { return +b[1]! - +a[1]! })

        if (response.articles.length > 3) {
          let others = response.articles.splice(3, response.articles.length - 1)
          models.push(["Others", (others.map((el) => +el.items).reduce((partialSum, a) => partialSum + a, 0) / response.total) * 100])
        }
        models.unshift(["Articles", "Number of articles"])

        setArticleUsage(models);
      })
      .catch(_ex => {
        showError(t("unableToLoadArticleUsage"));
        setIsLoadInProgress(false);
      })
      .finally(() => {
        setIsLoadInProgress(false)
      })
  }, [t]);

  useEffect(() => {
    fetchArticleUsage();
  }, [fetchArticleUsage])

  return (
    <EditableCard sx={{ height: "360px", mt: 3 }}>
      <CardContent>
        <CardTitleBar title={t("articleUsage")} />
        <Box sx={{ display: "flex", height: "270px", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          {
            articleUsage?.length > 1 ?
              <>
                <Chart
                  chartType="PieChart"
                  data={articleUsage}
                  width={"70%"}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-10px" }}
                  height={"170px"}
                  onLoad={() => { setIsLoadInProgress(false) }}
                  options={
                    {
                      chartArea: {
                        height: '90%',
                        width: '90%',
                      },
                      colors: colors,
                      legend: "none",
                      pieHole: 0.7,
                      pieSliceText: "none",
                      tooltip: {
                        trigger: 'none'
                      }
                    }
                  }
                />
                <Box sx={{ fontSize: "16px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", width: "100%", gap: "10px", zIndex: 1000, padding: "0px 35px 0px 30px" }}>
                  {
                    articleUsage?.filter((el: any) => el[0] !== "Articles").map((el: any, idx: number) => {
                      return (
                        <Box key={idx} sx={{ display: "flex", justifyContent: "space-between", gap: "40px", width: "100%" }}>
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                            <StopCircle sx={{ color: colors[idx], fontSize: "16px" }} /> {el[0]}
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            {Math.round(+el[1]!)}%
                          </Box>
                        </Box>
                      )
                    })
                  }
                </Box>
              </>
              :
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                {t("noData")}
              </Box>
          }
        </Box>
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default ArticleUsage;