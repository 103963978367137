import React from "react";
import { useAuth } from "context/auth";
import ListMaster from "./Partial/List/ListMaster";
import ListOrg from "./Partial/List/ListOrg";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";

const List = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { authUser } = useAuth();

  return (
    <React.Fragment>
      {isGranted(perms.view_distribution_units) &&
        <>
          {
            authUser.isMasterAdmin() &&
            <ListMaster />
          }
          {
            authUser.isOrganizationAdmin() &&
            <ListOrg />
          }
        </>
      }
      {isNotGranted(perms.view_distribution_units) && <AccessDenied />}
    </React.Fragment >
  )
}

export default List;