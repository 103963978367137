import React, { useState, FocusEvent, useCallback } from "react";
import { Autocomplete, Box, Card, CardContent, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import logo from 'asset/image/logo.png';
import background from 'asset/image/bgPattern.png';
import * as Yup from "yup"
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import { getTimezoneOptions } from "helper/date";
import { selfInviteClient } from "helper/backend";
import { route, routes } from "helper/route";
import { isErrType, showError, toSimpleError } from "helper/util";
import { ValidationException } from "helper/error";
import { Check } from "@mui/icons-material";
import ProgressButton from "component/common/ProgressButton";

const SelfInviteClient = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationUuid } = useParams();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    timezone: undefined,
  };

  /**
  * Form validation rules
  */
  const validationSchema = {
    firstName: Yup.string().trim().required(t("fieldIsRequired")),
    lastName: Yup.string().trim().required(t("fieldIsRequired")),
    email: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    timezone: Yup.string().trim().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      inviteClient(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const inviteClient = (values: any) => {
    setIsSubmitInProgress(true);
    selfInviteClient(values, organizationUuid!)
      .then(response => {
        console.log(response)
        return navigate(route(routes.register_client, response.token));
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToProcessOnboardingRequest"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
   * Returns the selected timezone as a select option
   */
  const getSelectedTimezoneOption = () => getTimezoneOptions().find(option => option.value === values.timezone) || null;

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: { xs: "100%", md: "auto" } }}>
        <Card sx={{ height: { xs: "100%", md: "auto" }, width: { xs: "100%", md: "700px" } }}>
          <CardContent sx={{ p: 4, overflow: "auto", height: "100%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: 'center', mb: 3, height: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
              <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
              </Box>
            </Box>
            <Box>
              <Box sx={{ width: "100%", textAlign: "center", mb: 2 }}>
                Before we get started, please provide some information about yourself.
              </Box>
              <form noValidate onSubmit={handleSubmit}>
                <GridContainer spacing={2}>
                  <Grid xs={12} md={6}>
                    <TextField name="firstName" label={t("firstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.firstName} error={!!errors.firstName} helperText={errors.firstName} sx={{ width: '100%' }} />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField name="lastName" label={t("lastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.lastName} error={!!errors.lastName} helperText={errors.lastName} sx={{ width: '100%' }} />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField type="email" name="email" label={t("email")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Autocomplete
                      onChange={(_e, selectedOption) => {
                        setFieldValue('timezone', selectedOption?.value);
                      }}
                      onFocus={_e => {
                        onSelectFieldFocused('timezone');
                      }}
                      value={getSelectedTimezoneOption()}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={getTimezoneOptions()}
                      renderInput={(params: any) => <TextField {...params} label={t("timezone")} error={!!errors.timezone} helperText={errors.timezone} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
                    />
                  </Grid>
                  <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <ProgressButton type="submit" name="submitBillingInfo" variant="contained" color="primary" sx={{ ml: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("next")}</ProgressButton>
                  </Grid>
                </GridContainer>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box >
  )
};

export default SelfInviteClient;