import {
  APPLY_LOCATIONS_DT_PARAMS,
  PATCH_LOCATIONS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyLocationDtParams = (params: DtParams) => ({
  type: APPLY_LOCATIONS_DT_PARAMS,
  payload: { params },
});

export const patchLocationDtParams = (params: DtParams) => ({
  type: PATCH_LOCATIONS_DT_PARAMS,
  payload: { params },
});