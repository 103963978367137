import React from 'react';
import Box from '@mui/material/Box';
import { routes } from 'helper/route';
import { Card, CardContent } from '@mui/material';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';
import FormCreate from './Partial/Form/Create';

const New = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    {isGranted(perms.create_exchange_rate) && <Box>
      <PageTitleBar breadcrumbs={breadcrumbs(t)} />
      <GridContainer>
        <Grid xs={12} md={5}>
          <Card>
            <CardContent>
              <FormCreate />
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </Box>}
    {isNotGranted(perms.create_exchange_rate) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (t: Function) => [{
  text: t("exchangeRates"),
  url: routes.list_exchange_rates,
}, {
  text: t("newExchangeRate"),
}];

export default New;
