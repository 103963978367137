import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { confirmExpedition, getOperation, getOperationPDF } from "helper/backend";
import { buildOperationNumber, showError, toSimpleError } from "helper/util";
import { useParams } from "react-router-dom";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import SectionItems from "./Partial/SectionItems";
import Operation from "model/operation";
import { formatTimestamp, formats } from "helper/date";
import SectionInfo from "./Partial/SectionInfo";
import ProgressButton from "component/common/ProgressButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from "react-i18next";
import SectionExpeditionDetails from "./Partial/SectionExpedition";
import SectionSupplyDetails from "./Partial/SectionSupply";
import SectionInfoHandover from "./Partial/SectionInfoHandover";
import QrCodeDetails from "./Partial/SectionQr";
import { useAuth } from "context/auth";
import SectionCollectionDetails from "./Partial/SectionCollection";
import SectionReceptionDetails from "./Partial/SectionReception";
import ExpeditionConfirmation from "component/common/ExpeditionConfirmation";

const operationBreadcrumb = (operation: Operation) => {
  switch (operation.type) {
    case Operation.TYPE_PICKUP:
      return 'pickups';
    case Operation.TYPE_HANDOVER:
      return 'handovers';
    case Operation.TYPE_EXPEDITION:
      return 'dispatches'
    case Operation.TYPE_COLLECTION:
      return 'collections'
    case Operation.TYPE_SUPPLY:
      return 'supplies'
    case Operation.TYPE_RECEPTION:
      return 'reception'
    default:
      return ''
  }
}

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the operation record fetched from the backend
  const [operation, setOperation] = useState<Operation | undefined>()
  // error encoutered while fetching the operation (if any)
  const [operationError, setOperationerror] = useState<AppError | undefined>();
  // whether the loading of the operation is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the loading of the download is in progress
  const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false)
  // whether the confirmation is visible
  const [isConfOpen, setIsConfOpen] = useState(false);
  // whether the loading of the dispatch confirmation is in progress
  const [isConfirmInProgress, setIsConfirmInProgress] = useState<boolean>(false)

  /**
  * Download the operation record from the backend
  */
  const downloadReport = useCallback(() => {
    setIsDownloadInProgress(true)
    getOperationPDF(recordId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Operation.getTypeName(operation?.type!)}Note_${formatTimestamp(operation?.createdTs!, formats.DATE_SPLIT)}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
      })
      .catch(_ex => {
        showError(t("unableToDownloadReport"));
      })
      .finally(() => {
        setIsDownloadInProgress(false);
      })
  }, [recordId, operation, t])

  /**
  * Fetches the operation record from the backend
  */
  const refreshOperation = useCallback(() => {
    setIsLoadInProgress(true)
    getOperation(recordId)
      .then(response => {
        setOperation(response.operation);
      })
      .catch(ex => {
        setOperationerror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  /**
  * Opens the confirm modal
  */
  const confirmDispatch = () => {
    setIsConfOpen(true);
  }

  /**
  * Confirms the dispatch in the backend
  */
  const handleConfirmDispatch = (values: any) => {
    setIsConfirmInProgress(true)
    confirmExpedition(values)
      .then(_response => {
        refreshOperation()
        setIsConfOpen(false)
      })
      .catch(_ex => {
        showError(t("unableToConfirmDispatch"));
      })
      .finally(() => {
        setIsConfirmInProgress(false)
      })
  }

  // This hook runs once on component mount
  useEffect(() => {
    refreshOperation();
  }, [refreshOperation]);

  const getPickupHandoverLink = (operation: Operation) => {
    switch (operation.type) {
      case Operation.TYPE_PICKUP:
        return '/pickups';
      case Operation.TYPE_HANDOVER:
        return '/handovers';
      case Operation.TYPE_EXPEDITION:
        return '/dispatches'
      case Operation.TYPE_COLLECTION:
        return '/collection'
      case Operation.TYPE_SUPPLY:
        return '/supplies'
      case Operation.TYPE_RECEPTION:
        return '/reception'
      default:
        return '/'
    }
  };

  return (
    <>
      {isGranted(perms.view_operation)
        &&
        <>
          {
            !!operation
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(operation, getPickupHandoverLink(operation), t)}>
                <ProgressButton onClick={downloadReport} variant="contained" color="dark" sx={{ mr: 1 }} isBusy={isDownloadInProgress} startIcon={<FileDownloadIcon />}>{t("downloadReport")}</ProgressButton>
                {
                  authUser.isDriverType() && !operation.confirmedTs && !operation.packageCount && operation.type === Operation.TYPE_EXPEDITION &&
                  <Button onClick={confirmDispatch} variant="contained" color="primary" sx={{ mr: 1 }} startIcon={<Check />}>{t("confirm")}</Button>
                }
                <Button variant="contained" color="secondary" component={Link} href={getPickupHandoverLink(operation)} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              {operation.type === Operation.TYPE_EXPEDITION &&
                <GridContainer>
                  <Grid xs={12} md={6}>
                    <SectionExpeditionDetails operation={operation} />
                  </Grid>
                  {
                    authUser.isOrganizationType() &&
                    <Grid xs={12} md={6}>
                      <QrCodeDetails operation={operation} />
                    </Grid>
                  }
                </GridContainer>
              }
              {operation.type === Operation.TYPE_SUPPLY &&
                <GridContainer>
                  <Grid xs={12} md={6}>
                    <SectionSupplyDetails operation={operation} />
                  </Grid>
                  {
                    authUser.isOrganizationType() &&
                    <Grid xs={12} md={6}>
                      <QrCodeDetails operation={operation} />
                    </Grid>
                  }
                </GridContainer>
              }
              {operation.type === Operation.TYPE_COLLECTION &&
                <GridContainer>
                  <Grid xs={12} md={6}>
                    <SectionCollectionDetails operation={operation} />
                  </Grid>
                  {
                    authUser.isOrganizationType() &&
                    <Grid xs={12} md={6}>
                      <QrCodeDetails operation={operation} />
                    </Grid>
                  }
                </GridContainer>
              }
              <GridContainer>
                {operation.type === Operation.TYPE_PICKUP &&
                  <Grid xs={12} md={6}>
                    <SectionInfo operation={operation} />
                  </Grid>
                }
                {operation.type === Operation.TYPE_HANDOVER &&
                  <Grid xs={12} md={6}>
                    <SectionInfoHandover operation={operation} />
                  </Grid>
                }
                {
                  operation.type === Operation.TYPE_RECEPTION &&
                  <>
                    <Grid xs={12} md={6}>
                      <SectionReceptionDetails operation={operation} />
                    </Grid>
                    {
                      authUser.isOrganizationType() &&
                      <Grid xs={12} md={6}>
                        <QrCodeDetails operation={operation} />
                      </Grid>
                    }
                  </>
                }
                <Grid xs={12}>
                  <SectionItems operationId={recordId} operationType={operation.type} />
                </Grid>
              </GridContainer>
              {/* Expedition Confirmation Modal */}
              <ExpeditionConfirmation operation={operation} isConfirmInProgress={isConfirmInProgress} setIsConfOpen={setIsConfOpen} isConfOpen={isConfOpen} handleConfirmDispatch={handleConfirmDispatch} />
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !operation && <Preloader container="content" />}
          {!!operationError && <Error error={operationError} title404={t("operationNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_operation) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (operation: Operation, link: string, t: Function) => [{
  text: t(operationBreadcrumb(operation)),
  url: link,
}, {
  text: buildOperationNumber(operation.id, operation.type, operation.createdTs),
}];

export default Single;