import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumb } from '@type';

type Props = {
  title?: string;
  items?: Breadcrumb[];
};

const Breadcrumbs = ({ title, items }: Props) => {
  if (!items) {
    items = [];
  }
  if (!!title) {
    items.push({ text: title });
  }
  return <MuiBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} sx={{
    fontSize: { xs: "14px", sm: "18px" },
  }}>
    {items.map((item, idx) => {
      if (idx === items!.length - 1 || !item.url) {
        return <Typography color="text.primary" variant="inherit" key={idx}>{item.text}</Typography>
      } else {
        return <Link underline="hover" color="inherit" href={item.url} key={idx}>{item.text}</Link>
      }
    })}
  </MuiBreadcrumbs>
}

export default Breadcrumbs;

