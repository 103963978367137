import React from "react";
import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Lock } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { useTranslation } from "react-i18next";
import Location from "model/location";

type Props = {
  location: Location;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ location, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={location.name!} iconTitle={location.type !== Location.TYPE_CUSTOM && <Tooltip title={t("autoGeneratedLocation")}><Lock sx={{ fontSize: "18px", ml: 0.5 }} /></Tooltip>}>
      {isGranted(perms.edit_location) && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{location.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("type")}</TableCell>
          <TableCell className="preview-value">{Location.getTypeName(location.type)}</TableCell>
        </TableRow>
        {location.type === Location.TYPE_DISTRIBUTION_UNIT &&
          <TableRow>
            <TableCell>{t("distributionUnitName")}</TableCell>
            <TableCell className="preview-value">{location.distributionUnitName}</TableCell>
          </TableRow>
        }
        {location.type === Location.TYPE_CLIENT &&
          <TableRow>
            <TableCell>{t("clientName")}</TableCell>
            <TableCell className="preview-value">{location.clientName}</TableCell>
          </TableRow>
        }
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >

};

export default SectionInfoView;