import Currency from "model/currency";

export const formatCurrency = (value: string | number, symbol: string, pos: number, spacing: boolean) => {
  let formattedCurrency = "";

  if (!symbol) {
    return value;
  }

  if (pos === Currency.POSITION_BEFORE) {
    if (spacing) {
      formattedCurrency = `${symbol} ${value}`
    } else {
      formattedCurrency = `${symbol}${value}`
    }
  } else if (pos === Currency.POSITION_AFTER) {
    if (spacing) {
      formattedCurrency = `${value} ${symbol}`
    } else {
      formattedCurrency = `${value}${symbol}`
    }
  }

  return formattedCurrency;
}
