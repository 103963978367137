import {
  APPLY_TAG_PARAMS,
  PATCH_TAG_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyTagParams = (params: DtParams) => ({
  type: APPLY_TAG_PARAMS,
  payload: { params },
});

export const patchTagParams = (params: DtParams) => ({
  type: PATCH_TAG_PARAMS,
  payload: { params },
});