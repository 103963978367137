import { takeEvery, put, call } from 'redux-saga/effects';

import {
  GET_AUTH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_AUTH_USER,
} from './actionTypes';

import {
  getAuthUserOk,
  getAuthUserErr,
  loginUserOk,
  loginUserErr,
  logoutUserOk,
  logoutUserErr,
  refreshAuthUserOk,
  refreshAuthUserErr,
} from './actions';

import {
  getAuthUser,
  loginUser,
  logoutUser,
} from 'helper/backend';

import { PayloadAction } from '@reduxjs/toolkit';
import { AuthResponse, GetAuthUserResponse, LogoutResponse } from '@type/backend';
import { AuthLoginActionPayload } from './types';

/********** LOGIN **********/

function* onGetAuthUser() {
  try {
    const response: GetAuthUserResponse = yield call(getAuthUser);
    yield put(getAuthUserOk(response));
  } catch (error) {
    yield put(getAuthUserErr(error as Error));
  }
}

function* onRefreshAuthUser() {
  try {
    const response: GetAuthUserResponse = yield call(getAuthUser);
    yield put(refreshAuthUserOk(response));
  } catch (error) {
    yield put(refreshAuthUserErr(error as Error));
  }
}

function* onLoginUser({ payload: { data } }: PayloadAction<AuthLoginActionPayload>) {
  try {
    const response: AuthResponse = yield call(loginUser, data);
    yield put(loginUserOk(response));
  } catch (error) {
    yield put(loginUserErr(error as Error));
  }
}

function* onLogoutUser() {
  try {
    const response: LogoutResponse = yield call(logoutUser);
    yield put(logoutUserOk(response));
  } catch (error) {
    yield put(logoutUserErr(error as Error));
  }
}

function* authSaga() {
  yield takeEvery(GET_AUTH_USER, onGetAuthUser);
  yield takeEvery(REFRESH_AUTH_USER, onRefreshAuthUser);
  yield takeEvery(LOGIN_USER, onLoginUser);
  yield takeEvery(LOGOUT_USER, onLogoutUser);
}

export default authSaga;
