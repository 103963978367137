import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import Preloader from "component/common/Preloader";
import FormCreateOrganizationUser from '../Partial/Form/CreateOrgUser';
import { useParams } from 'react-router-dom';
import { routes, route } from 'helper/route';
import { getOrganization } from 'helper/backend';
import { toSimpleError } from 'helper/util';
import { AppError } from '@type';
import AccessDenied from 'page/Error/AccessDenied';
import Error from 'page/Error';
import Organization from 'model/organization';
import { useTranslation } from 'react-i18next';

const NewOrgUser = () => {

  const { allAreGranted, notAllAreGranted } = useAccess();
  const { t } = useTranslation();
  const { id } = useParams();
  const orgId = parseInt(id!)

  // the organization record fetched from the backend
  const [organization, setOrganization] = useState<Organization>()
  // error encoutered while fetching the organization (if any)
  const [organizationError, setOrganizationError] = useState<AppError>();
  // whether the loading of the organization is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);

  /**
  * Fetches from the backend the organization this user is allowed to see
  */
  const fetchOrganization = useCallback(() => {
    setIsLoadInProgress(true)
    getOrganization(orgId)
      .then(response => {
        setOrganization(response.organization);
      })
      .catch(ex => {
        setOrganizationError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [orgId]);

  // This hook runs on first component mount to save the organization in the state
  useEffect(() => {
    fetchOrganization()
  }, [fetchOrganization]);

  return <React.Fragment>
    {allAreGranted([perms.create_users, perms.edit_organizations]) && <>
      {!!organization && <Box>
        <PageTitleBar breadcrumbs={breadcrumbs(organization, t)} />
        <Grid container>
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <FormCreateOrganizationUser orgId={orgId} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>}
      {isLoadInProgress && !organization && <Preloader container="parent" />}
      {!!organizationError && <Error error={organizationError} title404={t("organizationNotFound")} />}
    </>}
    {notAllAreGranted([perms.create_users, perms.create_organizations]) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (organization: Organization | undefined, t: Function) => [
  {
    text: t("organization"),
    url: routes.list_organizations,
  },
  {
    text: organization?.companyName || "",
    url: route(routes.view_organization, organization?.id),
  },
  {
    text: t("newUser"),
  }
];

export default NewOrgUser;
