import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import { routes } from 'helper/route';
import logo from 'asset/image/logo.png';
import Divider from '@mui/material/Divider';
import VerticalMenu from 'component/common/VerticalMenu';
import UserMenu from 'component/common/UserMenu';
import QuickMenu from 'component/common/QuickMenu';
import { Box, styled } from '@mui/material';
import { useAuth } from 'context/auth';

const drawerWidth = 300;

type Props = {
  sx: any;
};

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  zIndex: 20,
  padding: "18px 0px",
  height: "100px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const TopBarMobile = ({ sx }: Props) => {

  const { authUser } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [logoImg, setLogoImg] = useState("")

  const setLogo = () => {
    if (authUser.hasCustomLogo) {
      setLogoImg(`${process.env.REACT_APP_API_URL}/static/logo/${authUser.organizationId}/logo.png`)
    } else {
      setLogoImg(logo)
    }
  }

  useEffect(() => {
    setLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleMenu = () => setIsMenuOpen(prevState => !prevState);

  return <React.Fragment>
    <AppBar position="static" sx={sx}>
      <Toolbar sx={{ flexGrow: 1 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleMenu}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <QuickMenu />
        <UserMenu />
      </Toolbar>
    </AppBar>
    <Drawer
      variant="temporary"
      open={isMenuOpen}
      onClose={toggleMenu}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, p: 2 },
      }}
    >
      <Box sx={{ height: "90px", width: "100%", marginBottom: "16px", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain" }}>
        <Link href={routes.home} sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
          <img src={logoImg} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Link>
      </Box>
      <Divider />
      <Box sx={{ zIndex: 21, overflow: "auto" }}>
        <VerticalMenu isCollapsed={false} />
      </Box>
      {isMenuOpen && !!authUser.hasCustomLogo &&
        <DrawerFooter sx={{ padding: "0px", height: "90px" }}>
          <Box sx={{ height: "50px", width: "90%", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", position: "absolute", bottom: "18px" }}>
            <Link href={routes.home} sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
              <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
            </Link>
          </Box>
        </DrawerFooter>
      }
    </Drawer>
  </React.Fragment >
}

export default TopBarMobile;