import React, { useCallback, useEffect, useState } from 'react';
import { Box, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { getPaymentPlanQuota } from 'helper/backend';
import { showError } from 'helper/util';
import { GetQuotaResponse } from '@type/backend';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  marginTop: "4px",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#757575',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#33AD93' : '#33AD93',
  },
}));

const QuotaIndicator = () => {

  const [quota, setQuota] = useState<GetQuotaResponse | undefined>();

  /**
  * Fetches the client record from the backend
  */
  const refreshQuota = useCallback(() => {
    getPaymentPlanQuota()
      .then(response => {
        setQuota(response);
      })
      .catch(_ex => {
        showError("Unable to load tag quota");
      })
  }, [])

  useEffect(() => {
    refreshQuota();
  }, [refreshQuota])

  return <React.Fragment>
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "200px" }}>
      {
        !!quota &&
        <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
          <Box>
            {Math.floor((quota?.currentTags! * 100) / quota?.quota!)}% of tag quota used
          </Box>
          <BorderLinearProgress
            variant="determinate"
            value={2}  //{(quota?.currentTags! * 100) / quota?.quota!}
          />
        </Box>
      }
    </Box>
  </React.Fragment>
}

export default QuotaIndicator;
