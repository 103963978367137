import React, { useCallback, useEffect, useState } from "react";
import EditableCard from "component/styled/EditableCard";
import { CardContent } from "@mui/material";
import SectionCardView from "./View";
import { updatePayment } from "helper/backend";
import { useStripe } from "@stripe/react-stripe-js";
import { showError } from "helper/util";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";
import withStripeElements from "hoc/stripeElements";
import Organization from "model/organization";
import SectionCardEdit from "./Edit";

type Props = {
  organization: Organization;
  refreshHandler: Function;
  isLoadInProgress: boolean;
};

const SectionCard = ({ organization, refreshHandler, isLoadInProgress }: Props) => {

  const stripe = useStripe();
  const { t } = useTranslation();
  const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');

  // whenever stripe is in progress
  const [isStripeInProgress, setIsStripeInProgress] = useState(false);
  // whether the edit mode is active for this section
  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);
  // whether the edit form has just been saved and the user record is being reloaded from the backend
  const [isReloading, setIsReloading] = useState(false);
  // used to save the setupIntent from stripe
  const [setupIntent, setSetupIntent] = useState<any>();

  // This hook runs every time the loading status changes
  useEffect(() => {
    if (!isLoadInProgress) {
      // the loading has finished so change the flag
      setIsReloading(false);
    }
  }, [isLoadInProgress]);

  /**
  * Event handler called whenever the user shows or hides the edit form
  * a) clicks on the edit button to edit the section
  * b) clicks on the cancel button to close the form
  * c) clicks on the save button to apply the changes
  */
  const switchEditMode = () => {
    setIsEditModeActive(mode => !mode);
  };

  /**
  * Event handler called at first render to create a payment intent
  */
  const updatePaymentMethod = useCallback(() => {
    setIsStripeInProgress(true)
    updatePayment({ paymentMethodId: setupIntent.payment_method })
      .then((_response: any) => {
        refreshHandler()
      })
      .catch(_ex => {
        showError(t("unableToSavePaymentMethod"));
      })
      .finally(() => {
        setIsStripeInProgress(false)
      });
  }, [setupIntent, t, refreshHandler]);

  useEffect(() => {
    if (!!clientSecret) {
      if (!stripe) {
        return;
      }
      if (!clientSecret) {
        showError(t("stripeBadRequest"));
        return;
      }
      setIsStripeInProgress(true);
      stripe
        .retrieveSetupIntent(clientSecret)
        .then(({ setupIntent }) => {
          setIsStripeInProgress(false);
          switch (setupIntent?.status) {
            case 'succeeded':
              setSetupIntent(setupIntent);
              break;
            case 'processing':
              showError(t("stripeUnableToConfirmResult"));
              break;
            case 'requires_payment_method':
              showError(t("stripeUnableToSavePayment"));
              break;
          }
        });
    } else {
      return;
    }
  }, [stripe, t, clientSecret]);

  useEffect(() => {
    if (!setupIntent) {
      return;
    }
    updatePaymentMethod()
  }, [setupIntent, updatePaymentMethod]);


  return <EditableCard sx={{ height: '100%' }}>
    <CardContent>
      {!isEditModeActive && <SectionCardView organization={organization} onEdit={switchEditMode} isReloading={isReloading} />}
      {isEditModeActive && <SectionCardEdit onFinish={switchEditMode} />}
    </CardContent>
    {/* Show the prealoder only on the first fetch */}
    {isStripeInProgress && <Preloader container="parent" />}
  </EditableCard>
}

export default withStripeElements(SectionCard);