import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import CardTitleBar from 'component/common/CardTitleBar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, FormControlLabel, Link, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { isErrType, showError, showSuccess, toSimpleError } from 'helper/util';
import { createExchangeRate, getCurrenciesList } from 'helper/backend';
import { ValidationException } from 'helper/error';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ProgressButton from 'component/common/ProgressButton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'helper/route';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';
import Currency from 'model/currency';
import env from "env";
import Preloader from 'component/common/Preloader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';

type Values = {
  dateTs: number,
  pullExchangeRates: boolean,
  currencies: any[]
};

const FormCreate = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  // list of currencies
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  // whether fetching currencies is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    dateTs: 0,
    pullExchangeRates: false,
    currencies: []
  };

  /**
   * Form validation rules
   */
  let validationSchema: any = {
    dateTs: Yup.number().required(t("fieldIsRequired")),
    currencies: Yup.array().required(t("fieldIsRequired"))
  };

  /**
   * Form configuration
   */
  const { values, errors, setStatus, setFieldError, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveExchangeRate(values);
    },
  });

  /**
* Fetches from the backend the list of currencies this user is allowed to see
*/
  const fetchCurrencies = useCallback(() => {
    setIsLoadInProgress(true);
    getCurrenciesList()
      .then(response => {
        setCurrencies(response.currencies);
      })
      .catch(_ex => {
        showError(t("unableToLoadCurrencies"))
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies])

  /**
   * Event handler called whenever the user saves the form
   */
  const saveExchangeRate = (values: any) => {
    setIsSubmitInProgress(true);
    createExchangeRate(values)
      .then(_response => {
        showSuccess(t("exchangeRateHasBeenSaved"));
        return navigate(routes.list_exchange_rates);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveExchangeRate"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
  * Event handler called whenever the currencies changes
  */
  const handleCurrencyChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currency: Currency) => {
    // copy of the currencies array
    let currenciesArr = [...values.currencies];
    // whether the currency is present in the array
    let currencyIsFound = currenciesArr.find(el => el.id === currency.id)

    if (!!currencyIsFound) {
      currenciesArr[currenciesArr.indexOf(currencyIsFound)] = {
        id: currency.id,
        value: e.target.value
      }
    } else {
      currenciesArr.push({
        id: currency.id,
        value: e.target.value
      })
    }

    setFieldValue('currencies', currenciesArr)
  }

  const handleDateChange = (e: any) => {
    const date = e.utcOffset(3);
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    setFieldValue("dateTs", date.unix())
  }

  // sets the dateTs to current date
  useEffect(() => {
    const date = moment().utcOffset(3);
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    setFieldValue("dateTs", date.unix())
  }, [setFieldValue])

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <>
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveExchangeRate")}</ProgressButton>
        <Button variant="contained" color="secondary" component={Link} href={routes.list_exchange_rates} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid xs={12}>
            <DatePicker
              defaultValue={moment()}
              onChange={(e) => handleDateChange(e)}
              disableFuture
              sx={{ width: "100%" }}
              slotProps={
                {
                  textField: {
                    name: 'dateTs',
                    variant: "outlined",
                    error: !!errors.dateTs,
                    helperText: errors.dateTs,
                    onFocus: onTextFieldFocused
                  }
                }
              } />
          </Grid>
        </LocalizationProvider>
        {
          currencies.filter(el => el.code === env.BASE_CURRENCY_CODE).map((currency, idx) => {
            return (
              <Grid xs={12} key={idx}>
                <TextField name="currency" label={t("baseCurrency")} variant="outlined" onFocus={onTextFieldFocused} value={`${currency.name} (${currency.code})`} sx={{ width: '100%' }} disabled />
              </Grid>
            )
          })
        }
        <Grid xs={12}>
          <FormControlLabel control={<Switch name="pullExchangeRates" onChange={(e) => { setFieldValue('pullExchangeRates', e.target.checked ? true : false) }} checked={values.pullExchangeRates} value={values.pullExchangeRates} />} label={t("pullExchangeRates")} />
        </Grid>
        {
          !values.pullExchangeRates &&
          currencies.filter(el => el.code !== env.BASE_CURRENCY_CODE).map((currency, idx) => {
            return (
              <Grid xs={12} key={idx}>
                <TextField type="number" name="currency" label={`${currency.name} (${currency.code})`} variant="outlined" onChange={(e) => handleCurrencyChange(e, currency)} onFocus={onTextFieldFocused} value={values.currencies.find(el => el.id === currency.id)?.value || ""} sx={{ width: '100%' }} />
              </Grid>
            )
          })
        }
      </GridContainer>
    </form>
    {isLoadInProgress && <Preloader container="parent" />}
    {/* Show the prealoder only on the first fetch */}
  </>
}

export default FormCreate;
