import React, { useCallback, useEffect } from "react";
import L, { LatLng } from "leaflet"
import { useMap, Marker, Popup } from 'react-leaflet';
import DistributionUnit from "model/distributionUnit";
import { PinIcon, WarningPinIcon } from "./PinIcon";
import { Box, Link } from "@mui/material";
import { route, routes } from "helper/route";

type Props = {
  allPins: DistributionUnit[]
}

const MapAllPins = ({ allPins }: Props) => {

  const map = useMap();

  const fitLocations = useCallback(() => {

    let latCoords: number[] = [];
    let lonCoords: number[] = [];

    if (!!allPins) {
      allPins?.forEach((pin) => {
        if (pin.latitude !== null && pin.longitude !== null) {
          //Save all latitude coords
          latCoords.push(pin.latitude)
          lonCoords.push(pin.longitude)
        }
      })
      const boundMax = new LatLng(Math.max(...latCoords), Math.max(...lonCoords))
      const boundMin = new LatLng(Math.min(...latCoords), Math.min(...lonCoords))
      //Bound and fit map
      map.fitBounds(L.latLngBounds([boundMax, boundMin]), { padding: [50, 50] })
    }
  }, [map, allPins])

  useEffect(() => {
    fitLocations()
  }, [fitLocations, allPins])

  return (
    <>
      {allPins.filter(pin => pin.latitude !== null).map((pin, idx) => {
        return (
          <Marker
            key={idx}
            position={[pin.latitude, pin.longitude]}
            icon={!!pin.isReaderActive && !!pin.isSecurityActive ? PinIcon : WarningPinIcon}
          >
            <Popup closeButton={false} offset={[4, 4]}>
              <Box component={Link} href={route(routes.view_distirbution_unit, pin.id)} sx={{ display: "flex", alignItem: "center", justifyContent: "center" }}>{pin.name}</Box>
            </Popup>
          </Marker>
        )
      })}
    </>
  );
}

export default MapAllPins;