import React, { MouseEvent, useState } from 'react';
import { Badge, IconButton, Menu, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

const QuickNotifications = () => {

  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement | null>(null);

  const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElem(prevElem => !!prevElem ? null : e.currentTarget);
  }

  return <React.Fragment>
    <IconButton size="large" color="inherit" onClick={toggleMenu}>
      <Badge color="error" max={9}>
        <NotificationsIcon />
      </Badge>
    </IconButton>
    <Menu
      sx={{ mt: '36px' }}
      anchorEl={menuAnchorElem}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!menuAnchorElem}
      onClose={toggleMenu}
    >
      <Typography sx={{ px: 2, py: 1 }}>There are no notifications</Typography>
    </Menu>
  </React.Fragment>
}

export default QuickNotifications;
