import { Box, TableCell } from "@mui/material";
import React from "react";

const IconTableCell = ({ children }: any) => (
  <TableCell>
    <Box sx={{ marginTop: "-10px", marginBottom: "-15px" }}>
      {children}
    </Box>
  </TableCell>
)

export default IconTableCell;