import React, { FocusEvent, useCallback, useEffect, useState } from "react";
import { Autocomplete, Card, CardContent, FormControlLabel, InputAdornment, Switch, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import { getCountryOptions } from "helper/country";
import GridContainer from "component/common/GridContainer";
import Currency from "model/currency";
import { getCurrenciesList } from "helper/backend";
import { useTranslation } from "react-i18next";
import MuiAutocomplete from "component/common/MuiAutocomplete";

const FormCreate = ({ formik }: any) => {

  const { t } = useTranslation();

  const { values, errors, setStatus, setFieldValue, handleChange, setFieldError } = formik;

  // list of currencies that populate the select field
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  /**
   * Fetches from the backend the list of currencies this user is allowed to see
   */
  const fetchCurrencies = useCallback(() => {
    getCurrenciesList()
      .then(response => {
        setCurrencies(response.currencies);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies])

  /**
  * Returns the selected country as a select option
  */
  const getSelectedCountryOption = () => getCountryOptions().find(option => option.value === values.billingCountry) || null;

  /**
  * Converts the list of currencies to select options
  */
  const getCurrencyOptions = () => currencies.map(currency => ({ label: `${currency.name} ( ${currency.symbol} )`, value: currency.id }));

  /**
   * Returns the selected currencies as a select option
   */
  const getSelectedCurrencyOption = () => getCurrencyOptions().find(option => option.value === values.currencyId) || null;


  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <GridContainer>
    <Grid xs={12} md={6} >
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <CardTitleBar title={t("generalInfo")} />
          <GridContainer spacing={2}>
            <Grid xs={12} md={6}>
              <TextField name="companyName" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.companyName} error={!!errors.companyName} helperText={errors.companyName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="vatNo" label={t("vatNumber")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatNo} error={!!errors.vatNo} helperText={errors.vatNo} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="contactFirstName" label={t("contactFirstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactFirstName} error={!!errors.contactFirstName} helperText={errors.contactFirstName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="contactLastName" label={t("contactLastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactLastName} error={!!errors.contactLastName} helperText={errors.contactLastName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField type="email" name="contactEmail" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactEmail} error={!!errors.contactEmail} helperText={errors.contactEmail} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="contactPhone" label={t("phone")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactPhone} error={!!errors.contactPhone} helperText={errors.contactPhone} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!values.isActive}
                    onChange={(e) => {
                      setFieldValue('isActive', e.target.checked ? 1 : 0)
                    }}
                    name="isActive"
                    value={values.isActive}
                  />
                }
                label={t("active")}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
    <Grid xs={12} md={6} >
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={t("billing")} />
          <GridContainer spacing={2}>
            <Grid xs={12}>
              <TextField name="billingAddress" label={t("address")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingAddress} error={!!errors.billingAddress} helperText={errors.billingAddress} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="billingCity" label={t("city")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCity} error={!!errors.billingCity} helperText={errors.billingCity} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="billingZip" label={t("zipCode")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingZip} error={!!errors.billingZip} helperText={errors.billingZip} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="billingCounty" label={t("county")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCounty} error={!!errors.billingCounty} helperText={errors.billingCounty} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <Autocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('billingCountry', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('billingCountry');
                }}
                value={getSelectedCountryOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getCountryOptions()}
                renderInput={(params: any) => <TextField {...params} name="billingCountry" label={t("country")} error={!!errors.billingCountry} helperText={errors.billingCountry} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <MuiAutocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('currencyId', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('currencyId');
                }}
                value={getSelectedCurrencyOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getCurrencyOptions()}
                renderInput={(params: any) => <TextField {...params} name="currencyId" label={t("currency")} error={!!errors.currencyId} helperText={errors.currencyId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField type="number" name="vatRate" label={t("vatRate")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatRate} error={!!errors.vatRate} helperText={errors.vatRate} sx={{ width: '100%' }} InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }} />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
    <Grid xs={6} >
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={t("settings")} />
          <GridContainer spacing={2}>
            <Grid xs={12} md={6}>
              <TextField type="number" name="penaltyPercent" label={t("penaltyPercent")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.penaltyPercent} error={!!errors.penaltyPercent} helperText={errors.penaltyPercent} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="invoicePrefix" label={t("invoicePrefix")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.invoicePrefix} error={!!errors.invoicePrefix} helperText={errors.invoicePrefix} sx={{ width: '100%' }} />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
  </GridContainer>
}

export default FormCreate;