import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { perms, useAccess } from "context/access";
import { useFormik } from "formik";
import { createDuArticle, getDu } from "helper/backend";
import { ValidationException } from "helper/error";
import { route, routes } from "helper/route";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import AccessDenied from "page/Error/AccessDenied";
import { useNavigate } from "react-router-dom";
import FormAddDuArticle from "./Partial/Form/Create";
import DistributionUnit from "model/distributionUnit";
import Preloader from "component/common/Preloader";
import { useParams } from "react-router-dom";
import { AppError } from "@type";
import Error from 'page/Error';
import * as Yup from "yup"
import { useTranslation } from "react-i18next";

type Values = {
  articleId: number | undefined,
  minStock: string,
  distributionUnitId: number
}

const NewDuArticle = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const duId = parseInt(id!);

  // the Du record fetched from the backend
  const [distributionUnit, setDistributionUnit] = useState<DistributionUnit>();
  // error encoutered while fetching the distribution unit (if any)
  const [distributionUnitError, setDistributionUnitError] = useState<AppError>();
  // whether the loading of the distribution unit is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    articleId: undefined,
    minStock: "",
    distributionUnitId: duId!
  };

  /**
  * Form validation rules
  */
  const validationSchema = {
    articleId: Yup.number().required(t("fieldIsRequired")),
    minStock: Yup.number().typeError(t("minimumStockNumber")).required(t("fieldIsRequired")),
    distributionUnitId: Yup.number().required(t("fieldIsRequired"))
  };

  /**
  * Form configuration
  */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDuArticle(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDuArticle = (values: any) => {
    setIsSubmitInProgress(true);
    createDuArticle(values)
      .then(_response => {
        showSuccess(t("duArticleHasBeenSaved"));
        return navigate(route(routes.view_distirbution_unit, duId));
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDuArticle"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Fetches from the backend the distribution unit
  */
  const fetchDu = useCallback(() => {
    setIsLoadInProgress(true)
    getDu(duId)
      .then(response => {
        setDistributionUnit(response.distributionUnit);
      })
      .catch(ex => {
        setDistributionUnitError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [duId]);

  // This hook runs on first component mount to save the distribution unit in the state
  useEffect(() => {
    fetchDu()
  }, [fetchDu]);

  return (
    <>
      {isGranted(perms.create_distribution_unit_articles) &&
        <Box>
          {!!distributionUnit &&
            <form noValidate onSubmit={formik.handleSubmit}>
              <PageTitleBar breadcrumbs={breadcrumbs(distributionUnit, t)}>
                <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("addArticle")}</ProgressButton>
                <Button variant="contained" color="secondary" component={Link} href={route(routes.view_distirbution_unit, duId)} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6} >
                  <Card>
                    <CardContent>
                      <FormAddDuArticle formik={formik} />
                    </CardContent>
                  </Card>
                </Grid>
              </GridContainer>
            </form >
          }
          {isLoadInProgress && !distributionUnit && <Preloader container="parent" />}
          {!!distributionUnitError && <Error error={distributionUnitError} title404={t("distributionUnitNotFound")} />}
        </Box>
      }
      {isNotGranted(perms.create_distribution_unit_articles) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (du: DistributionUnit, t: Function) => [
  {
    text: t("distributionUnits"),
    url: routes.list_distribution_units,
  },
  {
    text: du.name,
    url: route(routes.view_distirbution_unit, du.id)
  },
  {
    text: t("distributionUnitArticle"),
  }
];


export default NewDuArticle;