import AccessRole from "./accessRole";
import Client from "./client";
import Currency from "./currency";

export default class User {

  id!: number;
  organizationId!: number | null;
  organizationCompanyName?: string | null;
  clientId!: number | null;
  clientCompanyName?: string | null;
  accessRoleId!: number;
  accessRoleName?: string | null;
  accessRoleType!: number;
  firstName!: string;
  lastName!: string;
  fullName!: string | null;
  email!: string;
  isActive!: boolean;
  lastLoginTs!: number | null;
  timezone!: string;
  isEmailVerified!: boolean;
  accessCode!: number | null;
  hasCustomLogo!: boolean;
  clientBusinessModel!: number | null;
  currency!: Currency;
  isTutorialComplete!: number;
  createdTs!: number;
  updatedTs!: number;

  static createFrom(data: User) {
    return Object.assign(new User(), data);
  }

  /***** ROLES *****/

  isMasterAdmin() {
    return AccessRole.isMasterAdmin(this.accessRoleId);
  }

  isOrganizationAdmin() {
    return AccessRole.isOrganizationAdmin(this.accessRoleId);
  }

  /***** ROLE TYPES *****/

  isMasterType() {
    return AccessRole.isMasterType(this.accessRoleId);
  }

  isOrganizationType() {
    return AccessRole.isOrganizationType(this.accessRoleId);
  }

  isDriverType() {
    return AccessRole.isDriverType(this.accessRoleId);
  }

  isClientAdmin() {
    return AccessRole.isClientAdmin(this.accessRoleId);
  }

  isClientType() {
    return AccessRole.isClientType(this.accessRoleId);
  }

  getType() {
    return AccessRole.getType(this.accessRoleId);
  }

  isSameType(user2: User) {
    return AccessRole.isSameType(this.accessRoleId!, user2.accessRoleId);
  }

  /***** CLIENT CHECK *****/

  isNonSsdClient() {
    return this.isClientType() && this.clientBusinessModel === Client.BUSINESS_MODEL_NON_SSD;
  }

  isFreeSsdClient() {
    return this.isClientType() && this.clientBusinessModel === Client.BUSINESS_MODEL_FREE_SSD;
  }

  isSsdClient() {
    return this.isClientType() && this.clientBusinessModel === Client.BUSINESS_MODEL_SSD;
  }
}