import React from "react";
import { Box, IconButton, Typography, Link } from '@mui/material';
import CardTitleBar from 'component/common/CardTitleBar';
import { perms, useAccess } from "context/access";
import { Edit, PictureAsPdf } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";
import { MasterSettings } from "@type/backend";

type Props = {
  settings: MasterSettings;
  onEdit: Function;
  isReloading: boolean;
};

const SectionTermsView = ({ settings, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("clientTermsAndConditions")}>
      {isGranted(perms.edit_master) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    {
      !!settings.clientTermsAndConditions
        ?
        <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Link href={`${process.env.REACT_APP_API_URL}/static/terms/ClientTerms.pdf`} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} target="/blank">
            <PictureAsPdf sx={{ fontSize: "45px", color: "#d90a0a" }} />
            <Typography>
              ClientTerms.pdf
            </Typography>
          </Link>
        </Box>
        :
        <Box>
          {t("noTermsAndConditionsUploaded")}
        </Box>
    }
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionTermsView;