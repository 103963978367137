import React, { useState } from 'react';
import { Button, Link, Box } from '@mui/material';
import { Check, KeyboardArrowLeft } from '@mui/icons-material';
import { routes } from 'helper/route';
import { createOrganization } from 'helper/backend';
import { ValidationException } from 'helper/error';
import { isErrType, showError, showSuccess, toSimpleError } from 'helper/util';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import AccessDenied from 'page/Error/AccessDenied';
import FormCreate from './Partial/Form/Create';
import ProgressButton from 'component/common/ProgressButton';
import * as Yup from "yup"
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { regexPhone, regexZip } from 'constant/regex';
import { useTranslation } from 'react-i18next';

const New = () => {

  const { isGranted, isNotGranted } = useAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * Event handler called whenever the user saves the form
  */
  const saveOrganization = (values: any) => {
    setIsSubmitInProgress(true);
    createOrganization(values)
      .then(_response => {
        showSuccess(t("organizationHasBeenSaved"));
        return navigate(routes.list_organizations);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableSaveOrganization"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }

  /**
   * These are the values loaded into the form as the component mounts
   */
  const formInitialValues = {
    //First Card
    companyName: '',
    isActive: 1,
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    vatNo: '',
    //Second Card
    billingAddress: '',
    billingCity: '',
    billingZip: '',
    billingCounty: '',
    billingCountry: undefined,
    currencyId: undefined,
    vatRate: undefined,
    //Third Card
    penaltyPercent: '',
    invoicePrefix: ''
  };

  /**
   * Form validation rules
   */
  const validationSchema = {
    companyName: Yup.string().trim().required(t("fieldIsRequired")),
    isActive: Yup.number().min(0),
    contactFirstName: Yup.string().trim().required(t("fieldIsRequired")),
    contactLastName: Yup.string().trim().required(t("fieldIsRequired")),
    contactEmail: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    contactPhone: Yup.string().trim().required(t("fieldIsRequired")).matches(regexPhone, t("invalidPhoneNumber")),
    vatNo: Yup.string().trim(),
    billingAddress: Yup.string().trim().required(t("fieldIsRequired")),
    billingCity: Yup.string().trim().required(t("fieldIsRequired")),
    billingZip: Yup.string().trim().required(t("fieldIsRequired")).matches(regexZip, t("invalidZipCode")),
    billingCounty: Yup.string().trim().required(t("fieldIsRequired")),
    billingCountry: Yup.string().trim().required(t("fieldIsRequired")),
    currencyId: Yup.number().required(t("fieldIsRequired")),
    penaltyPercent: Yup.number().typeError(t("penaltyPercentNumber")).required(t("fieldIsRequired")),
    invoicePrefix: Yup.string().trim(),
    vatRate: Yup.number().min(0, "VAT must be 0 or more").required(t("fieldIsRequired"))
  };

  /**
   * Form configuration
   */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveOrganization(values);
    },
  });

  return <React.Fragment>
    {isGranted(perms.create_organizations) && <Box>
      <form noValidate onSubmit={formik.handleSubmit}>
        <PageTitleBar breadcrumbs={breadcrumbs(t)}>
          <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveOrganization")}</ProgressButton>
          <Button variant="contained" color="secondary" component={Link} href={routes.list_organizations} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
        </PageTitleBar>
        <FormCreate
          formik={formik}
        />
      </form >
    </Box>
    }
    {isNotGranted(perms.create_organizations) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (t: Function) => [{
  text: t("organization"),
  url: routes.list_organizations,
}, {
  text: t("newOrganization"),
}];

export default New;