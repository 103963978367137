import React from "react";
import { CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import EditableCard from "component/styled/EditableCard";
import ActivityLog from "model/activityLog";
import { route, routes } from "helper/route";
import { useAuth } from "context/auth";

type Props = {
  activityLog: ActivityLog;
};

const SectionUser = ({ activityLog }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("user")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("userId")}</TableCell>
              {
                !!activityLog.subject ?
                  <TableCell className="preview-value">{activityLog.subject.userId}</TableCell>
                  :
                  <TableCell className="preview-value">--</TableCell>
              }
            </TableRow>
            <TableRow>
              <TableCell>{t("userName")}</TableCell>
              {
                !!activityLog.subject ?
                  <TableCell className="preview-value">{activityLog.subject.userName}</TableCell>
                  :
                  <TableCell className="preview-value">--</TableCell>
              }
            </TableRow>
            <TableRow>
              <TableCell>{t("entityType")}</TableCell>
              {
                !!activityLog.subject ?
                  <TableCell className="preview-value">{ActivityLog.getEntityTypeName(activityLog.subject.entityType)}</TableCell>
                  :
                  <TableCell className="preview-value">--</TableCell>
              }
            </TableRow>
            {
              authUser.isMasterAdmin() &&
              <TableRow>
                <TableCell>{t("organization")}</TableCell>
                {
                  !!activityLog.organization ?
                    <TableCell className="preview-value"><Link href={route(routes.view_organization, activityLog.organization.id)}>{activityLog.organization.name}</Link></TableCell>
                    :
                    <TableCell className="preview-value">--</TableCell>
                }
              </TableRow>
            }
            <TableRow>
              <TableCell>{t("client")}</TableCell>
              {
                !!activityLog.client ?
                  <TableCell className="preview-value">
                    {authUser.isMasterAdmin() ?
                      activityLog.client.name
                      :
                      <Link href={route(routes.view_client, activityLog.client.id)}>{activityLog.client.name}</Link>
                    }
                  </TableCell>
                  :
                  <TableCell className="preview-value">--</TableCell>
              }
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionUser;