import { combineReducers } from 'redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  APPLY_ACTIVITY_LOGS_DT_PARAMS,
  PATCH_ACTIVITY_LOGS_DT_PARAMS
} from './actionTypes';
import { ActivityLogDtRequestActionPayload } from './types';
import { DtParams } from '@type';
import { getDefaultDtParams } from 'helper/dt';

/********** Data Table **********/

const defaultDtParamsState: DtParams = getDefaultDtParams();

const DtRequest = (state = defaultDtParamsState, action: PayloadAction<ActivityLogDtRequestActionPayload>): DtParams => {
  switch (action.type) {
    case APPLY_ACTIVITY_LOGS_DT_PARAMS:
      return {
        ...action.payload.params
      };
    case PATCH_ACTIVITY_LOGS_DT_PARAMS:
      return {
        ...state,
        ...action.payload.params,
        filters: {
          ...state.filters,
          ...action.payload.params.filters
        }
      }
    default:
      return state;
  }
}

export default combineReducers({
  DtRequest,
});
