import { useEffect } from "react";
import { OpenStreetMapProvider } from "leaflet-geosearch";

type Props = {
  searchValue: string;
  setAddressOptions: Function;
  setIsLoadInProgress: Function;
  isReversed?: boolean;
  values: any;
  setFieldValue: Function;
}

const GeoSearch = ({ searchValue, setAddressOptions, setIsLoadInProgress, isReversed, values, setFieldValue }: Props) => {

  const host = 'https://nominatim.openstreetmap.org';

  const provider: any = new OpenStreetMapProvider({ searchUrl: isReversed ? `${host}/reverse` : `${host}/search` });

  const search = async () => {
    let results: any;
    if (isReversed && !!values.latitude) {
      results = await provider.search({ query: { lat: values.latitude, lon: values.longitude } })
    } else {
      results = await provider.search({ query: { q: searchValue, } })
    }

    const resultsAutocomplete = results.map((res: any, idx: number) => {
      return { key: idx, ...res }
    });

    if (isReversed) {
      setFieldValue("address", results[0].label)
    }

    setAddressOptions(resultsAutocomplete);
    setIsLoadInProgress(false);
  }

  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, values.latitude, values.longitude])

  return null;
}

export default GeoSearch;
