import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { routes } from 'helper/route';
import { perms, useAccess } from 'context/access';
import AccessDenied from 'page/Error/AccessDenied';
import { deleteUser, getUser, getVerificationLink, impersonateUser, sendVerificationLink } from 'helper/backend';
import User from 'model/user';
import { extractParamsFromUrl, showError, showSuccess, toSimpleError } from 'helper/util';
import { AppError } from '@type';
import Preloader from 'component/common/Preloader';
import Error from 'page/Error';
import PageTitleBar from 'component/common/PageTitleBar';
import { Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Link } from '@mui/material';
import SectionInfo from './Partial/SectionInfo';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'component/common/ConfirmDialog';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ProgressButton from 'component/common/ProgressButton';
import { mdiAccountArrowLeft } from '@mdi/js';
import Mdi from 'component/common/Mdi';
import { saveAccessToken, saveRefreshToken } from 'helper/storage';
import { useAuth } from 'context/auth';
import MoreVert from '@mui/icons-material/MoreVert';
import GridContainer from 'component/common/GridContainer';
import AccessRole from 'model/accessRole';
import { useTranslation } from 'react-i18next';

const Single = () => {

  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
   * Read params from the url
   * Also cast to the right data type
   */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the user record fetched from the backend
  const [user, setUser] = useState<User | undefined>();
  // error encoutered while fetching the user (if any)
  const [userError, setUserError] = useState<AppError | undefined>();
  // whether the loading of the user is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the user is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  // whether the impersonation of the user is in progress (being requested from the server)
  const [isImpersInProgress, setIsImpersInProgress] = useState(false);
  // menu anchor element
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement | null>(null);
  // whether the send verification link confirmation is visible
  const [isSendVerifConfOpen, setIsSendVerifConfOpen] = useState(false);
  // whether the verification link sending is in progress
  const [isSendVerifInProgress, setIsSendVerifInProgress] = useState(false);
  // the email verification link
  const [emailVerifLink, setEmailVerifLink] = useState<string | null>(null);
  // list of URL parameters
  const [urlParams, setUrlParams] = useState<{ [k: string]: any }>();

  /**
   * Fetches the user record from the backend
   */
  const refreshUser = useCallback(() => {
    setIsLoadInProgress(true);
    getUser(recordId)
      .then(response => {
        setUser(response.user);
      })
      .catch(ex => {
        setUserError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [recordId]);

  /**
   * Deletes the user record in the backend
   */
  const removeUser = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteUser(recordId)
      .then(_response => {
        showSuccess(t("userHasBeenDeleted"));
        return navigate(routes.list_users);
      })
      .catch(_ex => {
        showError(t('unableToDeleteUser'));
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  /**
   * Impersonate the user
   */
  const masqueradeUser = useCallback(() => {
    setIsImpersInProgress(true);
    impersonateUser(recordId)
      .then(response => {
        saveAccessToken(response.access.token);
        saveRefreshToken(response.refresh.token);
        // redirect to homepage using native redirect
        // this will re-check and refresh the authenticated user
        window.location.replace(routes.home);
      })
      .catch(_ex => {
        showError(t("unableToImpersonateUser"));
      })
      .finally(() => {
        setIsImpersInProgress(false);
      });
  }, [recordId, t]);

  const confirmSendVerificationLink = () => {
    setIsSendVerifConfOpen(true);
    toggleMenu({} as MouseEvent<HTMLButtonElement>);
  }

  const doSendVerificationLink = () => {
    setIsSendVerifInProgress(true);
    sendVerificationLink(recordId)
      .then(_response => {
        showSuccess(t("verificationLinkSent"));
      })
      .catch(_ex => {
        showError(t("unableToSendVerificationLink"));
      })
      .finally(() => {
        setIsSendVerifInProgress(false);
      });
  }

  const doGetVerificationLink = useCallback(() => {
    getVerificationLink(recordId)
      .then(response => {
        setEmailVerifLink(response.url);
      })
      .catch(_ex => {
        showError(t("unableToFetchVerificationLink"));
      });
  }, [recordId, t]);

  // This hook runs on first component mount for saving the URL params in the state
  useEffect(() => {
    setUrlParams(extractParamsFromUrl())
  }, [])

  // This hook runs once on component mount
  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  useEffect(() => {
    // make sure the user is loaded
    if (!!user && !user.isEmailVerified) {
      doGetVerificationLink();
    }
  }, [doGetVerificationLink, user]);

  const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElem(prevElem => !!prevElem ? null : e.currentTarget);
  }

  const copyVerificationLink = () => {
    if (!emailVerifLink) {
      return;
    }
    navigator.clipboard.writeText(emailVerifLink);
    showSuccess(t("verificationLinkCopied"));
    toggleMenu({} as MouseEvent<HTMLButtonElement>);
  }

  /**
  * Checking the permission to impersonate the user
  */
  const checkImpersonatePermission = () => {
    let canImpersonate = false;

    if (user?.id !== authUser.id) {
      canImpersonate = true;
    };
    if (user?.accessRoleId === AccessRole.ID_MASTER_ADMIN && authUser.accessRoleId === AccessRole.ID_MASTER_ADMIN) {
      canImpersonate = false;
    };

    return canImpersonate;
  }

  return <React.Fragment>
    {isGranted(perms.view_users) && <React.Fragment>
      {!!user && <Box>
        <PageTitleBar breadcrumbs={breadcrumbs(user, t)}>
          {isGranted(perms.edit_users) && !user.isEmailVerified && <IconButton sx={{ mr: 1 }} onClick={toggleMenu}><MoreVert /></IconButton>}
          <Menu
            sx={{ mt: '36px' }}
            anchorEl={menuAnchorElem}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={!!menuAnchorElem}
            onClose={toggleMenu}
          >
            <MenuItem disabled={isSendVerifInProgress} onClick={confirmSendVerificationLink}>
              <Typography textAlign="center">{t("resendVerificationLink")}</Typography>
            </MenuItem>
            {!!emailVerifLink && <MenuItem onClick={copyVerificationLink}>
              <Typography textAlign="center">{t("copyVerificationLink")}</Typography>
            </MenuItem>}
          </Menu>
          {isGranted(perms.impersonate_users, user) && checkImpersonatePermission() && <ProgressButton variant="contained" color="purple" sx={{ mr: 1 }} startIcon={<Mdi path={mdiAccountArrowLeft} />} isBusy={isImpersInProgress} onClick={masqueradeUser} className='buttonAlwaysText'>{t("impersonateUser")}</ProgressButton>}
          {isGranted(perms.delete_users) && user.id !== authUser.id && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteUser")}</ProgressButton>}
          <Button variant="contained" color="secondary" component={Link} href={urlParams?.ret ? urlParams.ret : routes.list_users} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
        </PageTitleBar>
        <GridContainer>
          <Grid xs={12} md={6}>
            <SectionInfo user={user} refreshHandler={refreshUser} isLoadInProgress={isLoadInProgress} />
          </Grid>
        </GridContainer>
        <ConfirmDialog
          isOpen={isDeleteConfOpen}
          yesButton={t("delete")}
          onConfirm={() => {
            setIsDeleteConfOpen(false);
            removeUser();
          }}
          onCancel={() => {
            setIsDeleteConfOpen(false);
          }}
        >{t("deleteUserConfirmation")} &quot;{user.fullName}&quot;?</ConfirmDialog>
        <ConfirmDialog
          isOpen={isSendVerifConfOpen}
          yesButton={t("send")}
          onConfirm={() => {
            setIsSendVerifConfOpen(false);
            doSendVerificationLink();
          }}
          onCancel={() => {
            setIsSendVerifConfOpen(false);
          }}
        >{t("resendVerificationLinkConfirmation")} &quot;{user.email}&quot;?</ConfirmDialog>
      </Box>}
      {/* Show the prealoder only on the first fetch */}
      {isLoadInProgress && !user && <Preloader container="content" />}
      {!!userError && <Error error={userError} title404={t("userNotFound")} />}
    </React.Fragment>}
    {isNotGranted(perms.view_users) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (user: User, t: Function) => [{
  text: t("users"),
  url: routes.list_users,
}, {
  text: user.fullName!,
}];

export default Single;
