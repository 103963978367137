import React from 'react';
import { Box } from "@mui/material";
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

const CustomGridToolbar = () => {
  return <Box sx={{ display: 'flex', p: 1, justifyContent: "flex-end", border: 0 }}>
    <GridToolbarQuickFilter
      sx={{
        "& .MuiInputBase-root::before": {
          border: 0,
        },
      }}
    />
  </Box>
}
export default CustomGridToolbar;