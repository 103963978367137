import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Link, Paper, TableContainer } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { AppError, DtParams } from "@type";
import { perms, useAccess } from "context/access";
import { insertDtParamsIntoUrl } from "helper/dt";
import { toSimpleError } from "helper/util";
import { getCurrenciesDt } from "helper/backend";
import useDtUrlParams from "hook/dtUrlParams";
import { useAppDispatch, useAppSelector } from "hook/redux";
import AccessDenied from "page/Error/AccessDenied";
import Dt from "component/common/Dt";
import PageTitleBar from "component/common/PageTitleBar";
import { applyCurrencyDtParams, patchCurrencyDtParams } from "store/actions";
import { route, routes } from "helper/route";
import Currency from "model/currency";
import { useTranslation } from "react-i18next";

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * DataGrid params may sometimes be passed in the url
  * so here we attempt to read any params from the url
  */
  const urlParams = useDtUrlParams();

  // DataGrid rows
  const [dtRows, setDtRows] = useState([] as Currency[]);
  // DataGrid total number of rows
  const [dtRowCount, setDtRowCount] = useState(0);
  // DataGrid sorting, filtering, pagination, etc
  const dtParams = useAppSelector(store => store.Currency.DtRequest);
  // whether the loading of the DataGrid rows is in progress
  const [isDtLoadInProgress, setIsDtLoadInProgress] = useState(false);
  // DataGrid error encoutered while fetching the rows (if any)
  const [dtError, setDtError] = useState<AppError | null>(null);

  // This hook runs once on component mount
  useEffect(() => {
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }
    // update the DataGrid params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchCurrencyDtParams(urlParams));
  }, [dispatch, urlParams]);

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    // fetch the list of DataGrid rows from the server
    setIsDtLoadInProgress(true);
    getCurrenciesDt(dtParams)
      .then(response => {
        setDtRows(response.rows);
        setDtRowCount(response.totalCount);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      })
      .finally(() => {
        setIsDtLoadInProgress(false);
      });
    // having 'urlParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'dtParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtParams]);

  /**
  * Saves new DataGrid params to the redux store
  * @param params
  */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyCurrencyDtParams(params));
  }, [dispatch]);

  return (
    <React.Fragment>
      {isGranted(perms.view_currencies) && <Box>

        {/********** Page Title and Actions Toolbar **********/}
        <PageTitleBar title={t("currencies")}>
          <Button variant="contained" color="dark" startIcon={<PlaylistAddIcon />} component={Link} href={routes.new_currency}>{t("addNewCurrency")}</Button>
        </PageTitleBar>

        {/********** DataGrid **********/}
        <TableContainer component={Paper}>
          {!!dtError && <Alert severity="error" sx={{ mb: 1 }}>{t("unableToLoadCurrencies")}</Alert>}
          <Dt
            rows={dtRows}
            rowCount={dtRowCount}
            columns={columns(t)}
            params={dtParams}
            isBusy={isDtLoadInProgress}
            localeText={{
              noRowsLabel: t("noCurrenciesFound"),
              noResultsOverlayLabel: t("noCurrenciesFound"),
            }}
            onPaginationChange={updateDtParams}
            onSearchChange={updateDtParams}
            onSortChange={updateDtParams}
          />
        </TableContainer>
      </Box>}
      {isNotGranted(perms.view_currencies) && <AccessDenied />}
    </React.Fragment>
  )
}

const columns = (t: Function) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t("name"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_currency, params.row.id)}>{params.value}</Link>
      },
    },
    {
      field: 'code',
      headerName: t("code"),
      flex: 1,
      minWidth: 120,
    },
  ];
  return columns;
}

export default List;