import React from "react";
import NetworkPicture1 from 'asset/image/NetworkPicture1.png';
import NetworkPicture2 from 'asset/image/NetworkPicture2.png';
import NetworkPicture3 from 'asset/image/NetworkPicture3.png';
import GridContainer from "component/common/GridContainer";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";

type Props = {
  isClient?: boolean
}

const NetworkStep = ({ isClient = false }: Props) => {

  const { t } = useTranslation();

  return (
    <GridContainer sx={{ overflow: "auto", maxHeight: "80vh" }}>
      <Grid xs={12}>
        <Box sx={{ fontSize: "22px", fontWeight: 600, marginTop: "12px" }}>
          {t("network")}
        </Box>
      </Grid>
      {
        !isClient &&
        <>
          <Grid lg={12} xl={4} >
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={NetworkPicture1} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("networkOrganizationText1")}
            </p>
          </Grid>
          <Grid lg={12} xl={4}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={NetworkPicture2} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("networkOrganizationText2")}
            </p>
          </Grid>
          <Grid lg={12} xl={4}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={NetworkPicture3} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("networkOrganizationText3")}
            </p>
          </Grid>
        </>
      }
      {
        isClient &&
        <>
          <Grid md={12} lg={6} >
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={NetworkPicture1} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("networkClientText1")}
            </p>
          </Grid>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={NetworkPicture2} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("networkClientText2")}
            </p>
          </Grid>
        </>
      }
    </GridContainer>
  )
};

export default NetworkStep;