import React, { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Location from 'model/location';
import { useTranslation } from 'react-i18next';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, handleChange, setFieldValue }: Props) => {

  const { t } = useTranslation();

  /**
  * Converts the list of types to select options
  */
  const getTypeOptions = () => {
    const options = [];
    options.push({ label: 'All', value: Number.MIN_SAFE_INTEGER });
    const types = Location.LocationTypes.map(type => ({ label: type.name, value: type.id }));
    options.push(...types);
    return options;
  };

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedTypeOption = () => getTypeOptions().find(option => option.value === values.type) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3} >
            <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('type', selectedOption?.value);
              }}
              onFocus={_e => {
                onSelectFieldFocused('type');
              }}
              value={getSelectedTypeOption()}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getTypeOptions()}
              renderInput={(params: any) => <TextField {...params} name="type" label={t("locationType")} error={!!errors.entityType} helperText={errors.entityType} inputProps={{ ...params.inputProps, autoComplete: 'new-type' }} />}
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;