import React, { useCallback, useEffect, useState } from "react";
import WizardStepper from "./WizardStepper";
import { Box, CardContent } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import EditableCard from "component/styled/EditableCard";
import Organization from "model/organization";
import Client from "model/client";
import { getOwnClient, getOwnOrganization } from "helper/backend";
import Preloader from "component/common/Preloader";
import { showError } from "helper/util";
import { useAuth } from "context/auth";
import AccessRole from "model/accessRole";
import SectionSettingsOrganization from "../Form/SectionSettingsOrganization";
import { useTranslation } from "react-i18next";
import background from 'asset/image/bgPattern.png';
import logo from 'asset/image/logo.png';

const BillingInformation = () => {

  const { authUser } = useAuth();
  const { t } = useTranslation();

  // the organization record fetched from the backend
  const [organization, setOrganization] = useState<Organization>()
  // the client record fetched from the backend
  const [client, setClient] = useState<Client>()
  // whether the loading of the organization is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the organization record from the backend
  */
  const refreshOrganization = useCallback(() => {
    setIsLoadInProgress(true)
    getOwnOrganization()
      .then(response => {
        setOrganization(response.organization);
      })
      .catch(_ex => {
        showError(t("unableToLoadOrganization"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t])

  /**
  * Fetches the client record from the backend
  */
  const refreshClient = useCallback(() => {
    setIsLoadInProgress(true)
    getOwnClient()
      .then(response => {
        setClient(response.client);
      })
      .catch(_ex => {
        showError(t("unableToLoadClient"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t])

  // This hook runs once on component mount
  useEffect(() => {
    if (authUser.accessRoleId === AccessRole.ID_ORGANIZATION_ADMIN) {
      refreshOrganization();
    } else if (authUser.accessRoleId === AccessRole.ID_CLIENT_ADMIN) {
      refreshClient();
    }
  }, [refreshClient, refreshOrganization, authUser]);

  return (
    <>
      <Box sx={{ display: 'flex', height: '100vh', width: "100%", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <WizardStepper activeStep={0} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            marginTop: { xs: 0, md: "-64px" },
            width: "100%",
            '@media (max-height: 800px)': {
              marginTop: 0
            }
          }}
        >
          <GridContainer
            sx={{
              width: { sx: "100%", md: "600px" },
              margin: { xs: 0, md: "auto" },
              height: { xs: "100%", md: "auto" },
              '@media (max-height: 800px)': {
                width: "100%",
                margin: 0,
                height: "100%"
              }
            }}
          >
            <Grid xs={12} sx={{ padding: { xs: 0 } }}>
              {(!!organization || !!client) &&
                <EditableCard sx={{ height: '100%', padding: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CardContent>
                    <Box sx={{ textAlign: 'center', mb: 6, height: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
                      <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                        <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                      </Box>
                    </Box>
                    {
                      !!organization &&
                      <SectionSettingsOrganization organization={organization!} />
                    }
                  </CardContent>
                </EditableCard>
              }
            </Grid>
          </GridContainer>
        </Box>
        {/* Show the prealoder only on the first fetch */}
        {isLoadInProgress && !organization && <Preloader container="parent" />}
      </Box>
    </>
  )
}

export default BillingInformation;