import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import { toSimpleError } from "helper/util";
import { getAppVersionDevices } from "helper/backend";
import { AppError } from "@type";
import TableNoBb from "component/styled/TableNoBb";
import Spinner from "component/common/Spinner";
import env from "env";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import IconTableCell from "component/common/IconCell";
import Device from "model/device";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { useTranslation } from "react-i18next";

type Props = {
  appVersionId: number,
};

const SectionDevices = ({ appVersionId }: Props) => {

  const { t } = useTranslation();

  // the devices record fetched from the backend
  const [devices, setDevices] = useState([] as Device[]);
  // whether the loading of the devices is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // error encoutered while fetching the devices (if any)
  const [error, setError] = useState<AppError>();
  // the number of seen devices in the Users Table
  const [numOfVisibleDevices, setNumOfVisibleDevices] = useState(env.COLLAPSED_TABLE_ROWS);

  /**
  * On click function that shows all the users
  */
  const viewMore = () => {
    setNumOfVisibleDevices(devices.length);
  };

  /**
  * On click function that shows only the number of users set in the environment variable
  */
  const viewLess = () => {
    setNumOfVisibleDevices(env.COLLAPSED_TABLE_ROWS);
  };

  /**
  * Fetches the organization users from the backend
  */
  const getDevicesList = useCallback(() => {
    setIsLoadInProgress(true)
    getAppVersionDevices(appVersionId)
      .then(response => {
        setDevices(response.devices);
      })
      .catch(ex => {
        setError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [appVersionId]);

  // This hook runs once on component mount
  useEffect(() => {
    getDevicesList();
  }, [getDevicesList]);

  const viewDeviceLink = (duId: number, id: number) => route(routes.view_du_device, [duId, id]);

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title={t("devices")} />
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>{t("organization")}</TableCell>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("code")}</TableCell>
              <TableCell>{t("lastSync")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!devices.length && devices.slice(0, numOfVisibleDevices).map((device, idx) =>
              <TableRow key={idx}>
                <TableCell><Link href={route(routes.view_organization, device.organizationId)}>{device.organizationName}</Link></TableCell>
                <TableCell><Link href={viewDeviceLink(appVersionId, device.id!)}>{device.name}</Link></TableCell>
                <TableCell>{device.code}</TableCell>
                <IconTableCell>{device.lastSyncTs ? formatTimestamp(device.lastSyncTs, formats.DATETIME) : t("never")}</IconTableCell>
              </TableRow>)}
            {!devices.length && !isLoadInProgress && !error && <TableRow>
              <TableCell colSpan={4} align="center">{t("noDevicesFound")}</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {devices.length > env.COLLAPSED_TABLE_ROWS && <Grid container justifyContent="center" alignItems="center">
          {numOfVisibleDevices === devices.length
            ?
            <Button startIcon={<KeyboardArrowUpIcon />} sx={{ mt: 2 }} onClick={viewLess}>
              {t("viewLess")}
            </Button>
            :
            <Button startIcon={<KeyboardArrowDownIcon />} sx={{ mt: 2 }} onClick={viewMore}>
              {t("viewMore")}
            </Button>
          }
        </Grid>}
        {!!error && <Alert severity="error" sx={{ justifyContent: 'center' }}>{t("unableToLoadDevices")}</Alert>}
        {isLoadInProgress && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}><Spinner sx={{ width: 200 }} /></Box>}
      </CardContent>
    </EditableCard>
  </React.Fragment>
};

export default SectionDevices;