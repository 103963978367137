import React from 'react';
import { useAuth } from './auth';
import User from 'model/user';

export const perms = {
  view_users: 'view_users',
  create_users: 'create_users',
  edit_users: 'edit_users',
  delete_users: 'delete_users',
  impersonate_users: 'impersonate_users',
  view_organizations: 'view_organizations',
  view_own_organization: 'view_own_organization',
  edit_own_organization: "edit_own_organization",
  create_organizations: 'create_organizations',
  delete_organizations: 'delete_organizations',
  edit_organizations: 'edit_organizations',
  invite_organizations: 'invite_organizations',
  view_own_client: 'view_own_client',
  create_clients: 'create_clients',
  view_articles: 'view_articles',
  edit_articles: 'edit_articles',
  delete_articles: 'delete_articles',
  create_articles: 'create_articles',
  view_clients: 'view_clients',
  delete_clients: 'delete_clients',
  edit_clients: 'edit_clients',
  edit_own_client: 'edit_own_client',
  invite_clients: 'invite_clients',
  view_distribution_units: 'view_distribution_units',
  delete_distribution_units: 'delete_distribution_units',
  edit_distribution_units: 'edit_distribution_units',
  create_distribution_unit: 'create_distribution_unit',
  view_catalog: 'view_catalog',
  view_network: 'view_network',
  view_locations: 'view_locations',
  view_devices: 'view_devices',
  create_devices: 'create_devices',
  delete_devices: 'delete_devices',
  edit_devices: 'edit_devices',
  view_reports: 'view_reports',
  view_article_overview: 'view_article_overview',
  view_supply_overview: 'view_supply_overview',
  view_operation: 'view_operation',
  view_expeditions: 'view_expeditions',
  view_handovers: 'view_handovers',
  view_pickups: 'view_pickups',
  view_supplies: 'view_supplies',
  view_tags: 'view_tags',
  view_packages: 'view_packages',
  view_distribution_unit_articles: 'view_distribution_unit_articles',
  create_distribution_unit_articles: 'create_distribution_unit_articles',
  delete_distribution_unit_articles: 'delete_distribution_unit_articles',
  edit_distribution_unit_articles: 'edit_distribution_unit_articles',
  view_currencies: 'view_currencies',
  create_currency: 'create_currency',
  delete_currency: 'delete_currency',
  edit_currency: 'edit_currency',
  view_master: 'view_master',
  edit_master: 'edit_master',
  create_location: 'create_location',
  edit_location: 'edit_location',
  delete_location: 'delete_location',
  view_drivers: 'view_drivers',
  create_drivers: 'create_drivers',
  delete_drivers: 'delete_driver',
  edit_drivers: 'edit_drivers',
  view_in_house_articles: 'view_in_house_articles',
  view_expedition_locations: 'view_expedition_locations',
  view_du_stock_level: 'view_du_stock_level',
  view_due_pickups: 'view_due_pickups',
  view_next_handover: 'view_next_handover',
  view_upcoming_handovers: 'view_upcoming_handovers',
  view_overdue_handovers: 'view_overdue_handovers',
  view_organization_tutorial: 'view_organization_tutorial',
  view_client_tutorial: 'view_client_tutorial',
  view_invoices: 'view_invoices',
  view_my_invoices: 'view_my_invoices',
  view_client_invoices: 'view_client_invoices',
  view_intents: 'view_intents',
  view_my_payment_plans: 'view_my_payment_plans',
  view_payment_plans: 'view_payment_plans',
  create_payment_plan: 'create_payment_plan',
  delete_payment_plans: 'delete_payment_plans',
  edit_payment_plans: 'edit_payment_plans',
  view_security_overview: 'view_security_overview',
  view_security_details: 'view_security_details',
  view_quota_indicator: 'view_quota_indicator',
  view_master_code: 'view_master_code',
  view_collection: 'view_collection',
  view_info: 'view_info',
  view_security: 'view_security',
  view_handover_corrections: 'view_handover_corrections',
  view_exchange_rates: 'view_exchange_rates',
  create_exchange_rate: 'create_exchange_rate',
  delete_exchange_rates: 'delete_exchange_rates',
  edit_exchange_rates: 'edit_exchange_rates',
  view_reception: 'view_reception',
  view_article_usage: 'view_article_usage',
  view_system: 'view_system',
  view_app_version: 'view_app_version',
  create_app_versions: 'create_app_versions',
  delete_app_version: 'delete_app_version',
  view_monitoring: 'view_monitoring',
  view_activity_logs: 'view_activity_logs',
  delete_activity_logs: 'delete_activity_logs',
  view_operation_menu: 'view_operation_menu',
  view_offline_locations_master: 'view_offline_locations_master',
  view_offline_locations_organization: 'view_offline_locations_organization',
  view_offline_locations: 'view_offline_locations',
  view_next_expedition: 'view_next_expedition',
  view_api_url: 'view_api_url',
};

type AccessContextValue = {
  isGranted: (attribute: string, subject?: any) => boolean;
  isNotGranted: (attribute: string, subject?: any) => boolean;
  anyIsGranted: (attributes: string[], subject?: any) => boolean;
  noneIsGranted: (attributes: string[], subject?: any) => boolean;
  allAreGranted: (attributes: string[], subject?: any) => boolean;
  notAllAreGranted: (attributes: string[], subject?: any) => boolean;
  iAmMasterAdmin: () => boolean;
  iAmOrganizationAdmin: () => boolean;
  iAmClientAdmin: () => boolean;
  iAmMasterType: () => boolean;
  iAmOrganizationType: () => boolean;
  iAmClientType: () => boolean;
};

const AccessContext = React.createContext<AccessContextValue>({} as AccessContextValue);

const AccessProvider = (props: any) => {

  const { authUser } = useAuth();

  const iAmMasterAdmin = () => authUser && authUser.isMasterAdmin();
  const iAmOrganizationAdmin = () => authUser && authUser.isOrganizationAdmin();
  const iAmDriverType = () => authUser && authUser.isDriverType();
  const iAmClientAdmin = () => authUser && authUser.isClientAdmin();
  const iAmMasterType = () => authUser && authUser.isMasterType();
  const iAmOrganizationType = () => authUser && authUser.isOrganizationType();
  const iAmClientType = () => authUser && authUser.isClientType();
  const iAmSsdClient = () => authUser && authUser.isSsdClient();
  const iAmNonSsdClient = () => authUser && authUser.isNonSsdClient();
  const iAmFreeSsdClient = () => authUser && authUser.isFreeSsdClient();
  // Higher importance role translates in lower role id, hence the operator
  const myRoleIsHigherThan = (subject: User) => authUser && authUser.accessRoleId < subject.accessRoleId;

  const isGranted = (attribute: string, subject?: User): boolean => {
    switch (attribute) {
      case perms.view_users:
      case perms.create_users:
      case perms.edit_users:
      case perms.delete_users:
        return iAmMasterType() || iAmOrganizationAdmin() || iAmClientType();

      case perms.create_organizations:
      case perms.delete_organizations:
      case perms.view_organizations:
      case perms.invite_organizations:
      case perms.create_distribution_unit:
      case perms.delete_distribution_units:
      case perms.create_devices:
      case perms.delete_devices:
      case perms.edit_devices:
      case perms.view_currencies:
      case perms.create_currency:
      case perms.delete_currency:
      case perms.edit_currency:
      case perms.view_master:
      case perms.edit_master:
      case perms.view_payment_plans:
      case perms.create_payment_plan:
      case perms.delete_payment_plans:
      case perms.edit_payment_plans:
      case perms.view_master_code:
      case perms.view_exchange_rates:
      case perms.create_exchange_rate:
      case perms.delete_exchange_rates:
      case perms.edit_exchange_rates:
      case perms.view_app_version:
      case perms.create_app_versions:
      case perms.delete_app_version:
      case perms.delete_activity_logs:
      case perms.view_offline_locations_master:
      case perms.view_api_url:
        return iAmMasterType();

      case perms.edit_own_organization:
      case perms.create_clients:
      case perms.invite_clients:
      case perms.view_articles:
      case perms.edit_articles:
      case perms.delete_articles:
      case perms.create_articles:
      case perms.view_clients:
      case perms.delete_clients:
      case perms.view_locations:
      case perms.edit_clients:
      case perms.view_article_overview:
      case perms.view_supply_overview:
      case perms.view_tags:
      case perms.view_packages:
      case perms.view_distribution_unit_articles:
      case perms.create_distribution_unit_articles:
      case perms.delete_distribution_unit_articles:
      case perms.edit_distribution_unit_articles:
      case perms.create_location:
      case perms.edit_location:
      case perms.delete_location:
      case perms.view_supplies:
      case perms.view_drivers:
      case perms.create_drivers:
      case perms.delete_drivers:
      case perms.edit_drivers:
      case perms.view_du_stock_level:
      case perms.view_due_pickups:
      case perms.view_organization_tutorial:
      case perms.view_intents:
      case perms.view_my_payment_plans:
      case perms.view_security_overview:
      case perms.view_security_details:
      case perms.view_quota_indicator:
      case perms.view_my_invoices:
      case perms.view_client_invoices:
      case perms.view_handover_corrections:
      case perms.view_reception:
      case perms.view_offline_locations_organization:
        return iAmOrganizationAdmin();

      case perms.impersonate_users:
        return iAmMasterType() || (iAmOrganizationAdmin() && myRoleIsHigherThan(subject!))

      case perms.view_devices:
      case perms.edit_organizations:
      case perms.view_distribution_units:
      case perms.edit_distribution_units:
      case perms.view_activity_logs:
      case perms.view_offline_locations:
        return iAmMasterType() || iAmOrganizationAdmin();

      case perms.view_operation:
        return iAmClientType() || iAmOrganizationType();

      case perms.view_invoices:
        return (iAmClientType() && !iAmFreeSsdClient() && !iAmNonSsdClient()) || iAmMasterType();

      case perms.view_expeditions:
        return iAmNonSsdClient() || iAmOrganizationType();

      case perms.view_expedition_locations:
      case perms.view_collection:
      case perms.view_own_organization:
        return iAmOrganizationType();

      case perms.view_own_client:
      case perms.edit_own_client:
      case perms.view_in_house_articles:
      case perms.view_client_tutorial:
      case perms.view_article_usage:
        return iAmClientType();

      case perms.view_next_handover:
      case perms.view_upcoming_handovers:
      case perms.view_overdue_handovers:
      case perms.view_handovers:
      case perms.view_pickups:
        return iAmSsdClient() || iAmFreeSsdClient();

      case perms.view_next_expedition:
        return iAmNonSsdClient() || iAmDriverType();

      case perms.view_catalog:
        return anyIsGranted([perms.view_articles, perms.view_currencies, perms.view_locations, perms.view_distribution_units, perms.view_devices, perms.view_exchange_rates]);

      case perms.view_network:
        return anyIsGranted([perms.view_users, perms.view_organizations, perms.view_clients, perms.view_currencies, perms.view_drivers, perms.view_invoices, perms.view_my_invoices, perms.view_client_invoices, perms.view_intents, perms.view_payment_plans, perms.view_next_expedition])

      case perms.view_reports:
        return anyIsGranted([perms.view_handovers, perms.view_pickups, perms.view_article_overview, perms.view_handover_corrections])

      case perms.view_operation_menu:
        return anyIsGranted([perms.view_expeditions, perms.view_supplies, perms.view_collection, perms.view_reception])

      case perms.view_info:
        return anyIsGranted([perms.view_packages, perms.view_tags]);

      case perms.view_security:
        return anyIsGranted([perms.view_security_details, perms.view_security_overview, perms.view_offline_locations_organization]);

      case perms.view_system:
        return anyIsGranted([perms.view_app_version])

      case perms.view_monitoring:
        return anyIsGranted([perms.view_activity_logs, perms.view_security, perms.view_info])

      default:
        return false;
    }
  }

  const isNotGranted = (attribute: string, subject?: any) => !isGranted(attribute, subject);

  const anyIsGranted = (attributes: string[], subject?: any) => attributes.some(attr => isGranted(attr, subject));

  const noneIsGranted = (attributes: string[], subject?: any) => !anyIsGranted(attributes, subject);

  const allAreGranted = (attributes: string[], subject?: any) => attributes.every(attr => isGranted(attr, subject));

  const notAllAreGranted = (attributes: string[], subject?: any) => !allAreGranted(attributes, subject);

  const contextValue = {
    isGranted,
    isNotGranted,
    anyIsGranted,
    noneIsGranted,
    allAreGranted,
    notAllAreGranted,
    iAmMasterAdmin,
    iAmOrganizationAdmin,
    iAmClientAdmin,
    iAmMasterType,
    iAmOrganizationType,
    iAmClientType,
  };

  return <AccessContext.Provider value={contextValue} {...props} />
}

export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;