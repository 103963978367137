import { GridPaginationModel, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { DtParams } from '@type';
import { mapNonEmptyProps, omit, valueIsEmpty } from './util';

// axios serializes only plain objects
// so any nested objects will end up as json
// but we need the filters as a query string array
// so here we do the transformation
export const flattenFilters = (params: DtParams) => {
  // clone the object to avoid changing the original
  const newParams = JSON.parse(JSON.stringify(params));
  if (newParams.filters) {
    // loop through all filters
    for (const [key, value] of Object.entries(newParams.filters)) {
      // if the filter value is not empty
      // and if the filter value is not the value of 'All' select option
      if (!valueIsEmpty(value, true)) {
        // add the filter under the root object
        // change the key so it will be decoded properly on the backend
        newParams[`filters[${key}]`] = value;
      }
    }
    // remove the filters key since it is no longer needed
    delete newParams.filters;
  }
  return newParams;
}

export const fixDtPaginationModel = (paginationModel: GridPaginationModel) => {
  // Mui DataGrid page values start from 0
  // whilte the API expects values >= 1
  // !!! do NOT change the original object because that triggers some weird state changes
  return {
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
  };
};

export const handleGridPaginationModel = (paginationModel: GridPaginationModel, prevParams: DtParams) => {
  const newModel = fixDtPaginationModel(paginationModel);
  return { ...prevParams, ...newModel };
}

export const handleGridSearchModel = (filterModel: GridFilterModel, prevParams: DtParams) => {
  let result = { ...prevParams };
  const search = !!filterModel.quickFilterValues && filterModel.quickFilterValues[0];
  if (!!search) {
    result = { ...result, search };
  } else {
    result = omit(result, 'search') as DtParams;
  }
  return result;
}

export const handleGridFilterModel = (filterModel: GridFilterModel, prevParams: DtParams) => {
  let result = { ...prevParams };
  if (!!filterModel.items.length) {
    const filters = filterModel.items.reduce((allFilters, item) => {
      return {
        ...allFilters,
        [item.field]: item.value,
      };
    }, {});
    result = {
      ...result,
      filters,
    };
  } else {
    result = omit(result, 'filters') as DtParams;
  }
  return result;
}

export const handleGridSortModel = (sortModel: GridSortModel, prevParams: DtParams) => {
  const sorting = sortModel[0];
  if (!sorting) {
    return omit(prevParams, 'sortBy', 'sortDir') as DtParams;
  } else {
    return {
      ...prevParams,
      sortBy: sorting.field,
      sortDir: sorting.sort as string,
    };
  }
}

export const getDefaultDtParams = () => ({
  pageSize: 50,
  sortBy: 'updatedTs',
  sortDir: 'desc',
} as DtParams);

export const getDefaultSearchParams = () => ({
  search: "",
} as DtParams);

export const extractDtParamsFromUrl = () => {
  // pull the query string data into an object
  let query: { [k: string]: any } = Object.fromEntries(new URLSearchParams(window.location.search));
  // we need to convert the flattened filters into a standard object
  // so prepare a new variable that will hold the filters
  const filters: { [k: string]: any } = {};
  // loop through the query params
  for (const [key, value] of Object.entries(query)) {
    const match = key.match(/filters\[(.+)\]/);
    if (!!match) {
      // this is a filter param
      // so move it from the query into the new filter variable
      delete query[key];
      if (!!value) {
        filters[match[1]] = value;
      }
    }
  }
  // replace the old query filters with the new object
  if (Object.keys(filters).length > 0) {
    if (!!filters.tags) {
      filters.tags = filters.tags.split(",").map(Number);
    }
    query.filters = filters;
  }
  // cast url params to proper type according to DtParams
  for (const param of ['page', 'pageSize']) {
    if (query.hasOwnProperty(param)) {
      query[param] = Number(query[param]);
    }
  }
  return query;
}

export const insertDtParamsIntoUrl = (params: DtParams) => {
  // pull the query string data into an object
  let query = Object.fromEntries(new URLSearchParams(window.location.search));
  // build a list of query string params to be ommited
  // basically we want to omit all the DataGrid params
  // but we want to keep other non-DataGrid-related params untouched
  const omitted = Object.keys(query).filter(key => listDtParams().includes(key) || key.startsWith('filters'));
  // remove all target params from the query string
  query = omit(query, ...omitted);
  // add the new DataGrid params to the query string
  for (const [key, value] of Object.entries(mapNonEmptyProps(params, true))) {
    if (typeof value === 'object') {
      for (const [key2, value2] of Object.entries(mapNonEmptyProps(value, true))) {
        query[`${key}[${key2}]`] = value2.toString();
      }
    } else {
      query[key] = value.toString();
    }
  }
  // compile the new url
  let newUrl = window.location.pathname;
  const newQuery = new URLSearchParams(query).toString();
  if (!!newQuery) {
    newUrl += '?' + newQuery;
  }
  // update browser history
  window.history.replaceState(null, '', newUrl);
}

const listDtParams = () => ['sortBy', 'sortDir', 'pageSize', 'page', 'search', 'filters'];