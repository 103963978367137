import * as React from 'react';
import TopBarDesktop from './TopBar/Desktop';
import TopBarMobile from './TopBar/Mobile';

const TopBar = () => {

  return <React.Fragment>
    <TopBarMobile sx={{ display: { xs: 'block', lg: 'none' } }} />
    <TopBarDesktop sx={{ display: { xs: 'none', lg: 'block' } }} />
  </React.Fragment>
}
export default TopBar;