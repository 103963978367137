import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Link, Paper, TableContainer } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "hook/redux";
import useDtUrlParams from "hook/dtUrlParams";
import PageTitleBar from "component/common/PageTitleBar";
import Dt from "component/common/Dt";
import { AppError, DtParams } from "@type";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { showError, showSuccess, toSimpleError } from "helper/util";
import { applyExchangeRateDtParams, patchExchangeRateDtParams } from "store/actions";
import { getExchangeRatesDt, pullExchangeRate } from "helper/backend";
import { insertDtParamsIntoUrl } from "helper/dt";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { useTranslation } from "react-i18next";
import ExchangeRate from "model/exchangeRate";
import { PlaylistAdd, Refresh } from "@mui/icons-material";
import ProgressButton from "component/common/ProgressButton";
import moment from "moment";

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * DataGrid params may sometimes be passed in the url
  * so here we attempt to read any params from the url
  */
  const urlParams = useDtUrlParams();

  // DataGrid rows
  const [dtRows, setDtRows] = useState([] as ExchangeRate[]);
  // DataGrid total number of rows
  const [dtRowCount, setDtRowCount] = useState(0);
  // DataGrid sorting, filtering, pagination, etc
  const dtParams = useAppSelector(store => store.ExchangeRate.DtRequest);
  // whether the loading of the DataGrid rows is in progress
  const [isDtLoadInProgress, setIsDtLoadInProgress] = useState(false);
  // DataGrid error encoutered while fetching the rows (if any)
  const [dtError, setDtError] = useState<AppError | null>(null);
  // whether the pulling of the exchange rate is in progress
  const [isPullInProgress, setIsPullInProgress] = useState(false);


  // This hook runs once on component mount
  useEffect(() => {
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }
    // update the DataGrid params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchExchangeRateDtParams(urlParams));
  }, [dispatch, urlParams]);

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    // fetch the list of DataGrid rows from the server
    setIsDtLoadInProgress(true);
    getExchangeRatesDt(dtParams)
      .then(response => {
        const rowsWithIds = response.rows.map((el: any, idx: number) => { return { ...el, id: idx } })
        setDtRows(rowsWithIds);
        setDtRowCount(response.totalCount);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      })
      .finally(() => {
        setIsDtLoadInProgress(false);
      });
    // having 'urlParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'dtParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtParams]);

  /**
* Pulls the exchange rate record in the backend
*/
  const pullDataExchangeRate = useCallback(() => {
    setIsPullInProgress(true);
    const date = moment().utcOffset(3);
    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    pullExchangeRate(date.unix())
      .then(_response => {
        showSuccess(t("exchangeRatesHasBeenPulled"));
      })
      .catch(_ex => {
        let errMessage = t("unableToPullExchangeRates");
        showError(errMessage);
      })
      .finally(() => {
        setIsPullInProgress(false);
      });
  }, [t]);

  /**
  * Saves new DataGrid params to the redux store
  * @param params
  */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyExchangeRateDtParams(params));
  }, [dispatch]);

  return <React.Fragment>
    {isGranted(perms.view_exchange_rates) && <Box>

      {/********** Page Title and Actions Toolbar **********/}
      <PageTitleBar title={t("exchangeRates")}>
        <ProgressButton variant="contained" color="primary" sx={{ mr: 1 }} startIcon={<Refresh />} isBusy={isPullInProgress} onClick={pullDataExchangeRate}>{t("pullExchangeRates")}</ProgressButton>
        <Button variant="contained" color="dark" startIcon={<PlaylistAdd />} component={Link} href={routes.new_exchange_rate}>{t("addNewExchangeRate")}</Button>
      </PageTitleBar>

      {/********** DataGrid **********/}
      <TableContainer component={Paper}>
        {!!dtError && <Alert severity="error" sx={{ mb: 1 }}>{t("unableToLoadExchangeRates")}</Alert>}
        <Dt
          rows={dtRows}
          rowCount={dtRowCount}
          columns={columns(t)}
          params={dtParams}
          isBusy={isDtLoadInProgress}
          localeText={{
            noRowsLabel: t("noExchangeRatesFound"),
            noResultsOverlayLabel: t("noExchangeRatesFound"),
          }}
          onPaginationChange={updateDtParams}
          onSearchChange={updateDtParams}
          onSortChange={updateDtParams}
        />
      </TableContainer>
    </Box>}
    {isNotGranted(perms.view_exchange_rates) && <AccessDenied />}
  </React.Fragment>
}

const columns = (t: Function) => {
  const columns: GridColDef[] = [
    {
      field: 'dateTs',
      headerName: t("date"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_exchange_rate, formatTimestamp(params.value, 'MM-DD-YYYY'), { dateTs: params.value })}>{formatTimestamp(params.value, formats.LONG_DATE)}</Link>
      },
    },
    {
      field: 'currencies',
      headerName: t("currencies"),
      flex: 1,
      minWidth: 120,
    },
  ];
  return columns
}

export default List;