import React from 'react';
import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomHeaderListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  borderRadius: '8px',
  marginLeft: '15px',
  marginRight: '15px',
  marginBottom: '4px',
  paddingTop: '10px',
  paddingBottom: '10px',
  '&.Mui-selected': {
    border: 'none',
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 167, 111, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 167, 111, 0.16)',
    },
    '& .MuiListItemIcon-root': {
      color: 'inherit',
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontWeight: '600',
      }
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
  },
}));

type Props = {
  icon: React.ReactNode;
  text: string;
  isCollapsed: boolean;
  children: any;
  onClick: any
};

const VerticalMenuHeader = ({ icon, text, isCollapsed, children, onClick }: Props) => {

  const justification = isCollapsed ? 'center' : 'flex-start';

  const style = {
    justifyContent: justification,

  };

  return <CustomHeaderListItemButton sx={{ ...style }} onClick={onClick}>
    {isCollapsed ?
      <Tooltip title={text} placement="right">
        <ListItemIcon sx={{ minWidth: 0 }}>
          {icon}
        </ListItemIcon>
      </Tooltip>
      :
      <>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ my: 0, ml: 2, '& .MuiTypography-root': { fontSize: 15, fontWeight: 400 } }} />
        {children}
      </>
    }
  </CustomHeaderListItemButton>
}

export default VerticalMenuHeader;