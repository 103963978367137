import {
  APPLY_SUPPLIES_DT_PARAMS,
  PATCH_SUPPLIES_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applySuppliesDtParams = (params: DtParams) => ({
  type: APPLY_SUPPLIES_DT_PARAMS,
  payload: { params },
});

export const patchSuppliesDtParams = (params: DtParams) => ({
  type: PATCH_SUPPLIES_DT_PARAMS,
  payload: { params },
});