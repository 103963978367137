import React from "react";
import DashboardPicture1 from 'asset/image/DashboardPicture1.png';
import DashboardPicture2 from 'asset/image/DashboardPicture2.png';
import DashboardPicture3 from 'asset/image/DashboardPicture3.png';
import DashboardPicture4 from 'asset/image/DashboardPicture4.png';
import GridContainer from "component/common/GridContainer";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  isClient?: boolean
}

const DashboardStep = ({ isClient = false }: Props) => {

  const { t } = useTranslation();

  return (
    <GridContainer sx={{ overflow: "auto", maxHeight: "80vh" }}>
      <Grid xs={12}>
        <Box sx={{ fontSize: "22px", fontWeight: 600, marginTop: "12px" }}>
          {t("dashboard")}
        </Box>
      </Grid>
      {!isClient &&
        <>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={DashboardPicture1} alt="" style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("dashboardOrganizationText1")}
            </p>
          </Grid>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={DashboardPicture2} alt="" style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("dashboardOrganizationText2")}
            </p>
          </Grid>
        </>
      }
      {
        isClient &&
        <>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "80%" }}>
              <img src={DashboardPicture3} alt="" style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("dashboardClientText1")}
            </p>
          </Grid>
          <Grid md={12} lg={6}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "80%" }}>
              <img src={DashboardPicture4} alt="" style={{ maxWidth: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
            <p>
              {t("dashboardClientText2")}
            </p>
          </Grid>
        </>
      }
    </GridContainer >
  )
};

export default DashboardStep;