import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import PageTitleBar from "component/common/PageTitleBar";
import { AppError } from "@type";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { buildOperationNumber, showError, toSimpleError } from "helper/util";
import { confirmExpedition, getNextExpeditionList } from "helper/backend";
import Operation from "model/operation";
import { useTranslation } from "react-i18next";
import Preloader from "component/common/Preloader";
import Error from 'page/Error';
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import { formatTimestamp, formats } from "helper/date";
import { useAuth } from "context/auth";
import TableNoBb from "component/styled/TableNoBb";
import GridContainer from "component/common/GridContainer";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { route, routes } from "helper/route";
import ExpeditionConfirmation from "component/common/ExpeditionConfirmation";
import { Check } from "@mui/icons-material";

const List = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  // hold the next expeditions
  const [nextExpeditions, setNextExpeditions] = useState([] as Operation[]);
  // next expedition error encoutered while fetching the rows (if any)
  const [nextExpeditionsError, setNextExpeditionsError] = useState<AppError | null>(null);
  // whether the loading of the next expeditions rows is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // whether the confirmation is visible
  const [isConfOpen, setIsConfOpen] = useState(false);
  // whether the loading of the dispatch confirmation is in progress
  const [isConfirmInProgress, setIsConfirmInProgress] = useState<boolean>(false)
  // holds the selected operation for confirmation
  const [selectedOperation, setSelectedOperation] = useState<Operation>()

  const refreshNextExpeditions = useCallback(() => {
    setIsLoadInProgress(true);
    getNextExpeditionList()
      .then(response => {
        setNextExpeditions(response.expeditions);
      })
      .catch(ex => {
        setNextExpeditionsError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [])

  /**
  * Confirms the dispatch in the backend
  */
  const handleConfirmDispatch = (values: any) => {
    setIsConfirmInProgress(true)
    confirmExpedition(values)
      .then(_response => {
        refreshNextExpeditions();
        setIsConfOpen(false);
        setSelectedOperation(undefined);
      })
      .catch(_ex => {
        showError(t("unableToConfirmDispatch"));
      })
      .finally(() => {
        setIsConfirmInProgress(false)
      })
  }

  /**
  * Opens the confirm modal
  */
  const confirmDispatch = (operation: Operation) => {
    setIsConfOpen(true);
    setSelectedOperation(operation)
  }

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    refreshNextExpeditions();
  }, [refreshNextExpeditions]);

  return <React.Fragment>
    {isGranted(perms.view_next_expedition) && <Box>

      {/********** Page Title and Actions Toolbar **********/}
      <PageTitleBar title={t("nextDispatches")} />

      {/********** List **********/}
      {
        !!nextExpeditions.length
          ?
          nextExpeditions.map((exp, idx) => {
            return (
              <EditableCard key={idx} sx={{ mt: 3 }}>
                <CardContent sx={{ ml: 2 }}>
                  <CardTitleBar title={buildOperationNumber(exp.id, exp.type, exp.createdTs)} />
                  <GridContainer>
                    <Grid xs={12} md={3}>
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                          {t("date")}:
                        </Typography>
                        <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{formatTimestamp(exp.createdTs, formats.DATETIME)}</Typography>
                      </Box>
                      {
                        authUser.isDriverType() &&
                        <Box sx={{ mt: 1 }}>
                          <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                            {t("destination")}:
                          </Typography>
                          <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{exp.destinationName}</Typography>
                        </Box>
                      }
                      {
                        authUser.isNonSsdClient() &&
                        <>
                          <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                              {t("driver")}:
                            </Typography>
                            <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{exp.driverName}</Typography>
                          </Box>
                        </>
                      }
                      {
                        !!exp.packageCount &&
                        <Box sx={{ mt: 1 }}>
                          <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                            {t("packages")}:
                          </Typography>
                          <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{exp.packageCount}</Typography>
                        </Box>
                      }
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                          {t("noOfItems")}:
                        </Typography>
                        <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{exp.itemCount}</Typography>
                      </Box>
                      {
                        authUser.isNonSsdClient() &&
                        <Box sx={{ mt: 1 }}>
                          <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "block", mr: 1 }}>
                            {t("confirmationCode")}:
                          </Typography>
                          <Typography sx={{ display: "block", fontSize: "1.4rem", pt: 1 }}>{exp.confirmationCode}</Typography>
                        </Box>
                      }
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Box>
                        <TableNoBb>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("article")}</TableCell>
                              <TableCell>{t("noOfItems")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              exp?.articles?.slice(0, 2).map((el: any, idx: number) => {
                                return (
                                  <TableRow key={idx}>
                                    <TableCell>{el.name} ({el.code})</TableCell>
                                    <TableCell>{el.items}</TableCell>
                                  </TableRow>
                                )
                              })
                            }
                            {
                              exp.articles.length > 2 &&
                              <TableRow key={3}>
                                <TableCell>... {exp.articles.length - 2} {t("moreItems")}</TableCell>
                              </TableRow>
                            }
                          </TableBody>
                        </TableNoBb>
                      </Box>
                    </Grid>
                    <Grid xs={12} md={3}>
                      <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", height: "100%", flexDirection: "row", gap: "10px" }}>
                        <Button variant="contained" color="dark" component={Link} href={route(routes.view_expedition_operation, exp.id)} className='buttonAlwaysText'>{t("moreDetails")}</Button>
                        {
                          authUser.isDriverType() && !exp.confirmedTs && !exp.packageCount && exp.type === Operation.TYPE_EXPEDITION &&
                          <Button className='buttonAlwaysText' onClick={() => confirmDispatch(exp)} variant="contained" color="primary" sx={{ mr: 1 }} startIcon={<Check />}>{t("confirm")}</Button>
                        }
                      </Box>
                    </Grid>
                  </GridContainer>
                </CardContent>
              </EditableCard>
            )
          })
          :
          <EditableCard>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                {t("noNextDispatchesFound")}
              </Box>
            </CardContent>
          </EditableCard>
      }
      {/* Expedition Confirmation Modal */}
      {
        !!selectedOperation &&
        <ExpeditionConfirmation operation={selectedOperation} isConfirmInProgress={isConfirmInProgress} setIsConfOpen={setIsConfOpen} isConfOpen={isConfOpen} handleConfirmDispatch={handleConfirmDispatch} />
      }
    </Box>
    }
    {/* Show the prealoder only on the first fetch */}
    {isLoadInProgress && !nextExpeditions && <Preloader container="content" />}
    {!!nextExpeditionsError && <Error error={nextExpeditionsError} />}
    {isNotGranted(perms.view_next_expedition) && <AccessDenied />}
  </React.Fragment >
}

export default List;