import * as Yup from "yup";
import * as regx from "constant/regex";
import i18n from "i18n";

export const passwordChecksMap = {
  MIN_CHARACTERS: i18n.t("passwordMinCharacters"),
  ONE_LOWERCASE: i18n.t("passwordOneLowerCase"),
  ONE_UPPERCASE: i18n.t("passwordOneUpperCase"),
  ONE_DIGIT: i18n.t("passwordOneDigit"),
  ONE_SPECIAL: i18n.t("passwordOneSpecial"),
};

// array with all the password checks
export const getPasswordRequirements = () => Object.values(passwordChecksMap);

// contains all validations needed for creating / updating a password
export const getPasswordValidationSchema = (changePasswd: boolean = false) => {
  let validationSchema = {
    passwd: Yup.string().trim()
      .min(10, passwordChecksMap.MIN_CHARACTERS)
      .matches(regx.oneLowercase, passwordChecksMap.ONE_LOWERCASE)
      .matches(regx.oneUppercase, passwordChecksMap.ONE_UPPERCASE)
      .matches(regx.oneSpecial, passwordChecksMap.ONE_SPECIAL)
      .matches(regx.oneDigit, passwordChecksMap.ONE_DIGIT),
    passwdConf: Yup.string().trim().required(i18n.t("fieldIsRequired"))
      .oneOf([Yup.ref('passwd')], i18n.t("passwordConfirmNoMatch")),
  };

  if (changePasswd)
    return {
      ...validationSchema,
      oldPasswd: Yup.string().trim(),
    };

  return validationSchema;
};

// set abortEarly to false to run all validators
export const validatePassword = (schema: any) =>
  (values: any) => schema.validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(({ inner }: any) => (
      // reduce all error messages for a field to an array
      inner.reduce((memo: any, { path, message }: any) => ({
        ...memo,
        [path]: (memo[path] || []).concat(message),
      }), {})
    ));
