import React, { useEffect, useState } from "react";
import { CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import DistributionUnit from "model/distributionUnit";
import SectionLocationView from "./View";
import SectionLocationEdit from "./Edit";

type Props = {
  distributionUnit: DistributionUnit;
  refreshHandler: Function;
  isLoadInProgress: boolean;
};

const SectionLocation = ({ distributionUnit, refreshHandler, isLoadInProgress }: Props) => {

  // whether the edit mode is active for this section (edit form is visible)
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // whether the edit form has just been saved and the user record is being reloaded from the backend
  const [isReloading, setIsReloading] = useState(false);

  // This hook runs every time the loading status changes
  useEffect(() => {
    if (!isLoadInProgress) {
      // the loading has finished so change the flag
      setIsReloading(false);
    }
  }, [isLoadInProgress]);

  /**
  * Event handler called whenever the user shows or hides the edit form
  * a) clicks on the edit button to edit the section
  * b) clicks on the cancel button to close the form
  * c) clicks on the save button to apply the changes
  */
  const switchEditMode = (shouldRefresh: boolean = false) => {
    setIsEditModeActive(mode => !mode);
    if (shouldRefresh) {
      // edit form has been saved so we need to refresh the data
      setIsReloading(true);
      refreshHandler();
    }
  };

  return <EditableCard>
    <CardContent>
      {!isEditModeActive && <SectionLocationView distributionUnit={distributionUnit} onEdit={switchEditMode} isReloading={isReloading} />}
      {isEditModeActive && <SectionLocationEdit distributionUnit={distributionUnit} onFinish={switchEditMode} />}
    </CardContent>
  </EditableCard>
}
export default SectionLocation;