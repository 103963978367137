import {
  APPLY_OFFLINE_LOCATIONS_DT_PARAMS,
  PATCH_OFFLINE_LOCATIONS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyOfflineLocationsDtParams = (params: DtParams) => ({
  type: APPLY_OFFLINE_LOCATIONS_DT_PARAMS,
  payload: { params },
});

export const patchOfflineLocationsDtParams = (params: DtParams) => ({
  type: PATCH_OFFLINE_LOCATIONS_DT_PARAMS,
  payload: { params },
});