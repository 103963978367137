import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { GridColDef, GridFilterItem, GridFilterModel, GridRenderCellParams } from '@mui/x-data-grid';
import { Alert, Box, Button, Link, Paper, TableContainer } from '@mui/material';
import { route, routes } from 'helper/route';
import { getPaymentPlansDt } from 'helper/backend';
import { handleGridFilterModel, insertDtParamsIntoUrl } from 'helper/dt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DtFilterControls from 'component/common/DtFilterControls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { objectIsEmpty, toSimpleError } from 'helper/util';
import { AppError, DtParams } from '@type';
import { useAppDispatch, useAppSelector } from 'hook/redux';
import { applyPaymentPlanDtParams, patchPaymentPlanDtParams } from 'store/actions';
import Dt from 'component/common/Dt';
import { perms, useAccess } from 'context/access';
import AccessDenied from 'page/Error/AccessDenied';
import PageTitleBar from 'component/common/PageTitleBar';
import useDtUrlParams from 'hook/dtUrlParams';
import { useTranslation } from 'react-i18next';
import PaymentPlan from 'model/paymentPlan';
import Filters from './Partial/Filters';
import { formatCurrency } from 'helper/currency';
import Currency from 'model/currency';

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const currency = useAppSelector(store => store.Auth.Login.user?.currency);

  /**
   * DataGrid params may sometimes be passed in the url
   * so here we attempt to read any params from the url
   */
  const urlParams = useDtUrlParams();

  // DataGrid rows
  const [dtRows, setDtRows] = useState([] as PaymentPlan[]);
  // DataGrid total number of rows
  const [dtRowCount, setDtRowCount] = useState(0);
  // DataGrid sorting, filtering, pagination, etc
  const dtParams = useAppSelector(store => store.PaymentPlan.DtRequest);
  // whether the loading of the DataGrid rows is in progress
  const [isDtLoadInProgress, setIsDtLoadInProgress] = useState(false);
  // DataGrid error encoutered while fetching the rows (if any)
  const [dtError, setDtError] = useState<AppError | null>(null);
  // whether the filters panel is expanded
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  /**
   * Filter form default values
   * These are the values the form will be reset to when you click "Clear Filters"
   * Thy should be "empty" unless you have a clear need to force default values on specific fields
   */
  const filterFormDefaultValues = useMemo(() => ({
    isPublic: Number.MIN_SAFE_INTEGER,
  }), []);

  /**
   * Filter form initial values
   * These are the values loaded into the form as the component mounts (usually from redux store)
   * Datagrid parameters are stored in redux so they survive component unmount
   * Therefore when the user returns to the same DataGrid we can restore the previous parameters
   */
  const filterFormInitialValues = useMemo(() => ({
    ...filterFormDefaultValues,
    ...dtParams.filters,
  }), [dtParams.filters, filterFormDefaultValues]);

  /**
   * Filter form validation rules
   */
  const validationSchema = useMemo(() => ({
    isPublic: Yup.number(),
  }), []);

  /**
   * Filter form configuration
   */
  const { values, errors, setValues, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filterFormInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      applyFilters(values);
    },
  });

  // This hook runs once on component mount
  useEffect(() => {
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }
    // update the DataGrid params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchPaymentPlanDtParams(urlParams));
  }, [dispatch, urlParams]);

  // This hook runs every time the DataGrid params change
  useEffect(() => {
    if (urlParams === null) {
      // abort if the url filters have not been evaluated yet
      // this is in order to avoid making 2 requests:
      // a) the initial one and b) another one after the url filters have been evaluated
      // better to wait until the url is parsed and make a single request
      return;
    }
    // fetch the list of DataGrid rows from the server
    setIsDtLoadInProgress(true);
    getPaymentPlansDt(dtParams)
      .then(response => {
        setDtRows(response.rows);
        setDtRowCount(response.totalCount);
      })
      .catch(ex => {
        setDtError(toSimpleError(ex));
      })
      .finally(() => {
        setIsDtLoadInProgress(false);
      });
    // having 'urlParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'dtParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtParams]);

  /**
   * Saves new DataGrid params to the redux store
   * @param params
   */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyPaymentPlanDtParams(params));
  }, [dispatch]);

  /**
   * Event handler called whenever the user applies new filters
   * @param values
   */
  const applyFilters = useCallback((values: any) => {
    // prepare the filters in the format expected by the DataGrid
    const filterModel = { items: [] } as GridFilterModel;
    // add all new filters
    for (const [key, value] of Object.entries(values)) {
      filterModel.items.push({ id: key, field: key, value } as GridFilterItem);
    }
    // convert the DataGrid format to DtParams
    const newParams = handleGridFilterModel(filterModel, dtParams);
    // save filters in redux store
    updateDtParams(newParams);
  }, [dtParams, updateDtParams]);

  /**
   * Event handler called whenever the user clears the filters
   */
  const clearFilters = useCallback(() => {
    // reset the form to the default values
    setValues(filterFormDefaultValues);
    // submit the form so the new filters are applied
    handleSubmit();
  }, [filterFormDefaultValues, handleSubmit, setValues]);

  /**
   * Event handler called whenever the user shows or hides the filters panel
   */
  const toggleFilters = useCallback(() => {
    setIsFiltersVisible(val => !val);
  }, []);

  return <React.Fragment>
    {isGranted(perms.view_payment_plans) && <Box>

      {/********** Page Title and Actions Toolbar **********/}
      <PageTitleBar title={t("paymentPlans")}>
        <DtFilterControls
          isFiltersVisible={isFiltersVisible}
          hasFilters={!objectIsEmpty(dtParams.filters, true)}
          onApplyFilters={handleSubmit}
          onClearFilters={clearFilters}
          onToggleFilters={toggleFilters}
          sx={{ mr: 1 }}
        ></DtFilterControls>
        <Button variant="contained" color="dark" startIcon={<PlaylistAddIcon />} component={Link} href={routes.new_payment_plan} sx={{ ml: 1 }}>{t("addNewPaymentPlan")}</Button>
      </PageTitleBar>

      {/********** Filters Panel **********/}
      {isFiltersVisible && <Filters
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        handleChange={handleChange}
        setStatus={setStatus}
      />}

      {/********** DataGrid **********/}
      <TableContainer component={Paper}>
        {!!dtError && <Alert severity="error" sx={{ mb: 1 }}>{t("unableToLoadPaymentPlans")}</Alert>}
        <Dt
          rows={dtRows}
          rowCount={dtRowCount}
          columns={columns(t, currency!)}
          params={dtParams}
          isBusy={isDtLoadInProgress}
          localeText={{
            noRowsLabel: t("noPaymentPlansFound"),
            noResultsOverlayLabel: t("noPaymentPlansFound"),
          }}
          onPaginationChange={updateDtParams}
          onSearchChange={updateDtParams}
          onSortChange={updateDtParams}
        />
      </TableContainer>
    </Box>}
    {isNotGranted(perms.view_payment_plans) && <AccessDenied />}
  </React.Fragment>
}

const columns = (t: Function, currency: Currency) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t("name"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Link href={route(routes.view_payment_plan, params.row.id)}>{params.value}</Link>
      },
    },
    {
      field: 'price',
      headerName: t("price"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return formatCurrency(params.value, currency.symbol, currency.position, currency.hasSpacing)
      }
    },
    {
      field: 'isPublic',
      headerName: t("public"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return !!params.value ? <TaskAltIcon /> : <UnpublishedIcon />
      },
    },
  ];
  return columns;
}

export default List;
