import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { routes } from 'helper/route';
import { Button, Card, CardContent, Link } from '@mui/material';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from 'component/common/GridContainer';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormCreate from './Partial/Form/Create';
import { useNavigate } from 'react-router-dom';
import { createAppVersion } from 'helper/backend';
import { isErrType, showError, showSuccess, toSimpleError } from 'helper/util';
import { ValidationException } from 'helper/error';
import ProgressButton from 'component/common/ProgressButton';
import { Check, KeyboardArrowLeft } from '@mui/icons-material';
import UploadApp from './Partial/Form/UploadApp';

type Values = {
  name: string;
  deviceType: number | undefined;
  description: string;
  app: any;
};

const New = () => {

  const { isGranted, isNotGranted } = useAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    name: '',
    deviceType: undefined,
    description: '',
    app: null,
  };

  /**
   * Form validation rules
   */
  let validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    deviceType: Yup.number().required(t("fieldIsRequired")),
    description: Yup.string().trim(),
    app: Yup.mixed().required(t("fieldIsRequired"))
  };

  /**
   * Form configuration
   */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveAppVersion(values);
    },
  });

  /**
   * Event handler called whenever the user saves the form
   */
  const saveAppVersion = (values: any) => {
    setIsSubmitInProgress(true);
    let formData = new FormData();
    if (values.app instanceof File) {
      formData.append("app", values.app);
    }
    formData.append("name", formik.values.name);
    formData.append("deviceType", values.deviceType);
    formData.append("description", values.description);
    createAppVersion(formData)
      .then(_response => {
        showSuccess(t("appVersionHasBeenSaved"));
        return navigate(routes.list_app_version);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        let errMessage = t("unableToSaveAppVersion");
        showError(errMessage);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  }


  return <React.Fragment>
    {isGranted(perms.create_app_versions) && <Box>
      <form noValidate onSubmit={formik.handleSubmit}>
        <PageTitleBar breadcrumbs={breadcrumbs(t)}>
          <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveAppVersion")}</ProgressButton>
          <Button variant="contained" color="secondary" component={Link} href={routes.list_app_version} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
        </PageTitleBar>
        <GridContainer>
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <FormCreate formik={formik} />
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} md={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <UploadApp formik={formik} />
              </CardContent>
            </Card>
          </Grid>
        </GridContainer>
      </form >
    </Box>}
    {isNotGranted(perms.create_app_versions) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (t: Function) => [{
  text: t("appVersions"),
  url: routes.list_app_version,
}, {
  text: t("newAppVersion"),
}];

export default New;
