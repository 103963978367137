import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Preloader from "component/common/Preloader";
import { getPublicKey } from "helper/backend";
import { showError } from "helper/util";
import { useTranslation } from "react-i18next";

const withStripeElements = (Component: any) => {

  const StripeElements = (props: any) => {

    const { t } = useTranslation();
    const [stripeKey, setStripeKey] = useState("");

    /**
    * Fetches the record from the backend
    */
    const fetchStripeKey = useCallback(() => {
      getPublicKey()
        .then(response => {
          setStripeKey(response.publicKey);
        })
        .catch(_ex => {
          showError(t("unableToFetchPublicKey"))
        })
    }, [t]);

    useEffect(() => {
      fetchStripeKey();
    }, [fetchStripeKey])

    const stripe = stripeKey?.length > 0 ? loadStripe(stripeKey) : null;

    const isStripeReady = () => !!stripe;

    return <React.Fragment>
      {isStripeReady() && <Elements stripe={stripe} options={props.options}>
        <Component {...props} />
      </Elements>}
      {!isStripeReady() && <Preloader container="parent" />}
    </React.Fragment>
  }

  StripeElements.propTypes = {
    options: PropTypes.object,
    onFinish: PropTypes.any,
    client: PropTypes.object,
    organization: PropTypes.object,
    isLoadInProgress: PropTypes.bool,
    refreshHandler: PropTypes.any
  }

  return StripeElements;
}

export default withStripeElements;