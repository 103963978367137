import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { AttachFile } from "@mui/icons-material";

type Props = {
  pdf?: any;
  setFieldValue: Function;
  errors: any;
  setStatus: Function;
}

const UploadFileApp = ({ setFieldValue, errors, setStatus }: Props) => {

  // Drop zone hook for drag and drop
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.android.package-archive': [],
      'application/zip': [],
      'multipart/x-zip': [],
      'application/zip-compressed': [],
      'application/x-zip-compressed': []
    },
    onFileDialogOpen: () => {
      setFieldValue("app", null);
      const formErrors = errors;
      delete formErrors['app' as keyof typeof formErrors];
      setStatus(formErrors);
    }
  });

  // Runs when an image is uploaded
  useEffect(() => {
    if (!!acceptedFiles[0]) {
      setFieldValue("app", acceptedFiles[0]);
    };
  }, [acceptedFiles, setFieldValue])

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        height: "250px",
        flexDirection: "column"
      }}
    >
      <div
        {...getRootProps({ className: "dropzone" })}
        style={
          {
            flex: 1,
            position: "relative",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: isDragAccept ? "#31ce77" : isDragReject ? "#f34943" : '#5d6575',
            borderStyle: 'dashed',
            color: '#5d6575',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            cursor: 'pointer',
            height: "100%"
          }
        }
      >
        <input {...getInputProps()} />
        {
          !!acceptedFiles[0]
            ?
            <>
              <Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "column", alignItems: "center" }}>
                <AttachFile sx={{ fontSize: "45px", color: "#33AD93" }} />
                <Typography>
                  {acceptedFiles[0].name}
                </Typography>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" }, mt: 2 }}>
                Upload
              </Box>
            </>
            :
            <>
              <Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "column", alignItems: "center" }}>
                <FileUploadIcon fontSize="large" />
                <Typography>
                  Drag and Drop here
                </Typography>
                <Typography>
                  or
                </Typography>
                <Typography sx={{ color: "#33AD93" }}>
                  Browse files
                </Typography>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" }, mt: 2 }}>
                Upload
              </Box>
            </>
        }
      </div>
      {
        !!errors.app &&
        <Box sx={{ color: "#f34943", fontSize: "14px" }}>{errors.app}</Box>
      }
    </Box>
  )
}

export default UploadFileApp;