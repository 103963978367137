import { combineReducers } from 'redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  APPLY_TAG_PARAMS,
  PATCH_TAG_PARAMS
} from './actionTypes';
import { TagRequestActionPayload } from './types';
import { DtParams } from '@type';
import { getDefaultSearchParams } from 'helper/dt';

/********** LOGIN **********/

const defaultDtParamsState: DtParams = getDefaultSearchParams();

const SearchRequest = (state = defaultDtParamsState, action: PayloadAction<TagRequestActionPayload>): DtParams => {
  switch (action.type) {
    case APPLY_TAG_PARAMS:
      return {
        ...action.payload.params
      };
    case PATCH_TAG_PARAMS:
      return {
        ...state,
        ...action.payload.params,
      }
    default:
      return state;
  }
}

export default combineReducers({
  SearchRequest,
});
