import { LocalException } from './error';
import { isObject, valueIsEmpty } from './util';

export const routes = {
  home: '/',
  login: '/login',
  lost_password: '/lost-password',
  reset_password: '/reset-password/:token',
  activate_account: '/activate-account/:token',
  verify_email: '/verify-email/:token',
  my_account: '/me',
  change_password: '/my/password',
  register_organization: '/register/organization/:token',
  register_client: '/register/client/:token',
  self_invite_client: '/client/invite/:organizationUuid',

  list_organizations: '/organizations',
  view_organization: '/organization/:id',
  new_organization: '/organization',
  new_organization_user: '/organization/:id/user',
  my_organization: '/my-organization',
  invite_organization: '/invite-organization',
  list_users: '/users',
  view_user: '/user/:id',
  new_user: '/user',
  list_articles: '/articles',
  view_article: '/article/:id',
  new_article: '/article',
  list_clients: '/clients',
  view_client: '/client/:id',
  new_client: '/client',
  new_client_user: '/client/:id/user',
  my_client: '/my-client',
  invite_client: '/invite-client',
  list_distribution_units: '/distribution-units',
  view_distirbution_unit: '/distribution-unit/:id',
  new_distribution_unit: '/distribution-unit',
  new_distribution_unit_device: '/distribution-unit/:id/device',
  new_distribution_unit_article: '/distribution-unit/:id/article',
  view_distribution_unit_article: '/distribution-unit/:duId/article/:articleId',
  list_locations: '/locations',
  view_location: '/location/:id',
  view_du_device: '/distribution-unit/:duId/device/:deviceId',
  view_device_reader: '/device/:deviceId/reader/:readerId',
  view_du_device_reader: '/distribution-unit/:duId/reader/:readerId',
  new_standalone_device_reader: 'device/:id/reader',
  new_du_device_reader: '/distribution-unit/:duId/device/:id/reader',
  list_devices: '/devices',
  new_device: '/device',
  view_standalone_device: '/device/:id',
  list_pickups: '/pickups',
  list_handovers: '/handovers',
  list_article_overview: '/overview-article',
  list_supply_overview: '/overview-supply',
  view_pickup_operation: '/pickup/:id/',
  view_handover_operation: '/handover/:id/',
  view_expedition_operation: '/dispatch/:id/',
  view_supplies_operation: '/supplies/:id',
  view_collection_operation: '/collection/:id',
  view_reception_operation: '/reception/:id',
  list_packages: '/packages',
  list_tags: '/tags',
  list_currencies: '/currencies',
  new_currency: '/currency',
  view_currency: '/currency/:id',
  my_billing: '/billing',
  new_location: '/location',
  list_drivers: '/drivers',
  new_driver: '/driver',
  view_driver: '/driver/:id',
  list_expeditions: '/dispatches',
  list_supplies: '/supplies',
  list_in_house_articles: '/in-house-articles',
  master_settings: '/master-settings',
  list_invoices: '/invoices',
  view_invoice: '/invoice/:id',
  list_client_invoices: '/invoice-clients',
  view_client_invoice: '/invoice-clients/:id',
  list_my_invoice: '/my-invoices',
  view_my_invoice: '/my-invoices/:id',
  list_intents: '/intents',
  view_intent: '/intent/:id',
  payment_plans: '/me/payment-plans',
  list_payment_plans: '/payment-plans',
  new_payment_plan: '/payment-plan',
  view_payment_plan: '/payment-plan/:id',
  list_security_overview: '/security-overview',
  list_security_details: '/security-details',
  logout: '/logout',
  list_collection: '/collection',
  list_handover_corrections: '/handover-corrections',
  list_exchange_rates: '/exchange-rates',
  new_exchange_rate: '/exchange-rate',
  view_exchange_rate: '/exchange-rate/:date',
  login_du: '/login/du/:id',
  list_reception: '/reception',
  list_app_version: '/app-versions',
  new_app_version: '/app-version',
  view_app_version: '/app-version/:id',
  list_activity_logs: '/activity-logs',
  view_activity_log: '/activity-log/:id',
  list_offline_locations: '/offline-locations',
  list_next_expeditions: '/next-dispatches'
};

export const route = (route: string, params?: any, query?: any) => {
  if (!Object.values(routes).includes(route)) {
    throw new LocalException(`Unknown route '${route}'`);
  }
  if (!valueIsEmpty(params)) {
    if (Array.isArray(params)) {
      params.forEach(param => {
        // handle optional params
        if (valueIsEmpty(param)) {
          route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
        } else {
          route = route.replace(new RegExp(':([a-zA-Z]+)'), param);
        }
      });
    } else if (isObject(params)) {
      for (let key in params) {
        // handle optional params
        if (valueIsEmpty(params[key])) {
          route = route.replace(new RegExp(`/:${key}`), '');
        } else {
          route = route.replace(new RegExp(`:${key}`), params[key]);
        }
      }
    } else {
      // handle optional params
      if (valueIsEmpty(params)) {
        route = route.replace(new RegExp('/:([a-zA-Z]+)'), '');
      } else {
        route = route.replace(new RegExp(':([a-zA-Z]+)'), params);
      }
    }
  }
  if (query) {
    if (isObject(query)) {
      let parts = [];
      for (let key in query) {
        if (Array.isArray(query[key])) {
          query[key].forEach((value: string) => {
            parts.push(`${key}[]=${value}`);
          });
        } else if (isObject(query[key])) {
          for (let key2 in query[key]) {
            parts.push(`${key}[${key2}]=${query[key][key2]}`);
          }
        } else {
          parts.push(`${key}=${query[key]}`);
        }
      }
      route += `?${parts.join('&')}`;
    } else {
      route += `?${query}`;
    }
  }
  return route;
};