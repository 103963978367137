
export default class Location {
  id!: number;
  organizationId!: number;
  clientId!: number | null;
  clientName?: string | null;
  distributionUnitId!: number | null;
  distributionUnitName?: string | null;
  name!: string;
  type!: number;
  createdTs!: number;
  updatedTs!: number;

  static TYPE_DISTRIBUTION_UNIT = 1;
  static TYPE_CLIENT = 2;
  static TYPE_USER = 3;
  static TYPE_CUSTOM = 4;

  static LocationTypes = [
    {
      id: this.TYPE_DISTRIBUTION_UNIT,
      name: "Distribution unit"
    },
    {
      id: this.TYPE_CLIENT,
      name: "Client"
    },
    {
      id: this.TYPE_USER,
      name: "Driver"
    },
    {
      id: this.TYPE_CUSTOM,
      name: "Custom"
    }
  ];

  static getTypeName(id: number) {
    return this.LocationTypes.find((locationType) => locationType.id === id)?.name
  }
}