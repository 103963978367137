import React from "react";
import { CardContent, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import DistributionUnit from "model/distributionUnit";
import { useTranslation } from "react-i18next";
import EditableCard from "component/styled/EditableCard";

type Props = {
  distributionUnit: DistributionUnit;
};

const SectionAddress = ({ distributionUnit }: Props) => {

  const { t } = useTranslation();

  return <React.Fragment>
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("address")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell className="preview-value">{distributionUnit.address}</TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  </React.Fragment >

};

export default SectionAddress;