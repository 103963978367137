import React from "react";
import { Box, CardContent } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import Operation from "model/operation";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

type Props = {
  operation: Operation;
}

const QrCodeDetails = ({ operation }: Props) => {

  const { t } = useTranslation();

  return (
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("qrCode")} />
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "12px" }}>
          <Box
            sx={{
              mt: 2,
              width: { xs: "100%", lg: "325px" },
              height: { xs: "100%", lg: "325px" },
            }}>
            <QRCode
              id="qr-code"
              value={`${operation.id}_${operation.type}` || ""}
              style={{
                height: "225px",
                width: "100%"
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </EditableCard>
  )
}

export default QrCodeDetails;