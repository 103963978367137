import React, { FocusEvent, useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Button, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import ProgressButton from "component/common/ProgressButton";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ValidationException } from "helper/error";
import { getOrganizationList, updateDu } from "helper/backend";
import DistributionUnit from "model/distributionUnit";
import Organization from "model/organization";
import { useAuth } from "context/auth";
import AccessRole from "model/accessRole";
import { useTranslation } from "react-i18next";
import MuiAutocomplete from "component/common/MuiAutocomplete";

type Values = {
  name: string,
  organizationId: number | undefined
};

type Props = {
  distributionUnit: DistributionUnit;
  onFinish: Function;
};

const SectionInfoEdit = ({ distributionUnit, onFinish }: Props) => {

  const { authUser } = useAuth();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    name: '',
    organizationId: undefined,
  };
  formInitialValues = {
    ...formInitialValues,
    name: distributionUnit.name!,
    organizationId: distributionUnit.organizationId!
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    organizationId: Yup.number().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldError, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDu(values);
    },
  });

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    if (authUser.accessRoleId === AccessRole.ID_MASTER_ADMIN)
      // fetch the list of organizations
      fetchOrganizations();
  }, [fetchOrganizations, authUser.accessRoleId]);

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDu = (values: any) => {
    setIsSubmitInProgress(true);
    updateDu(distributionUnit.id!, values)
      .then(_response => {
        showSuccess(t("distributionUnitHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDistributionUnit"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Converts the list of organizations to select options
  */
  const getOrganizationOptions = () => organizations.map(organization => ({ label: organization.companyName, value: organization.id }));

  /**
  * Returns the selected organization as a select option
  */
  const getSelectedOrganizationOption = () => getOrganizationOptions().find(option => option.value === values.organizationId) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar title={distributionUnit.name!} sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveDistributionUnit")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12} md={6}>
          <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
        </Grid>
        {authUser.accessRoleId === AccessRole.ID_MASTER_ADMIN &&
          <Grid xs={12} md={6}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('organizationId', selectedOption?.value);
              }}
              onFocus={_e => {
                onSelectFieldFocused('organizationId');
              }}
              value={getSelectedOrganizationOption()}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getOrganizationOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
            />
          </Grid>
        }
      </GridContainer>
    </form>
  )
};

export default SectionInfoEdit;