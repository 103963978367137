import React, { FocusEvent } from "react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";

const FormCreate = ({ formik }: any) => {
  const { values, errors, setStatus, handleChange } = formik;

  const { t } = useTranslation();

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
      </Grid>
    </GridContainer >
  )
}

export default FormCreate;