import React, { useState, FocusEvent, useCallback } from "react";
import { Button, TextField, Autocomplete } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateCurrency } from "helper/backend";
import { ValidationException } from "helper/error";
import { getYesNoOptions, isErrType, nullsToEmptyStrings, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import Currency from "model/currency";
import { useTranslation } from "react-i18next";

type Values = {
  name: string;
  code: string;
  symbol: string;
  position: number;
  hasSpacing: number;
  decimals: number;
};

type Props = {
  currency: Currency;
  onFinish: Function;
};

const SectionInfoEdit = ({ currency, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    name: "",
    code: "",
    symbol: '',
    position: Number.MIN_SAFE_INTEGER,
    hasSpacing: Number.MIN_SAFE_INTEGER,
    decimals: Number.MIN_SAFE_INTEGER
  };
  formInitialValues = {
    ...formInitialValues,
    ...nullsToEmptyStrings(currency),
    hasSpacing: currency.hasSpacing ? 1 : 0
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    code: Yup.string().trim().required(t("fieldIsRequired")),
    symbol: Yup.string().trim().required(t("fieldIsRequired")),
    position: Yup.number().positive(t("fieldIsRequired")),
    hasSpacing: Yup.number().min(0).required(t("fieldIsRequired")),
    decimals: Yup.number().min(0, "You must select a value").required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveCurrency(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveCurrency = (values: any) => {
    setIsSubmitInProgress(true)
    updateCurrency(currency.id!, values)
      .then(_response => {
        showSuccess(t("currencyHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveCurrency"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Converts the list of positions to select options
  */
  const getPositionOptions = () => Currency.PositionTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected position types as a select option
  */
  const getSelectedPositionOption = () => getPositionOptions().find(option => option.value === values.position) || null;

  /**
  * Converts the list of positions to select options
  */
  const getDecimalOptions = () => Currency.DecimalTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected position types as a select option
  */
  const getSelectedDecimalOption = () => getDecimalOptions().find(option => option.value === values.decimals) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={currency.name!} sx={{ mb: 4 }} >
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveGeneralInfo")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="code" label={t("code")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.code} error={!!errors.code} helperText={errors.code} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="symbol" label={t("symbol")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.symbol} error={!!errors.symbol} helperText={errors.symbol} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('position', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('position');
          }}
          value={getSelectedPositionOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getPositionOptions()}
          renderInput={(params: any) => <TextField {...params} name="position" label={t("position")} error={!!errors.position} helperText={errors.position} inputProps={{ ...params.inputProps }} />}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('hasSpacing', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('hasSpacing');
          }}
          value={getYesNoOptions().find(option => option.value === values.hasSpacing) || null}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getYesNoOptions()}
          renderInput={(params: any) => <TextField {...params} name="spacing" label={t("spacing")} error={!!errors.hasSpacing} helperText={errors.hasSpacing} inputProps={{ ...params.inputProps }} />}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('decimals', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('decimals');
          }}
          value={getSelectedDecimalOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getDecimalOptions()}
          renderInput={(params: any) => <TextField {...params} name="decimals" label={t("decimals")} error={!!errors.decimals} helperText={errors.decimals} inputProps={{ ...params.inputProps }} />}
        />
      </Grid>
    </GridContainer>
  </form>
}

export default SectionInfoEdit;
