import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { deleteIntent, getIntent } from "helper/backend";
import { showError, showSuccess, toSimpleError } from "helper/util";
import { useNavigate, useParams } from "react-router-dom";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import { formatTimestamp, formats } from "helper/date";
import { useTranslation } from "react-i18next";
import Intent from "model/intent";
import { routes } from "helper/route";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { useAppSelector } from "hook/redux";
import { formatCurrency } from "helper/currency";
import ConfirmDialog from "component/common/ConfirmDialog";
import ProgressButton from "component/common/ProgressButton";

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currency = useAppSelector(store => store.Auth.Login.user?.currency);

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the intent record fetched from the backend
  const [intent, setIntent] = useState<Intent | undefined>()
  // error encoutered while fetching the intent (if any)
  const [intentError, setIntenterror] = useState<AppError | undefined>();
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the Du is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  // whether the loading of the operation is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the operation record from the backend
  */
  const refreshIntent = useCallback(() => {
    setIsLoadInProgress(true)
    getIntent(recordId)
      .then(response => {
        setIntent(response.intent);
      })
      .catch(ex => {
        setIntenterror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  /**
  * Deletes the intent record in the backend
  */
  const removeIntent = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteIntent(recordId)
      .then(_response => {
        showSuccess(t("intentHasBeenDeleted"));
        return navigate(routes.list_intents);
      })
      .catch(_ex => {
        let errMessage = t("unableToDeleteIntent");
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshIntent();
  }, [refreshIntent]);

  return (
    <>
      {isGranted(perms.view_intents)
        &&
        <>
          {
            !!intent
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(intent, t)}>
                <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteIntent")}</ProgressButton>
                <Button variant="contained" color="secondary" component={Link} href={routes.list_intents} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={6}>
                  <EditableCard sx={{ height: '100%' }}>
                    <CardContent>
                      <CardTitleBar title={t("generalInfo")} />
                      <TableNoBb>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("client")}</TableCell>
                            <TableCell className="preview-value">{intent.clientName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("type")}</TableCell>
                            <TableCell className="preview-value">{Intent.getTypeName(intent.type)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell className="preview-value">{formatTimestamp(intent.createdTs, formats.DATETIME)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("amount")}</TableCell>
                            <TableCell className="preview-value">{formatCurrency(intent.totalAmount, currency?.symbol!, currency?.position!, currency?.hasSpacing!)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("stripeId")}</TableCell>
                            <TableCell className="preview-value">{intent.stripeId}</TableCell>
                          </TableRow>
                        </TableBody>
                      </TableNoBb>
                    </CardContent>
                  </EditableCard>
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeIntent();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteIntentConfirmation")} ?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !intent && <Preloader container="content" />}
          {!!intentError && <Error error={intentError} title404={t("intentNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_intents) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (intent: Intent, t: Function) => [{
  text: t("intents"),
  url: routes.list_intents,
}, {
  text: formatTimestamp(intent.createdTs, formats.DATETIME),
}];

export default Single;