import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import DistributionUnit from "model/distributionUnit";
import Maps from "component/map/Map";
import { LatLng } from "leaflet";
import MapPin from "component/map/MapPin";
import { useTranslation } from "react-i18next";

type Props = {
  distributionUnit: DistributionUnit;
  onEdit: Function;
  isReloading: boolean;
};

const SectionLocationView = ({ distributionUnit, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("geolocation")}>
      {isGranted(perms.edit_distribution_units) &&
        (!!distributionUnit.latitude && !!distributionUnit.longitude
          ?
          <IconButton onClick={() => onCancel()}>
            <Edit />
          </IconButton>
          :
          <IconButton onClick={() => onCancel()}>
            <Add />
          </IconButton>
        )
      }
    </CardTitleBar>
    {!!distributionUnit.latitude && !!distributionUnit.longitude
      ?
      <Maps
        defaultMapPosition={new LatLng(distributionUnit.latitude, distributionUnit.longitude)}
        zoom={14}
        zoomControl={false}
      >
        <MapPin
          isDraggable={false}
          locationCoord={distributionUnit}
          locationInfo={distributionUnit.name}
        />
      </Maps>
      :
      <Typography>{t("noLocationProvided")}</Typography>
    }
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >
};

export default SectionLocationView;