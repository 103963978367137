import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import { injectStore } from 'helper/api';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContext from 'context/auth';
import AccessContext from 'context/access';
import OnboardingContext from 'context/onboarding';
import SocketContext from 'context/socket';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import SnackbarCloseButton from 'component/common/SnackbarCloseButton';
import './i18n';

injectStore(store);

const snackbarAction = (snackbarKey: SnackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store} >
    <AuthContext>
      <AccessContext>
        <SocketContext>
          <OnboardingContext>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} action={snackbarAction}>
                <App />
              </SnackbarProvider>
            </ThemeProvider>
          </OnboardingContext>
        </SocketContext>
      </AccessContext>
    </AuthContext>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
