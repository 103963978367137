
//Function to download the QR Code (Please note that the QR Code should not have margins added)
export const downloadQrCode = async (svg: HTMLElement) => {
  const svgData = new XMLSerializer().serializeToString(svg)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D
  const img = new Image()
  img.onload = function () {
    canvas.width = img.width + 40
    canvas.height = img.height + 40
    ctx.strokeRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = "#FFFFFF"
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(img, 20, 20)
    const pngFile = canvas.toDataURL("image/png", 1.0)

    const downloadLink = document.createElement("a")
    downloadLink.download = "qrcode"
    downloadLink.href = `${pngFile}`
    downloadLink.target = "_blank"
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    downloadLink.remove()
  }

  img.src = "data:image/svg+xml;base64," + window.btoa(svgData)
}
