import { createTheme } from '@mui/material/styles';
import LinkBehavior from 'component/common/LinkBehavior';
import { LinkProps } from '@mui/material/Link';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          color: "#33AD93",
          fontWeight: 400,
          textDecoration: "none",
          //Place ... if the link text is too long
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xxl',
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& .MuiListItemText-primary": {
            fontSize: "0.9rem"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.15rem",
          fontSize: ".9rem",
          fontWeight: 500,
          textTransform: "none",
          boxShadow: "none",
          color: "#FFFFFF",
          '@media (max-width:550px)': {
            fontSize: 0,
            "& .MuiButton-startIcon": {
              margin: "2px"
            }
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#343b4a",
          color: "rgba(255,255,255,.8)",
          fontWeight: 400
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          marginRight: 10,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#33AD93',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: "rgb(223, 223, 223)",
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
          },
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0",
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        sx: {
          fontSize: "0.9rem"
        }
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "0.9rem"
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "0.9rem"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: "45px",
          padding: 0,
          fontSize: "15px",
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          color: "#c8cbcd"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    },
  },
  palette: {
    primary: {
      main: '#33AD93',
    },
    secondary: {
      main: '#6c757d',
    },
    success: {
      main: '#31ce77',
    },
    error: {
      main: '#f34943',
    },
    warning: {
      main: '#fbcc5c',
      light: '#fef1d1',
    },
    info: {
      main: '#35b8e0',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#414d5f',
      contrastText: '#ffffff',
    },
    light: {
      main: '#f1f5f7',
      contrastText: '#000000',
    },
    purple: {
      main: '#6b5eae',
      contrastText: '#ffffff',
    },
    black: {
      main: '#000000',
      light: '#000000',
      dark: '#000000',
      contrastText: 'rgba(0, 0, 0, 1)',
    },
    white: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
  },
  ktsd: {

  },
});

export default theme;