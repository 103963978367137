import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Organization from 'model/organization';
import { getOrganizationList } from 'helper/backend';
import User from 'model/user';
import AccessRole from 'model/accessRole';
import { useTranslation } from 'react-i18next';
import MuiAutocomplete from 'component/common/MuiAutocomplete';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  authUser: User;
};

const Filters = ({ values, errors, setStatus, handleChange, setFieldValue, setFieldError, authUser }: Props) => {

  const { t } = useTranslation();

  // list of organizations that populate the select field
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchOrganizations = useCallback(() => {
    getOrganizationList()
      .then(response => {
        setOrganizations(response.organizations);
      })
      .catch(_ex => {
        setFieldError('organizationId', t("unableToLoadOrganizations"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    if (authUser.accessRoleId === AccessRole.ID_MASTER_ADMIN)
      fetchOrganizations();
  }, [fetchOrganizations, authUser.accessRoleId]);

  /**
  * Converts the list of organizations to select options
  */
  const getOrganizationOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = organizations.map(org => ({ label: org.companyName, value: org.id }));
    options.push(...orgs);
    return options;
  }, [organizations, t]);

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
          </Grid>
          {authUser.accessRoleId === AccessRole.ID_MASTER_ADMIN &&
            <Grid xs={12} md={3}>
              <MuiAutocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('organizationId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('organizationId');
                }}
                value={getOrganizationOptions().find(option => option.value === values.organizationId)}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getOrganizationOptions()}
                renderInput={(params: any) => <TextField {...params} label={t("organization")} error={!!errors.organizationId} helperText={errors.organizationId} />}
              />
            </Grid>
          }
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;