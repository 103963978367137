import {
  APPLY_ARTICLE_OVERVIEW_DT_PARAMS,
  PATCH_ARTICLE_OVERVIEW_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyArticleOverviewDtParams = (params: DtParams) => ({
  type: APPLY_ARTICLE_OVERVIEW_DT_PARAMS,
  payload: { params },
});

export const patchArticleOverviewDtParams = (params: DtParams) => ({
  type: PATCH_ARTICLE_OVERVIEW_DT_PARAMS,
  payload: { params },
});