import React, { FocusEvent, useState } from "react";
import { Box, Button, Card, CardContent, CircularProgress, Link, TextField, Typography } from "@mui/material";
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import logo from "asset/image/logo.png"
import env from "env";
import { routes } from "helper/route";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { forgotPass } from "helper/backend";
import { UNABLE_SEND_PASSWORD_RESET_MAIL, USER_NOT_FOUND } from "helper/error";
import { ForgotPassRequest } from "@type/backend";
import background from 'asset/image/bgPattern.png';

const LostPassword = () => {

  const [loadingPasswordReset, setLoadingPasswordReset] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const formInitialValues = {
    email: "",
  }

  const forgotYourPassword = async (values: ForgotPassRequest) => {
    setLoadingPasswordReset(true)
    try {
      await forgotPass(values)
      setEmailSent(true)
      setLoadingPasswordReset(false)
    } catch (error: any) {
      const errors = { email: "" };

      if (error.code === USER_NOT_FOUND) {
        errors.email = "User not found";
      } else if (error.code === UNABLE_SEND_PASSWORD_RESET_MAIL) {
        errors.email = "Unable to initiate password recovery"
      }
      formik.setErrors(errors)
      setLoadingPasswordReset(false)
    }
  }

  const onFieldFocused = (e: FocusEvent<HTMLInputElement>, fieldName?: string) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name as keyof typeof errors];
    formik.setStatus(errors);
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      email: Yup.string().trim().required('Field is required').email('Invalid email address'),
    }),
    onSubmit: values => {
      forgotYourPassword(values)
    },
  });

  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <Card sx={{ maxWidth: 450 }}>
        <CardContent sx={{ p: 3, '&:last-child': { pb: 3 } }}>
          <Box sx={{ height: 100, display: "flex", justifyContent: "center", mb: 8 }}>
            <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </Box>
          {
            emailSent
              ?
              <>
                <Box sx={{ textAlign: 'center' }}>
                  <CheckCircleOutlined color="success" sx={{ fontSize: "60px" }} />
                  <Typography variant="h5" display="block" sx={{ mb: 1 }}>Email Sent</Typography>
                  <Typography display="block">Instructions to reset your password have been sent to the specified email address</Typography>
                  <Button variant="contained" size="large" component={Link} href={routes.home} sx={{ width: '100%', mt: 5 }}>Sign In</Button>
                </Box>
              </>
              :
              <>
                <Typography display="block" sx={{ mb: 3, textAlign: "center" }}>
                  Enter your email address and we'll send you an email with instructions to reset your password.
                </Typography>
                <Box sx={{ margin: '0 auto' }}>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <TextField
                      name="email"
                      label="Email Address"
                      variant="outlined"
                      required
                      onChange={formik.handleChange}
                      onFocus={onFieldFocused}
                      value={formik.values.email}
                      error={!!formik.errors.email}
                      helperText={formik.errors.email}
                      sx={{ width: '100%', mb: 3 }}
                    />
                    <Button
                      type="submit"
                      className='buttonAlwaysText'
                      variant="contained"
                      size="large"
                      disabled={loadingPasswordReset}
                      sx={{ width: '100%' }}
                    >
                      {loadingPasswordReset && <CircularProgress color="white" size={18} sx={{ marginRight: 2 }} />}
                      Send Email
                    </Button>
                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                      <Link href={routes.login} sx={{ fontSize: "1rem", color: "#98a6ad" }}>Back to Sign In</Link>
                    </Box>
                  </form>
                </Box>
              </>
          }
        </CardContent>
      </Card>
      <Box sx={{ textAlign: 'center', mt: 3, color: "#98a6ad" }}>
        <Typography variant="caption" display="block">
          © {new Date().getFullYear()} {env.APP_TITLE} by <a href="https://www.codeadept.ro" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#33AD93", fontWeight: 600 }}>CodeAdept</a>
        </Typography>
      </Box>
    </Box>
  )
}

export default LostPassword;