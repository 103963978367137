export default class Operation {
  id!: number;
  date!: number;
  locationName!: string;
  itemCount!: number;
  type!: number;
  createdTs!: number;
  handoverByTs!: number;
  handoverByDays!: number;
  overdueDays!: number;
  penalty!: number;
  confirmedTs!: number;
  confirmationCode!: number;
  driverName!: string;
  driverId!: number;
  locationId!: number;
  destinationName!: string;
  destinationId!: number;
  items!: any;
  articles!: any;
  packageCount!: number;
  isConfirmedByClient!: number;

  static TYPE_EXPEDITION = 1;
  static TYPE_RECEPTION = 2;
  static TYPE_COLLECTION = 3;
  static TYPE_PICKUP = 4;
  static TYPE_HANDOVER = 5;
  static TYPE_SUPPLY = 6;

  static OperationTypes = [
    {
      id: this.TYPE_EXPEDITION,
      name: "Dispatch"
    },
    {
      id: this.TYPE_RECEPTION,
      name: "Reception"
    },
    {
      id: this.TYPE_COLLECTION,
      name: "Collection"
    },
    {
      id: this.TYPE_PICKUP,
      name: "Pickup"
    },
    {
      id: this.TYPE_HANDOVER,
      name: "Handover"
    },
    {
      id: this.TYPE_SUPPLY,
      name: "Supplies"
    }
  ];

  static getTypeName(id: number) {
    return this.OperationTypes.find((operationType) => operationType.id === id)?.name
  }

}