import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import CardTitleBar from 'component/common/CardTitleBar';
import TableNoBb from "component/styled/TableNoBb";
import { perms, useAccess } from "context/access";
import { Edit } from "@mui/icons-material";
import Preloader from "component/common/Preloader";
import { useTranslation } from "react-i18next";
import { MasterSettings } from "@type/backend";
import { cutout } from "helper/util";

type Props = {
  settings: MasterSettings;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ settings, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("stripe")}>
      {isGranted(perms.edit_master) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("publicKey")}</TableCell>
          <TableCell className="preview-value">{settings.stripePublicKey ? cutout(settings.stripePublicKey, 8, 5) : "--"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("secretKey")}</TableCell>
          <TableCell className="preview-value">{settings.stripeSecretKey || "--"}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionInfoView;