import React, { useState, FocusEvent } from 'react';
import { Autocomplete, Button, Link, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from 'component/common/CardTitleBar';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { isErrType, showError, showSuccess, toSimpleError } from 'helper/util';
import { CLIENT_USERS_LIMIT_REACHED, ValidationException } from 'helper/error';
import { getTimezoneOptions } from 'helper/date';
import ProgressButton from 'component/common/ProgressButton';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { route, routes } from 'helper/route';
import { createUser } from 'helper/backend';
import AccessRole from 'model/accessRole';
import { useTranslation } from 'react-i18next';

type Values = {
  firstName: string;
  lastName: string;
  email: string;
  timezone: string | undefined;
  clientId: number;
  accessRoleId: number;
  organizationId: number;
};

type Props = {
  clientId: number;
  organizationId: number;
};

const FormCreateClientUser = ({ clientId, organizationId }: Props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    firstName: '',
    lastName: '',
    email: '',
    timezone: undefined,
    clientId: clientId,
    accessRoleId: AccessRole.ID_CLIENT_ADMIN,
    organizationId: organizationId
  };

  /**
  * Form validation rules
  */
  const validationSchema: any = {
    firstName: Yup.string().trim().required(t("fieldIsRequired")),
    lastName: Yup.string().trim().required(t("fieldIsRequired")),
    email: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    timezone: Yup.string().trim().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, setFieldValue, setFieldError, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveUser(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveUser = (values: any) => {
    setIsSubmitInProgress(true);
    createUser(values)
      .then(_response => {
        showSuccess(t("userHasBeenSaved"));
        return navigate(`/client/${clientId}`)
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        let errMessage = t("unableToSaveUser");
        switch (err.code) {
          case CLIENT_USERS_LIMIT_REACHED:
            errMessage = t("userLimitReached");
            break;
        }
        showError(errMessage);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Returns the selected timezone as a select option
  */
  const getSelectedTimezoneOption = () => getTimezoneOptions().find(option => option.value === values.timezone) || null;

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar sx={{ mb: 4 }}>
      <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveUser")}</ProgressButton>
      <Button variant="contained" color="secondary" component={Link} href={route(routes.view_client, clientId)} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="firstName" label={t("firstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.firstName} error={!!errors.firstName} helperText={errors.firstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="lastName" label={t("lastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.lastName} error={!!errors.lastName} helperText={errors.lastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="email" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('timezone', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('timezone');
          }}
          value={getSelectedTimezoneOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getTimezoneOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("timezone")} error={!!errors.timezone} helperText={errors.timezone} inputProps={{ ...params.inputProps, autoComplete: 'new-timezone' }} />}
        />
      </Grid>
    </Grid>
  </form>
};

export default FormCreateClientUser;