import {
  APPLY_EXPEDITIONS_DT_PARAMS,
  PATCH_EXPEDITIONS_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyExpeditionDtParams = (params: DtParams) => ({
  type: APPLY_EXPEDITIONS_DT_PARAMS,
  payload: { params },
});

export const patchExpeditionDtParams = (params: DtParams) => ({
  type: PATCH_EXPEDITIONS_DT_PARAMS,
  payload: { params },
});