import React from 'react';
import { Navigate } from 'react-router-dom';
import { route, routes } from 'helper/route';
import { useAuth } from 'context/auth';

type Props = {
  children: any;
  isPublic: boolean;
  isStrict?: boolean | undefined;
};

const Authmiddleware = ({ children, isPublic, isStrict }: Props) => {

  const { isLoggedIn } = useAuth();

  if (!isPublic && !isLoggedIn()) {
    // this route is protected and the user is not logged in
    // so redirect to login
    // keep this if we want to return user on the last page encodeURIComponent(window.location.href)
    return <Navigate to={route(routes.login, null, { ret: "/" })} replace />
  }
  if (isPublic && isStrict && isLoggedIn()) {
    // this route is strictly public and the user is logged in
    // so redirect to homepage
    return <Navigate to={routes.home} replace />
  }
  return children;
}

export default Authmiddleware;
