import React from "react";
import OperationsPicture1 from 'asset/image/OperationsPicture1.png';
import OperationsPicture2 from 'asset/image/OperationsPicture2.png';
import GridContainer from "component/common/GridContainer";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";

const OperationsStep = () => {

  const { t } = useTranslation();

  return (
    <GridContainer sx={{ overflow: "auto", maxHeight: "80vh" }}>
      <Grid xs={12}>
        <Box sx={{ fontSize: "22px", fontWeight: 600, marginTop: "12px" }}>
          {t("operations")}
        </Box>
      </Grid>
      <Grid md={12} lg={6}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "70%" }}>
          <img src={OperationsPicture1} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
        </Box>
        <p>
          {t("operationsText1")}
        </p>
      </Grid>
      <Grid md={12} lg={6}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "70%" }}>
          <img src={OperationsPicture2} alt="" style={{ maxWidth: "100%", maxHeight: "100%", height: "auto", objectFit: "contain" }} />
        </Box>
        <p>
          {t("operationsText2")}
        </p>
      </Grid>
    </GridContainer>
  )
};

export default OperationsStep;