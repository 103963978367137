import { CardContent, Link, TableBody, TableCell, TableRow } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { buildOperationNumber } from "helper/util";
import Operation from "model/operation";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  operation: Operation;
}

const SectionSupplyDetails = ({ operation }: Props) => {

  const { t } = useTranslation();

  return (
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("supplyDetails")} />
        <TableNoBb>
          <TableBody>
            <TableRow>
              <TableCell>{t("number")}</TableCell>
              <TableCell className="preview-value">{buildOperationNumber(operation.id, operation.type, operation.createdTs)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("date")}</TableCell>
              <TableCell className="preview-value">{formatTimestamp(operation.createdTs, formats.DATETIME)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("location")}</TableCell>
              <TableCell className="preview-value">{operation.locationName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("driver")}</TableCell>
              <TableCell className="preview-value">
                <Link href={route(routes.view_driver, operation.driverId)}>{operation.driverName}</Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </TableNoBb>
      </CardContent>
    </EditableCard>
  )
}

export default SectionSupplyDetails;