import {
  APPLY_SUPPLY_OVERVIEW_DT_PARAMS,
  PATCH_SUPPLY_OVERVIEW_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applySupplyOverviewDtParams = (params: DtParams) => ({
  type: APPLY_SUPPLY_OVERVIEW_DT_PARAMS,
  payload: { params },
});

export const patchSupplyOverviewDtParams = (params: DtParams) => ({
  type: PATCH_SUPPLY_OVERVIEW_DT_PARAMS,
  payload: { params },
});