import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from 'asset/image/logo.png';
import { Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { preRegisterOrganization } from 'helper/backend';
import Spinner from 'component/common/Spinner';
import { toSimpleError } from 'helper/util';
import { ORGANIZATION_ALREADY_REGISTERED, REGISTRATION_INVALID_TOKEN } from 'helper/error';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import background from 'asset/image/bgPattern.png';
import TermsAndConditions from './Partial/TermsAndCond';
import OrganizationInformation from './Partial/OrganizationInformation';

const RegisterOrganization = () => {

  const { token } = useParams();

  const [isValidationInProgress, setIsValidationInProgress] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [payload, setPayload] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [confirmedTerms, setConfirmedTerms] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    setIsValidationInProgress(true);
    preRegisterOrganization(token)
      .then(response => {
        setIsValid(true);
        setPayload(response);
      })
      .catch(ex => {
        setIsValid(false);
        let errMessage = 'Unable to validate registration link';
        const err = toSimpleError(ex);
        switch (err.code) {
          case REGISTRATION_INVALID_TOKEN:
            errMessage = 'This link is invalid or has expired';
            break;
          case ORGANIZATION_ALREADY_REGISTERED:
            errMessage = 'This organization is already registered';
            break;
        }
        setError(errMessage);
      })
      .finally(() => {
        setIsValidationInProgress(false);
      });
  }, [token]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Card sx={{ height: { xs: "100%", md: "auto" }, margin: { xs: null, md: 5 }, overflow: "auto" }}>
          <CardContent sx={{ p: 4, overflow: "auto", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: 'center', padding: "20px 0px 40px 0px", height: "180px", width: "100%", display: "flex", justifyContent: "center" }}>
              <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
              </Box>
            </Box>
            <Box>

              {/* Visible when initial token validation request is in progress */}
              {isValidationInProgress && <Spinner sx={{ width: '100%' }} />}

              {/* Visible when initial token validation has failed */}
              {!isValid && !!error && <Box sx={{ textAlign: 'center', width: 400 }}>
                <HighlightOffOutlined color="error" sx={{ fontSize: "60px" }} />
                <Typography variant="h5" display="block" sx={{ mb: 1 }}>Registration Failed</Typography>
                <Typography display="block">{error}</Typography>
              </Box>}

              {/* Visible when initial token validation is successful */}
              {isValid && !!payload && !confirmedTerms &&
                <TermsAndConditions payload={payload} agreeTerms={agreeTerms} setAgreeTerms={setAgreeTerms} setConfirmedTerms={setConfirmedTerms} />
              }
              {isValid && !!payload && confirmedTerms &&
                <OrganizationInformation payload={payload} setConfirmedTerms={setConfirmedTerms} token={token} agreeTerms={agreeTerms} />
              }
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box >
  )
}

export default RegisterOrganization;
