import React, { FocusEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from 'asset/image/logo.png';
import { Alert, Card, CardContent, Link, TextField } from '@mui/material';
import env from 'env';
import { useParams } from 'react-router-dom';
import { activateAccount, preActivateAccount } from 'helper/backend';
import Spinner from 'component/common/Spinner';
import { isErrType, toSimpleError } from 'helper/util';
import { ACCOUNT_ALREADY_ACTIVATED, EMAIL_VERIFICATION_INVALID_TOKEN, USER_NOT_FOUND, ValidationException } from 'helper/error';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getPasswordValidationSchema, getPasswordRequirements, validatePassword } from 'helper/validation';
import ProgressButton from 'component/common/ProgressButton';
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { Button } from '@mui/material';
import { routes } from 'helper/route';
import background from 'asset/image/bgPattern.png';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type Values = {
  passwd: string;
  passwdConf: string;
};

const ActivateAccount = () => {

  const { token } = useParams();
  const { t } = useTranslation();

  const [isValidationInProgress, setIsValidationInProgress] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isActivationInProgress, setIsActivationInProgress] = useState(false);
  const [isActivated, setIsActivated] = useState(false);

  const formInitialValues: Values = {
    passwd: '',
    passwdConf: '',
  };

  /**
   * Form configuration
   */
  const { values, errors, setStatus, handleChange, handleSubmit, setFieldError } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: formInitialValues,
    validate: validatePassword(Yup.object(getPasswordValidationSchema())),
    onSubmit: values => {
      doActivateAccount(values);
    },
  });

  useEffect(() => {
    if (!token) {
      return;
    }
    setIsValidationInProgress(true);
    preActivateAccount(token)
      .then(_response => {
        setIsValid(true);
      })
      .catch(ex => {
        setIsValid(false);
        let errMessage = t("unableToValidateLink");
        const err = toSimpleError(ex);
        switch (err.code) {
          case EMAIL_VERIFICATION_INVALID_TOKEN:
            errMessage = t("invalidLink");
            break;
          case USER_NOT_FOUND:
            errMessage = t("userAccountNotFound");
            break;
          case ACCOUNT_ALREADY_ACTIVATED:
            errMessage = t("accountAlreadyActivated");
            break;
        }
        setError(errMessage);
      })
      .finally(() => {
        setIsValidationInProgress(false);
      });
  }, [token, t]);

  const doActivateAccount = (values: any) => {
    if (!token) {
      return;
    }
    setIsActivationInProgress(true);
    activateAccount(token, values)
      .then(_response => {
        setIsActivated(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        let errMessage = t("unableToActivateAccount");
        switch (err.code) {
          case EMAIL_VERIFICATION_INVALID_TOKEN:
            errMessage = t("invalidLink");
            break;
          case USER_NOT_FOUND:
            errMessage = t("userAccountNotFound");
            break;
          case ACCOUNT_ALREADY_ACTIVATED:
            errMessage = t("accountAlreadyActivated");
            break;
        }
        setError(errMessage);
      })
      .finally(() => {
        setIsActivationInProgress(false);
      });
  }

  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <Box sx={{
    display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
  }}>
    <Card sx={{ height: { xs: "100%", md: "auto" }, width: { md: 475 } }}>
      <CardContent sx={{ p: 4, '&:last-child': { pb: 4 } }}>
        <Box sx={{ height: 100, display: "flex", justifyContent: "center", mb: 8 }}>
          <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
        <Box>

          {/* Visible when initial token validation request is in progress */}
          {isValidationInProgress && <Spinner sx={{ width: '100%' }} />}

          {/* Visible when initial token validation has failed */}
          {!isValid && !!error && <Box sx={{ textAlign: 'center' }}>
            <HighlightOffOutlined color="error" sx={{ fontSize: "60px" }} />
            <Typography variant="h5" display="block" sx={{ mb: 1 }}>Activation Failed</Typography>
            <Typography display="block">{error}</Typography>
          </Box>}

          {/* Visible when account activation is successfull */}
          {isActivated && <Box sx={{ textAlign: 'center' }}>
            <CheckCircleOutlined color="success" sx={{ fontSize: "60px" }} />
            <Typography variant="h5" display="block" sx={{ mb: 1 }}>Registration Successful</Typography>
            <Typography display="block">Your account has been activated successfully.<br />You can now sign in</Typography>
            <Button variant="contained" size="large" component={Link} href={routes.home} sx={{ width: '100%', mt: 5 }} startIcon={<Check />}>Sign In</Button>
          </Box>}

          {/* Visible when initial token validation is successful but account activation is not yet complete */}
          {isValid && !isActivated && <form noValidate onSubmit={handleSubmit}>
            <Typography align="center" sx={{ mb: 3 }}>Secure your account with a strong password</Typography>

            {/* Visible when account activation has failed */}
            {!!error && <Alert color="error" sx={{ mb: 3 }}>{error}</Alert>}

            <TextField type="password" name="passwd" label="Password" variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.passwd} error={!!errors.passwd} sx={{ width: '100%' }} />
            <Box sx={{ p: 1 }}>
              {getPasswordRequirements().map(requirement => {
                const failed = errors.passwd?.includes(requirement);
                const passed = values.passwd && !failed;
                const color = passed ? 'success.dark' : 'inherit';
                const Icon = passed ? CheckBoxOutlined : CheckBoxOutlineBlankOutlined;
                return <Typography variant="caption" key={requirement} color={color} sx={{ display: 'block', mt: 1 }}>
                  <Icon fontSize="small" sx={{ verticalAlign: 'middle', mt: '-3px' }} /> {requirement}
                </Typography>
              })}
            </Box>

            <TextField type="password" name="passwdConf" label="Confirm Password" variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.passwdConf} error={!!errors.passwdConf} helperText={errors.passwdConf} sx={{ width: '100%', mt: 3 }} />

            <ProgressButton type="submit" variant="contained" color="primary" size="large" sx={{ width: '100%', mt: 3 }} isBusy={isActivationInProgress} startIcon={<Check />}>Activate Account</ProgressButton>
          </form>}

        </Box>
      </CardContent>
    </Card>
    <Box sx={{ textAlign: 'center', mt: 3, color: "#98a6ad", display: { xs: "none", md: "block" } }}>
      <Typography variant="caption" display="block">
        © {new Date().getFullYear()} {env.APP_TITLE} by <a href="https://www.codeadept.ro" target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#33AD93", fontWeight: 600 }}>CodeAdept</a>
      </Typography>
    </Box>
  </Box >
}

export default ActivateAccount;
