import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, CardContent, Link, Slider, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { AppError } from "@type";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { getDuStockLevel } from "helper/backend";
import { useTranslation } from "react-i18next";
import { toSimpleError } from "helper/util";
import DistributionUnit from "model/distributionUnit";
import TableNoBb from "component/styled/TableNoBb";
import { route, routes } from "helper/route";

const OutOfSupplyDus = () => {

  const { t } = useTranslation();

  // Holds the stock level received from backend
  const [stockLevel, setStockLevel] = useState<DistributionUnit[]>([])
  // error encoutered while fetching the stock (if any)
  const [stockLevelError, setStockLevelError] = useState<AppError | undefined>();
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchStock = useCallback(() => {
    setIsLoadInProgress(true)
    getDuStockLevel()
      .then(response => {
        setStockLevel(response.distributionUnits);
      })
      .catch(ex => {
        setStockLevelError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, []);

  useEffect(() => {
    fetchStock();
  }, [fetchStock])

  // Returns the color of the slider
  const getColorByPercentage = (minStock: number) => {
    if (minStock >= DistributionUnit.STOCK_LIMIT_HIGH) {
      return "#31ce77";
    } else if (minStock >= DistributionUnit.STOCK_LIMIT_LOW) {
      return "#fbcc5c"
    } else {
      return '#f34943'
    }
  }

  return (
    <EditableCard sx={{ height: "360px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={t("duSupplyLevel")} />
        {
          stockLevel.length > 0
            ?
            <TableNoBb>
              <TableHead>
                <TableRow>
                  <TableCell>{t("duName")}</TableCell>
                  <TableCell>{t("stock")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockLevel.map((el, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell><Link href={route(routes.view_distirbution_unit, el.id)}>{el.name}</Link></TableCell>
                      <TableCell className="preview-value">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <Slider
                            value={el.minStockPercentage}
                            max={100}
                            sx={{
                              color: getColorByPercentage(el.minStockPercentage),
                              height: 10,
                              '& .MuiSlider-track': {
                                border: 'none',
                              },
                              '& .MuiSlider-thumb': {
                                height: 0,
                                width: 0,
                                backgroundColor: '#fff',
                                border: '0px solid',
                                display: "none"
                              },
                              '& .MuiSlider-rail': {
                                border: el.minStockPercentage === 0 ? "2px solid #f34943" : "none"
                              },
                            }}
                          />
                          {`${el.minStockPercentage}%`}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </TableNoBb>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noItems")}
            </Box>
        }
        {!!stockLevelError &&
          <Alert severity="error">
            {stockLevelError.message}
          </Alert>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard>
  )
}

export default OutOfSupplyDus;