import Edit from '@mui/icons-material/Edit';
import { TableCell, TableRow } from '@mui/material';
import { IconButton, TableBody } from '@mui/material';
import CardTitleBar from 'component/common/CardTitleBar';
import Preloader from 'component/common/Preloader';
import TableNoBb from 'component/styled/TableNoBb';
import { perms, useAccess } from 'context/access';
import { useAuth } from 'context/auth';
import { formats, formatTimestamp } from 'helper/date';
import User from 'model/user';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  user: User;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ user, onEdit: onCancel, isReloading }: Props) => {

  const { authUser } = useAuth();
  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={user.fullName!} {...(user.isActive ? null : { chip: user.isEmailVerified ? t("inactive") : t("pendingVerification"), bgcolor: 'warning.light' })}>
      {isGranted(perms.edit_users) && user.id !== authUser.id && <IconButton onClick={() => onCancel()}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{user.fullName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("email")}</TableCell>
          <TableCell className="preview-value">{user.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("accessRole")}</TableCell>
          <TableCell className="preview-value">{user.accessRoleName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("timezone")}</TableCell>
          <TableCell className="preview-value">{user.timezone}</TableCell>
        </TableRow>
        {isGranted(perms.create_organizations) && <TableRow>
          <TableCell>{t("organization")}</TableCell>
          <TableCell className="preview-value">{user.organizationCompanyName || '--'}</TableCell>
        </TableRow>}
        {isGranted(perms.create_clients) && <TableRow>
          <TableCell>{t("client")}</TableCell>
          <TableCell className="preview-value">{user.clientCompanyName || '--'}</TableCell>
        </TableRow>}
        <TableRow>
          <TableCell>{t("lastLogin")}</TableCell>
          <TableCell className="preview-value">{user.lastLoginTs ? formatTimestamp(user.lastLoginTs, formats.DATETIME) : t("never")}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment >
}

export default SectionInfoView;
