import React, { useState, FormEvent } from "react";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Preloader from "component/common/Preloader";
import ProgressButton from "component/common/ProgressButton";
import { showError } from "helper/util";
import withStripeElements from "hoc/stripeElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/auth";
import AccessRole from "model/accessRole";
import { useDispatch } from "react-redux";
import { logoutUser } from "store/actions";

const PaymentForm = () => {

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authUser } = useAuth();
  const dispatch = useDispatch();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false)
  // whether stripe elements are loading
  const [stripeIsLoading, setStripeIsLoading] = useState(true);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    setIsSubmitInProgress(true);
    const { error } = await stripe!.confirmSetup({
      // @ts-ignore
      elements,
      confirmParams: {
        return_url: authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION ? `${process.env.REACT_APP_URL}/onboarding/payment-plans` : `${process.env.REACT_APP_URL}/onboarding/payment-confirmation`,
      },
    });
    if (error) {
      // This point will only be reached if there is an immediate error (before redirect)
      showError(error.message!);
      setIsSubmitInProgress(false);
    }
    // user will be redirected to `return_url`
  }

  // Log out the user at quit
  const logUserOut = () => {
    dispatch(logoutUser());
  }

  const isStripeReady = () => !!stripe && !!elements && !stripeIsLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 4, textAlign: "center" }}>
        {t("onboardingPayment")}
      </Box>
      <PaymentElement onReady={() => setStripeIsLoading(false)} />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", pt: 1 }}>
        {
          authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION
            ?
            <Button variant="contained" color="secondary" onClick={() => navigate("/onboarding/billing-information")} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
            :
            <Button variant="contained" color="secondary" onClick={logUserOut} startIcon={<KeyboardArrowLeft />}>{t("quit")}</Button>
        }
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1, ml: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("next")}</ProgressButton>
      </Box>
      {!isStripeReady() && <Preloader container="full" />}
    </form>
  )
}

export default withStripeElements(PaymentForm);