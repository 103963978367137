import React, { useCallback } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridFilterModel, GridLocaleText, GridPaginationModel, GridRenderCellParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { DtParams } from '@type';
import CustomGridToolbar from './CustomGridToolbar';
import DtLoader from './DtLoader';
import { handleGridPaginationModel, handleGridSearchModel, handleGridSortModel } from 'helper/dt';
import StyledDt from './StyledDt';
import { getTableRowIndex } from 'helper/util';

type Props = {
  rows: any[];
  rowCount: number;
  columns: GridColDef<any, any, any>[];
  params: DtParams;
  isBusy: boolean;
  localeText: Partial<GridLocaleText>;
  hiddenColumns?: string[];
  onPaginationChange: (p: DtParams) => void;
  onSearchChange: (p: DtParams) => void;
  onSortChange: (p: DtParams) => void;
};

/**
 * Renders a custom DataGrid component which is a wrapper over the MUI DataGrid
 * With pagination, sorting and quick search configured to work with the backend
 */
const Dt = ({ rows, rowCount, columns, params, isBusy, localeText, hiddenColumns, onPaginationChange, onSearchChange, onSortChange }: Props) => {

  /**
   * Returns the initial pagination settings to be applied to the DataGrid as the component mounts
   * DataGrid parameters are stored in redux so they survive component unmount
   * Therefore when the user returns to the same DataGrid we can restore the previous parameters
   * Note that with the DataGrid page values start from 0
   * While with the backend page values start from 1
   * Hence the "params.page - 1" conversion
   */
  const getInitialPaginationModel = useCallback(() => ({
    page: params.page !== undefined ? params.page - 1 : 0,
    pageSize: params.pageSize || 100,
  }), [params.page, params.pageSize]);

  /**
   * Event handler called whenever the user changes the pagination properties of the DataGrid
   */
  const handlePaginationChange = useCallback((paginationModel: GridPaginationModel) => {
    // convert the DataGrid format to DtParams
    const newParams = handleGridPaginationModel(paginationModel, params);
    // send the updated params to the parent component
    onPaginationChange(newParams);
  }, [params, onPaginationChange]);

  /**
   * Returns the initial quick search settings to be applied to the DataGrid as the component mounts
   * DataGrid parameters are stored in redux so they survive component unmount
   * Therefore when the user returns to the same DataGrid we can restore the previous parameters
   * Note that the DataGrid handles quick search and filters together
   * However we implement our custom filtering so we can safely ignore the default grid filtering
   * Therefore we only care about quick search here
   */
  const getIntitalSearchModel = useCallback(() => {
    // prepare the return value in the format expected by the DataGrid
    // "items" is a required property so we have to include it even though we do not use it
    const filterModel = { items: [] } as GridFilterModel;
    // check if the parameters contain any value for quick search
    if (!!params.search) {
      // add the search value to the return value
      filterModel.quickFilterValues = [params.search];
    }
    return filterModel;
  }, [params.search]);

  /**
   * Event handler called whenever the user changes the quick search value of the DataGrid
   * Since the DataGrid treats quick search much like a filter, this would normally be called for filters too
   * However we do not use the default DataGrid filtering
   * So it is safe to asume this will only be called when quick search changes
   */
  const handleSearchChange = useCallback((filterModel: GridFilterModel) => {
    // convert the DataGrid format to DtParams
    const newParams = handleGridSearchModel(filterModel, params);
    // send the updated params to the parent component
    onSearchChange(newParams);
  }, [params, onSearchChange]);

  /**
   * Returns the initial sorting settings to be applied to the DataGrid as the component mounts
   * DataGrid parameters are stored in redux so they survive component unmount
   * Therefore when the user returns to the same DataGrid we can restore the previous parameters
   */
  const getInitialSortModel = useCallback(() => {
    // prepare the return value in the format expected by the DataGrid
    const sortModel = [];
    // check if the parameters contain any sorting
    if (!!params.sortBy) {
      sortModel.push({
        field: params.sortBy,
        sort: params.sortDir,
      } as GridSortItem);
    }
    return sortModel;
  }, [params.sortBy, params.sortDir]);

  /**
   * Event handler called whenever the user changes the sorting properties of the DataGrid
   */
  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    // convert the DataGrid format to DtParams
    const newParams = handleGridSortModel(sortModel, params);
    // send the updated params to the parent component
    onSortChange(newParams);
  }, [params, onSortChange]);

  /**
   * Converts the list of hidden columns to the format expected by the DataGrid
   */
  const getColumnVisibilityModel = () => {
    // prepare the return value in the format expected by the DataGrid
    const model = {} as GridColumnVisibilityModel;
    // check if we have hidden columns
    if (!!hiddenColumns) {
      // add the hidden columns to the model
      for (const column of hiddenColumns) {
        model[column] = false;
      }
    }
    return model;
  }

  const getColumns = (columns: any) => {

    const columnsWithIndex = [...columns];

    if (window.innerWidth > 500) {
      columnsWithIndex.unshift({
        field: 'index',
        headerName: "#",
        id: "test",
        renderCell: (params: GridRenderCellParams) => {
          const { page, pageSize } = params.api.state.pagination.paginationModel;
          return getTableRowIndex(page, pageSize, params.row.index)
        }
      })
    }

    return columnsWithIndex
  }

  return <StyledDt
    rows={rows.map((row, idx) => { return { index: idx, ...row } })}
    columns={getColumns(columns)}
    slots={{
      toolbar: CustomGridToolbar,
      loadingOverlay: DtLoader,
    }}
    slotProps={{
      toolbar: {
        quickFilterProps: { debounceMs: 500 },
      },
    }}
    autoHeight
    rowHeight={45}
    pageSizeOptions={[5, 25, 50, 100]}
    disableColumnMenu
    disableRowSelectionOnClick
    disableColumnSelector
    disableDensitySelector
    rowSelection={false}
    loading={isBusy}
    filterMode="server"
    paginationMode="server"
    sortingMode="server"
    rowCount={rowCount}
    filterModel={getIntitalSearchModel()}
    onFilterModelChange={handleSearchChange}
    paginationModel={getInitialPaginationModel()}
    onPaginationModelChange={handlePaginationChange}
    sortModel={getInitialSortModel()}
    onSortModelChange={handleSortChange}
    columnVisibilityModel={getColumnVisibilityModel()}
    localeText={localeText}
    sx={{ '& .MuiDataGrid-columnSeparator': { display: 'none' }, padding: "0px 16px", border: 0 }}
  />
}

export default Dt;