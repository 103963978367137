import React, { useState, FocusEvent, useCallback } from "react";
import { Button, TextField, Autocomplete } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { regexPhone } from "constant/regex";
import { updateOwnClient } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import Client from "model/client";
import { useTranslation } from "react-i18next";

type Values = {
  entityType: number;
  companyName: string;
  vatNo: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
};

type Props = {
  client: Client;
  onFinish: Function;
};

const SectionInfoEdit = ({ client, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    entityType: 0,
    companyName: '',
    vatNo: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
  };
  formInitialValues = {
    ...formInitialValues,
    entityType: client.entityType!,
    companyName: client.companyName!,
    vatNo: client.vatNo!,
    contactFirstName: client.contactFirstName!,
    contactLastName: client.contactLastName!,
    contactEmail: client.contactEmail!,
    contactPhone: client.contactPhone!
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    entityType: Yup.number().positive(t("fieldIsRequired")),
    companyName: Yup.string().trim().when("entityType", {
      is: (value: number) => value === Client.ENTITY_TYPE_COMPANY,
      then: schema => schema.required(t("fieldIsRequired"))
    }),
    contactFirstName: Yup.string().trim().required(t("fieldIsRequired")),
    contactLastName: Yup.string().trim().required(t("fieldIsRequired")),
    contactEmail: Yup.string().trim().required(t("fieldIsRequired")).email(t("invalidEmailAddress")),
    contactPhone: Yup.string().trim().required(t("fieldIsRequired")).matches(regexPhone, t("invalidPhoneNumber")),
    vatNo: Yup.string().trim(),
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveClient(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveClient = (values: any) => {
    setIsSubmitInProgress(true)
    updateOwnClient(values)
      .then(_response => {
        showSuccess(t("clientHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        showError(t("unableToSaveClient"));
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Converts the list of entity types to select options
  */
  const getEntityTypeOptions = () => Client.EntityTypes.map(type => ({ label: type.name, value: type.id }));

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedEntityTypeOption = () => getEntityTypeOptions().find(option => option.value === values.entityType) || null;

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={client.companyName! || `${client.contactFirstName} ${client.contactLastName}`} sx={{ mb: 4 }} >
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveGeneralInfo")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('entityType', selectedOption?.value);
            setFieldValue('companyName', "");
            setFieldValue('vatNo', "");
          }}
          onFocus={_e => {
            onSelectFieldFocused('entityType');
          }}
          value={getSelectedEntityTypeOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getEntityTypeOptions()}
          renderInput={(params: any) => <TextField {...params} name="entityType" label={t("entityType")} error={!!errors.entityType} helperText={errors.entityType} inputProps={{ ...params.inputProps, autoComplete: 'new-entity' }} />}
        />
      </Grid>
      {
        values.entityType === Client.ENTITY_TYPE_COMPANY &&
        <Grid xs={12} md={6}>
          <TextField name="companyName" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.companyName} error={!!errors.companyName} helperText={errors.companyName} sx={{ width: '100%' }} />
        </Grid>
      }
      <Grid xs={12} md={6}>
        <TextField name="contactFirstName" label={t("contactFirstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactFirstName} error={!!errors.contactFirstName} helperText={errors.contactFirstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="contactLastName" label={t("contactLastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactLastName} error={!!errors.contactLastName} helperText={errors.contactLastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="contactEmail" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactEmail} error={!!errors.contactEmail} helperText={errors.contactEmail} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={6}>
        <TextField name="contactPhone" label={t("phone")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactPhone} error={!!errors.contactPhone} helperText={errors.contactPhone} sx={{ width: '100%' }} />
      </Grid>
      {
        values.entityType === Client.ENTITY_TYPE_COMPANY &&
        <Grid xs={6}>
          <TextField name="vatNo" label={t("vatNumber")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.vatNo} error={!!errors.vatNo} helperText={errors.vatNo} sx={{ width: '100%' }} />
        </Grid>
      }
    </GridContainer>
  </form>
}

export default SectionInfoEdit;
