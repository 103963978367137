import React from "react";
import { Box, Button, CardContent, Typography } from "@mui/material";
import WizardStepper from "./WizardStepper";
import GridContainer from "component/common/GridContainer";
import EditableCard from "component/styled/EditableCard";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Check } from "@mui/icons-material";
import { useOnboarding } from "context/onboarding";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import background from 'asset/image/bgPattern.png';
import logo from 'asset/image/logo.png';

const Finish = () => {

  const { refreshFinalStep } = useOnboarding();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: "100%", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <WizardStepper activeStep={3} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          marginTop: { xs: 0, md: "-64px" },
          width: "100%",
          '@media (max-height: 800px)': {
            marginTop: 0
          }
        }}>
        <GridContainer
          sx={{
            width: { xs: "100%", md: "600px" },
            margin: { xs: 0, md: "auto" },
            height: { xs: "100%", md: "auto" },
            '@media (max-height: 800px)': {
              width: "100%",
              margin: 0,
              height: "100%"
            }
          }}
        >
          <Grid xs={12} sx={{ padding: { xs: 0 } }}>
            <EditableCard sx={{ height: '100%', padding: "15px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <CardContent>
                <Box sx={{ textAlign: 'center', mb: 5, height: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
                  <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                    <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                  </Box>
                </Box>
                <Box sx={{ mb: 4, textAlign: "center", fontSize: "21px" }}>
                  {t("onboardingComplete")}!
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItem: "center", justifyContent: "center", width: "100%", p: 2 }}>
                  <Typography textAlign="center">
                    {t("youAreAllSet")}
                  </Typography>
                  <Typography textAlign="center">
                    {t("allyourSettings")}
                  </Typography>
                  <Typography textAlign="center" mb={6}>
                    {t("goAheadAndEnter")}
                  </Typography>
                  <Typography textAlign="center">
                    {t("thanksForJoining")}
                  </Typography>
                  <Typography textAlign="center" fontWeight={600}>
                    {t("24LinenTeam")}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 5 }}>
                  <Button variant="contained" color="primary" startIcon={<Check />} onClick={() => { refreshFinalStep(); navigate("/") }} sx={{ ml: 1 }}>{t("finish")}</Button>
                </Box>
              </CardContent>
            </EditableCard>
          </Grid>
        </GridContainer>
      </Box>
    </Box>
  )
}

export default Finish;