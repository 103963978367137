
export default class Client {
  id!: number;
  name!: string;
  organizationId!: number;
  entityType!: number;
  businessModel!: number;
  companyName!: string | null;
  vatNo!: string | null;
  contactFirstName!: string;
  contactLastName!: string;
  contactEmail!: string;
  contactPhone!: string;
  billingAddress!: string;
  billingCity!: string;
  billingZip!: string;
  billingCounty!: string;
  billingCountry!: string;
  isActive!: boolean;
  isOnboardingComplete!: boolean;
  stripeCustomerId!: string;
  cardExpMonth!: number;
  cardExpYear!: number;
  cardNum!: string;
  createdTs!: number;
  updatedTs!: number;

  // USER LIMIT NUMBER
  static CLIENT_USERS_LIMIT = 5;

  //ENTITY TYPE AND BUSINESS MODEL
  static ENTITY_TYPE_COMPANY = 1;
  static ENTITY_TYPE_INDIVIDUAL = 2;
  static BUSINESS_MODEL_SSD = 1;
  static BUSINESS_MODEL_NON_SSD = 2;
  static BUSINESS_MODEL_FREE_SSD = 3;

  static EntityTypes = [
    {
      id: this.ENTITY_TYPE_COMPANY,
      name: "Company"
    },
    {
      id: this.ENTITY_TYPE_INDIVIDUAL,
      name: "Individual"
    }
  ];

  static BusinessModels = [
    {
      id: this.BUSINESS_MODEL_SSD,
      name: "SSD"
    },
    {
      id: this.BUSINESS_MODEL_NON_SSD,
      name: "Non SSD"
    },
    {
      id: this.BUSINESS_MODEL_FREE_SSD,
      name: "Free SSD"
    }
  ];

  static getEntityTypeName(id: number) {
    return this.EntityTypes.find((entityType) => entityType.id === id)?.name
  }

  static getBusinessModelName(id: number) {
    return this.BusinessModels.find((businessModel) => businessModel.id === id)?.name
  }
}