import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';

type Props = {
  logo?: any;
  setFieldValue: Function;
}

const UploadPicture = ({ logo, setFieldValue }: Props) => {

  // Drop zone hook for drag and drop
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });

  // If no image found don't show the left box
  const [imageNotFound, setImageNotFound] = useState(true)

  // Runs when an image is uploaded
  useEffect(() => {
    if (!!acceptedFiles[0]) {
      setImageNotFound(true)
      setFieldValue("logo", acceptedFiles[0]);
    };
  }, [acceptedFiles, setFieldValue])

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px"
      }}
    >
      {!!logo &&
        <Box
          sx={{
            display: imageNotFound ? "none" : "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid #5d6575",
            padding: "10px",
            maxWidth: "250px",
            maxHeight: "250px",
            height: "155px",
            objectFit: "contain",
            overflow: "hidden"
          }}
        >
          <img src={!!acceptedFiles[0] ? URL.createObjectURL(new Blob([logo], { type: "application/img" })) : logo} alt="logo" onError={() => setImageNotFound(true)} onLoad={() => setImageNotFound(false)} style={{ width: "100%" }} />
        </Box>
      }
      <div
        {...getRootProps({ className: "dropzone" })}
        style={
          {
            flex: 1,
            position: "relative",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: isDragAccept ? "#31ce77" : isDragReject ? "#f34943" : '#5d6575',
            borderStyle: 'dashed',
            color: '#5d6575',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            cursor: 'pointer'
          }
        }
      >
        <input {...getInputProps()} />
        <FileUploadIcon fontSize="large" />
        <Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "column", alignItems: "center" }}>
          <Typography>
            Drag and Drop here
          </Typography>
          <Typography>
            or
          </Typography>
          <Typography sx={{ color: "#33AD93" }}>
            Browse files
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" }, mt: 2 }}>
          Upload
        </Box>
      </div>
    </Box>
  )
}

export default UploadPicture;