import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, Download, KeyboardArrowLeft, KeyboardDoubleArrowUp, ToggleOff, ToggleOn } from "@mui/icons-material";
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { AppError } from "@type";
import { activateAppVersion, deleteAppVersion, getAppFile, getAppVersion, promoteAppToStable } from "helper/backend";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess, toSimpleError } from "helper/util";
import Error from 'page/Error';
import { routes } from "helper/route";
import { useTranslation } from "react-i18next";
import AppVersion from "model/appVersion";
import SectionInfoView from "./Partial/SectionInfo";
import SectionDevices from "./Partial/SectionDevices";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the app version record fetched from the backend
  const [appVersion, setAppVersion] = useState<AppVersion | undefined>()
  // error encoutered while fetching the app version (if any)
  const [appVersionError, setAppVersionError] = useState<AppError | undefined>();
  // whether the loading of the app version is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the Du is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  // whether the loading of the download is in progress
  const [isDownloadInProgress, setIsDownloadInProgress] = useState<boolean>(false)
  // whether the loading of the promotion is in progress
  const [isPromotionInProgress, setIsPromotionInProgress] = useState<boolean>(false)
  // whether the loading of the activation is in progress
  const [isActivatingInProgress, setIsActivatingInProgress] = useState<boolean>(false)
  // whether the promote confirmation is visible
  const [isPromoteConfOpen, setIsPromoteConfOpen] = useState(false);
  // whether the activate confirmation is visible
  const [isActivateConfOpen, setIsActivateConfOpen] = useState(false);
  // whether the deactivate confirmation is visible
  const [isDeactivateConfOpen, setIsDeactivateConfOpen] = useState(false);

  /**
  * Download the app version from the backend
  */
  const downloadApp = useCallback(() => {
    setIsDownloadInProgress(true)
    getAppFile(recordId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', appVersion?.fileName || "");
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
      })
      .catch(_ex => {
        showError(t("unableToDownloadFile"));
      })
      .finally(() => {
        setIsDownloadInProgress(false);
      })
  }, [appVersion, recordId, t])

  /**
  * Fetches the app version from the backend
  */
  const refreshAppVersion = useCallback(() => {
    setIsLoadInProgress(true)
    getAppVersion(recordId)
      .then(response => {
        setAppVersion(response.appVersion);
      })
      .catch(ex => {
        setAppVersionError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId]);

  /**
  * Deletes the app version record in the backend
  */
  const removeAppVersion = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteAppVersion(recordId)
      .then(_response => {
        showSuccess(t("appVersionHasBeenDeleted"));
        return navigate(routes.list_app_version);
      })
      .catch(_ex => {
        let errMessage = t("unableToDeleteAppVersion");
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  /**
  * Promotes the app version from the backend
  */
  const promoteToStable = useCallback(() => {
    setIsPromotionInProgress(true)
    promoteAppToStable(recordId)
      .then(_response => {
        refreshAppVersion()
      })
      .catch(_ex => {
        showError(t("unableToPromoteAppVersion"));
      })
      .finally(() => {
        setIsPromotionInProgress(false);
      })
  }, [recordId, refreshAppVersion, t]);

  /**
  * Toggles activate of the app version from the backend
  */
  const toggleActivation = useCallback(() => {
    setIsActivatingInProgress(true)
    activateAppVersion(recordId)
      .then(_response => {
        refreshAppVersion()
      })
      .catch(_ex => {
        showError(t("unableToActivateAppVersion"));
      })
      .finally(() => {
        setIsActivatingInProgress(false);
      })
  }, [recordId, refreshAppVersion, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshAppVersion();
  }, [refreshAppVersion]);

  return (
    <>
      {isGranted(perms.view_app_version)
        &&
        <>
          {
            !!appVersion
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(appVersion, t)}>
                {
                  !!appVersion.isActive
                    ?
                    <ProgressButton onClick={() => setIsDeactivateConfOpen(true)} variant="contained" color="warning" sx={{ mr: 1 }} isBusy={isActivatingInProgress} startIcon={<ToggleOff />}>{t("deactivate")}</ProgressButton>
                    :
                    <ProgressButton onClick={() => setIsActivateConfOpen(true)} variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isActivatingInProgress} startIcon={<ToggleOn />}>{t("activate")}</ProgressButton>
                }
                {
                  !!appVersion.isBeta &&
                  <ProgressButton onClick={() => setIsPromoteConfOpen(true)} variant="contained" color="info" sx={{ mr: 1 }} isBusy={isPromotionInProgress} startIcon={<KeyboardDoubleArrowUp />}>{t("promoteToStable")}</ProgressButton>
                }
                <ProgressButton onClick={downloadApp} variant="contained" color="dark" sx={{ mr: 1 }} isBusy={isDownloadInProgress} startIcon={<Download />}>{t("downloadApp")}</ProgressButton>
                {isGranted(perms.delete_app_version) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteAppVersion")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_app_version} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfoView appVersion={appVersion} />
                </Grid>
                <Grid xs={12}>
                  <SectionDevices appVersionId={appVersion.id} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeAppVersion();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteAppVersionConfirmation")} &quot;{appVersion.name}&quot;?</ConfirmDialog>
              <ConfirmDialog
                isOpen={isPromoteConfOpen}
                yesButton={t("promote")}
                title={t("promotion")}
                yesButtonColor="info"
                onConfirm={() => {
                  setIsPromoteConfOpen(false);
                  promoteToStable();
                }}
                onCancel={() => {
                  setIsPromoteConfOpen(false);
                }}
              >{t("promoteConfirmation")}?</ConfirmDialog>
              <ConfirmDialog
                isOpen={isActivateConfOpen}
                yesButton={t("activate")}
                title={t("activation")}
                yesButtonColor="primary"
                onConfirm={() => {
                  setIsActivateConfOpen(false);
                  toggleActivation();
                }}
                onCancel={() => {
                  setIsActivateConfOpen(false);
                }}
              >{t("activateConfirmation")}?</ConfirmDialog>
              <ConfirmDialog
                isOpen={isDeactivateConfOpen}
                yesButton={t("deactivate")}
                title={t("deactivation")}
                yesButtonColor="warning"
                onConfirm={() => {
                  setIsDeactivateConfOpen(false);
                  toggleActivation();
                }}
                onCancel={() => {
                  setIsDeactivateConfOpen(false);
                }}
              >{t("deactivateConfirmation")}?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !appVersion && <Preloader container="content" />}
          {!!appVersionError && <Error error={appVersionError} title404={t("appVersionNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_app_version) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (appVersion: AppVersion, t: Function) => [
  {
    text: t("appVersions"),
    url: routes.list_app_version
  },
  {
    text: `${appVersion.name!} ${!!appVersion.isBeta ? "(beta)" : ""}`,
  }
];

export default Single;