import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Button } from "@mui/material";
import { AddLocationAlt, Check, Delete, KeyboardArrowLeft } from "@mui/icons-material";
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import ProgressButton from "component/common/ProgressButton";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { ValidationException } from "helper/error";
import { updateDu } from "helper/backend";
import DistributionUnit from "model/distributionUnit";
import Maps from "component/map/Map";
import MapPin from "component/map/MapPin";
import { LatLng } from "leaflet";
import GeoSearchAutocomplete from "component/map/GeoSearchAutocomplete";
import GeoSearch from "component/map/GeoSearch";
import { useDebounce } from "hook/Debounce";
import { MAP_CENTER_BRASOV } from "constant/map";
import { useTranslation } from "react-i18next";

type Values = {
  name: string,
  organizationId: number | undefined,
  latitude: number | undefined,
  longitude: number | undefined,
  address: string,
};

type Props = {
  distributionUnit: DistributionUnit;
  onFinish: Function;
};

const SectionLocationEdit = ({ distributionUnit, onFinish }: Props) => {

  const { t } = useTranslation();

  // this shows/hides the Draggable Drop Pin
  const [dropPinVisible, setDropPinVisible] = useState(false)
  // holds the value of the search address input
  const [addressValue, setAddressValue] = useState("");
  // holds the options of the autocomplete
  const [addressOptions, setAddressOptions] = useState([]);
  // holds the selected option
  const [addressSelected, setAddressSelected] = useState<any>();
  // whether the loading of the search is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // keeps the reverse search option
  const [isReversed, setIsReversed] = useState(false);

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  //Debounce the value of the search
  const debouncedAddress = useDebounce(addressValue, 500) as string;

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues: Values = {
    name: '',
    organizationId: undefined,
    latitude: undefined,
    longitude: undefined,
    address: '',
  };
  formInitialValues = {
    ...formInitialValues,
    name: distributionUnit.name!,
    organizationId: distributionUnit.organizationId!,
    latitude: distributionUnit.latitude,
    longitude: distributionUnit.longitude,
    address: distributionUnit.address,
  };

  /**
  * Form validation rules
  */
  let validationSchema: any = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    organizationId: Yup.number().required(t("fieldIsRequired")),
  };

  /**
  * Form configuration
  */
  const { values, setFieldError, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveDu(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveDu = (values: any) => {
    setIsSubmitInProgress(true);
    updateDu(distributionUnit.id!, values)
      .then(_response => {
        showSuccess(t("distributionUnitHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveDistributionUnit"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Toggles the dropped Pin
  */
  const toggleDropPin = () => {
    setDropPinVisible(true);
  };

  /**
  * Clear Lat/Lng Values
  */
  const clearCoords = () => {
    setDropPinVisible(false)
    setIsReversed(false)
    setFieldValue("latitude", null)
    setFieldValue("longitude", null)
    setAddressSelected(undefined)
    setAddressOptions([])
  };

  /**
  * Handle search bar change
  */
  const handleChange = (e: React.ChangeEvent<any>) => {
    setIsReversed(false);
    setAddressValue(e.target.value);
    setIsLoadInProgress(true);
  };

  /**
  * Returns the selected address as a select option
  */
  const getSelectedAddressOption = () => addressOptions.length === 1 && isReversed ? addressOptions[0] : addressOptions.find(option => option === addressSelected) || null;

  // Run on render if the location is setted
  useEffect(() => {
    if (!!distributionUnit.latitude && !!distributionUnit.longitude) {
      toggleDropPin()
    }
  }, [distributionUnit]);

  // Saves the address selected into the form
  useEffect(() => {
    if (!!addressSelected?.raw.lat!! && addressSelected?.raw.lon) {
      setFieldValue("latitude", addressSelected?.raw.lat)
      setFieldValue("longitude", addressSelected?.raw.lon)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressSelected]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CardTitleBar title={distributionUnit.name!} sx={{ mb: 4 }}>
        <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveLocation")}</ProgressButton>
        <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
      </CardTitleBar>
      <GridContainer spacing={2}>
        <Grid xs={12}>
          <GridContainer spacing={2} sx={{ alignItems: "center" }}>
            <Grid xs={12} md={6} sx={{ padding: 0 }}>
              <GeoSearchAutocomplete
                handleChange={handleChange}
                handleSelectedOption={getSelectedAddressOption}
                options={addressOptions}
                isLoadInProgress={isLoadInProgress}
                setOptionSelected={setAddressSelected}
                toggleDropPin={toggleDropPin}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {
                  dropPinVisible
                    ?
                    <Button onClick={clearCoords} sx={{ backgroundColor: "#f34943", "&:hover": { backgroundColor: "rgb(170, 51, 46)" }, color: "#FFFFFF" }} startIcon={<Delete sx={{ color: "#FFFFFF" }} />}>
                      {t("removePin")}
                    </Button>
                    :
                    <Button onClick={toggleDropPin} sx={{ backgroundColor: "#33AD93", "&:hover": { backgroundColor: "#4ca492" }, color: "#FFFFFF" }} startIcon={<AddLocationAlt sx={{ color: "#FFFFFF" }} />} >
                      {t("dropPin")}
                    </Button>
                }
              </Box>
            </Grid>
          </GridContainer>
        </Grid>
        <Grid xs={12}>
          <Maps
            defaultMapPosition={
              !!distributionUnit.latitude && !!distributionUnit.longitude
                ?
                new LatLng(distributionUnit.latitude!, distributionUnit.longitude!)
                :
                MAP_CENTER_BRASOV
            }
            zoom={14}
          >
            {dropPinVisible &&
              <MapPin
                isDraggable={true}
                locationCoord={values}
                defaultLocationCoord={new LatLng(values.latitude!, values.longitude!)}
                setLatLng={setFieldValue}
                refreshSelectedOption={setAddressSelected}
                setIsReversed={setIsReversed}
              />
            }
            <GeoSearch
              searchValue={debouncedAddress}
              setAddressOptions={setAddressOptions}
              setIsLoadInProgress={setIsLoadInProgress}
              isReversed={isReversed}
              values={values}
              setFieldValue={setFieldValue}
            />
          </Maps>
        </Grid>
      </GridContainer>
    </form>
  )
};

export default SectionLocationEdit;