import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Slider, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import Spinner from "component/common/Spinner";
import Article from "model/article";
import { AppError } from "@type";
import { toSimpleError } from "helper/util";
import { getOperationItems } from "helper/backend";
import env from "env";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import Operation from "model/operation";

type Props = {
  operationId: number,
  operationType: number
};

const SectionItems = ({ operationId, operationType }: Props) => {

  const { t } = useTranslation();

  // the operation article record fetched from the backend
  const [articles, setArticles] = useState([] as Article[]);
  // whether the loading of the operation articles is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // error encoutered while fetching the operation articles (if any)
  const [error, setError] = useState<AppError>();
  // the number of seen articles in the Article Table
  const [numOfVisibleArticles, setNumOfVisibleArticles] = useState(env.COLLAPSED_TABLE_ROWS);

  /**
  * On click function that shows all the articles
  */
  const viewMore = () => {
    setNumOfVisibleArticles(articles.length);
  };

  /**
  * On click function that shows only the number of articles set in the environment variable
  */
  const viewLess = () => {
    setNumOfVisibleArticles(env.COLLAPSED_TABLE_ROWS);
  };

  /**
  * Fetches the operation items from the backend
  */
  const getOperationItemsList = useCallback(() => {
    setIsLoadInProgress(true)
    getOperationItems(operationId)
      .then(response => {
        setArticles(response.articles);
      })
      .catch(ex => {
        setError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [operationId]);

  // This hook runs once on component mount
  useEffect(() => {
    getOperationItemsList();
  }, [getOperationItemsList]);

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title={t("articles")} />
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("code")}</TableCell>
              <TableCell>{t("noOfItems")}</TableCell>
              {
                operationType === Operation.TYPE_PICKUP &&
                <TableCell>{t("returned")}</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {!!articles.length && articles.slice(0, numOfVisibleArticles).map((article, idx) =>
              <TableRow key={idx}>
                <TableCell>{article.name}</TableCell>
                <TableCell>{article.code}</TableCell>
                <TableCell>{article.items}</TableCell>
                {
                  operationType === Operation.TYPE_PICKUP &&
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <Slider
                        value={article.toBeReturned === 0 ? +article.items : +article.items - article.toBeReturned}
                        max={+article.items}
                        sx={{
                          color: article.toBeReturned === 0 ? "#33AD93" : "#fbcc5c",
                          width: "80%",
                          height: 10,
                          '& .MuiSlider-track': {
                            border: 'none',
                          },
                          '& .MuiSlider-thumb': {
                            height: 0,
                            width: 0,
                            backgroundColor: '#fff',
                            border: '0px solid',
                            display: "none"
                          },
                          '& .MuiSlider-rail': {
                            border: +article.items - article.toBeReturned === 0 ? "2px solid #f34943" : "none"
                          },
                        }}
                      />
                      {`${+article.items - article.toBeReturned}/${article.items}`}
                    </Box>
                  </TableCell>
                }
              </TableRow>)}
            {!articles.length && !isLoadInProgress && !error && <TableRow>
              <TableCell colSpan={4} align="center">{t("noArticlesFound")}</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {articles.length > env.COLLAPSED_TABLE_ROWS && <Grid container justifyContent="center" alignItems="center">
          {numOfVisibleArticles === articles.length
            ?
            <Button startIcon={<KeyboardArrowUpIcon />} sx={{ mt: 2 }} onClick={viewLess}>
              {t("viewLess")}
            </Button>
            :
            <Button startIcon={<KeyboardArrowDownIcon />} sx={{ mt: 2 }} onClick={viewMore}>
              {t("viewMore")}
            </Button>
          }
        </Grid>}
        {!!error && <Alert severity="error" sx={{ justifyContent: 'center' }}>{t("unableToLoadArticles")}</Alert>}
        {isLoadInProgress && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}><Spinner sx={{ width: 200 }} /></Box>}
      </CardContent>
    </EditableCard>
  </React.Fragment>
}

export default SectionItems;