import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { getMasterSettings } from "helper/backend";
import { toSimpleError } from "helper/util";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import { MasterSettings as MasterSettingsType } from "@type/backend";
import SectionStripe from "./Partial/SectionStripe";
import SectionOrgTerms from "./Partial/SectionOrgTermsAndCond";
import SectionClientTerms from "./Partial/SectionClientTermsAndCond";

const MasterSettings = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  // the billing record fetched from the backend
  const [settings, setSettings] = useState<MasterSettingsType | undefined>();
  // error encoutered while fetching the billing (if any)
  const [settingsError, setSettingsError] = useState<AppError | undefined>();
  // whether the loading of the billing is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the billing record from the backend
  */
  const refreshSettings = useCallback(() => {
    setIsLoadInProgress(true)
    getMasterSettings()
      .then(response => {
        setSettings(response.settings);
      })
      .catch(ex => {
        setSettingsError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [])

  // This hook runs once on component mount
  useEffect(() => {
    refreshSettings();
  }, [refreshSettings]);

  return (
    <>
      {isGranted(perms.view_master)
        &&
        <>
          {
            !!settings
            &&
            <Box>
              <PageTitleBar title={t("settings")} />
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionStripe settings={settings} refreshHandler={refreshSettings} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionOrgTerms settings={settings} refreshHandler={refreshSettings} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionClientTerms settings={settings} refreshHandler={refreshSettings} isLoadInProgress={isLoadInProgress} />
                </Grid>
              </GridContainer>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !settings && <Preloader container="content" />}
          {!!settingsError && <Error error={settingsError} title404={t("settingsNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_master) && <AccessDenied />}
    </>
  )
}

export default MasterSettings;