import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { AppError } from "@type";
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import TableNoBb from "component/styled/TableNoBb";
import Spinner from "component/common/Spinner";
import { route, routes } from "helper/route";
import User from "model/user";
import { toSimpleError } from "helper/util";
import { getClientAdmins } from "helper/backend";
import IconTableCell from "component/common/IconCell";
import Client from "model/client";
import { useTranslation } from "react-i18next";

type Props = {
  clientId: number,
};

const SectionUsers = ({ clientId }: Props) => {

  const { t } = useTranslation();

  // the client users record fetched from the backend
  const [users, setUsers] = useState([] as User[]);
  // whether the loading of the client users is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // error encoutered while fetching the client users (if any)
  const [error, setError] = useState<AppError>();

  /**
  * Fetches the client users from the backend
  */
  const getClientUsersList = useCallback(() => {
    setIsLoadInProgress(true)
    getClientAdmins(clientId)
      .then(response => {
        setUsers(response.users);
      })
      .catch(ex => {
        setError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [clientId]);

  // This hook runs once on component mount
  useEffect(() => {
    getClientUsersList();
  }, [getClientUsersList]);

  const addUserLink = () => route(routes.new_client_user, clientId);
  const viewUserLink = (id: number) => route(routes.view_user, id);
  const limitNumberUsers = () => users.length >= Client.CLIENT_USERS_LIMIT;

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title={t("users")}>
          <Button variant="contained" color="primary" component={Link} href={addUserLink()} startIcon={<PlaylistAddIcon />} disabled={limitNumberUsers()}>{t("addNewUser")}</Button>
        </CardTitleBar>
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("email")}</TableCell>
              <TableCell>{t("role")}</TableCell>
              <TableCell>{t("active")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!users.length && users.map((user, idx) =>
              <TableRow key={idx}>
                <TableCell><Link href={viewUserLink(user.id!)}>{user.fullName}</Link></TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.accessRoleName}</TableCell>
                <IconTableCell>
                  {user.isActive ? <TaskAltIcon /> : <UnpublishedIcon />}
                </IconTableCell>
              </TableRow>)}
            {!users.length && !isLoadInProgress && !error && <TableRow>
              <TableCell colSpan={4} align="center">{t("noUsersFound")}</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {!!error && <Alert severity="error" sx={{ justifyContent: 'center' }}>{t("unableToLoadUsers")}</Alert>}
        {isLoadInProgress && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}><Spinner sx={{ width: 200 }} /></Box>}
      </CardContent>
    </EditableCard>
  </React.Fragment>
}

export default SectionUsers;