import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { perms, useAccess } from 'context/access';
import PageTitleBar from 'component/common/PageTitleBar';
import Preloader from "component/common/Preloader";
import { useParams } from 'react-router-dom';
import { routes, route } from 'helper/route';
import { getClient } from 'helper/backend';
import { toSimpleError } from 'helper/util';
import { AppError } from '@type';
import AccessDenied from 'page/Error/AccessDenied';
import Error from 'page/Error';
import Client from 'model/client';
import FormCreateClientUser from '../Partial/Form/CreateClientUser';
import { useTranslation } from 'react-i18next';

const NewClientUser = () => {

  const { allAreGranted, notAllAreGranted } = useAccess();
  const { id } = useParams();
  const clientId = parseInt(id!)
  const { t } = useTranslation();

  // the client record fetched from the backend
  const [client, setClient] = useState<Client>()
  // error encoutered while fetching the client (if any)
  const [clientError, setClientError] = useState<AppError>();
  // whether the loading of the client is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);

  /**
  * Fetches from the backend the client this user is allowed to see
  */
  const fetchClient = useCallback(() => {
    setIsLoadInProgress(true)
    getClient(clientId)
      .then(response => {
        setClient(response.client);
      })
      .catch(ex => {
        setClientError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [clientId]);

  // This hook runs on first component mount to save the client in the state
  useEffect(() => {
    fetchClient()
  }, [fetchClient]);

  return <React.Fragment>
    {allAreGranted([perms.create_users, perms.edit_clients]) && <>
      {!!client && <Box>
        <PageTitleBar breadcrumbs={breadcrumbs(client, t)} />
        <Grid container>
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <FormCreateClientUser clientId={client.id!} organizationId={client.organizationId!} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>}
      {isLoadInProgress && !client && <Preloader container="parent" />}
      {!!clientError && <Error error={clientError} title404={t("clientNotFound")} />}
    </>}
    {notAllAreGranted([perms.create_users, perms.create_clients]) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (client: Client | undefined, t: Function) => [
  {
    text: t("clients"),
    url: routes.list_clients,
  },
  {
    text: client?.companyName || "",
    url: route(routes.view_client, client?.id),
  },
  {
    text: t("newUser"),
  }
];

export default NewClientUser;
