import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import Preloader from 'component/common/Preloader';
import ConfirmDialog from "component/common/ConfirmDialog";
import { perms, useAccess } from "context/access";
import { deleteActivityLog, getActivityLog } from "helper/backend";
import { routes } from "helper/route";
import { showError, showSuccess, toSimpleError } from "helper/util";
import { useNavigate, useParams } from "react-router-dom";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import ActivityLog from "model/activityLog";
import SectionInfo from "./Partial/SectionInfo";
import SectionUser from "./Partial/SectionUser";
import SectionException from "./Partial/SectionException";
import SectionRequest from "./Partial/SectionRequest";
import SectionDetails from "./Partial/SectionDetails";
import { useAuth } from "context/auth";
import SectionDevice from "./Partial/SectionDevice";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { authUser } = useAuth();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the activity log record fetched from the backend
  const [activityLog, setActivityLog] = useState<ActivityLog | undefined>()
  // error encoutered while fetching the activity log (if any)
  const [activityLogError, setActivityLogerror] = useState<AppError | undefined>();
  // whether the loading of the activity log is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the activity log is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the activity log record from the backend
  */
  const refreshActivityLog = useCallback(() => {
    setIsLoadInProgress(true)
    getActivityLog(recordId)
      .then(response => {
        setActivityLog(response.activityLog);
      })
      .catch(ex => {
        setActivityLogerror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  /**
   * Deletes the activity log record in the backend
   */
  const removeActivityLog = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteActivityLog(recordId)
      .then(_response => {
        showSuccess(t("activityLogHasBeenDeleted"));
        return navigate(routes.list_activity_logs);
      })
      .catch(_ex => {
        showError(t("unableToDeleteActivityLog"));
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshActivityLog();
  }, [refreshActivityLog]);

  return (
    <>
      {isGranted(perms.view_activity_logs)
        &&
        <>
          {
            !!activityLog
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(activityLog, t)}>
                {isGranted(perms.delete_activity_logs) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteActivityLog")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_activity_logs} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo activityLog={activityLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionUser activityLog={activityLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionDevice activityLog={activityLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionDetails activityLog={activityLog} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionRequest activityLog={activityLog} />
                </Grid>
                {
                  authUser.isMasterAdmin() &&
                  <Grid xs={12} md={6}>
                    <SectionException activityLog={activityLog} />
                  </Grid>
                }
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton="Delete"
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeActivityLog();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteActivityLogConfirmation")}</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !activityLog && <Preloader container="content" />}
          {!!activityLogError && <Error error={activityLogError} title404={t("activityLogNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_activity_logs) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (activityLog: ActivityLog, t: Function) => [{
  text: t("activityLogs"),
  url: routes.list_activity_logs,
}, {
  text: activityLog.message!,
}];

export default Single;