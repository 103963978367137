import React, { FocusEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { getTimezoneOptions } from 'helper/date';
import { useTranslation } from 'react-i18next';

type Props = {
  formik: any
};

const FormCreateDriver = ({ formik }: Props) => {

  const { setStatus, values, handleChange, errors, setFieldValue } = formik;
  const { t } = useTranslation();

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = (fieldName?: string) => {
    const formErrors = errors;
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Returns the selected timezone as a select option
  */
  const getSelectedTimezoneOption = () => getTimezoneOptions().find(option => option.value === values.timezone) || null;

  return <>
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="firstName" label={t("firstName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.firstName} error={!!errors.firstName} helperText={errors.firstName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField name="lastName" label={t("lastName")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.lastName} error={!!errors.lastName} helperText={errors.lastName} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField type="email" name="email" label={t("emailAddress")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.email} error={!!errors.email} helperText={errors.email} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          onChange={(_e, selectedOption) => {
            setFieldValue('timezone', selectedOption?.value);
          }}
          onFocus={_e => {
            onSelectFieldFocused('timezone');
          }}
          value={getSelectedTimezoneOption()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={getTimezoneOptions()}
          renderInput={(params: any) => <TextField {...params} label={t("timezone")} error={!!errors.timezone} helperText={errors.timezone} inputProps={{ ...params.inputProps, autoComplete: 'new-timezone' }} />}
        />
      </Grid>
    </Grid>
  </>
};

export default FormCreateDriver;