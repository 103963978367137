import {
  APPLY_DU_DT_PARAMS,
  PATCH_DU_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyDuDtParams = (params: DtParams) => ({
  type: APPLY_DU_DT_PARAMS,
  payload: { params },
});

export const patchDuDtParams = (params: DtParams) => ({
  type: PATCH_DU_DT_PARAMS,
  payload: { params },
});