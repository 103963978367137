import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, CardContent, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import EditableCard from "component/styled/EditableCard";
import Preloader from "component/common/Preloader";
import TableNoBb from "component/styled/TableNoBb";
import { useDebounce } from "hook/Debounce";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { DtParams } from "@type";
import { useAppDispatch, useAppSelector } from "hook/redux";
import { extractDtParamsFromUrl, insertDtParamsIntoUrl } from "helper/dt";
import { applyTagParams, patchTagParams } from "store/actions";
import { showError, toSimpleError } from "helper/util";
import { getTags } from "helper/backend";
import { TAG_NOT_FOUND } from "helper/error";
import Tag from "model/tag";
import { useTranslation } from "react-i18next";
import { formatTimestamp, formats } from "helper/date";

const List = () => {

  const dispatch = useAppDispatch();
  const { isGranted, isNotGranted } = useAccess()
  const { t } = useTranslation();

  // Tag fetched from the backend
  const [tagFound, setTag] = useState<Tag | undefined>();
  // Tag search params
  const searchParams = useAppSelector(store => store.Tag.SearchRequest);
  // Search input value
  const [searchValue, setSearchValue] = useState<string>(searchParams.search!)
  // whether the loading of the tag is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // Debounced searchParams
  const debouncedSearchParams = useDebounce(searchParams, 500)

  // This hook runs once on component mount
  useEffect(() => {
    /**
    * Search params may sometimes be passed in the url
    * so here we attempt to read any params from the url
    */
    const urlParams = extractDtParamsFromUrl();
    // 'urlParams' will be 'NULL' initially
    // then, after the url is parsed, it will be set to an object
    if (urlParams === null) {
      return;
    }

    // update the search params with the filters from url
    // we want to call this even if the url does not contain any filters (in which case 'urlParams' will be an empty object {})
    // because the component is waiting for this signal to begin fetching the data from the backend
    dispatch(patchTagParams(urlParams));
  }, [dispatch]);

  // This hook runs every time the search params change
  useEffect(() => {
    // We don't need to do extra calls if the input is empty
    if (searchValue.length === 0) {
      setTag(undefined);
      return;
    }
    // fetch the list of search rows from the server
    setIsLoadInProgress(true);
    getTags(searchParams)
      .then(response => {
        setTag(response.tag);
      })
      .catch(ex => {
        let errMessage = t("unableToFindTag");
        const err = toSimpleError(ex)
        switch (err.code) {
          case TAG_NOT_FOUND:
            setTag(undefined);
            break;
          default:
            showError(errMessage);
        }
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
    // having 'searchParams' as a dependency is not what we want here because it would trigger a fetch from the server
    // what we need is just to check its value but the fetch should only depend on 'debouncedSearchParams'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchParams]);

  // Saves the search value from store
  useEffect(() => {
    setSearchValue(searchParams.search! || "")
  }, [searchParams])

  /**
  * Saves new Search params to the redux store
  * @param params
  */
  const updateDtParams = useCallback((params: DtParams) => {
    // update the url with the new params
    // so we can easily link to this result set or safely refresh the page
    insertDtParamsIntoUrl(params);
    // update the store
    dispatch(applyTagParams(params));
  }, [dispatch]);

  // Handle search change
  const handleChange = (e: ChangeEvent<any>) => {
    updateDtParams({ search: e.target.value })
  }

  return (
    <>
      {isGranted(perms.view_tags) &&
        <>
          <PageTitleBar title={t("tagInfo")} />
          <GridContainer>
            <Grid xs={12} md={6}>
              <EditableCard>
                <CardContent>
                  <TextField name="tag" label={t("searchTag")} variant="outlined" onChange={handleChange} value={searchValue} sx={{ width: '100%', mb: 2 }} />
                  <Box position="relative">
                    {!!tagFound ?
                      <TableNoBb>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("epc")}</TableCell>
                            <TableCell className="preview-value">{tagFound?.epc}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("package")}</TableCell>
                            <TableCell className="preview-value">{tagFound?.packageCode}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("article")}</TableCell>
                            <TableCell className="preview-value">{tagFound?.articleName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("articleCode")}</TableCell>
                            <TableCell className="preview-value">{tagFound?.articleCode}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("location")}</TableCell>
                            <TableCell className="preview-value">{tagFound?.locationName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("active")}</TableCell>
                            <TableCell className="preview-value">{!!tagFound?.isActive ? <TaskAltIcon /> : <UnpublishedIcon />}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("created")}</TableCell>
                            <TableCell className="preview-value">{formatTimestamp(tagFound.createdTs, formats.DATETIME)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </TableNoBb>
                      :
                      searchValue.length > 0 &&
                      t("noTagFound")
                    }
                    {isLoadInProgress && <Preloader container="parent" />}
                  </Box>
                </CardContent>
              </EditableCard>
            </Grid>
          </GridContainer>

        </>
      }
      {isNotGranted(perms.view_tags) && <AccessDenied />}
    </>
  )
}

export default List;