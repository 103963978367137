import React, { useCallback, useEffect, useState } from "react";
import { Button, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import LockIcon from '@mui/icons-material/Lock';
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from "component/common/Preloader";
import { routes } from "helper/route";
import { toSimpleError } from "helper/util";
import User from "model/user";
import { AppError } from "@type";
import { getAuthUser } from "helper/backend";
import Error from 'page/Error';
import SectionInfo from "./Partial/SectionInfo";
import SectionAccess from "./Partial/SectionAccess";
import { useTranslation } from "react-i18next";
import SectionMasterCode from "./Partial/SectionMasterCode";
import SectionApiUrl from "./Partial/SectionApiUrl";
import { perms, useAccess } from "context/access";
import { useAuth } from "context/auth";

const Single = () => {

  const { t } = useTranslation()
  const { isGranted } = useAccess();
  const { authUser } = useAuth();

  // the user record fetched from the backend
  const [user, setUser] = useState<User | undefined>()
  // error encoutered while fetching the user (if any)
  const [userError, setUserError] = useState<AppError | undefined>()
  // whether the loading of the user is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the user record from the backend
  */
  const refreshUser = useCallback(() => {
    setIsLoadInProgress(true)
    getAuthUser()
      .then(response => {
        setUser(response.user);
      })
      .catch(ex => {
        setUserError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [])

  // This hook runs once on component mount
  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  return (
    <>
      <PageTitleBar breadcrumbs={breadcrumbs(t)}>
        <Button variant="contained" color="primary" component={Link} href={routes.change_password} startIcon={<LockIcon />}>{t("changePassword")}</Button>
      </PageTitleBar>
      <GridContainer>
        <Grid xs={12} md={6}>
          <SectionInfo user={user!} refreshHandler={refreshUser} isLoadInProgress={isLoadInProgress} />
        </Grid>
        {
          !authUser.isNonSsdClient() &&
          <Grid xs={12} md={6}>
            <SectionAccess user={user!} refreshHandler={refreshUser} />
          </Grid>
        }
        {
          isGranted(perms.view_master_code) &&
          <Grid xs={12} md={6}>
            <SectionMasterCode />
          </Grid>
        }
        {
          isGranted(perms.view_api_url) &&
          <Grid xs={12} md={6}>
            <SectionApiUrl />
          </Grid>
        }
      </GridContainer>
      {/* Show the prealoder only on the first fetch */}
      {isLoadInProgress && !user && <Preloader container="content" />}
      {!!userError && <Error error={userError} title404={t("accountNotFound")} />}
    </>
  )
}

const breadcrumbs = (t: Function) => [{
  text: t("myAccount"),
  url: routes.my_account,
}];

export default Single;