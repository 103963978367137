import Preloader from 'component/common/Preloader';
import { initTimezone } from 'helper/date';
import { NetworkErrorException } from 'helper/error';
import { useAppDispatch, useAppSelector } from 'hook/redux';
import User from 'model/user';
import ErrorPage from 'page/Error';
import React, { useCallback, useEffect } from 'react';
import { getAuthUser } from 'store/actions';

type AuthContextValue = {
  authUser: User;
  isLoggedIn: () => boolean;
  returnUrl: string;
  refreshAuthUser: () => void;
};

const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue);

const AuthProvider = (props: any) => {

  const dispatch = useAppDispatch();

  // warning
  // useSelector() will cause a re-render whenever the result of its callback function changes
  // for example: const { user, isAuthenticated } = useSelector(state => state.Auth.Login);
  // you might expect to get a re-render only when 'user' or 'isAuthenticated' change
  // in fact you will get a re-render when anything inside 'state.Auth.Login' changes, not just 'user' and 'isAuthenticated'
  // so if that object contains other properties, unwanted re-renders will happen when they change
  // that is why, instead of destructuring, we target specific state properties one by one
  // https://9oelm.github.io/2020-09-13--How-to-make-useSelector-not-a-disaster/#function-component
  const user = useAppSelector(state => state.Auth.Login.user);
  const isAuthenticated = useAppSelector(state => state.Auth.Login.isAuthenticated);
  const userError = useAppSelector(state => state.Auth.Login.userError);
  const returnUrl = useAppSelector(state => state.Auth.Login.returnUrl);

  const isLoggedIn = () => !!user;

  // so far the user is a plain object that matches the User type
  // we need it to be an actual instance of User so we can call methods on it
  const authUser = !!user ? User.createFrom(user) : null;

  if (!!authUser && authUser.timezone) {
    initTimezone(authUser.timezone);
  }

  const refreshAuthUser = useCallback(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated === undefined) {
      refreshAuthUser();
    }
  }, [isAuthenticated, refreshAuthUser]);

  // wait until we decide whether the user is authenticated or not
  if (isAuthenticated !== undefined) {
    if (userError instanceof NetworkErrorException) {
      return <ErrorPage error={userError} />
    }
    return <AuthContext.Provider value={{ authUser, isLoggedIn, returnUrl, refreshAuthUser }} {...props} />
  }
  // until then show a preloader
  return <Preloader />
}

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;