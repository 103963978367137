import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, CardContent, Link, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CardTitleBar from "component/common/CardTitleBar";
import EditableCard from "component/styled/EditableCard";
import { toSimpleError } from "helper/util";
import { getReaders } from "helper/backend";
import { AppError } from "@type";
import TableNoBb from "component/styled/TableNoBb";
import Spinner from "component/common/Spinner";
import env from "env";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { route, routes } from "helper/route";
import { perms, useAccess } from "context/access";
import { useTranslation } from "react-i18next";
import Reader from "model/reader";
import { useParams } from "react-router-dom";

type Props = {
  deviceId: number,
  standalone: boolean
};

const SectionReaders = ({ deviceId, standalone }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();
  const { duId } = useParams();
  const distributionUnitId = parseInt(duId!)

  // the readers record fetched from the backend
  const [readers, setReaders] = useState([] as Reader[]);
  // whether the loading of the readers is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // error encoutered while fetching the readers (if any)
  const [error, setError] = useState<AppError>();
  // the number of seen devices in the Users Table
  const [numOfVisibleReaders, setNumOfVisibleReaders] = useState(env.COLLAPSED_TABLE_ROWS);

  /**
  * On click function that shows all the users
  */
  const viewMore = () => {
    setNumOfVisibleReaders(readers.length);
  };

  /**
  * On click function that shows only the number of users set in the environment variable
  */
  const viewLess = () => {
    setNumOfVisibleReaders(env.COLLAPSED_TABLE_ROWS);
  };

  /**
  * Fetches the organization users from the backend
  */
  const getReadersList = useCallback(() => {
    setIsLoadInProgress(true)
    getReaders(deviceId)
      .then(response => {
        setReaders(response.readers);
      })
      .catch(ex => {
        setError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [deviceId]);

  // This hook runs once on component mount
  useEffect(() => {
    getReadersList();
  }, [getReadersList]);

  const viewDeviceLink = (deviceId: number, id: number) => standalone ? route(routes.view_device_reader, [deviceId, id]) : route(routes.view_du_device_reader, [distributionUnitId, id]);

  return <React.Fragment>
    <EditableCard>
      <CardContent>
        <CardTitleBar title={t("readers")}>
          {
            isGranted(perms.create_devices) &&
            <Button variant="contained" color="primary" component={Link} href={standalone ? `/device/${deviceId}/reader` : `/distribution-unit/${distributionUnitId}/device/${deviceId}/reader`} startIcon={<PlaylistAddIcon />}>{t("addNewReader")}</Button>
          }
        </CardTitleBar>
        <TableNoBb>
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("vendor")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!readers.length && readers.slice(0, numOfVisibleReaders).map((reader, idx) =>
              <TableRow key={idx}>
                <TableCell><Link href={viewDeviceLink(deviceId, reader.id!)}>{reader.name}</Link></TableCell>
                <TableCell>{Reader.getVendorName(reader.vendor)}</TableCell>
              </TableRow>)}
            {!readers.length && !isLoadInProgress && !error && <TableRow>
              <TableCell colSpan={4} align="center">{t("noReadersFound")}</TableCell>
            </TableRow>}
          </TableBody>
        </TableNoBb>
        {readers.length > env.COLLAPSED_TABLE_ROWS && <Grid container justifyContent="center" alignItems="center">
          {numOfVisibleReaders === readers.length
            ?
            <Button startIcon={<KeyboardArrowUpIcon />} sx={{ mt: 2 }} onClick={viewLess}>
              {t("viewLess")}
            </Button>
            :
            <Button startIcon={<KeyboardArrowDownIcon />} sx={{ mt: 2 }} onClick={viewMore}>
              {t("viewMore")}
            </Button>
          }
        </Grid>}
        {!!error && <Alert severity="error" sx={{ justifyContent: 'center' }}>{t("unableToLoadReaders")}</Alert>}
        {isLoadInProgress && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}><Spinner sx={{ width: 200 }} /></Box>}
      </CardContent>
    </EditableCard>
  </React.Fragment>
};

export default SectionReaders;