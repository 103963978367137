import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent, Slider, Typography } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";
import { getGeographicDistribution } from "helper/backend";
import { showError } from "helper/util";
import Preloader from "component/common/Preloader";
import countries from 'i18n-iso-countries'

// Support english languages.
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const colors = ["#02A8B5", "#FA5C7C", "#33AD93", "#fbcc5c"];

const GeoDistribution = () => {

  const { t } = useTranslation();

  const [geoDistribution, setGeoDistribution] = useState<(string | number | undefined)[][]>();
  const [organizations, setOrganizations] = useState<any>();
  const [totalOrganizations, setTotalOrganizations] = useState<number>(0);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchBusinessModels = useCallback(() => {
    setIsLoadInProgress(true)
    getGeographicDistribution()
      .then(response => {
        let rawOrganizations = [...response.organizations]
        let models: any = response.organizations.splice(0, 4).map((el, idx) => { return [countries.getName(el.billingCountry, "en", { select: "official" }), idx, `<div>No. of organizations: ${el.orgCount}</div>`] })
        models.unshift(["Country", "", { type: 'string', role: "tooltip", p: { html: true } }])
        setGeoDistribution(models);
        setTotalOrganizations(response.total)
        setOrganizations(rawOrganizations.splice(0, 4))
      })
      .catch(_ex => {
        showError(t("unableToLoadGeographicDistribution"));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchBusinessModels();
  }, [fetchBusinessModels])

  return (
    <EditableCard sx={{ height: "100%" }}>
      <CardContent>
        <CardTitleBar title={t("geographicDistribution")} />
        <Box sx={{ display: "flex", height: "280px", gap: "30px", width: "100%", justifyContent: "space-between" }}>
          {
            !!geoDistribution?.length &&
            <>
              <Box sx={{ width: "70%", display: { xs: "none", md: "flex" } }}>
                <Chart
                  chartType="GeoChart"
                  data={geoDistribution}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  width="100%"
                  height={"280px"}
                  onLoad={() => { setIsLoadInProgress(false) }}
                  options={
                    {
                      colorAxis: {
                        colors: colors,
                        values: [0, 1, 2, 3]
                      },
                      legend: "none",
                      region: "150",
                      tooltip: { isHtml: true }
                    }
                  }
                />
              </Box>
              <Box sx={{ fontSize: "16px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: { xs: "100%", md: "30%" }, gap: "10px", zIndex: 1000 }}>
                {
                  organizations?.map((el: any, idx: number) => {
                    return (
                      <Box key={idx} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%", flexDirection: "column" }}>
                          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            {`${el.orgCount}`}
                            <Typography sx={{ fontSize: "14px", color: "#a3a6a7" }}>
                              {countries.getName(el.billingCountry, "en", { select: "official" })}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: "20px", justifyContent: "center" }}>
                            <Slider
                              value={(el.orgCount / totalOrganizations) * 100}
                              max={100}
                              sx={{
                                color: colors[idx],
                                height: 5,
                                '& .MuiSlider-track': {
                                  border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                  height: 0,
                                  width: 0,
                                  backgroundColor: '#fff',
                                  border: '0px solid',
                                  display: "none"
                                },
                              }}
                            />
                            <Box sx={{ fontSize: "14px", color: "#a3a6a7" }}>
                              {((el.orgCount / totalOrganizations) * 100).toFixed(2)}%
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </>
          }
        </Box>
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default GeoDistribution;