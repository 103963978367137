import { AppError, SelectOption } from '@type';
import Operation from 'model/operation';
import moment from 'moment';
import { enqueueSnackbar, VariantType } from 'notistack';

export const isObject = (obj: any) => typeof obj === 'object' && obj !== null;

export const valueIsEmpty = (value: any, treatSpecialAsEmpty: boolean = false) => {
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && !!value) {
    return !Object.keys(value).length;
  }

  if (treatSpecialAsEmpty && Number(value) === Number.MIN_SAFE_INTEGER) {
    return true;
  }

  // allow 0 and FALSE
  return value !== 0 && value !== false && !value;
}

export const mapNonEmptyProps = (obj: any, treatSpecialAsEmpty: boolean = false) => Object
  .entries(obj)
  .reduce((a, [k, v]) => (!valueIsEmpty(v, treatSpecialAsEmpty) ? (a[k] = v, a) : a), {} as { [k: string]: any });

export const hasNonEmptyProps = (obj: any, treatSpecialAsEmpty: boolean = false) => Object.keys(mapNonEmptyProps(obj, treatSpecialAsEmpty)).length > 0;

export const objectIsEmpty = (obj: any, treatSpecialAsEmpty: boolean = false) => !obj || !hasNonEmptyProps(obj, treatSpecialAsEmpty);

export const pick = (obj: any, ...keys: string[]) => Object.fromEntries(
  keys
    .filter(key => key in obj)
    .map(key => [key, obj[key]])
);

export const omit = (obj: { [k: string]: any }, ...keys: string[]) => Object.fromEntries(
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key))
);

export const toSimpleError = (error: Error | undefined): AppError => pick(error, 'className', 'message', 'code', 'fields') as AppError;

export const isErrType = (error: AppError | undefined, cls: any) => {
  if (!error) {
    return false;
  }

  //TBD CHECK
  return error.className === new cls().className;
}

export const getYesNoOptions = (allLabel?: string): SelectOption[] => {
  const options = [];
  if (!!allLabel) {
    options.push({ label: allLabel, value: Number.MIN_SAFE_INTEGER });
  }
  options.push({ label: 'Yes', value: 1 });
  options.push({ label: 'No', value: 0 });
  return options;
}

export const nullsToEmptyStrings = (obj: any) => {
  if (!obj) {
    return;
  }
  if (Array.isArray(obj)) {
    return obj.map(v => v === null ? '' : v);
  } else {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, v === null ? '' : v]));
  }
}

export const extractParamsFromUrl = () => {
  // pull the query string data into an object
  let query: { [k: string]: any } = Object.fromEntries(new URLSearchParams(window.location.search));

  return query;
}

export const showSuccess = (message: string, options?: any) => {
  showSnackbar(message, 'success', options);
}

export const showWarning = (message: string, options?: any) => {
  showSnackbar(message, 'warning', options);
}

export const showError = (message: string, options?: any) => {
  showSnackbar(message, 'error', options);
}

const showSnackbar = (message: string, variant: VariantType, options?: any) => {
  enqueueSnackbar(message, { variant, autoHideDuration: 6000, className: 'anchorOriginTopCenter', ...options });
}

export const getTableRowIndex = (pageNumber: number, pageSize: number, rowIndex: number) => {
  const rowNumber = (pageNumber * pageSize) + (rowIndex + 1);
  return rowNumber.toString();
}

export const showDaysText = (numberOfDays: number) => {
  if (numberOfDays === 1) {
    return "day"
  } else {
    return "days"
  }
}

export const cutout = (value: string, keepLeft: number, keepRight: number) => {
  if (value.length < (keepLeft + keepRight)) {
    return value;
  }
  return value.slice(0, keepLeft) + '*****' + value.slice(value.length - keepRight);
}

export const ltrim = (s: string, c: string) => {
  c = c.replace(/[-/^$*+?.()|[]{}]/g, '$&');
  return s.replace(new RegExp(
    "^[" + c + "]+", "g"
  ), "");
}

export const rtrim = (s: string, c: string) => {
  c = c.replace(/[-/^$*+?.()|[]{}]/g, '$&');
  return s.replace(new RegExp(
    "[" + c + "]+$", "g"
  ), "");
}

export const buildUrl = (base: string, fragment: string) => rtrim(base, '/') + (fragment ? '/' + ltrim(fragment, '/') : '')

// create operation Number
export const buildOperationNumber = (id: number, type: number, date: number) => {
  const dateObj = moment.unix(date)

  let operationLetter = "";
  if (type === Operation.TYPE_COLLECTION) {
    operationLetter = "C"
  } else if (type === Operation.TYPE_EXPEDITION) {
    operationLetter = "D"
  } else if (type === Operation.TYPE_HANDOVER) {
    operationLetter = "H"
  } else if (type === Operation.TYPE_PICKUP) {
    operationLetter = "P"
  } else if (type === Operation.TYPE_RECEPTION) {
    operationLetter = "R"
  } else if (type === Operation.TYPE_SUPPLY) {
    operationLetter = "S"
  }

  return `${operationLetter}${id}-${dateObj.month() + 1 < 10 ? `0${dateObj.month() + 1}` : dateObj.month() + 1}/${dateObj.year()}`
}