import React from "react";
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CardTitleBar from 'component/common/CardTitleBar';
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import Organization from "model/organization";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";

type Props = {
  organization: Organization;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ organization, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("generalInfo")} {...(organization.isActive ? null : { chip: 'Inactive', bgcolor: 'warning.light' })}>
      {isGranted(perms.edit_organizations) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{organization.companyName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("vatNumber")}</TableCell>
          <TableCell className="preview-value">{organization.vatNo || "--"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("contactFirstName")}</TableCell>
          <TableCell className="preview-value">{organization.contactFirstName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("contactLastName")}</TableCell>
          <TableCell className="preview-value">{organization.contactLastName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("email")}</TableCell>
          <TableCell className="preview-value">{organization.contactEmail}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("phone")}</TableCell>
          <TableCell className="preview-value">{organization.contactPhone}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionInfoView;