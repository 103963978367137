import React from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import { routes } from "helper/route";
import SectionPassword from "./Partial/SectionPassword";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {

  const { t } = useTranslation();

  return (
    <>
      <PageTitleBar breadcrumbs={breadcrumbs(t)} />
      <GridContainer>
        <Grid xs={12} md={6}>
          <SectionPassword />
        </Grid>
      </GridContainer>
    </>
  )
}

const breadcrumbs = (t: Function) => [{
  text: t("myAccount"),
  url: routes.my_account,
},
{
  text: t("changePassword"),
}];

export default ChangePassword;